import { useContext } from 'react';
import { IconButton } from 'src/components/Buttons';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { OpenLinkIcon } from 'src/svgs';
import { MarketplacePaymentOptions } from 'src/tables/MarketplacePaymentsTable/Cells/MarketplacePaymentOptionsAction';
import { ContentId } from 'src/utils/constants/contentId';
import { getMarketplacePaymentDetailsModalConfigWithDeepLink } from 'src/utils/marketplacePaymentUtils';
import { MarketplaceSalePayment } from 'src/WebApiController';

interface MarketplacePaymentsActionsCellProps {
  marketplaceSalePayment: MarketplaceSalePayment;
}

export const MarketplacePaymentsActions = ({
  marketplaceSalePayment,
}: MarketplacePaymentsActionsCellProps) => {
  const { setModal } = useContext(ModalContext);
  return (
    <>
      <MarketplacePaymentOptions
        marketplaceSalePayment={marketplaceSalePayment}
      />

      <IconButton
        onClick={() =>
          setModal(
            getMarketplacePaymentDetailsModalConfigWithDeepLink(
              marketplaceSalePayment.id
            )
          )
        }
        icon={
          <OpenLinkIcon
            size={vars.iconSize.m}
            fill={vars.color.textBrand}
            withHoverEffect
          />
        }
        titleContentId={ContentId.ViewDetails}
      />
    </>
  );
};
