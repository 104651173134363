import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ListingClient } from 'src/WebApiController';

export const useListingCanBeWasted = (listingId?: number | null) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null && listingId != null;

  return useQuery({
    queryKey: [
      'getListingsCanBeWasted',
      activeAccountWebClientConfig.activeAccountId,
      listingId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new ListingClient(
        activeAccountWebClientConfig
      ).getListingsCanBeWasted(listingId!);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getListingsCanBeWasted', error, {
          listingId,
        });
      },
    },
  });
};
