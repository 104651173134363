import React, { useCallback } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { useUnwasteSale } from 'src/hooks/api/useUnwasteSale';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { IconsFill, ResetIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

interface UnwasteTicketsButtonProps {
  saleId: number | undefined | null;
}

export const UnwasteTicketsButton = ({ saleId }: UnwasteTicketsButtonProps) => {
  const isMobile = useMatchMedia('mobile');
  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();
  const { mutateAsync, isPending } = useUnwasteSale();
  const { posEntity: sale, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();

  const onReload = useCallback(() => {
    if (sale) {
      setActivePosEntity(sale.id, sale.idOnMkp, true);
    }
  }, [sale, setActivePosEntity]);

  if (!saleId) {
    return null;
  }

  return (
    <>
      <ButtonWithIcon
        variant={isMobile ? 'text' : 'outline'}
        icon={
          <ResetIcon
            withHoverEffect
            fill={IconsFill.textNegative}
            size={vars.iconSize.m}
          />
        }
        textContentId={ContentId.UnwasteTickets}
        onClick={launchDialog}
      />
      <ConfirmDialog
        headerText={<Content id={ContentId.UnwasteTickets} />}
        bodyText={<Content id={ContentId.UnwasteTicketsConfirmation} />}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        {...dialogProps}
        isLoading={isPending}
        onOkay={async () => {
          await mutateAsync({ saleId });
          closeDialog();
          onReload();
        }}
      />
    </>
  );
};
