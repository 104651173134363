import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SearchClient, SearchResult } from 'src/WebApiController';

import { container } from './EntitySearchConfigsToolbar.css';

export type EntitySearchConfigsToolbarProps<T extends SearchResult> = {
  disabled?: boolean;
  setDisabled?: (disabled: boolean) => void;
  activeSearchConfig?: T;
  setActiveSearchConfig: (config: T) => void;
  getSearchConfigs: (
    client: SearchClient,
    includeAllUsers: boolean
  ) => Promise<T[]>;
  getSearchResult: (client: SearchClient, id: string) => Promise<T>;
};

export const EntitySearchConfigsToolbar = <T extends SearchResult>({
  disabled,
  setDisabled,
  activeSearchConfig,
  setActiveSearchConfig,
  getSearchConfigs,
  getSearchResult,
}: EntitySearchConfigsToolbarProps<T>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;

  const userSearchConfigsQuery = useQuery({
    queryKey: [
      'SearchClient.getSearchConfigsForUser',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const client = new SearchClient(activeAccountWebClientConfig);

      const r = await getSearchConfigs(client, false);
      if (r) {
        return r.reduce(
          (r, c) => {
            r[c.id!] = c;
            return r;
          },
          {} as Record<string, T>
        );
      }

      return {} as Record<string, T>;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getSearchConfigsForUser', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  const accountSearchConfigsQuery = useQuery({
    queryKey: [
      'SearchClient.getSearchConfigsForAccount',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const client = new SearchClient(activeAccountWebClientConfig);

      const r = await getSearchConfigs(client, true);
      if (r) {
        return r.reduce(
          (r, c) => {
            r[c.id!] = c;
            return r;
          },
          {} as Record<string, T>
        );
      }

      return {} as Record<string, T>;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getSearchConfigsForAccount', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  const onSearchConfigClick = useCallback(
    (id: string) => {
      setDisabled?.(true);
      tryInvokeApi(
        async () => {
          const client = new SearchClient(activeAccountWebClientConfig);

          const result = await getSearchResult(client, id);

          if (result) {
            setActiveSearchConfig(result);
          }
        },
        (error) => {
          showErrorDialog('SearchClient.getSearchResult', error, {
            trackErrorData: {
              searchConfigId: id,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      activeAccountWebClientConfig,
      getSearchResult,
      setActiveSearchConfig,
      setDisabled,
      showErrorDialog,
    ]
  );

  const otherConfigs = useMemo(() => {
    return Object.values(accountSearchConfigsQuery.data ?? {}).reduce(
      (r, c) => {
        // Only show if it's not already shown as this user's search result

        if (!userSearchConfigsQuery.data?.[c.id!]) {
          r[c.id!] = c.name!;
        }

        return r;
      },
      {} as Record<string, string>
    );
  }, [accountSearchConfigsQuery.data, userSearchConfigsQuery.data]);

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <Stack gap="m" alignItems="center">
        <ToolbarButton disabled style={{ margin: `${vars.spacing['sm']} 0` }}>
          <Content id={ContentId.Saved} />:
        </ToolbarButton>
        <ScrollableToolbar>
          <Stack gap="m" direction="row">
            {Object.values(userSearchConfigsQuery.data ?? {}).map((item, i) => (
              <ToolbarButton
                key={i}
                isSelected={activeSearchConfig?.id === item.id}
                disabled={disabled}
                onClick={() => onSearchConfigClick(item.id!)}
              >
                {item.name}
              </ToolbarButton>
            ))}
          </Stack>
          {Boolean(Object.entries(otherConfigs).length) && (
            <ToolbarButton
              style={{ padding: '2' }}
              isSelected={Boolean(
                activeSearchConfig?.id
                  ? otherConfigs[activeSearchConfig.id]
                    ? activeSearchConfig.id // we only show selection if the active search config is one of the available items
                    : undefined
                  : undefined
              )}
            >
              <PosSelect
                variant="textPlain"
                shape="pill"
                size="unset"
                placeholderText={ContentId.Other}
                value={
                  activeSearchConfig?.id
                    ? otherConfigs[activeSearchConfig.id]
                      ? activeSearchConfig.id // we only show selection if the active search config is one of the available items
                      : undefined
                    : undefined
                }
                onChange={(id) => onSearchConfigClick(id)}
                valueOptionsContent={otherConfigs}
              />
            </ToolbarButton>
          )}
        </ScrollableToolbar>
      </Stack>
    </Stack>
  );
};
