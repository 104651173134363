import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useMergedTagFilters } from 'src/components/MainFilterBar/Tags/useMergedTagFilters';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Slider } from 'src/core/ui/Slider';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKET_PLACE_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  PARENT_CATEGORY_TO_CID,
  SALES_STATUS_TO_CID,
  TICKET_TYPE_TO_CID,
  TOP_LEVEL_CATEGORY_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  InhandDateRangePresetNames,
  SaleDateRangeRelativePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import { formatNumber } from 'src/utils/numberFormatter';
import { ReportTypes } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  EventTimeFrameFilter,
  Feature,
  Marketplace,
  Permission,
  SaleQuery,
  TopLevelCategory,
} from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import { useLocationFilter } from '../Filters/LocationFilter';
import { RowFilter } from '../Filters/RowFilter';
import { SectionFilter } from '../Filters/SectionFilter';
import {
  CurrencyFilterMultiSelector,
  CurrencyFilterSelector,
} from '../Selectors/CurrencyFilterSelector';
import { SellerAccountEmployeeSelector } from '../Selectors/SellerAccountEmployeeSelector';
import { useEventStateFilters } from './useEventStateFilters';
import { usePurchaseInfoFilters } from './usePurchaseInfoFilters';

const MAX_NUMBER_OF_TICKETS_HELD = 100_000;

export const saleMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
  'saleStatuses',
];

export function useSaleFilters(isReportsPage?: boolean) {
  const { loginContext } = useAppContext();

  const hasFilterSaleByVendorOrderIdFeature = useUserHasFeature(
    Feature.FilterSaleByVendorOrderId
  );
  const hasFilterSalesByOriginatedByFeature = useUserHasFeature(
    Feature.FilterSalesByOriginatedBy
  );
  const hasFilterByCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByCurrencyCodeMulti
  );
  const hasFilterByCurrencyCodeFeature =
    useUserHasFeature(Feature.FilterByCurrencyCode) &&
    !hasFilterByCurrencyCodeMultiFeature;
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );
  const hasAutoFulfillSetting = useUserHasFeature(Feature.AutoFulfillSetting);

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<SaleQuery>();

  const purchaseInfoFiltersBase = usePurchaseInfoFilters(
    tempQuery,
    setTempQuery,
    isReportsPage ? ReportTypes.Sale : undefined
  );

  const tagsFilter = useMergedTagFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    entityType: ActionOutboxEntityType.Sale,
  });

  const eventStateFilters = useEventStateFilters({
    query: tempQuery,
    setQuery: setTempQuery,
  });

  const purchaseInfoFilters = useMemo(() => {
    if (!hasFilterSaleByVendorOrderIdFeature) {
      return purchaseInfoFiltersBase;
    }

    return {
      ...purchaseInfoFiltersBase,
      items: [
        ...purchaseInfoFiltersBase.items,
        {
          filterId: 'purchaseVendorOrderId' as FilterToolbarItemId,
          labelContentId: ContentId.ExternalPurchaseId,
          filterQueryKeys: ['purchaseVendorOrderId'] as FilterToolbarItemId[],
          filterItem: (
            <PosTextField
              style={{ width: '100%' }}
              value={tempQuery.purchaseVendorOrderId ?? ''}
              type="text"
              onChange={(e) => {
                const newValue = e.target.value?.length
                  ? e.target.value.trim()
                  : null;
                if (tempQuery.purchaseVendorOrderId !== newValue) {
                  setTempQuery({
                    ...tempQuery,
                    purchaseVendorOrderId: newValue,
                  });
                }
              }}
              maxLength={200}
            />
          ),
        },
      ],
    };
  }, [
    hasFilterSaleByVendorOrderIdFeature,
    purchaseInfoFiltersBase,
    setTempQuery,
    tempQuery,
  ]);

  const notesFilters = useInternalNotesFilter({
    entityType: ActionOutboxEntityType.Sale,
  });

  const locationFilters = useLocationFilter();

  const seatingFilters = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Seating,
      type: 'row',
      items: [
        {
          filterId: 'sectionContains',
          labelContentId: ContentId.Section,
          filterQueryKeys: [
            'sectionEquals',
            'sectionContains',
            'sectionNotContains',
          ],
          filterItem: (
            <SectionFilter query={tempQuery} setQuery={setTempQuery} />
          ),
        },
        {
          filterId: 'rowContains',
          labelContentId: ContentId.Row,
          filterQueryKeys: ['rowEquals', 'rowContains', 'rowNotContains'],
          filterItem: <RowFilter query={tempQuery} setQuery={setTempQuery} />,
        },
      ],
    }),
    [setTempQuery, tempQuery]
  );

  return {
    filters: [
      {
        titleContentId: ContentId.Events,
        items: [
          {
            filterId: 'eventDates' as FilterToolbarItemId,
            labelContentId: ContentId.EventDate,
            filterQueryKeys: [
              'eventDates',
              'eventTimeFrameFilter',
            ] as FilterToolbarItemId[],
            filterItem: (
              <EventDateSelector
                useRelativePresets={
                  isReportsPage || hasDynamicDateTimeFiltersFeature
                }
                eventTimeFrame={tempQuery.eventTimeFrameFilter}
                value={tempQuery.eventDates}
                onChange={(eventTimeFrameFilter, dateRange) => {
                  if (
                    eventTimeFrameFilter !== tempQuery.eventTimeFrameFilter ||
                    !isEqual(dateRange, tempQuery.eventDates)
                  ) {
                    let isSortDescending = tempQuery.isSortDescending;
                    if (eventTimeFrameFilter !== EventTimeFrameFilter.Future) {
                      // When sorting by past or by all - we do not want to sort by date asc (as that would put the oldest event first)
                      // Changing this to desc
                      isSortDescending = true;
                    } else {
                      isSortDescending = initialQuery.isSortDescending;
                    }

                    setTempQuery({
                      ...tempQuery,
                      eventTimeFrameFilter: eventTimeFrameFilter ?? null,
                      eventDates: dateRange ?? null,
                      isSortDescending,
                    });
                  }
                }}
              />
            ),
          },
          ...locationFilters,
          {
            filterId: 'lastSaleDates' as FilterToolbarItemId,
            labelContentId: ContentId.LastSaleDates,
            filterQueryKeys: ['lastSaleDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.lastSaleDates}
                defaultValue={initialQuery.lastSaleDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    lastSaleDates: value,
                  })
                }
                disabledDay={(d) =>
                  getDisabledDatesForTimeFrameFilter(
                    d,
                    tempQuery.eventTimeFrameFilter
                  )
                }
              />
            ),
          },
          {
            filterId: 'numberOfTicketsHeld' as FilterToolbarItemId,
            labelContentId: ContentId.NumberOfTicketsHeld,
            filterQueryKeys: ['numberOfTicketsHeld'] as FilterToolbarItemId[],
            filterItem: (
              <Slider.Root
                min={0}
                max={MAX_NUMBER_OF_TICKETS_HELD}
                step={1}
                value={[
                  tempQuery.numberOfTicketsHeld?.start ?? 0,
                  tempQuery.numberOfTicketsHeld?.end ??
                    MAX_NUMBER_OF_TICKETS_HELD,
                ]}
                renderHandle={(n) => (
                  <Slider.Handle>{formatNumber(n)}</Slider.Handle>
                )}
                onValueChange={([start, end]: [number, number]) => {
                  if (
                    start !== (tempQuery.numberOfTicketsHeld?.start || 0) ||
                    end !== tempQuery.numberOfTicketsHeld?.end
                  ) {
                    const newQuery = {
                      ...tempQuery,
                      numberOfTicketsHeld: {
                        start: start === 0 ? null : start,
                        end: end === MAX_NUMBER_OF_TICKETS_HELD ? null : end,
                      },
                    };
                    setTempQuery(newQuery);
                  }
                }}
              />
            ),
          },
          {
            filterId: 'topLevelCategories' as FilterToolbarItemId,
            filterQueryKeys: ['topLevelCategories'] as FilterToolbarItemId[],
            labelContentId: ContentId.Genre,
            filterItem: (
              <PosEnumMultiSelect
                triggerProps={{ style: { width: '100%' } }}
                values={
                  // TODO: set up enum type for this prop
                  (tempQuery.topLevelCategories as TopLevelCategory[]) || []
                }
                placeholderText={ContentId.AllGenres}
                enableEmptySelection
                onChange={(topLevelCategories) => {
                  const isChanged = !isEqual(
                    topLevelCategories,
                    tempQuery.topLevelCategories
                  );

                  setTempQuery({
                    ...tempQuery,
                    topLevelCategories: isChanged ? topLevelCategories : [],
                  });
                }}
                valueOptionsContent={TOP_LEVEL_CATEGORY_TO_CID}
              />
            ),
          },
          {
            filterId: 'parentCategories' as FilterToolbarItemId,
            labelContentId: ContentId.SubGenre,
            filterQueryKeys: ['parentCategories'] as FilterToolbarItemId[],
            filterItem: (
              <PosMultiSelect
                triggerProps={{ style: { width: '100%' } }}
                values={
                  tempQuery.parentCategories
                    ? tempQuery.parentCategories.map((c) => String(c))
                    : []
                }
                onChange={(newValues: string[]) => {
                  setTempQuery({
                    ...tempQuery,
                    parentCategories:
                      newValues.length > 0
                        ? newValues.map((value) => Number(value))
                        : null,
                  });
                }}
                searchable={true}
                valueOptionsContent={PARENT_CATEGORY_TO_CID}
              />
            ),
          },
          ...eventStateFilters,
        ],
      },
      {
        titleContentId: ContentId.Fulfillment,
        items: [
          {
            filterId: 'saleStatuses' as FilterToolbarItemId,
            labelContentId: ContentId.Status,
            filterQueryKeys: ['saleStatuses'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumMultiSelect
                triggerProps={{
                  style: { width: '100%' },
                }}
                values={tempQuery.saleStatuses ?? []}
                placeholderText={ContentId.AllSaleStatuses}
                valueOptionsContent={SALES_STATUS_TO_CID}
                onChange={(saleStatuses) => {
                  setTempQuery({
                    ...tempQuery,
                    saleStatuses,
                  });
                }}
              />
            ),
          },
          {
            filterId: 'inHandDates' as FilterToolbarItemId,
            labelContentId: ContentId.InHand,
            filterQueryKeys: ['inHandDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={InhandDateRangePresetNames}
                value={tempQuery.inHandDates}
                defaultValue={initialQuery.inHandDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    inHandDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'ticketType' as FilterToolbarItemId,
            labelContentId: ContentId.TicketType,
            filterQueryKeys: ['ticketType'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.ticketType}
                placeholderText={ContentId.AllTicketTypes}
                enableEmptySelection
                onChange={(ticketType) => {
                  if (ticketType !== tempQuery.ticketType) {
                    setTempQuery({
                      ...tempQuery,
                      ticketType: ticketType,
                    });
                  }
                }}
                valueOptionsContent={TICKET_TYPE_TO_CID}
              />
            ),
          },
          {
            filterId: 'fulfillerId' as FilterToolbarItemId,
            labelContentId: ContentId.FulfilledBy,
            filterQueryKeys: ['fulfillerSellerUserId'] as FilterToolbarItemId[],
            filterItem: (
              <SellerAccountEmployeeSelector
                value={tempQuery.fulfillerSellerUserId ?? undefined}
                enableEmptySelection
                style={{ width: '100%' }}
                onChange={(newId) => {
                  if (newId !== tempQuery.fulfillerSellerUserId) {
                    setTempQuery({
                      ...tempQuery,
                      fulfillerSellerUserId: newId,
                    });
                  }
                }}
              />
            ),
          },
          {
            filterId: 'isSeatSaver' as FilterToolbarItemId,
            labelContentId: ContentId.Placeholder,
            filterQueryKeys: ['isSeatSaver'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={ToYesNoEnum(tempQuery.isSeatSaver)}
                placeholderText={ContentId.All}
                enableEmptySelection
                onChange={(yesNoEnumValue) => {
                  if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)) {
                    setTempQuery({
                      ...tempQuery,
                      isSeatSaver: FromYesNoEnum(yesNoEnumValue),
                    });
                  }
                }}
                valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
              />
            ),
          },
          {
            filterId: 'fulfillmentDates' as FilterToolbarItemId,
            labelContentId: ContentId.FulfillmentDate,
            filterQueryKeys: ['fulfillmentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.fulfillmentDates}
                defaultValue={initialQuery.fulfillmentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    fulfillmentDates: value,
                  })
                }
              />
            ),
          },
          ...(hasAutoFulfillSetting
            ? [
                {
                  filterId: 'isNoFulfill' as FilterToolbarItemId,
                  labelContentId: ContentId.AutoFulfill,
                  type: 'row',
                  filterQueryKeys: ['isNoFulfill'] as FilterToolbarItemId[],
                  filterItem: (
                    <PosEnumSelect
                      style={{ width: '100%' }}
                      value={ToYesNoEnum(
                        // the ui is the inverse of isNoFulfil
                        tempQuery.isNoFulfill == null
                          ? null
                          : !tempQuery.isNoFulfill
                      )}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(yesNoEnumValue) => {
                        if (
                          yesNoEnumValue !==
                          ToYesNoEnum(
                            // the ui is the inverse of isNoFulfil
                            tempQuery.isNoFulfill == null
                              ? null
                              : !tempQuery.isNoFulfill
                          )
                        ) {
                          const v = FromYesNoEnum(yesNoEnumValue);
                          setTempQuery({
                            ...tempQuery,
                            isNoFulfill: v == null ? null : !v, // the ui is the inverse of isNoFulfil
                          });
                        }
                      }}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                    />
                  ),
                },
              ]
            : []),
          {
            filterId: 'cancellationDates' as FilterToolbarItemId,
            labelContentId: ContentId.CancellationDate,
            filterQueryKeys: ['cancellationDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.cancellationDates}
                defaultValue={initialQuery.cancellationDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    cancellationDates: value,
                  })
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.WhenAndWhere,
        type: 'row',
        items: [
          {
            filterId: 'soldOnMarketplace' as FilterToolbarItemId,
            labelContentId: ContentId.Marketplace,
            filterQueryKeys: ['soldOnMarketplace'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.soldOnMarketplace}
                placeholderText={ContentId.AllMerchants}
                enableEmptySelection
                onChange={(soldOnMarketplace) => {
                  if (soldOnMarketplace !== tempQuery.soldOnMarketplace) {
                    setTempQuery({
                      ...tempQuery,
                      soldOnMarketplace,
                    });
                  }
                }}
                valueOptionsContent={(
                  loginContext?.user?.activeAccount?.marketplaceSettings?.map(
                    (m) => m.mkp
                  ) ?? [Marketplace.StubHub]
                )
                  .concat(Marketplace.Offline)
                  .reduce(
                    (cur, m) => {
                      cur[m] = MARKET_PLACE_TO_CID[m];
                      return cur;
                    },
                    {} as Record<Marketplace, string>
                  )}
              />
            ),
          },
          {
            filterId: 'saleDates' as FilterToolbarItemId,
            labelContentId: ContentId.SaleDate,
            filterQueryKeys: ['saleDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                presetNames={
                  isReportsPage || hasDynamicDateTimeFiltersFeature
                    ? SaleDateRangeRelativePresetNames
                    : StandardDateRangePresetNames
                }
                value={tempQuery.saleDates}
                defaultValue={initialQuery.saleDates}
                onBlur={(value) => {
                  setTempQuery({
                    ...tempQuery,
                    saleDates: value,
                  });
                }}
                useRelativePresets={
                  isReportsPage || hasDynamicDateTimeFiltersFeature
                }
              />
            ),
          },
          ...(hasFilterSalesByOriginatedByFeature
            ? [
                {
                  filterId: 'listingInsertBy' as FilterToolbarItemId,
                  labelContentId: ContentId.OriginatedBy,
                  filterQueryKeys: ['listingInsertBy'] as FilterToolbarItemId[],
                  filterItem: (
                    <SellerAccountEmployeeSelector
                      value={tempQuery.listingInsertBy ?? undefined}
                      enableEmptySelection
                      style={{ width: '100%' }}
                      onChange={(newId) => {
                        if (newId !== tempQuery.listingInsertBy) {
                          setTempQuery({
                            ...tempQuery,
                            listingInsertBy: newId,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        titleContentId: ContentId.Payment,
        requiredPermissions: [Permission.Sales_ViewPaymentState],
        type: 'row',
        items: [
          {
            filterId: 'marketplacePaymentState' as FilterToolbarItemId,
            labelContentId: ContentId.Payment,
            filterQueryKeys: [
              'marketplacePaymentState',
            ] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.marketplacePaymentState}
                placeholderText={ContentId.AllPaymentStates}
                enableEmptySelection
                onChange={(marketplacePaymentState) => {
                  if (
                    marketplacePaymentState !==
                    tempQuery.marketplacePaymentState
                  ) {
                    setTempQuery({
                      ...tempQuery,
                      marketplacePaymentState: marketplacePaymentState,
                    });
                  }
                }}
                valueOptionsContent={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'paymentReceived' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentReceived,
            filterQueryKeys: ['paymentReceived'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '50%' }}
                value={ToYesNoEnum(tempQuery.paymentReceived)}
                placeholderText={ContentId.All}
                enableEmptySelection
                onChange={(yesNoEnumValue) => {
                  if (
                    yesNoEnumValue !== ToYesNoEnum(tempQuery.paymentReceived)
                  ) {
                    setTempQuery({
                      ...tempQuery,
                      paymentReceived: FromYesNoEnum(yesNoEnumValue),
                    });
                  }
                }}
                valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentReferenceId' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentReference,
            filterQueryKeys: ['paymentReferenceId'] as FilterToolbarItemId[],
            filterItem: (
              <PosTextField
                style={{ width: '50%' }}
                value={tempQuery.paymentReferenceId ?? ''}
                type="text"
                onChange={(e) => {
                  const newValue = e.target.value ?? null;
                  if (tempQuery.paymentReferenceId !== newValue) {
                    setTempQuery({
                      ...tempQuery,
                      paymentReferenceId: newValue,
                    });
                  }
                }}
                maxLength={200}
              />
            ),
          },
          ...(hasFilterByCurrencyCodeFeature
            ? [
                {
                  filterId: 'currencyCode' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCode'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterSelector
                      style={{ width: '100%' }}
                      value={tempQuery.currencyCode ?? ''}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCode) => {
                        if (tempQuery.currencyCode !== newCurrencyCode) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCode: newCurrencyCode?.length
                              ? newCurrencyCode
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterByCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'currencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      triggerProps={{ style: { width: '100%' } }}
                      values={tempQuery.currencyCodes ?? []}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCodes) => {
                        if (
                          !isEqual(tempQuery.currencyCodes, newCurrencyCodes)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCodes: newCurrencyCodes?.length
                              ? newCurrencyCodes
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      seatingFilters,
      purchaseInfoFilters,
      ...(notesFilters ? [notesFilters] : []),
      ...tagsFilter,
    ],
  };
}
