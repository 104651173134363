import { useMutation } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SaleClient } from 'src/WebApiController';

export function useUnwasteSale() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  return useMutation({
    mutationFn: async ({ saleId }: { saleId: number }) => {
      return await new SaleClient(activeAccountWebClientConfig).unwasteSale({
        saleId,
      });
    },
    onError: (error: ErrorTypes, { saleId }, context) => {
      trackError('SaleClient.unwasteSale', error, {
        saleId,
      });
      showErrorDialog('SaleClient.unwasteSale', error, {
        trackErrorData: { saleId },
      });
    },
  });
}
