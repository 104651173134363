import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useMergedTagFilters } from 'src/components/MainFilterBar/Tags/useMergedTagFilters';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Slider } from 'src/core/ui/Slider';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID,
  EVENT_MAPPING_STATE_FILTER_TO_CID,
  EVENT_PRICED_STATUS_TO_CID,
  PARENT_CATEGORY_TO_CID,
  TICKET_TYPE_TO_CID,
  TOP_LEVEL_CATEGORY_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
  YES_NO_INVERTED_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  InhandDateRangePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import {
  EventMappingStateFilter,
  FromEventMappingStateFilter,
  FromYesNoEnum,
  ToEventMappingStateFilter,
  ToYesNoEnum,
} from 'src/utils/eventQueryUtils';
import { formatNumber } from 'src/utils/numberFormatter';
import { ReportTypes } from 'src/utils/reportsUtils';
import {
  ActionOutboxEntityType,
  EventTimeFrameFilter,
  Feature,
  ListingQuery,
  TopLevelCategory,
} from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import {
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
} from '../Filters';
import { useLocationFilter } from '../Filters/LocationFilter';
import { RowFilter } from '../Filters/RowFilter';
import { SectionFilter } from '../Filters/SectionFilter';
import { PriceUpdatedDateStackedFilter } from '../Filters/StackedFilter/PriceUpdatedDateStackedFilter';
import { SaleDateStackedFilter } from '../Filters/StackedFilter/SaleDateStackedFilter';
import {
  CurrencyFilterMultiSelector,
  CurrencyFilterSelector,
} from '../Selectors/CurrencyFilterSelector';
import { DeliveryTypeSelector } from '../Selectors/DeliveryTypeSelector';
import { useEventLastReviewedFilters } from './useEventLastReviewedFilter';
import { useEventStateFilters } from './useEventStateFilters';
import { usePurchaseInfoFilters } from './usePurchaseInfoFilters';

const MAX_NUMBER_OF_TICKETS_HELD = 100_000;

export const listingsMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'eventDates',
];

export function useListingFilters(
  isEventPage?: boolean,
  isReportsPage?: boolean
) {
  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<ListingQuery>();

  const purchaseInfoFilters = usePurchaseInfoFilters(
    tempQuery,
    setTempQuery,
    isReportsPage ? ReportTypes.Inventory : undefined
  );

  const hasSalePriceFilterV2Feature = useUserHasFeature(
    Feature.SalePriceFilterV2
  );
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );
  const hasEventFilterHasUnpricedListings = useUserHasFeature(
    Feature.EventFilterHasUnpricedListings
  );

  const tagsFilter = useMergedTagFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    entityType: ActionOutboxEntityType.Listing,
  });

  const eventStateFilters = useEventStateFilters({
    query: tempQuery,
    setQuery: setTempQuery,
  });

  const eventLastReviewedFilters = useEventLastReviewedFilters();

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const hasPricedByMarketplaceFilterFeature = useUserHasFeature(
    Feature.PricedByMarketplaceFilter
  );
  const hasFilterByIsPendingProofFeature = useUserHasFeature(
    Feature.FilterByIsPendingProof
  );
  const hasFilterByCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByCurrencyCodeMulti
  );
  const hasFilterByCurrencyCodeFeature =
    useUserHasFeature(Feature.FilterByCurrencyCode) &&
    !hasFilterByCurrencyCodeMultiFeature;
  const hasFilterByUnsoldListings = useUserHasFeature(
    Feature.EventHasUnsoldListingFilter
  );
  const hasFilterInventoryByTicketTypeFeature = useUserHasFeature(
    Feature.FilterInventoryByTicketType
  );

  const hasAutoFulfillSetting = useUserHasFeature(Feature.AutoFulfillSetting);

  const autoPricingFilter = useMemo<FilterToolbarItem[]>(
    () => [
      {
        filterId: 'isAutoPricingEnabled',
        labelContentId: ContentId.AutoPricingEnabled,
        filterQueryKeys: ['isAutoPricingEnabled'],
        filterItem: (
          <PosEnumSelect
            style={{ width: '100%' }}
            value={ToYesNoEnum(tempQuery.isAutoPricingEnabled)}
            placeholderText={ContentId.All}
            enableEmptySelection
            onChange={(yesNoEnumValue) => {
              if (
                yesNoEnumValue !== ToYesNoEnum(tempQuery.isAutoPricingEnabled)
              ) {
                setTempQuery({
                  ...tempQuery,
                  isAutoPricingEnabled: FromYesNoEnum(yesNoEnumValue),
                });
              }
            }}
            valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
          />
        ),
      },
      ...[
        {
          filterId: 'autoPricingResultTypes' as FilterToolbarItemId,
          labelContentId: ContentId.AutoPricingResultType,
          filterQueryKeys: ['autoPricingResultTypes'] as FilterToolbarItemId[],
          filterItem: (
            <PosEnumMultiSelect
              triggerProps={{ style: { width: '100%' } }}
              values={tempQuery.autoPricingResultTypes || []}
              placeholderText={ContentId.AutoPricingAllResultTypeOption}
              enableEmptySelection
              onChange={(autoPricingResultTypes) => {
                // TODO: We should have it such that if they click on the placeholder, it could clear out the options
                // This should not be default though because sometimes the placeholder really is just a placeholder.
                const isChanged = !isEqual(
                  autoPricingResultTypes,
                  tempQuery.autoPricingResultTypes
                );
                setTempQuery({
                  ...tempQuery,
                  autoPricingResultTypes: isChanged
                    ? autoPricingResultTypes
                    : [],
                });
              }}
              valueOptionsContent={AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID}
            />
          ),
        },
      ],
    ],
    [setTempQuery, tempQuery]
  );

  const adminHoldFilter = useMemo<FilterToolbarItem[]>(
    () =>
      [
        {
          filterId: 'isAdminHold',
          labelContentId: ContentId.Hold,
          filterQueryKeys: ['isAdminHold'],
          filterItem: (
            <PosEnumSelect
              style={{ width: '100%' }}
              value={ToYesNoEnum(tempQuery.isAdminHold)}
              placeholderText={ContentId.All}
              enableEmptySelection
              onChange={(yesNoEnumValue) => {
                if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isAdminHold)) {
                  setTempQuery({
                    ...tempQuery,
                    isAdminHold: FromYesNoEnum(yesNoEnumValue),
                  });
                }
              }}
              valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
            />
          ),
        },
      ] as FilterToolbarItem[],
    [setTempQuery, tempQuery]
  );

  const seatingFilters = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Seating,
      type: 'row',
      items: [
        {
          filterId: 'sectionContains',
          labelContentId: ContentId.Section,
          filterQueryKeys: [
            'sectionEquals',
            'sectionContains',
            'sectionNotContains',
          ],
          filterItem: (
            <SectionFilter query={tempQuery} setQuery={setTempQuery} />
          ),
        },
        {
          filterId: 'rowContains',
          labelContentId: ContentId.Row,
          filterQueryKeys: ['rowEquals', 'rowContains', 'rowNotContains'],
          filterItem: <RowFilter query={tempQuery} setQuery={setTempQuery} />,
        },
      ],
    }),
    [setTempQuery, tempQuery]
  );

  const locationFilters = useLocationFilter();

  const eventFilter = useMemo<FilterToolbarGroup | undefined>(
    () =>
      isEventPage
        ? undefined
        : {
            titleContentId: ContentId.Events,
            type: 'row',
            items: [
              {
                labelContentId: ContentId.EventDate,
                filterId: 'eventDates',
                filterQueryKeys: ['eventDates', 'eventTimeFrameFilter'],
                filterItem: (
                  <EventDateSelector
                    useRelativePresets={
                      isReportsPage || hasDynamicDateTimeFiltersFeature
                    }
                    eventTimeFrame={tempQuery.eventTimeFrameFilter}
                    value={tempQuery.eventDates}
                    onChange={(eventTimeFrameFilter, dateRange) => {
                      if (
                        eventTimeFrameFilter !==
                          tempQuery.eventTimeFrameFilter ||
                        !isEqual(dateRange, tempQuery.eventDates)
                      ) {
                        let isSortDescending = tempQuery.isSortDescending;
                        if (
                          eventTimeFrameFilter !== EventTimeFrameFilter.Future
                        ) {
                          // When sorting by past or by all - we do not want to sort by date asc (as that would put the oldest event first)
                          // Changing this to desc
                          isSortDescending = true;
                        } else {
                          isSortDescending = initialQuery.isSortDescending;
                        }

                        setTempQuery({
                          ...tempQuery,
                          eventTimeFrameFilter: eventTimeFrameFilter ?? null,
                          eventDates: dateRange ?? null,
                          isSortDescending,
                        });
                      }
                    }}
                  />
                ),
              },
              ...locationFilters,
              ...eventLastReviewedFilters,
              ...(hasFilterByUnsoldListings
                ? [
                    {
                      filterId: 'eventHasUnsoldTickets' as FilterToolbarItemId,
                      labelContentId: ContentId.HasUnsoldListings,
                      filterQueryKeys: [
                        'eventHasUnsoldTickets',
                      ] as FilterToolbarItemId[],
                      filterItem: (
                        <PosEnumSelect
                          style={{ width: '100%' }}
                          value={ToYesNoEnum(tempQuery.eventHasUnsoldTickets)}
                          placeholderText={ContentId.All}
                          enableEmptySelection
                          onChange={(yesNoEnumValue) => {
                            if (
                              yesNoEnumValue !==
                              ToYesNoEnum(tempQuery.eventHasUnsoldTickets)
                            ) {
                              setTempQuery({
                                ...tempQuery,
                                eventHasUnsoldTickets:
                                  FromYesNoEnum(yesNoEnumValue),
                              });
                            }
                          }}
                          valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                        />
                      ),
                    },
                  ]
                : []),
              hasSalePriceFilterV2Feature
                ? {
                    filterId: 'noSaleDates',
                    labelContentId: ContentId.SaleDateFilterTitle,
                    filterQueryKeys: ['lastSaleDates', 'noSaleDates'],
                    filterItem: <SaleDateStackedFilter />,
                  }
                : {
                    filterId: 'lastSaleDates',
                    labelContentId: ContentId.LastSaleDates,
                    filterQueryKeys: ['lastSaleDates'],
                    filterItem: (
                      <DateRangeSelector
                        useRelativePresets={hasDynamicDateTimeFiltersFeature}
                        presetNames={StandardDateRangePresetNames}
                        value={tempQuery.lastSaleDates}
                        defaultValue={initialQuery.lastSaleDates}
                        onBlur={(value) =>
                          setTempQuery({
                            ...tempQuery,
                            lastSaleDates: value,
                          })
                        }
                        disabledDay={(d) =>
                          getDisabledDatesForTimeFrameFilter(
                            d,
                            tempQuery.eventTimeFrameFilter
                          )
                        }
                      />
                    ),
                  },
              {
                filterId: 'numberOfTicketsHeld',
                labelContentId: ContentId.NumberOfTicketsHeld,
                filterQueryKeys: ['numberOfTicketsHeld'],
                filterItem: (
                  <Slider.Root
                    min={0}
                    max={MAX_NUMBER_OF_TICKETS_HELD}
                    step={1}
                    value={[
                      tempQuery.numberOfTicketsHeld?.start ?? 0,
                      tempQuery.numberOfTicketsHeld?.end ??
                        MAX_NUMBER_OF_TICKETS_HELD,
                    ]}
                    renderHandle={(n) => (
                      <Slider.Handle>{formatNumber(n)}</Slider.Handle>
                    )}
                    onValueChange={([start, end]: [number, number]) => {
                      if (
                        start !== (tempQuery.numberOfTicketsHeld?.start || 0) ||
                        end !== tempQuery.numberOfTicketsHeld?.end
                      ) {
                        const newQuery = {
                          ...tempQuery,
                          numberOfTicketsHeld: {
                            start: start === 0 ? null : start,
                            end:
                              end === MAX_NUMBER_OF_TICKETS_HELD ? null : end,
                          },
                        };
                        setTempQuery(newQuery);
                      }
                    }}
                  />
                ),
              },
              {
                filterId: 'topLevelCategories',
                labelContentId: ContentId.Genre,
                filterQueryKeys: ['topLevelCategories'],
                filterItem: (
                  <PosEnumMultiSelect
                    triggerProps={{ style: { width: '100%' } }}
                    values={
                      // TODO: set up enum type for this prop
                      (tempQuery.topLevelCategories as TopLevelCategory[]) || []
                    }
                    placeholderText={ContentId.AllGenres}
                    enableEmptySelection
                    onChange={(topLevelCategories) => {
                      const isChanged = !isEqual(
                        topLevelCategories,
                        tempQuery.topLevelCategories
                      );

                      setTempQuery({
                        ...tempQuery,
                        topLevelCategories: isChanged ? topLevelCategories : [],
                      });
                    }}
                    valueOptionsContent={TOP_LEVEL_CATEGORY_TO_CID}
                  />
                ),
              },
              {
                filterId: 'parentCategories',
                labelContentId: ContentId.SubGenre,
                filterQueryKeys: ['parentCategories'],
                filterItem: (
                  <PosMultiSelect
                    triggerProps={{ style: { width: '100%' } }}
                    values={
                      tempQuery.parentCategories
                        ? tempQuery.parentCategories.map((c) => String(c))
                        : []
                    }
                    onChange={(newValues: string[]) => {
                      setTempQuery({
                        ...tempQuery,
                        parentCategories:
                          newValues.length > 0
                            ? newValues.map((value) => Number(value))
                            : null,
                      });
                    }}
                    searchable={true}
                    valueOptionsContent={PARENT_CATEGORY_TO_CID}
                  />
                ),
              },
              {
                filterId: 'isMapped' as FilterToolbarItemId,
                labelContentId: ContentId.RequestState,
                filterQueryKeys: [
                  'isMapped',
                  'isMappingRejected',
                ] as FilterToolbarItemId[],
                filterItem: (
                  <PosEnumSelect
                    style={{ width: '100%' }}
                    value={ToEventMappingStateFilter(
                      tempQuery.isMapped,
                      tempQuery.isMappingRejected
                    )}
                    placeholderText={ContentId.All}
                    onChange={(filterEnumValue) => {
                      if (
                        filterEnumValue !==
                        ToEventMappingStateFilter(
                          tempQuery.isMapped,
                          tempQuery.isMappingRejected
                        )
                      ) {
                        const [isMapped, isMappingRejected] =
                          FromEventMappingStateFilter(
                            filterEnumValue ?? EventMappingStateFilter.All
                          );
                        setTempQuery({
                          ...tempQuery,
                          isMapped,
                          isMappingRejected,
                        });
                      }
                    }}
                    valueOptionsContent={EVENT_MAPPING_STATE_FILTER_TO_CID}
                  />
                ),
              },
              ...eventStateFilters,
              ...(hasEventFilterHasUnpricedListings
                ? [
                    {
                      filterId: 'eventUnpricedStatus' as FilterToolbarItemId,
                      labelContentId: ContentId.HasUnpricedListings,
                      filterQueryKeys: [
                        'eventUnpricedStatus',
                      ] as FilterToolbarItemId[],
                      filterItem: (
                        <PosEnumSelect
                          style={{ width: '100%' }}
                          value={tempQuery.eventUnpricedStatus}
                          enableEmptySelection
                          onChange={(eventUnpricedStatus) => {
                            if (
                              eventUnpricedStatus !==
                              tempQuery.eventUnpricedStatus
                            ) {
                              setTempQuery({
                                ...tempQuery,
                                eventUnpricedStatus,
                              });
                            }
                          }}
                          valueOptionsContent={EVENT_PRICED_STATUS_TO_CID}
                        />
                      ),
                    },
                  ]
                : []),
            ],
          },
    [
      isEventPage,
      isReportsPage,
      hasDynamicDateTimeFiltersFeature,
      tempQuery,
      locationFilters,
      eventLastReviewedFilters,
      hasFilterByUnsoldListings,
      hasSalePriceFilterV2Feature,
      initialQuery.lastSaleDates,
      initialQuery.isSortDescending,
      eventStateFilters,
      hasEventFilterHasUnpricedListings,
      setTempQuery,
    ]
  );

  const notesFilters = useInternalNotesFilter<ListingQuery>({
    entityType: ActionOutboxEntityType.Listing,
  });

  const filters = useMemo<FilterToolbarGroup[]>(
    () =>
      [
        eventFilter,
        {
          titleContentId: ContentId.Pricing,
          type: 'row',
          items: [
            {
              filterId: 'pricerSellerUserId' as FilterToolbarItemId,
              filterQueryKeys: ['pricerSellerUserId'] as FilterToolbarItemId[],
              labelContentId: ContentId.PricedBy,
              filterItem: (
                <SellerAccountEmployeeSelector
                  value={tempQuery.pricerSellerUserId ?? undefined}
                  enableEmptySelection
                  style={{ width: '100%' }}
                  onChange={(newId) => {
                    if (newId !== tempQuery.pricerSellerUserId) {
                      setTempQuery({
                        ...tempQuery,
                        pricerSellerUserId: newId,
                      });
                    }
                  }}
                />
              ),
            },
            ...(hasAutoPricingFeature ? autoPricingFilter : []),
            ...[
              {
                filterId: 'isPricedAboveZero' as FilterToolbarItemId,
                labelContentId: ContentId.Priced,
                filterQueryKeys: ['isPricedAboveZero'] as FilterToolbarItemId[],
                filterItem: (
                  <PosEnumSelect
                    style={{ width: '100%' }}
                    value={ToYesNoEnum(tempQuery.isPricedAboveZero)}
                    placeholderText={ContentId.All}
                    enableEmptySelection
                    onChange={(yesNoEnumValue) => {
                      if (
                        yesNoEnumValue !==
                        ToYesNoEnum(tempQuery.isPricedAboveZero)
                      ) {
                        setTempQuery({
                          ...tempQuery,
                          isPricedAboveZero: FromYesNoEnum(yesNoEnumValue),
                        });
                      }
                    }}
                    valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                  />
                ),
              },
              hasSalePriceFilterV2Feature
                ? {
                    filterId: 'lastPriceUpdateDates' as FilterToolbarItemId,
                    filterQueryKeys: [
                      'lastPriceUpdateDates',
                      'lastPriceNoUpdateDates',
                    ] as FilterToolbarItemId[],
                    labelContentId: ContentId.PriceDateFilterTitle,
                    filterItem: <PriceUpdatedDateStackedFilter />,
                  }
                : {
                    filterId: 'lastPriceUpdateDates' as FilterToolbarItemId,
                    filterQueryKeys: [
                      'lastPriceUpdateDates',
                    ] as FilterToolbarItemId[],
                    labelContentId: ContentId.LastPriceUpdatedDate,
                    filterItem: (
                      <DateRangeSelector
                        useRelativePresets={hasDynamicDateTimeFiltersFeature}
                        presetNames={StandardDateRangePresetNames}
                        value={tempQuery.lastPriceUpdateDates}
                        defaultValue={initialQuery.lastPriceUpdateDates}
                        onBlur={(value) =>
                          setTempQuery({
                            ...tempQuery,
                            lastPriceUpdateDates: value,
                          })
                        }
                      />
                    ),
                  },
            ],
            ...(hasPricedByMarketplaceFilterFeature
              ? [
                  {
                    labelContentId: ContentId.IsPricedByMarketplace,
                    filterId: 'isPricedByMarketplace' as FilterToolbarItemId,
                    filterQueryKeys: [
                      'isPricedByMarketplace',
                    ] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={ToYesNoEnum(tempQuery.isPricedByMarketplace)}
                        placeholderText={ContentId.All}
                        enableEmptySelection
                        onChange={(yesNoEnumValue) => {
                          if (
                            yesNoEnumValue !==
                            ToYesNoEnum(tempQuery.isPricedByMarketplace)
                          ) {
                            setTempQuery({
                              ...tempQuery,
                              isPricedByMarketplace:
                                FromYesNoEnum(yesNoEnumValue),
                            });
                          }
                        }}
                        valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      />
                    ),
                  },
                ]
              : []),
            ...(hasFilterByCurrencyCodeFeature
              ? [
                  {
                    filterId: 'currencyCode' as FilterToolbarItemId,
                    labelContentId: ContentId.Currency,
                    filterQueryKeys: ['currencyCode'] as FilterToolbarItemId[],
                    filterItem: (
                      <CurrencyFilterSelector
                        value={tempQuery.currencyCode ?? ''}
                        style={{ width: '100%' }}
                        placeholderText={ContentId.All}
                        onChange={(newCurrencyCode) => {
                          if (tempQuery.currencyCode !== newCurrencyCode) {
                            setTempQuery({
                              ...tempQuery,
                              currencyCode: newCurrencyCode?.length
                                ? newCurrencyCode
                                : null,
                            });
                          }
                        }}
                        enableEmptySelection
                      />
                    ),
                  },
                ]
              : []),
            ...(hasFilterByCurrencyCodeMultiFeature
              ? [
                  {
                    filterId: 'currencyCodes' as FilterToolbarItemId,
                    labelContentId: ContentId.Currency,
                    filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
                    filterItem: (
                      <CurrencyFilterMultiSelector
                        values={tempQuery.currencyCodes ?? []}
                        triggerProps={{ style: { width: '100%' } }}
                        placeholderText={ContentId.All}
                        onChange={(newCurrencyCodes) => {
                          if (
                            !isEqual(tempQuery.currencyCodes, newCurrencyCodes)
                          ) {
                            setTempQuery({
                              ...tempQuery,
                              currencyCodes: newCurrencyCodes?.length
                                ? newCurrencyCodes
                                : null,
                            });
                          }
                        }}
                        enableEmptySelection
                      />
                    ),
                  },
                ]
              : []),
          ],
        },

        {
          titleContentId: ContentId.Broadcast,
          type: 'row',
          items: [
            {
              filterId: 'isListed' as FilterToolbarItemId,
              labelContentId: ContentId.Broadcasted,
              filterQueryKeys: ['isListed'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isListed)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isListed)) {
                      setTempQuery({
                        ...tempQuery,
                        isListed: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'hasBroadcastError' as FilterToolbarItemId,
              labelContentId: ContentId.HasBroadcastError,
              filterQueryKeys: ['hasBroadcastError'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.hasBroadcastError)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !==
                      ToYesNoEnum(tempQuery.hasBroadcastError)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        hasBroadcastError: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            ...adminHoldFilter,
            {
              filterId: 'isDuplicate' as FilterToolbarItemId,
              labelContentId: ContentId.IsDuplicate,
              filterQueryKeys: ['isDuplicate'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isDuplicate)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isDuplicate)) {
                      setTempQuery({
                        ...tempQuery,
                        isDuplicate: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            ...(hasFilterByIsPendingProofFeature
              ? [
                  {
                    filterId: 'isPendingProof' as FilterToolbarItemId,
                    labelContentId: ContentId.ProofOfPurchasedRequired,
                    filterQueryKeys: [
                      'isPendingProof',
                    ] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={ToYesNoEnum(tempQuery.isPendingProof)}
                        placeholderText={ContentId.All}
                        enableEmptySelection
                        onChange={(yesNoEnumValue) => {
                          if (
                            yesNoEnumValue !==
                            ToYesNoEnum(tempQuery.isPendingProof)
                          ) {
                            setTempQuery({
                              ...tempQuery,
                              isPendingProof: FromYesNoEnum(yesNoEnumValue),
                            });
                          }
                        }}
                        valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      />
                    ),
                  },
                ]
              : []),
          ],
        },
        {
          titleContentId: ContentId.Fulfillment,
          type: 'row',
          items: [
            {
              filterId: 'isPredelivered' as FilterToolbarItemId,
              labelContentId: ContentId.PreDelivered,
              type: 'row',
              filterQueryKeys: ['isPredelivered'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isPredelivered)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !== ToYesNoEnum(tempQuery.isPredelivered)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        isPredelivered: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'deliveryType' as FilterToolbarItemId,
              labelContentId: ContentId.Delivery,
              filterQueryKeys: ['deliveryType'] as FilterToolbarItemId[],
              filterItem: (
                <DeliveryTypeSelector
                  style={{ width: '100%' }}
                  value={tempQuery.deliveryType}
                  enableEmptySelection
                  onChange={(deliveryType) => {
                    if (deliveryType !== tempQuery.deliveryType) {
                      setTempQuery({
                        ...tempQuery,
                        deliveryType: deliveryType,
                      });
                    }
                  }}
                  allowCustomDeliveryType
                />
              ),
            },
            ...(hasFilterInventoryByTicketTypeFeature
              ? [
                  {
                    filterId: 'ticketType' as FilterToolbarItemId,
                    labelContentId: ContentId.TicketType,
                    filterQueryKeys: ['ticketType'] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={tempQuery.ticketType}
                        placeholderText={ContentId.AllTicketTypes}
                        enableEmptySelection
                        onChange={(ticketType) => {
                          if (ticketType !== tempQuery.ticketType) {
                            setTempQuery({
                              ...tempQuery,
                              ticketType: ticketType || null,
                            });
                          }
                        }}
                        valueOptionsContent={TICKET_TYPE_TO_CID}
                      />
                    ),
                  },
                ]
              : []),
            ...(hasAutoFulfillSetting
              ? [
                  {
                    filterId: 'isNoFulfill' as FilterToolbarItemId,
                    labelContentId: ContentId.AutoFulfill,
                    type: 'row',
                    filterQueryKeys: ['isNoFulfill'] as FilterToolbarItemId[],
                    filterItem: (
                      <PosEnumSelect
                        style={{ width: '100%' }}
                        value={ToYesNoEnum(
                          // the ui is the inverse of isNoFulfil
                          tempQuery.isNoFulfill == null
                            ? null
                            : !tempQuery.isNoFulfill
                        )}
                        placeholderText={ContentId.All}
                        enableEmptySelection
                        onChange={(yesNoEnumValue) => {
                          if (
                            yesNoEnumValue !==
                            ToYesNoEnum(
                              // the ui is the inverse of isNoFulfil
                              tempQuery.isNoFulfill == null
                                ? null
                                : !tempQuery.isNoFulfill
                            )
                          ) {
                            const v = FromYesNoEnum(yesNoEnumValue);
                            setTempQuery({
                              ...tempQuery,
                              isNoFulfill: v == null ? null : !v, // the ui is the inverse of isNoFulfil
                            });
                          }
                        }}
                        valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      />
                    ),
                  },
                ]
              : []),
            {
              filterId: 'hasUnsoldTickets' as FilterToolbarItemId,
              labelContentId: ContentId.HasUnsoldTickets,
              filterQueryKeys: ['hasUnsoldTickets'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.hasUnsoldTickets)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !== ToYesNoEnum(tempQuery.hasUnsoldTickets)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        hasUnsoldTickets: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'inHandDates' as const,
              labelContentId: ContentId.InHand,
              filterQueryKeys: ['inHandDates'] as FilterToolbarItemId[],
              filterItem: (
                <DateRangeSelector
                  useRelativePresets={hasDynamicDateTimeFiltersFeature}
                  presetNames={InhandDateRangePresetNames}
                  value={tempQuery.inHandDates}
                  defaultValue={initialQuery.inHandDates}
                  onBlur={(value) =>
                    setTempQuery({
                      ...tempQuery,
                      inHandDates: value,
                    })
                  }
                />
              ),
            },
            {
              filterId: 'isSeatSaver' as const,
              labelContentId: ContentId.Placeholder,
              filterQueryKeys: ['isSeatSaver'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isSeatSaver)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isSeatSaver)) {
                      setTempQuery({
                        ...tempQuery,
                        isSeatSaver: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ],
        },
        {
          titleContentId: ContentId.Mappings,
          type: 'row',
          items: [
            {
              filterId: 'isMapped' as FilterToolbarItemId,
              labelContentId: ContentId.EventMapped,
              filterQueryKeys: ['isMapped'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isMapped)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (yesNoEnumValue !== ToYesNoEnum(tempQuery.isMapped)) {
                      setTempQuery({
                        ...tempQuery,
                        isMapped: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                />
              ),
            },
            {
              filterId: 'isSeatingUnmapped' as FilterToolbarItemId,
              labelContentId: ContentId.SeatingMapped,
              filterQueryKeys: ['isSeatingUnmapped'] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(tempQuery.isSeatingUnmapped)}
                  placeholderText={ContentId.All}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !==
                      ToYesNoEnum(tempQuery.isSeatingUnmapped)
                    ) {
                      setTempQuery({
                        ...tempQuery,
                        isSeatingUnmapped: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_NO_INVERTED_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ],
        },
        seatingFilters,
        purchaseInfoFilters,
        notesFilters,
        ...tagsFilter,
      ]
        .filter((f) => f)
        .map((f) => f!),
    [
      eventFilter,
      tempQuery,
      hasAutoPricingFeature,
      autoPricingFilter,
      hasSalePriceFilterV2Feature,
      hasDynamicDateTimeFiltersFeature,
      initialQuery.lastPriceUpdateDates,
      initialQuery.inHandDates,
      hasPricedByMarketplaceFilterFeature,
      hasFilterByCurrencyCodeFeature,
      hasFilterByCurrencyCodeMultiFeature,
      adminHoldFilter,
      hasFilterByIsPendingProofFeature,
      hasFilterInventoryByTicketTypeFeature,
      hasAutoFulfillSetting,
      seatingFilters,
      purchaseInfoFilters,
      notesFilters,
      tagsFilter,
      setTempQuery,
    ]
  );

  return { filters };
}
