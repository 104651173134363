import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AdGroupListingsFlattenedView } from 'src/components/AdGroupsFlattenedView/AdGroupListingsFlattenedView';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import {
  AdGroupsDataContextProvider,
  useAdGroupsDataContext,
} from 'src/contexts/AdGroupsDataContext/AdGroupsDataContext';
import { AdPlatformCatalogDataContextProvider } from 'src/contexts/AdPlatformCatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalProvider } from 'src/contexts/ModalContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import {
  AdCampaignQueryParam,
  AdGroupListingsQueryParam,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { SomethingWentWrong } from 'src/views';
import { UserSetting } from 'src/WebApiController';

import { getDefaultReturnUrl } from '../InventoryEvent/constants';
import { MainRoute } from '../MainRoute';
import { AdGroupsToolbar } from '../SponsoredListings/AdGroupsToolbar/AdGroupsToolbar';
import * as styles from './AdGroupListings.css';

const SPONSOREDLISTINGS_USER_SETTINGS: UserSetting[] = [];

export const AdGroupListings = () => {
  const [searchParams] = useSearchParams();
  const adCampaignId = searchParams.get(AdCampaignQueryParam);
  const adGroupId = searchParams.get(AdGroupListingsQueryParam);

  const location = useLocation();

  const returnUrl = useMemo(() => {
    const relativeUrl =
      (location.state as { returnUrl?: string })?.returnUrl ||
      getDefaultReturnUrl(adGroupId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      AdGroupListingsQueryParam,
    ]);
  }, [adGroupId, location.state]);

  if (!adGroupId) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SPONSOREDLISTINGS_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <AdPlatformCatalogDataContextProvider>
        <ModalProvider>
          <LayoutContent mainRoute={MainRoute.SponsoredListings} routeTitle="">
            <AdGroupsDataContextProvider
              adCampaignId={adCampaignId ?? undefined}
              adGroupId={adGroupId}
            >
              <AdGroupsToolbar returnUrl={returnUrl ?? undefined} />
              <div className={styles.contentContainer}>
                <AdGroupListingsFlattenedView />
              </div>
            </AdGroupsDataContextProvider>
          </LayoutContent>
        </ModalProvider>
      </AdPlatformCatalogDataContextProvider>
    </SellerUserSettingsProvider>
  );
};

export const AdGroupListingsContent = ({
  adGroupId,
}: {
  adGroupId: string;
}) => {
  const { adGroupsDataQuery, adGroupsData, errorInfo } =
    useAdGroupsDataContext();

  return (
    <div className={styles.contentContainer}>
      {(adGroupsDataQuery.isLoading || adGroupsDataQuery.data == null) && (
        <PosSpinner />
      )}
      {errorInfo && (
        <SomethingWentWrong
          header={errorInfo.errorHeader}
          message={
            errorInfo.errorMessage ?? (
              <Content id={ContentId.FailToLoadListContent} />
            )
          }
        />
      )}
      {!(adGroupsDataQuery.isLoading || adGroupsDataQuery.data == null) &&
        !errorInfo && (
          <>
            {adGroupsData?.length ? (
              <AdGroupsDataContextProvider
                adCampaignId={undefined}
                adGroupId={adGroupId}
              >
                <AdGroupListingsFlattenedView />
              </AdGroupsDataContextProvider>
            ) : (
              <EmptySectionContent.Root
                icon={
                  <EmptySectionContent.SolidIconContainer>
                    <SearchSolidIcon />
                  </EmptySectionContent.SolidIconContainer>
                }
              >
                <EmptySectionContent.Label>
                  <Content id={ContentId.NoResultFound} />
                </EmptySectionContent.Label>
                <EmptySectionContent.DetailMessage>
                  <Content id={ContentId.NoEventsMatching} />
                </EmptySectionContent.DetailMessage>
              </EmptySectionContent.Root>
            )}
          </>
        )}
    </div>
  );
};
