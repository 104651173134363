import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SaleClient } from 'src/WebApiController';

export function useGetSaleLineItemsBySaleId(saleId?: number | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !!activeAccountWebClientConfig?.activeAccountId && !!saleId;

  return useQuery({
    queryKey: ['GetSaleLineItemsBySaleId', saleId],
    async queryFn() {
      if (!shouldQuery) return null;

      try {
        return await new SaleClient(
          activeAccountWebClientConfig
        ).getSaleLineItemsBySaleId(saleId);
      } catch (err: unknown) {
        trackError('SaleClient.getSaleLineItemsBySaleId', err as ErrorTypes, {
          saleId,
        });
        throw err;
      }
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });
}
