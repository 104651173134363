import { useMutation } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { MarketplacePaymentClient } from 'src/WebApiController';

export function useLinkMarketplacePaymentLineToSale() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  return useMutation({
    mutationFn: async ({
      marketplacePaymentLineId,
    }: {
      marketplacePaymentLineId: number | undefined;
    }) => {
      if (!marketplacePaymentLineId) {
        return Promise.reject('Invalid parameters');
      }
      return await new MarketplacePaymentClient(
        activeAccountWebClientConfig
      ).unlinkMarketplacePaymentLine(marketplacePaymentLineId);
    },
    onError: (error: ErrorTypes, { marketplacePaymentLineId }, context) => {
      trackError(
        'MarketplacePaymentClient.unlinkMarketplacePaymentLine',
        error,
        {
          marketplacePaymentLineId,
        }
      );
      showErrorDialog(
        'MarketplacePaymentClient.unlinkMarketplacePaymentLine',
        error,
        {
          trackErrorData: { marketplacePaymentLineId },
        }
      );
    },
  });
}
