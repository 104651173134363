import { ReactNode } from 'react';
import { BackSection } from 'src/components/common/BackSection';
import { Stack } from 'src/core/ui';

import * as styles from './LayoutContent.css';

export const LayoutContentHeader = ({
  routeTitle,
  centerContent,
  rightContent,
  returnUrl,
}: {
  routeTitle: ReactNode;
  centerContent?: ReactNode;
  rightContent?: ReactNode;
  returnUrl?: string;
}) => {
  return (
    <Stack
      gap="l"
      justifyContent="spaceBetween"
      alignItems="start"
      flexWrap="wrap"
      className={styles.sectionsContainer}
    >
      <Stack style={{ flex: 0.33 }} alignItems="center" gap="l">
        {returnUrl && <BackSection returnUrl={returnUrl} />}
        <h1 data-testid="routeTitle" className={styles.routeTitle}>
          {routeTitle}
        </h1>
      </Stack>
      <Stack justifyContent="center" style={{ flex: 0.33 }}>
        {centerContent ?? <div>&nbsp;</div>}
      </Stack>
      <Stack justifyContent="end" style={{ flex: 0.33 }}>
        {rightContent ?? <div>&nbsp;</div>}
      </Stack>
    </Stack>
  );
};
