import { format } from 'date-fns';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { EntitySearchConfigsToolbar } from 'src/components/EntitySearch/EntitySearchConfigsToolbar';
import { SelectedFilters } from 'src/components/Filters';
import { PurchasesGlobalBulkActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/PurchasesGlobalBulkActions';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import { SetPurchasesFlattenedViewSelectionCountLabel } from 'src/components/Purchases/SelectedItemsLabel';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import {
  PurchaseDataContextProvider,
  usePurchaseDataContext,
} from 'src/contexts/PurchaseDataContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { vars } from 'src/core/themes';
import { Button, DividerLine, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { LayoutContent } from 'src/navigations/LayoutContent';
import {
  IconsFill,
  ProcessingIcon,
  ReloadIcon,
  SearchSolidIcon,
} from 'src/svgs/Viagogo';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  getCatalogData,
  getCatalogDataExpanded,
  PURCHASE_USER_SETTINGS,
  purchaseQueryValueTransformFromUrl,
  purchaseQueryValueTransformToUrl,
} from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { EventPageLocationState } from 'src/utils/types/EventPageLocationState';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  CatalogClient,
  CatalogResults,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseSearchResult,
  PurchaseViewMode,
  SearchClient,
} from 'src/WebApiController';

import { getDefaultReturnUrl } from '../InventoryEvent/constants';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from '../InventoryEvent/InventoryEvent.constants';
import { MainRoute } from '../MainRoute';
import { PurchaseFlattenedView } from '../Purchases/PurchaseFlattenedView';
import * as styles from './PurchaseSearch.css';
import { PurchaseSearchFilterBar } from './PurchaseSearchFilterBar';
import { PurchaseSearchToolbar } from './PurchaseSearchToolbar';

export function PurchaseSearch() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={PURCHASE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<PurchaseOrderQuery>
        initialQuery={{
          ...EmptyPurchaseQuery,
          viewMode: PurchaseViewMode.FlattenedView,
        }}
        emptyQuery={EmptyPurchaseQuery}
        queryValueTransformToUrl={purchaseQueryValueTransformToUrl}
        queryValueTransformFromUrl={purchaseQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <PurchasePage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

function PurchasePage() {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();

  const hasSelectedFilters = useMemo(
    () => Object.keys(selectedFilters ?? {}).length > 0,
    [selectedFilters]
  );

  const getCatalogDataExpandedCallback = useCallback(
    (viagVirtualIds: string[], transformedFilterQuery: PurchaseOrderQuery) => {
      if (!hasSelectedFilters) {
        return Promise.resolve({});
      }

      return getCatalogDataExpanded(viagVirtualIds, transformedFilterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('PurchaseClient.getTicketGroupsForEvents', error, {
            ...transformedFilterQuery,
            eventIds: viagVirtualIds,
          });
        },
      });
    },
    [activeAccountWebClientConfig, hasSelectedFilters, trackError]
  );

  const getCatalogDataCallback = useCallback(
    async (c: CatalogClient, f: PurchaseOrderQuery) => {
      if (!hasSelectedFilters) {
        return {
          events: {},
          performers: {},
          venues: {},
          venueCfgs: {},
        } as CatalogResults;
      }

      return await getCatalogData(c, f, true);
    },
    [hasSelectedFilters]
  );

  return (
    <CatalogDataContextProvider<PurchaseOrderQuery>
      queryKey="getCatalogForPurchaseOrders"
      getCatalogData={getCatalogDataCallback}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
    >
      <PurchaseDataContextProvider
        viewModeOverride={PurchaseViewMode.FlattenedView}
        useReadonlyDB
      >
        <CatalogMultiSelectionContextProvider type={'purchase'}>
          <MultiSelectionContextProvider>
            <PurchaseSearchContent
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </MultiSelectionContextProvider>
        </CatalogMultiSelectionContextProvider>
      </PurchaseDataContextProvider>
    </CatalogDataContextProvider>
  );
}

function PurchaseSearchContent({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters?: SelectedFilters;
  setSelectedFilters: (sf?: SelectedFilters) => void;
}) {
  const purchaseSearchTitle = useContent(ContentId.SearchPurchases);
  const { filterQuery, isQueryInitialized, setFilterQuery, setTempQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const location = useLocation();
  const {
    eventsTransformed,
    isLoading: isCatalogDataLoading,
    refreshCatalog,
  } = useCatalogDataContext();
  const [disabled, setDisabled] = useState(false);
  const [activeSearchConfig, setActiveSearchConfig] =
    useState<PurchaseSearchResult>();

  // Only use value provided at the first time (should be from '/purchases')
  const returnUrl = useMemo(() => {
    const locationState = (location.state ?? {}) as EventPageLocationState;

    if (locationState.returnUrl && locationState.keepReturnUrlUnchanged) {
      return locationState.returnUrl;
    }

    const relativeUrl =
      locationState.returnUrl || getDefaultReturnUrl('purchases');

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      PurchaseDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state]);

  const { allPurchasesQuery, errorInfo, onSetActivePurchaseOrderCallback } =
    usePurchaseDataContext();

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  const dataIsLoading =
    !isQueryInitialized ||
    isCatalogDataLoading ||
    eventsTransformed == null ||
    allPurchasesQuery.isLoading;

  const hasData = allPurchasesQuery.data?.length;

  const { allPurchasesFiltered } = usePurchaseDataContext();

  const onSetActiveSearchConfig = useCallback(
    (searchConfig?: PurchaseSearchResult) => {
      // If this is already selected, clicking will do unselection
      if (searchConfig && searchConfig.id !== activeSearchConfig?.id) {
        // this config already has the result ids - use it to fetch for data
        setFilterQuery({
          ...(searchConfig.query ?? EmptyPurchaseQuery),
          // TODO - this should be replaced with the searchConfig id
          // and the entity ids can be obtained server-side
          entityIds: searchConfig.entityIds,
        });
        setTempQuery(searchConfig.query ?? EmptyPurchaseQuery);
        setActiveSearchConfig(searchConfig);
        setSelectedFilters(undefined);
      } else {
        setActiveSearchConfig(undefined);
        setSelectedFilters(undefined);
      }
    },
    [activeSearchConfig?.id, setFilterQuery, setSelectedFilters, setTempQuery]
  );

  const onSaveSearchResult = useCallback(
    (searchResult?: PurchaseSearchResult) => {
      setDisabled?.(true);
      tryInvokeApi(
        async () => {
          const entityIds = allPurchasesFiltered?.map((p) => p.id) ?? [];
          const newSearchConfig = {
            ...(searchResult ?? {
              // all these info will be filled in server-side
              id: null,
              name: null,
              ownerUserId: null,
              count: null,
              lastFetchDate: null,
            }),
            count: entityIds.length,
            entityIds,
            query: {
              ...filterQuery,
              // nullify the ids because we only want the raw filters, the ids is captured in entityIds
              entityIds: null,
              marketplaceEntityIds: null,
            },
          };

          const result = await new SearchClient(
            activeAccountWebClientConfig
          ).savePurchaseSearchResult(newSearchConfig);

          if (result) {
            setActiveSearchConfig(result);
          }
        },
        (error) => {
          showErrorDialog('SearchClient.savePurchaseSearchResult', error, {
            trackErrorData: {
              searchResult,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      activeAccountWebClientConfig,
      allPurchasesFiltered,
      filterQuery,
      setDisabled,
      showErrorDialog,
    ]
  );

  useEffect(() => {
    if (hasData) {
      const newEntityIds = (
        allPurchasesFiltered?.map((p) => p.id) ?? []
      ).sort();
      const curEntityIds = (activeSearchConfig?.entityIds ?? []).sort();

      if (!isEqual(newEntityIds, curEntityIds)) {
        if (activeSearchConfig?.id) {
          // if there is current result
          // then save it
          onSaveSearchResult(activeSearchConfig);
        } else {
          setActiveSearchConfig({
            id: null,
            name: null,
            ownerUserId: null,
            count: newEntityIds.length,
            lastFetchDate: new Date().toISOString(),
            query: {
              ...filterQuery,
              // nullify the ids because we only want the raw filters, the ids is captured in entityIds
              entityIds: null,
              marketplaceEntityIds: null,
            },
            entityIds: newEntityIds,
          });
        }
      }
    }
  }, [
    activeSearchConfig,
    allPurchasesFiltered,
    filterQuery,
    hasData,
    onSaveSearchResult,
  ]);

  const onRequeryForTheResult = useCallback(async () => {
    setDisabled(true);
    try {
      await refreshCatalog();
      await allPurchasesQuery.refetch();
    } finally {
      setDisabled(false);
    }
  }, [allPurchasesQuery, refreshCatalog]);

  return (
    <ActivePosEntityProvider<PurchaseOrderDetails>
      entityType={ActionOutboxEntityType.Purchase}
      getActivePosEntity={getActivePosEntity}
      onSetActiveItemCompleteCallback={onSetActivePurchaseOrderCallback}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.PurchaseSearch}
          routeTitle={purchaseSearchTitle}
          returnUrl={returnUrl}
          rightContent={
            activeSearchConfig?.lastFetchDate && (
              <Stack gap="s" alignItems="center">
                <span>
                  <Content id={ContentId.CompletedOn} />:
                </span>
                <span>
                  {format(
                    new Date(activeSearchConfig.lastFetchDate),
                    'M/d/yyyy h:mm a'
                  )}
                  .
                </span>
                <Button
                  disabled={disabled || allPurchasesQuery.isRefetching}
                  variant="textPlain"
                  size="unset"
                  shape="inline"
                  onClick={onRequeryForTheResult}
                >
                  <Content id={ContentId.RefreshNow} />
                  {disabled || allPurchasesQuery.isRefetching ? (
                    <RotatingWrapper>
                      <ProcessingIcon
                        fill={IconsFill.textPrimary}
                        size={vars.iconSize.s}
                      />
                    </RotatingWrapper>
                  ) : (
                    <ReloadIcon
                      withHoverEffect
                      size={vars.iconSize.s}
                      fill={IconsFill.textPrimary}
                    />
                  )}
                </Button>
              </Stack>
            )
          }
        >
          <EntitySearchConfigsToolbar
            disabled={disabled}
            setDisabled={setDisabled}
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={onSetActiveSearchConfig}
            getSearchConfigs={(client, includeAllUser) =>
              client.getPurchaseSearchConfigs(includeAllUser)
            }
            getSearchResult={(client, id) => client.getPurchaseSearchResult(id)}
          />
          <DividerLine style={{ margin: 0 }} />
          <PurchaseSearchFilterBar
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <PurchaseSearchToolbar
            disabled={disabled}
            setDisabled={setDisabled}
            activeSearchConfig={activeSearchConfig}
            setActiveSearchConfig={onSetActiveSearchConfig}
            onSaveSearchResult={onSaveSearchResult}
          />
          <DividerLine
            style={{ margin: 0, borderColor: vars.color.borderLight }}
          />
          <div className={styles.contentContainer}>
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {!dataIsLoading && !errorInfo && (
              <>
                {hasData ? (
                  <PurchaseFlattenedView
                    topListItemBefore={
                      <PurchaseGlobalActionsPermissionsWrapper>
                        <SetPurchasesFlattenedViewSelectionCountLabel>
                          <MultiSelectActionBar>
                            <PurchasesGlobalBulkActions />
                          </MultiSelectActionBar>
                        </SetPurchasesFlattenedViewSelectionCountLabel>
                      </PurchaseGlobalActionsPermissionsWrapper>
                    }
                  />
                ) : (
                  <EmptySectionContent.Root
                    icon={
                      <EmptySectionContent.SolidIconContainer>
                        <SearchSolidIcon />
                      </EmptySectionContent.SolidIconContainer>
                    }
                  >
                    <EmptySectionContent.Label>
                      <Content id={ContentId.NoResultFound} />
                    </EmptySectionContent.Label>
                    <EmptySectionContent.DetailMessage>
                      <Content id={ContentId.NoEventsMatching} />
                    </EmptySectionContent.DetailMessage>
                  </EmptySectionContent.Root>
                )}
              </>
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
