import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { EventInfo } from 'src/components/Events/EventInfo';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { PosLink } from 'src/core/POS/PosLink';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { MarketplacePaymentLineWithSaleAndEventInfo } from 'src/hooks/useGetMarketplacePaymentLineTableData';
import { SHIMMERING_DIV_HEIGHT_PAYMENTS } from 'src/tables/MarketplacePaymentLinesTable/MarketplacePaymentLinesTableCommon.constants';
import { TableShimmeringDiv } from 'src/tables/Table';
import { MarketplacePaymentLinesTableColumnId } from 'src/utils/columns/marketplacePaymentLines/marketplacePaymentLinesColumnsUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import {
  getSaleDetailsRelativeUrl,
  getSaleStatusDisplayContentId,
} from 'src/utils/saleUtils';
import {
  sortMarketplacePaymentLineSeating,
  sortMoney,
} from 'src/utils/tableUtils';
import {
  MarketplacePaymentLineType,
  PointOfSaleSaleStatus,
  Seating,
  UiMoney,
} from 'src/WebApiController';

import { MarketplacePaymentLinesActionsCell } from './Cell/MarketplacePaymentLinesActionsCell';

export const useMarketplacePaymentLinesTableColumnsConfig = () => {
  return useMemo<
    Record<
      MarketplacePaymentLinesTableColumnId,
      ColumnDef<MarketplacePaymentLineWithSaleAndEventInfo | null>
    >
  >(() => {
    return {
      [MarketplacePaymentLinesTableColumnId.Id]: {
        id: MarketplacePaymentLinesTableColumnId.Id,
        accessorFn: (data) => data?.extLineId,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentLine} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_BASE,
        minSize: COLUMN_DEFAULT_SIZE_SM,
        maxSize: COLUMN_DEFAULT_SIZE_LG,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          const paymentLineId = getValue<number>();
          return <TableCellDiv align="left">{paymentLineId}</TableCellDiv>;
        },
      },
      [MarketplacePaymentLinesTableColumnId.Paid]: {
        id: MarketplacePaymentLinesTableColumnId.Paid,
        accessorFn: (data) => data?.amt,
        header: (params) => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Amount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          const amount = getValue<UiMoney | null>();

          if (!amount) {
            return null;
          }

          return <TableCellDiv align="right">{amount.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplacePaymentLineWithSaleAndEventInfo>,
      },
      [MarketplacePaymentLinesTableColumnId.Type]: {
        id: MarketplacePaymentLinesTableColumnId.Type,
        accessorFn: (data) => data?.type,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Type} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_BASE,
        minSize: COLUMN_DEFAULT_SIZE_SM,
        maxSize: COLUMN_DEFAULT_SIZE_LG,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          const paymentType = getValue<MarketplacePaymentLineType>();
          return (
            <TableCellDiv align="left">
              <Content id={MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID[paymentType]} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentLinesTableColumnId.Event]: {
        id: MarketplacePaymentLinesTableColumnId.Event,
        accessorFn: (data) => data,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Event} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_DEFAULT_SIZE_LG,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          const paymentLine =
            getValue<MarketplacePaymentLineWithSaleAndEventInfo | null>();
          const { event, venue } = paymentLine ?? {};

          if (!event) {
            return null;
          }

          const [eventName, eventNameConnector, eventSubName] =
            getFormattedEventName({
              event,
              performer: null,
              venue,
              slimMode: false,
            });

          const venueLocation = venue && getFormattedLocation(venue);

          return (
            <TableCellDiv align="left">
              <EventInfo
                slimMode={true}
                eventName={eventName}
                eventNameConnector={eventNameConnector}
                eventSubName={eventSubName}
                venueName={venue?.name}
                venueLocation={venueLocation}
                eventDateString={event?.dates?.start ?? undefined}
              />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentLinesTableColumnId.Seating]: {
        id: MarketplacePaymentLinesTableColumnId.Seating,
        accessorFn: (data) => data?.seating,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Seating} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          const seating = getValue<Seating | null>();

          if (!seating) {
            return null;
          }

          return (
            <TableCellDiv align="left">
              <SeatingInfo {...seating} />
            </TableCellDiv>
          );
        },
        sortingFn: sortMarketplacePaymentLineSeating,
      },
      [MarketplacePaymentLinesTableColumnId.Sale]: {
        id: MarketplacePaymentLinesTableColumnId.Sale,
        accessorFn: (data) => data?.extSaleId,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Sale} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          const extSaleId = getValue<string | null>();

          if (!extSaleId) {
            return null;
          }

          return (
            <TableCellDiv align="left">
              <Stack direction="column" alignItems="end">
                {original.saleId != null ? (
                  <PosLink
                    to={getSaleDetailsRelativeUrl(original.saleId)}
                    variant="brand"
                  >
                    <span>#{extSaleId}</span>
                  </PosLink>
                ) : (
                  <span>#{extSaleId}</span>
                )}
                <span>{original.marketplace}</span>
              </Stack>
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentLinesTableColumnId.SaleStatus]: {
        id: MarketplacePaymentLinesTableColumnId.SaleStatus,
        accessorFn: (data) => data?.saleStatus,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.SaleStatus} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }
          const saleStatus = getValue<PointOfSaleSaleStatus | null>();

          if (!saleStatus) {
            return null;
          }

          return (
            <TableCellDiv align="left">
              <Content id={getSaleStatusDisplayContentId(saleStatus)} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentLinesTableColumnId.Actions]: {
        id: MarketplacePaymentLinesTableColumnId.Actions,
        accessorFn: (data) => data?.id,
        enableSorting: false,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.Actions} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const marketplacePaymentLineId = getValue<number | null>();

          if (!original) {
            return (
              <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
            );
          }

          if (marketplacePaymentLineId == null) {
            return null;
          }

          return (
            <TableCellDiv align="right" showTooltip={false}>
              <MarketplacePaymentLinesActionsCell
                marketplacePaymentLine={original}
              />
            </TableCellDiv>
          );
        },
      },
    };
  }, []);
};
