import { IconsProps } from 'src/svgs/Viagogo';
import { SvgWrapper } from 'src/svgs/Viagogo/svg-wrapper/icon-wrapper';

export const UnlockedIcon = (props: IconsProps) => {
  return (
    <SvgWrapper {...props} fill="none">
      <g clipPath="url(#clip0_451_604)">
        <path
          d="M13.3332 4.52665C13.3332 2.68582 11.8407 1.19332 9.99984 1.19332C8.159 1.19332 6.6665 2.68582 6.6665 4.52665V8.69332"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.8333 8.69332H4.16667C3.24619 8.69332 2.5 9.43951 2.5 10.36V17.0267C2.5 17.9471 3.24619 18.6933 4.16667 18.6933H15.8333C16.7538 18.6933 17.5 17.9471 17.5 17.0267V10.36C17.5 9.43951 16.7538 8.69332 15.8333 8.69332Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M10 12.86V15.36"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9.99984 13.6933C10.4601 13.6933 10.8332 13.3202 10.8332 12.86C10.8332 12.3998 10.4601 12.0267 9.99984 12.0267C9.5396 12.0267 9.1665 12.3998 9.1665 12.86C9.1665 13.3202 9.5396 13.6933 9.99984 13.6933Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_451_604">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.359985)"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
