import { useState } from 'react';
import { useToggle } from 'react-use';
import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import { FilterInput } from '../VendorManagement/Vendors/components/FilterInput';
import { AddUserModal } from './EditUser/AddUserModal';
import * as styles from './UsersManagement.css';
import { UsersTable } from './UsersTable';

export const Users = () => {
  const [isAddUserModalOpen, toggleAddUserModalOpen] = useToggle(false);
  const users = useContent(ContentId.Users);
  const [searchText, setSearchText] = useState<string>('');
  const hasUserSearchBar = useUserHasFeature(Feature.UserSearchBar);

  return (
    <Stack className={styles.root} direction="column" gap="l">
      <Stack justifyContent="spaceBetween" alignItems="center">
        <div className={styles.title}>
          <Content id={ContentId.Users} />
        </div>
        <Stack direction="row" gap="m" alignItems="center">
          {hasUserSearchBar && (
            <FilterInput
              onSearchTextChanged={setSearchText}
              searchEntityLabel={users}
            />
          )}
          <Button
            variant="outline"
            style={{ alignSelf: 'flex-end', textTransform: 'capitalize' }}
            onClick={toggleAddUserModalOpen}
          >
            <PlusIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
            <span style={{ whiteSpace: 'nowrap' }}>
              <Content id={ContentId.NewUser} />
            </span>
          </Button>
        </Stack>
      </Stack>
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={toggleAddUserModalOpen}
      />
      <UsersTable searchText={searchText} />
    </Stack>
  );
};
