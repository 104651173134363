import { useCallback } from 'react';
import { FilterToolbarItemId } from 'src/components/Filters';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { useMarketplacePaymentsFilters } from 'src/components/MainFilterBar/useMarketplacePaymentsFilters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import {
  DefaultQuickFilter,
  useFilterToolbarQuickFilters,
} from 'src/hooks/useQuickFilters';
import { MarketplacePaymentQuery, UserSetting } from 'src/WebApiController';

const mandatoryFiltersToShow: FilterToolbarItemId[] = ['paymentDates'];
const defaultQuickFilters: DefaultQuickFilter<MarketplacePaymentQuery>[] = [];

export const MarketplacePaymentsFilterBar = () => {
  const {
    initialQuery,
    tempQuery,
    resetTempQuery,
    setFilterQuery,
    filterQuery: marketplacePaymentsQuery,
  } = useFilterQueryContext<MarketplacePaymentQuery>();

  const { filters } = useMarketplacePaymentsFilters();

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: marketplacePaymentsQuery,
    mandatoryFiltersToShow: mandatoryFiltersToShow,
    initialQuery,
  });

  const onQuickFilterSelect = useCallback(
    ({ query }: { id: string; query: MarketplacePaymentQuery }) => {
      setFilterQuery(query);
    },
    [setFilterQuery]
  );

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateMarketplacePayments,
    customQuickFiltersSetting:
      UserSetting.QuickFiltersCustomMarketplacePayments,
    defaultQuickFilters,
    currentQuery: marketplacePaymentsQuery,
    initialQuery,
    onSelect: onQuickFilterSelect,
  });

  return (
    <div>
      <FilterToolbar
        {...filterToolbarProps}
        filterAppliedCounts={appliedFilters.length}
        filterAppliedIds={appliedFilters}
        onSubmitFilter={() => setFilterQuery(tempQuery)}
        filters={filters}
        resetTempQuery={resetTempQuery}
        tempQuery={tempQuery}
        mandatoryFiltersToShow={mandatoryFiltersToShow}
      />
    </div>
  );
};
