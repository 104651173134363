import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { SearchResult } from 'src/WebApiController';

import { EntitySearchConfigDialog } from '../EntitySearchConfigDialog';

export const EntitySearchConfigActions = <T extends SearchResult>({
  activeSearchConfig,
  disabled,
  onSaveSearchResult,
  onDeleteSearchResult,
}: {
  activeSearchConfig?: T;
  disabled?: boolean;
  onSaveSearchResult: (searchResult: T) => void;
  onDeleteSearchResult: (searchResult: T) => void;
}) => {
  const { loginContext } = useAppContext();
  const saveSearchResultDlg = useBasicDialog();
  const deleteConfirmDlg = useBasicDialog();

  const isNotOwned =
    activeSearchConfig?.ownerUserId &&
    activeSearchConfig.ownerUserId !== loginContext?.user?.userId;

  return (
    <Stack gap="m" alignItems="center">
      <Button
        variant="regular"
        disabled={disabled || !activeSearchConfig?.entityIds?.length}
        onClick={() => {
          saveSearchResultDlg.launchDialog();
        }}
      >
        <Content id={isNotOwned ? ContentId.SaveCopy : ContentId.Save} />
      </Button>

      {activeSearchConfig?.id && !isNotOwned && (
        <Button
          variant="outline"
          disabled={disabled}
          onClick={deleteConfirmDlg.launchDialog}
        >
          <Content id={ContentId.Delete} />
        </Button>
      )}

      <EntitySearchConfigDialog
        searchConfig={activeSearchConfig}
        onSave={(r) => {
          onSaveSearchResult(r);
          saveSearchResultDlg.closeDialog();
        }}
        {...saveSearchResultDlg.dialogProps}
        onClosed={saveSearchResultDlg.closeDialog}
      />
      <ConfirmDialog
        {...deleteConfirmDlg.dialogProps}
        size={'m'}
        headerText={<Content id={ContentId.AreYouSure} />}
        bodyText={
          <Stack gap="xl">
            <Content id={ContentId.DeleteSearchResultConfirm} />
            <b>{activeSearchConfig?.name}</b>
          </Stack>
        }
        onOkay={() => {
          if (activeSearchConfig?.id) {
            onDeleteSearchResult(activeSearchConfig);
          }
          deleteConfirmDlg.closeDialog();
        }}
        onCancel={deleteConfirmDlg.closeDialog}
      />
    </Stack>
  );
};
