import React, { ComponentProps, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  SetCommissionForm,
  SetCommissionsForm,
  SetCommissionsRefActions,
} from 'src/components/common/SetCommissions/SetCommissionsForm';

import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { SaleCommissionOverrideFormWrapper } from 'src/dialogs/SaleCommissionOverrideDialog/SaleCommissionOverrideFormWrapper';
import { ContentId } from 'src/utils/constants/contentId';
import { Commission, PosFieldArrayOfCommission } from 'src/WebApiController';
import * as styles from './SaleCommissionOverrideDialog.css';

export type SaleCommissionOverrideDialogProps = {
  onSave: (commissions: Commission[]) => void;
  onCancel?: () => void;
} & ComponentProps<typeof RSModal>;

export function SaleCommissionOverrideDialog({
  header,
  onSave,
  onCancel,
  ...rest
}: SaleCommissionOverrideDialogProps) {
  const actionsRef = useRef<SetCommissionsRefActions | null>(null);
  const { watch } = useFormContext<SetCommissionForm>();
  const commissions = watch('commissions');
  const [initialCommissions] = useState<PosFieldArrayOfCommission | null>(
    commissions
  );
  return (
    <GenericDialog
      size="m"
      {...rest}
      header={header}
      onCancel={onCancel}
      footer={
        <>
          {onCancel && <CancelButton onClick={onCancel} />}
          <OkButton
            textContentId={ContentId.Save}
            onClick={() => actionsRef.current?.onSave()}
          />
        </>
      }
      includeCloseButton={true}
    >
      <div>
        <span className={styles.buyerCommissionTitle}>
          <Content id={ContentId.BuyerCommission} />
        </span>
        <SaleCommissionOverrideFormWrapper commissions={initialCommissions}>
          <SetCommissionsForm onSave={onSave} actionsRef={actionsRef} />
        </SaleCommissionOverrideFormWrapper>
      </div>
    </GenericDialog>
  );
}
