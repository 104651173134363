import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PerformerVenueList } from 'src/components/Events/PerformerVenueList';
import {
  FilterToolbar,
  FilterToolbarProps,
} from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import {
  listingsMandatoryFiltersToShow,
  useListingFilters,
} from 'src/components/MainFilterBar/useListingFilters';
import {
  saleMandatoryFiltersToShow,
  useSaleFilters,
} from 'src/components/MainFilterBar/useSaleFilters';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Button, Popover, Stack } from 'src/core/ui';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultListingQuery,
  DefaultSaleQuery,
} from 'src/utils/eventQueryUtils';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
  ReportPerformerVenueFilterItemId,
} from 'src/utils/reportsFilterUtils';
import { ReportTypes } from 'src/utils/reportsUtils';
import { Feature, ListingQuery, SaleQuery } from 'src/WebApiController';

import * as styles from './ReportBuilderDialog.css';
import {
  getEditability,
  updateFilterItemIds,
} from './ReportBuilderFilterInput.utils';

type ReportFilterInputProps = {
  onSubmitFilter?: () => void;
  showAllButton?: boolean;
  filterAppliedMessage?: React.ReactNode;
} & Pick<FilterToolbarProps, 'embeddedDisplayOnly' | 'performerVenueFilter'>;

export function ReportBuilderFilterInput({
  reportType,
  onSubmitFilter,
  showAllButton = true,
  filterAppliedMessage,
  embeddedDisplayOnly,
}: {
  reportType: ReportTypes;
} & ReportFilterInputProps) {
  const { watch } = useFormContext<ReportConfig>();

  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const performerFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.PerformerIds
  );
  const venueFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.VenueIds
  );

  const hidePerformerVenueFilter =
    embeddedDisplayOnly &&
    (performerFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) ===
      ReportFilterEditability.Hidden &&
    (venueFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) ===
      ReportFilterEditability.Hidden;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="l"
      style={{ flexGrow: '1', maxWidth: '90%' }}
    >
      {!embeddedDisplayOnly && (
        <PerformerVenuePopover onSubmitFilter={onSubmitFilter} />
      )}
      {reportType === ReportTypes.Inventory ? (
        <ListingFilterInput
          onSubmitFilter={onSubmitFilter}
          showAllButton={showAllButton}
          filterAppliedMessage={filterAppliedMessage}
          embeddedDisplayOnly={embeddedDisplayOnly}
          performerVenueFilter={
            hidePerformerVenueFilter ? undefined : (
              <PerformerVenuePopover
                onSubmitFilter={onSubmitFilter}
                embeddedDisplayOnly={embeddedDisplayOnly}
              />
            )
          }
        />
      ) : (
        <SaleFilterInput
          onSubmitFilter={onSubmitFilter}
          showAllButton={showAllButton}
          filterAppliedMessage={filterAppliedMessage}
          embeddedDisplayOnly={embeddedDisplayOnly}
          performerVenueFilter={
            hidePerformerVenueFilter ? undefined : (
              <PerformerVenuePopover
                onSubmitFilter={onSubmitFilter}
                embeddedDisplayOnly={embeddedDisplayOnly}
              />
            )
          }
        />
      )}
    </Stack>
  );
}

const ListingFilterInput = ({
  onSubmitFilter,
  showAllButton = true,
  filterAppliedMessage,
  embeddedDisplayOnly,
  performerVenueFilter,
}: ReportFilterInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfig>();

  const formFilter = watch('filter');
  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const hasReportSeparateBuilderFiltersFeature = useUserHasFeature(
    Feature.ReportSeparateBuilderFilters
  );

  const {
    initialQuery,
    tempQuery,
    filterQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<ListingQuery>();
  const { filters } = useListingFilters(
    false /* isEventPage */,
    true /* isReportsPage */
  );

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  useEffect(() => {
    if (formFilter != null && !isEqual(formFilter as ListingQuery, tempQuery)) {
      setTempQuery(formFilter as ListingQuery);
      setFilterQuery(formFilter as ListingQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (embeddedDisplayOnly) {
      // We don't have apply button for embedded view. So, we need to submit filter on every change
      onSubmitFilterHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuery]);

  useEffect(() => {
    if (!isEqual(formFilter as ListingQuery, filterQuery)) {
      setValue('filter', filterQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: filterQuery,
    mandatoryFiltersToShow: listingsMandatoryFiltersToShow,
    initialQuery,
  });

  const { appliedFilters: appliedFiltersRelativeToEmpty } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: listingsMandatoryFiltersToShow,
    initialQuery: DefaultListingQuery,
  });

  const filtersWithEditability = useMemo(
    () =>
      filters.map((filterGroup) => ({
        ...filterGroup,
        items: filterGroup.items.map((filterItem) => ({
          ...filterItem,
          reportFilterEditability: getEditability(
            editableFilterItemIds,
            viewableFilterItemIds,
            hiddenFilterItemIds,
            filterItem.filterId
          ),
        })),
      })),
    [editableFilterItemIds, filters, hiddenFilterItemIds, viewableFilterItemIds]
  );

  const onEditabilityChange = useCallback(
    (filterId: string | string[], editability: ReportFilterEditability) => {
      const filterIds = Array.isArray(filterId) ? filterId : [filterId];

      const {
        newHiddenFilterItemIds,
        newViewableFilterItemIds,
        newEditableFilterItemIds,
      } = updateFilterItemIds(
        editableFilterItemIds,
        viewableFilterItemIds,
        hiddenFilterItemIds,
        filterIds,
        editability
      );

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds);
      setValue('viewableFilterItemIds', newViewableFilterItemIds);
      setValue('editableFilterItemIds', newEditableFilterItemIds);
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <FilterToolbar
      onResetAll={() => {
        setTempQuery(initialQuery);
        setFilterQuery(initialQuery);
      }}
      isAllSelected={false}
      showCustomFilterButton={false}
      filterAppliedCounts={appliedFilters.length}
      allContentId={ContentId.ResetAll}
      filters={filtersWithEditability}
      filterAppliedIds={appliedFiltersRelativeToEmpty}
      onSubmitFilter={onSubmitFilterHandler}
      showAllButton={showAllButton}
      mandatoryFiltersToShow={listingsMandatoryFiltersToShow}
      filterAppliedMessage={filterAppliedMessage}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      onEditabilityChange={
        hasReportSeparateBuilderFiltersFeature ? onEditabilityChange : undefined
      }
      embeddedDisplayOnly={embeddedDisplayOnly}
      performerVenueFilter={performerVenueFilter}
    />
  );
};

const SaleFilterInput = ({
  onSubmitFilter,
  showAllButton = true,
  filterAppliedMessage,
  embeddedDisplayOnly,
  performerVenueFilter,
}: ReportFilterInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfig>();

  const formFilter = watch('filter');
  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const hasReportSeparateBuilderFiltersFeature = useUserHasFeature(
    Feature.ReportSeparateBuilderFilters
  );

  const {
    initialQuery,
    tempQuery,
    filterQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<SaleQuery>();
  const { filters } = useSaleFilters(true /* isReportsPage */);

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  useEffect(() => {
    if (formFilter != null && !isEqual(formFilter as SaleQuery, tempQuery)) {
      setTempQuery(formFilter as SaleQuery);
      setFilterQuery(formFilter as SaleQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (embeddedDisplayOnly) {
      // We don't have apply button for embedded view. So, we need to submit filter on every change
      onSubmitFilterHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuery]);

  useEffect(() => {
    if (!isEqual(formFilter as SaleQuery, filterQuery)) {
      setValue('filter', filterQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: filterQuery,
    mandatoryFiltersToShow: saleMandatoryFiltersToShow,
    initialQuery,
  });

  const { appliedFilters: appliedFiltersRelativeToEmpty } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: saleMandatoryFiltersToShow,
    initialQuery: DefaultSaleQuery,
  });

  const filtersWithEditability = useMemo(
    () =>
      filters.map((filterGroup) => ({
        ...filterGroup,
        items: filterGroup.items.map((filterItem) => ({
          ...filterItem,
          reportFilterEditability: getEditability(
            editableFilterItemIds,
            viewableFilterItemIds,
            hiddenFilterItemIds,
            filterItem.filterId
          ),
        })),
      })),
    [editableFilterItemIds, filters, hiddenFilterItemIds, viewableFilterItemIds]
  );

  const onEditabilityChange = useCallback(
    (filterId: string | string[], editability: ReportFilterEditability) => {
      const filterIds = Array.isArray(filterId) ? filterId : [filterId];

      const {
        newHiddenFilterItemIds,
        newViewableFilterItemIds,
        newEditableFilterItemIds,
      } = updateFilterItemIds(
        editableFilterItemIds,
        viewableFilterItemIds,
        hiddenFilterItemIds,
        filterIds,
        editability
      );

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds);
      setValue('viewableFilterItemIds', newViewableFilterItemIds);
      setValue('editableFilterItemIds', newEditableFilterItemIds);
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <FilterToolbar
      onResetAll={() => {
        setTempQuery(initialQuery);
        setFilterQuery(initialQuery);
      }}
      isAllSelected={false}
      showCustomFilterButton={false}
      filterAppliedCounts={appliedFilters.length}
      allContentId={ContentId.ResetAll}
      filters={filtersWithEditability}
      filterAppliedIds={appliedFiltersRelativeToEmpty}
      onSubmitFilter={onSubmitFilterHandler}
      showAllButton={showAllButton}
      mandatoryFiltersToShow={saleMandatoryFiltersToShow}
      filterAppliedMessage={filterAppliedMessage}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      onEditabilityChange={
        hasReportSeparateBuilderFiltersFeature ? onEditabilityChange : undefined
      }
      embeddedDisplayOnly={embeddedDisplayOnly}
      performerVenueFilter={performerVenueFilter}
    />
  );
};

const PerformerVenuePopover = ({
  onSubmitFilter,
  embeddedDisplayOnly,
}: {
  onSubmitFilter?: () => void;
  embeddedDisplayOnly?: boolean;
}) => {
  const { setValue, watch } = useFormContext<ReportConfig>();

  const hasReportSeparateBuilderFiltersFeature = useUserHasFeature(
    Feature.ReportSeparateBuilderFilters
  );

  const hiddenFilterItemIds = watch('hiddenFilterItemIds');
  const viewableFilterItemIds = watch('viewableFilterItemIds');
  const editableFilterItemIds = watch('editableFilterItemIds');

  const performerFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.PerformerIds
  );
  const venueFilterEditability = getEditability(
    editableFilterItemIds,
    viewableFilterItemIds,
    hiddenFilterItemIds,
    ReportPerformerVenueFilterItemId.VenueIds
  );

  const onEditabilityChange = useCallback(
    (reportFilterId: string, editability: ReportFilterEditability) => {
      const newHiddenFilterItemIds = hiddenFilterItemIds
        ? hiddenFilterItemIds.filter((id) => id !== reportFilterId)
        : [];
      const newViewableFilterItemIds = viewableFilterItemIds
        ? viewableFilterItemIds.filter((id) => id !== reportFilterId)
        : [];
      const newEditableFilterItemIds = editableFilterItemIds
        ? editableFilterItemIds.filter((id) => id !== reportFilterId)
        : [];

      if (editability === ReportFilterEditability.Hidden) {
        newHiddenFilterItemIds.push(reportFilterId);
      } else if (editability === ReportFilterEditability.View) {
        newViewableFilterItemIds.push(reportFilterId);
      } else if (editability === ReportFilterEditability.Edit) {
        newEditableFilterItemIds.push(reportFilterId);
      }

      setValue('hiddenFilterItemIds', newHiddenFilterItemIds.sort());
      setValue('viewableFilterItemIds', newViewableFilterItemIds.sort());
      setValue('editableFilterItemIds', newEditableFilterItemIds.sort());
    },
    [
      editableFilterItemIds,
      hiddenFilterItemIds,
      setValue,
      viewableFilterItemIds,
    ]
  );

  return (
    <Popover.Root onOpenChange={onSubmitFilter}>
      <Popover.Trigger asChild>
        <Button
          className={styles.selectGroupingButton}
          variant={'text'}
          style={{ height: 'fit-content' }}
        >
          <Content id={ContentId.PerformerAndVenue} />
        </Button>
      </Popover.Trigger>
      <Popover.Content align="start">
        <div className={styles.mainGroupingContainer}>
          <PerformerVenueList
            // Don't need onItemsRendered to do anything as we're not showing metrics here
            onItemsRendered={() => {}}
            hideMetrics
            // Have to update tempQuery instead of filterQuery to sync with the main filter bar here
            updateTempQuery
            onEditabilityChange={
              hasReportSeparateBuilderFiltersFeature
                ? onEditabilityChange
                : undefined
            }
            performerFilterEditability={performerFilterEditability}
            venueFilterEditability={venueFilterEditability}
            embeddedDisplayOnly={embeddedDisplayOnly}
          />
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
