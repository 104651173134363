import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { NavLink } from 'reactstrap';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  getFormattedContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Radio, RadioGroup, SimpleTable, Stack } from 'src/core/ui';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { AttrLabel } from 'src/modals/common';
import { GenericFeatureIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import {
  Feature,
  SaleClient,
  SaleDetails,
  Seating,
  SeatingForAlloc,
} from 'src/WebApiController';

import * as styles from './SeatingAllocationInput.css';

export const SeatingAllocationInput = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { getValues, setValue, watch } = useFormContext<SaleDetails>();

  const [allPossibleSeatings, setAllPossibleSeatings] = useState(false);

  const sale = getValues();
  const seating = watch('seating');

  const ticketsForAllocationQuery = useQuery({
    queryKey: [
      'SaleClient.getSeatingsForSaleAllocation',
      sale.id,
      allPossibleSeatings,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const client = new SaleClient(activeAccountWebClientConfig);
      const data = await client.getSeatingsForSaleAllocation(
        sale.id,
        allPossibleSeatings, 
        false,
      );

      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('SaleClient.getSeatingsForSaleAllocation', error, {
          trackErrorData: {
            saleId: sale.id,
            allPossibleSeatings: allPossibleSeatings,
          },
        });
      },
    },
  });

  const possibleSeatings = useMemo(() => {
    const possibleSeatings = ticketsForAllocationQuery.data?.possibleSeatings;
    if (!possibleSeatings) {
      return possibleSeatings;
    }

    const possibleSeatingsConsecutive = possibleSeatings.filter(
      (seating) => !seating.nonConsecSeats
    );

    const possibleSeatingsNonConsecutive = possibleSeatings.filter(
      (seating) => seating.nonConsecSeats
    );

    return [...possibleSeatingsConsecutive, ...possibleSeatingsNonConsecutive];
  }, [ticketsForAllocationQuery.data?.possibleSeatings]);

  const getIndexOf = useCallback(
    (seating: Seating) => {
      let index = possibleSeatings?.findIndex((s) =>
        isEqual(s.ticketIds?.sort(), seating.ticketIds?.sort())
      );

      if (index == null || index < 0) {
        // If we don't have any selected...
        if (possibleSeatings?.length) {
          const newS = possibleSeatings.some((s, i) => {
            if (s.recAlloc) {
              // ...auto-select the first recommended one if there is one
              setValue('seating', s);
              index = i;

              // break
              return true;
            }

            return false;
          });

          if (!newS && !sale.isSeatSaver) {
            // if we still have nothing, just select the first one
            setValue('seating', possibleSeatings[0]);
            index = 0;
          }
        }
      }

      return index;
    },
    [possibleSeatings, sale.isSeatSaver, setValue]
  );

  if (ticketsForAllocationQuery.isFetching && !possibleSeatings?.length) {
    return <PosSpinner size={vars.iconSize.xxs} />;
  }

  return (
    <RadioGroup
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: vars.spacing.lg,
        height: '100%',
        width: '100%',
      }}
      onValueChange={(value) => {
        const selectedIndex = parseInt(value);
        const newSeating = possibleSeatings?.[selectedIndex];

        if (newSeating) {
          setValue('seating', newSeating);
        }
      }}
      value={getIndexOf(seating)?.toString()}
    >
      {possibleSeatings?.length ? (
        <>
          <div className={styles.tableContainerVirtuoso}>
            <TableVirtuoso
              data={possibleSeatings}
              style={{ width: '100%', height: '100%' }}
              overscan={{ main: 10, reverse: 10 }}
              components={{
                Table: SimpleTable.Table,
                TableHead: SimpleTable.Thead,
                TableBody: SimpleTable.Tbody,
                TableRow: SimpleTable.Tr,
              }}
              fixedHeaderContent={() => (
                <SimpleTable.Tr
                  style={{
                    backgroundColor: vars.color.backgroundPrimary,
                  }}
                >
                  <SimpleTable.Th>
                    <AttrLabel>&nbsp;</AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Section} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Row} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Seats} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.PurchasedBy} />
                    </AttrLabel>
                  </SimpleTable.Th>{' '}
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Vendor} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.VendorAccount} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>&nbsp;</AttrLabel>
                  </SimpleTable.Th>
                </SimpleTable.Tr>
              )}
              itemContent={(index, seating) => (
                <PossibleSeatingTableRow
                  key={index}
                  index={index}
                  seating={seating}
                />
              )}
            />
          </div>
          {ticketsForAllocationQuery.data?.hasMoreAllocations && (
            <Button
              variant="link"
              onClick={() => setAllPossibleSeatings(!allPossibleSeatings)}
            >
              <Content
                id={
                  allPossibleSeatings
                    ? ContentId.AllocateToListingTickets
                    : ContentId.AllocateToDifferentTickets
                }
              />
            </Button>
          )}
        </>
      ) : (
        <Stack direction="column" gap="l">
          <Content id={ContentId.NoSeatingsAvailableForAllocation} />
          <div className={styles.noAllocationContainer}>
            <NavLink
              title={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
              tag={Link}
              to={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
            >
              <Content id={ContentId.AddPurchase} />
            </NavLink>
          </div>
        </Stack>
      )}
    </RadioGroup>
  );
};

const PossibleSeatingTableRow = ({
  seating,
  index,
}: {
  seating: SeatingForAlloc;
  index: number;
}) => {
  const recommended = useContent(ContentId.Recommended);
  const contentContext = useContentContext();
  const deactivatedText = useContent(ContentId.Deactivated);
  const hasDisplayDeactivatedUser = useUserHasFeature(
    Feature.DisplayDeactivatedUser
  );

  const {
    section,
    row,
    seatFr,
    seatTo,
    recAlloc,
    nonConsecSeats,
    tktsForAlloc,
  } = seating;

  const purchasedByUsers = useGetUserInfos([
    ...new Set(
      tktsForAlloc
        ?.filter((t) => t.purchasedBy != null)
        ?.map((t) => t.purchasedBy!)
    ),
  ]);

  const { availableVendors } = usePurchaseVendorSelector({});

  const vendorNames = useMemo(() => {
    const missingVendorNameIds = new Set(
      tktsForAlloc
        .filter((t) => !t.vendorName && t.vendorId)
        .map((t) => t.vendorId!)
    );

    const vendorNames = [...missingVendorNameIds]
      .map((id) => availableVendors[id]?.name)
      .filter((n) => n)
      .map((n) => n!);

    const uniqueNames = new Set([
      ...vendorNames,
      ...tktsForAlloc.filter((t) => t.vendorName).map((t) => t.vendorName!),
    ]);

    return [...uniqueNames];
  }, [availableVendors, tktsForAlloc]);

  const vendorAccountNames = useMemo(() => {
    const uniqueNames = new Set(
      tktsForAlloc
        .filter((t) => t.vendorAccEmail || t.vendorAccName)
        .map((t) => (t.vendorAccEmail || t.vendorAccName)!)
    );

    return [...uniqueNames];
  }, [tktsForAlloc]);

  const seatNumbersDisplay = useMemo(() => {
    const allSeatsHaveValues = tktsForAlloc?.every((t) => !!t.seat);

    if (allSeatsHaveValues) {
      return tktsForAlloc
        ?.map((t) => t.seat)
        .sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
        ) // Sort alphanumerically, case insensitive. This may not follow the underlying ordinality of the seats, but for display purposes it's better
        .join(', ');
    }

    const allSeatsHaveListingOrdinal = tktsForAlloc?.every(
      (t) => t.listingOrd != null
    );

    return tktsForAlloc
      ?.sort((a, b) => {
        if (allSeatsHaveListingOrdinal) {
          return a.listingOrd! - b.listingOrd!;
        }
        return a.ticketOrd - b.ticketOrd;
      })
      .map((t) => {
        if (t.seat) {
          return t.seat;
        }

        return getFormattedContent(
          FormatContentId.SeatNumber,
          [
            (allSeatsHaveListingOrdinal
              ? t.listingOrd!
              : t.ticketOrd
            ).toString(),
          ],
          contentContext
        );
      })
      .join(', ');
  }, [contentContext, tktsForAlloc]);

  return (
    <>
      <SimpleTable.Td className={styles.tableCell}>
        <Radio value={index.toString()} label={null} />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>{section}</SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>{row}</SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>
        {nonConsecSeats ? (
          <>
            {seatNumbersDisplay ? (
              <span title={seatNumbersDisplay}>{seatNumbersDisplay}</span>
            ) : (
              <Content id={ContentId.AllocateToAnyAvailableSeats} />
            )}
          </>
        ) : (
          <>{formatSeatDisplay(seatFr, seatTo)}</>
        )}
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>
        <Stack direction="column">
          {Object.values(purchasedByUsers.data ?? {}).map((user) => (
            <div key={user.id}>
              {' '}
              {`${user?.name} ${
                user?.isDeactivated && !hasDisplayDeactivatedUser
                  ? ` (${deactivatedText})`
                  : ''
              }`}
            </div>
          ))}
        </Stack>
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>
        <Stack direction="column">
          {vendorNames?.map((vn) => <div key={vn}>{vn}</div>)}
        </Stack>
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>
        <Stack direction="column">
          {vendorAccountNames.map((van) => (
            <div key={van}>{van}</div>
          ))}
        </Stack>
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.tableCell}>
        <div
          title={recommended}
          // doing this so the table does not change widths of the column as the value disappear
          // another side-effect of virtuoso
          style={{ opacity: recAlloc && !nonConsecSeats ? '1' : '0' }}
        >
          <GenericFeatureIcon fill={IconsFill.textBrand} />
        </div>
      </SimpleTable.Td>
    </>
  );
};
