import { compareAsc, compareDesc } from 'date-fns';
import {
  CatalogResults,
  EventSort,
  EventWithData,
  ListingMetrics,
} from 'src/WebApiController';

import {
  EventIdGroup,
  MAX_ENTITIES_IN_GROUP_FOR_LOAD,
} from './CatalogMetrics.type';

export const getEventIdsForPerformersOrVenues = (
  events?: Record<string, EventWithData>,
  performerIds?: number[] | null,
  venueEventIds?: number[] | null
) => {
  if (!events || !performerIds) {
    return [];
  }

  const results = Object.values(events)
    .filter(
      (ev) =>
        (ev.event.perfId && performerIds?.includes(ev.event.perfId)) ||
        (ev.event.venueId && venueEventIds?.includes(ev.event.venueId))
    )
    .map((ev) => ev.event.viagVirtualId);

  return results;
};

/* The goal of this method is to aggregate all the listing metrics with similar currency so we can reduce significantly the number of listing metrics
 * being sent to the server for aggregation just because of currency conversions
 * if the aggregation result in just 1 ListingMetrics - we don't need to send anything
 */
export const groupAndAggregateListingMetrics = (
  listingMetrics: ListingMetrics[],
  addMetrics: (
    metricA: ListingMetrics,
    metricB: ListingMetrics
  ) => ListingMetrics
) => {
  const metricsByCurrency = {} as Record<string, ListingMetrics>;

  listingMetrics.forEach((lm) => {
    const existing = metricsByCurrency[lm.currency];
    if (!existing) {
      metricsByCurrency[lm.currency] = lm;
    } else {
      metricsByCurrency[lm.currency] = addMetrics(existing, lm);
    }
  });

  return Object.values(metricsByCurrency);
};

export function getEventsToGetMetricsFor(
  data: CatalogResults | null | undefined,
  sortBy?: EventSort | null,
  isSortDescending?: boolean | null
): EventIdGroup[][] {
  const events = data?.events;
  const allEventIds = Object.values(events ?? {})
    .map(
      (ev) =>
        ({
          viagVirtualId: ev.event.viagVirtualId,
          performerId: ev.event.perfId,
          venueId: ev.event.venueId,
          name: ev.event.name,
          date: ev.event.datesUtc,
        }) as EventIdGroup
    )
    .sort((ev1, ev2) => {
      if (sortBy === EventSort.MainDisplay) {
        return isSortDescending
          ? ev2.name.localeCompare(ev1.name)
          : ev1.name.localeCompare(ev2.name);
      }

      const date1 = ev1.date.start ? new Date(ev1.date.start) : new Date(0);
      const date2 = ev2.date.start ? new Date(ev2.date.start) : new Date(0);
      // Sort the events by dates
      // given 99% of the time the sort is date
      // If the sort is by name
      return isSortDescending
        ? compareDesc(date1, date2)
        : compareAsc(date1, date2);
    });

  if (allEventIds.length <= MAX_ENTITIES_IN_GROUP_FOR_LOAD) {
    return [allEventIds];
  }

  return [
    allEventIds.slice(0, MAX_ENTITIES_IN_GROUP_FOR_LOAD),
    allEventIds.slice(MAX_ENTITIES_IN_GROUP_FOR_LOAD),
  ];
}
