import { useCallback, useEffect, useMemo } from 'react';
import { translateEventToTicketGroupAccordionItem } from 'src/components/Accordions/TicketGroupsEventsAccordion';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { BulkEditHubContextProvider } from 'src/contexts/BulkEditHubContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { PurchaseDataContextProvider } from 'src/contexts/PurchaseDataContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { NoData } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  getCatalogData,
  getCatalogDataExpanded,
  PURCHASE_USER_SETTINGS,
} from 'src/utils/purchaseUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseOrderState,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';
import { useAccordionItem } from '../../Events/EventPage/hooks/useAccordionItem';
import { SidePanelToolbar } from '../../Events/EventPage/SidePanel/SidePanelToolbar';
import { PurchaseSideTableFilterBar } from '../PurchaseFilterBar/PurchaseSideTableFilterBar';

const Purchases = ({
  viagVirtualId,
  queryInput,
}: {
  viagVirtualId: string;
  queryInput?: Partial<Pick<PurchaseOrderQuery, 'listingIds' | 'saleIds'>>;
}) => {
  const { isLoading, isItemsLoading, accordionItemProps } =
    useAccordionItem(viagVirtualId);

  const { setFilterQuery, isQueryInitialized } =
    useFilterQueryContext<PurchaseOrderQuery>();

  useEffect(() => {
    setFilterQuery({
      ...EmptyPurchaseQuery,
      ...queryInput,
      eventOrMappingIds: [viagVirtualId],
      purchaseOrderState: PurchaseOrderState.Active,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInput, setFilterQuery, isQueryInitialized]);

  const purchasesAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToTicketGroupAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  const tgIds = useMemo(
    () =>
      purchasesAccordionItem?.ticketGroups?.map((tg) => Number(tg.tgId)) || [],
    [purchasesAccordionItem?.ticketGroups]
  );

  const hasSelectedEntities =
    (queryInput?.listingIds && queryInput?.listingIds?.length > 0) ||
    (queryInput?.saleIds && queryInput?.saleIds?.length > 0);

  const { BodyComponent, AfterHeaderComponent } = purchasesAccordionItem || {};
  const noData =
    (!isLoading && !purchasesAccordionItem) ||
    (!isItemsLoading && !accordionItemProps?.ticketGroups);
  return (
    <>
      <div className={styles.sidePanelToolbar}>
        <SidePanelToolbar
          sectionType={SectionType.PurchaseSideTable}
          filterBar={<PurchaseSideTableFilterBar />}
          noData={noData}
          entityIds={hasSelectedEntities ? tgIds : []}
        />
        {!noData && AfterHeaderComponent && <AfterHeaderComponent />}
      </div>
      {isLoading && <PosSpinner />}
      {noData ? (
        <NoData>
          <FormatContent
            id={FormatContentId.NoDataAvailable}
            params={<Content id={ContentId.Purchases} />}
          />
        </NoData>
      ) : (
        BodyComponent &&
        accordionItemProps && (
          <BodyComponent
            {...accordionItemProps}
            useTableNavKeys={true}
            useVirtuoso
            withOuterPadding={false}
            disablePagination={true}
            isSideTable={true}
          />
        )
      )}
    </>
  );
};

export const PurchasesSideTable = ({
  viagVirtualId,
  queryInput,
}: {
  viagVirtualId: string;
  queryInput?: Partial<Pick<PurchaseOrderQuery, 'listingIds' | 'saleIds'>>;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: PurchaseOrderQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('PurchaseClient.getTicketGroupsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  return (
    <BulkEditHubContextProvider>
      <SellerUserSettingsProvider
        initialUserSettingIds={PURCHASE_USER_SETTINGS}
        currentLoginUserOnly={true}
      >
        <FilterQueryContextProvider<PurchaseOrderQuery>
          initialQuery={{
            ...EmptyPurchaseQuery,
            eventOrMappingIds: [viagVirtualId],
            purchaseOrderState: PurchaseOrderState.Active,
          }}
          emptyQuery={EmptyPurchaseQuery}
          viewModeSettingId={UserSetting.PurchasePageViewMode}
          loadQueryFromUrl={false}
        >
          <CatalogDataContextProvider<PurchaseOrderQuery>
            queryKey={'getCatalogForPurchaseOrders'}
            getCatalogData={(c, f) => getCatalogData(c, f, true)}
            getCatalogDataExpanded={getCatalogDataExpandedCallback}
            transformEventData={transformData}
          >
            <ActivePosEntityProvider<PurchaseOrderDetails>
              entityType={ActionOutboxEntityType.Purchase}
              getActivePosEntity={getActivePosEntity}
            >
              <PurchaseDataContextProvider>
                <CatalogMultiSelectionContextProvider type="ticketGroup">
                  <MultiSelectionContextProvider>
                    <DialogProvider>
                      <Purchases
                        viagVirtualId={viagVirtualId}
                        queryInput={queryInput}
                      />
                    </DialogProvider>
                  </MultiSelectionContextProvider>
                </CatalogMultiSelectionContextProvider>
              </PurchaseDataContextProvider>
            </ActivePosEntityProvider>
          </CatalogDataContextProvider>
        </FilterQueryContextProvider>
      </SellerUserSettingsProvider>
    </BulkEditHubContextProvider>
  );
};
