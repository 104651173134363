import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SetCommissionForm } from 'src/components/common/SetCommissions/SetCommissionsForm';
import { PosFieldArrayOfCommission } from 'src/WebApiController';

interface SaleCommissionOverrideFormProps {
  children: ReactNode;
  commissions: PosFieldArrayOfCommission | null;
}

/**
 * This wrapper is used to modify commissions overrides, but not apply them
 * to parent form. This solves the cancel option of the modal to rollback changes.
 * @param commissions
 * @param children
 * @constructor
 */
export const SaleCommissionOverrideFormWrapper = ({
  commissions,
  children,
}: SaleCommissionOverrideFormProps) => {
  const methods = useForm<SetCommissionForm>({
    defaultValues: {
      commissions: commissions,
    },
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
