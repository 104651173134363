import { useMutation } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { MarketplacePaymentClient } from 'src/WebApiController';

export function useLinkMarketplacePaymentLineToSale() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  return useMutation({
    mutationFn: async ({
      saleId,
      marketplacePaymentLineId,
    }: {
      saleId: number | undefined;
      marketplacePaymentLineId: number | undefined;
    }) => {
      if (!saleId || !marketplacePaymentLineId) {
        return Promise.reject('Invalid parameters');
      }
      return await new MarketplacePaymentClient(
        activeAccountWebClientConfig
      ).linkMarketplacePaymentLine(saleId, marketplacePaymentLineId);
    },
    onError: (
      error: ErrorTypes,
      { saleId, marketplacePaymentLineId },
      context
    ) => {
      trackError('MarketplacePaymentClient.linkMarketplacePaymentLine', error, {
        saleId,
        marketplacePaymentLineId,
      });
      showErrorDialog(
        'MarketplacePaymentClient.linkMarketplacePaymentLine',
        error,
        {
          trackErrorData: { saleId, marketplacePaymentLineId },
        }
      );
    },
  });
}
