import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack, Switch } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup } from 'src/modals/common';
import { useShouldHideForWastedSale } from 'src/modals/SaleDetails/hooks/useShouldHideForWastedSale';
import { IconsFill, MoreIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency } from 'src/utils/numberFormatter';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  Feature,
  Permission,
  Sale,
  SaleCostType,
  SaleInput,
  SaleLineItem,
} from 'src/WebApiController';

import * as styles from './PaymentSection.css';
import { SaleLineItemModal } from './SaleLineItemModal';

export const PaymentSection = ({
  sale,
  lineItems,
  isBulkEdit,
  onLineItemChange,
}: {
  sale?: Sale | null;
  lineItems?: SaleLineItem[] | null;
  onLineItemChange?: (item: SaleLineItem, isDelete?: boolean) => void;
  isBulkEdit?: boolean;
}) => {
  const { loginContext } = useAppContext();
  const isMobile = useMatchMedia('mobile');
  const hasEditSaleLineItemsFeature = useUserHasFeature(
    Feature.SaleEditChargesAndCredits
  );
  const { watch, setValue } = useFormContext<SaleInput>();

  const costDialog = useBasicDialog();

  const hideForWastedSale = useShouldHideForWastedSale(sale);

  const onAddNewCostSave = useCallback(
    (item: SaleLineItem) => {
      onLineItemChange?.(item);

      // post logic goes here
      costDialog.closeDialog();
    },
    [costDialog, onLineItemChange]
  );

  const onAddNewCostCancel = useCallback(() => {
    costDialog.closeDialog();
  }, [costDialog]);

  const paymentReceived = watch('paymentReceived');
  const paymentReferenceId = watch('paymentReferenceId');

  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  const totalProfitAmount = useMemo(() => {
    let sum = hasTotalNetProceedsPermissions ? sale?.ttlNetProcs?.amt ?? 0 : 0;

    if (sale?.soldTktCost != null) {
      sum -= sale?.soldTktCost.amt;
    }

    if (hasEditSaleLineItemsFeature) {
      lineItems?.forEach((li) => {
        // Per James - charges subtracts and credits add
        sum += (li.costType === SaleCostType.Charge ? -1 : 1) * li.cost;
      });
    }

    return sum;
  }, [
    hasEditSaleLineItemsFeature,
    hasTotalNetProceedsPermissions,
    lineItems,
    sale?.soldTktCost,
    sale?.ttlNetProcs?.amt,
  ]);

  const [curItem, setCurItem] = useState<SaleLineItem | undefined>();

  const onAddNewCost = useCallback(() => {
    costDialog.launchDialog();
  }, [costDialog]);

  return (
    <Stack direction="column" gap="xl">
      {!isBulkEdit && (
        <DetailGroup>
          <Detail
            label={<Content id={ContentId.Overview} />}
            detail={
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>
                      <span>
                        <Content id={ContentId.ExpectedProceeds} />
                      </span>
                    </td>
                    <td>
                      <Stack justifyContent="end" gap="s">
                        {sale?.ttlNetProcs == null ? (
                          hasTotalNetProceedsPermissions ? (
                            <Content id={ContentId.NA} />
                          ) : null
                        ) : (
                          <strong>{sale.ttlNetProcs.disp}</strong>
                        )}
                      </Stack>
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td>
                      <Content id={ContentId.TotalCost} />
                    </td>
                    <td>
                      <Stack justifyContent="end" gap="s">
                        {sale?.soldTktCost == null ? (
                          <Content id={ContentId.NA} />
                        ) : (
                          <strong>
                            {'-'}
                            {sale.soldTktCost.disp}
                          </strong>
                        )}
                      </Stack>
                    </td>
                    <td />
                  </tr>

                  {hasEditSaleLineItemsFeature &&
                    lineItems?.map((i) => (
                      <tr key={i.id}>
                        <td>
                          <span>{i.description}</span>
                        </td>
                        <td>
                          <Stack justifyContent="end" gap="s">
                            <strong>
                              {i.costType === SaleCostType.Charge ? '-' : ''}
                            </strong>
                            <strong>
                              {formatCurrency(i.cost!, sale?.currency)}
                            </strong>
                          </Stack>
                        </td>
                        <td>
                          <div className={styles.editButtonContainer}>
                            <PosDropdown
                              trigger={
                                <MoreIcon
                                  withHoverEffect
                                  fill={IconsFill.textBrand}
                                  align="middle"
                                />
                              }
                              align="end"
                            >
                              <PosDropdownItem
                                onClick={() => {
                                  setCurItem(i);
                                  onAddNewCost();
                                }}
                              >
                                <Content id={ContentId.Edit} />
                              </PosDropdownItem>
                              <PosDropdownItem
                                onClick={() => onLineItemChange?.(i, true)}
                              >
                                <Content id={ContentId.Remove} />
                              </PosDropdownItem>
                            </PosDropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {/* Add line item button */}
                  {hasEditSaleLineItemsFeature && (
                    <Button
                      size="unset"
                      variant="link"
                      style={{ padding: `${vars.spacing['lg']} 0` }}
                      onClick={() => {
                        setCurItem(undefined);
                        onAddNewCost();
                      }}
                      tabIndex={0}
                      disabled={hideForWastedSale}
                    >
                      <PlusIcon
                        size={vars.iconSize.s}
                        fill={IconsFill.currentColor}
                        withHoverEffect
                      />
                      <Content id={ContentId.AddChargeOrCredit} />
                    </Button>
                  )}
                </tbody>
                <tfoot className={styles.tableFooter}>
                  <tr>
                    <td className={styles.totalCell}>
                      <Content id={ContentId.ExpectedProfit} />
                    </td>
                    <td className={styles.totalCell}>
                      <span>
                        {formatCurrency(totalProfitAmount, sale?.currency)}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            }
          />
        </DetailGroup>
      )}

      <Stack direction="column" gap="l">
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap="l"
          alignItems="start"
        >
          <Stack direction={isMobile ? 'row' : 'column'} gap="m">
            <Detail
              label={<Content id={ContentId.PaymentReceived} />}
              detail={
                <Switch
                  disabled={hideForWastedSale}
                  checked={
                    hideForWastedSale ? false : paymentReceived?.value ?? false
                  }
                  onCheckedChange={(isChecked) => {
                    setValue('paymentReceived', posChangedField(isChecked), {
                      shouldDirty: true,
                    });
                  }}
                />
              }
            />
          </Stack>
          <Stack direction="column" gap="m">
            <Detail
              label={<Content id={ContentId.PaymentReference} />}
              detail={
                <PosTextField
                  value={paymentReferenceId?.value ?? ''}
                  type="text"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!value) {
                      setValue('paymentReferenceId', null, {
                        shouldDirty: true,
                      });
                      return;
                    }
                    setValue('paymentReferenceId', posChangedField(value), {
                      shouldDirty: true,
                    });
                  }}
                />
              }
            />
          </Stack>
        </Stack>
      </Stack>

      <SaleLineItemModal
        item={curItem}
        isOpen={costDialog.dialogProps.isOpen}
        onClose={onAddNewCostCancel}
        onSave={onAddNewCostSave}
        currencyCode={
          sale?.currency ??
          loginContext?.user?.activeAccount.currencyCode ??
          'USD'
        }
      />
    </Stack>
  );
};
