import differenceWith from 'lodash-es/differenceWith';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, FormatContent } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { DeleteIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { DEAL_CONFIG_TRESHOLD_SPLIT_DIRECTION_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  DealSplitType,
  PurchaseOrderDealCommission,
  PurchaseOrderDealConfig,
} from 'src/WebApiController';

import * as styles from '../Deals.css';
import { DealConfigHurdleSplit } from './DealConfigHurdleSplit';
import { createDefaultDealCommission } from './DealConfigHurdleSplitCommissions';

export type ChangeCommissionInput = {
  changedUserId: string | null;
  changedValue: number | null;
  commissionIndex: number;
};

export const DealConfigHurdle = ({
  config,
  index,
}: {
  config: PurchaseOrderDealConfig;
  index: number;
}) => {
  const {
    updateLocalDealConfigHurdle,
    activeUserIds,
    updateLocalDealConfigHurdlePriority,
  } = useDealContext();

  const { watch, formState, setError } =
    useFormContext<PurchaseOrderDetailsInput>();
  const configSplitType = watch('deal.value.dealDetails.configSplitType.value');

  const { purchaserCommissions } = config;

  const vendorCommission = config.vendorCommission;
  const vendorAccount = watch('vendorAccount');

  useEffect(() => {
    updateLocalDealConfigHurdle(index, {
      vendorCommission: {
        ...vendorCommission,
        userId: vendorAccount?.value?.id || '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorAccount]);

  const onChangeVendorCommission = useCallback(
    (changedValue: number) => {
      updateLocalDealConfigHurdle(index, {
        vendorCommission: {
          ...vendorCommission,
          splitValue: changedValue,
        },
      });
    },
    [index, updateLocalDealConfigHurdle, vendorCommission]
  );

  const onAddNewCommission = useCallback(() => {
    const firstAvailableUserId = differenceWith(
      activeUserIds,
      purchaserCommissions ?? [],
      (activeUserId, commission) => activeUserId === commission.userId
    )[0];
    if (!firstAvailableUserId) return;
    updateLocalDealConfigHurdle(index, {
      purchaserCommissions: [
        ...purchaserCommissions,
        createDefaultDealCommission(firstAvailableUserId),
      ],
    });
  }, [activeUserIds, purchaserCommissions, updateLocalDealConfigHurdle, index]);

  const onChangeCommission = useCallback(
    ({
      changedUserId,
      changedValue,
      commissionIndex,
    }: ChangeCommissionInput) => {
      if (changedUserId) {
        const isUserIdAlreadyUsed = purchaserCommissions?.find(
          (existingCommission) => existingCommission.userId === changedUserId
        );
        if (isUserIdAlreadyUsed) return;
        updateLocalDealConfigHurdle(index, {
          purchaserCommissions: [
            ...purchaserCommissions.map((commission, i) =>
              i === commissionIndex
                ? { ...commission, userId: changedUserId }
                : commission
            ),
          ],
        });
      }

      if (changedValue !== null) {
        updateLocalDealConfigHurdle(index, {
          purchaserCommissions: [
            ...purchaserCommissions.map((commission, i) =>
              i === commissionIndex
                ? { ...commission, splitValue: changedValue }
                : commission
            ),
          ],
        });
      }
    },
    [index, purchaserCommissions, updateLocalDealConfigHurdle]
  );

  const onDeleteCommission = useCallback(
    (removeIndex: number) => {
      if (config.purchaserCommissions.length <= 1) return;
      const newCommissions = [...config.purchaserCommissions];
      const updatedCommissions = newCommissions.filter(
        (_, i) => i !== removeIndex
      );
      updateLocalDealConfigHurdle(index, {
        purchaserCommissions: updatedCommissions,
      });
    },
    [config.purchaserCommissions, index, updateLocalDealConfigHurdle]
  );

  return (
    <HurdleConfig
      config={config}
      vendorCommission={vendorCommission}
      onChangeVendorCommission={onChangeVendorCommission}
      onAddNewCommission={onAddNewCommission}
      onChangeCommission={onChangeCommission}
      onDeleteCommission={onDeleteCommission}
      index={index}
    />
  );
};

const HurdleConfig = ({
  config,
  vendorCommission,
  onChangeVendorCommission,
  onAddNewCommission,
  onChangeCommission,
  onDeleteCommission,
  index,
}: {
  config: PurchaseOrderDealConfig;
  vendorCommission: PurchaseOrderDealCommission;
  onChangeVendorCommission: (input: number) => void;
  onAddNewCommission: () => void;
  onChangeCommission: ({
    changedUserId,
    changedValue,
    commissionIndex,
  }: ChangeCommissionInput) => void;
  onDeleteCommission: (removeIndex: number) => void;
  index: number;
}) => {
  const {
    localDeal,
    deleteLocalDealConfigHurdle,
    updateLocalDealConfigHurdle,
    updateLocalDealConfigHurdlePriority,
  } = useDealContext();

  const { formState } = useFormContext<PurchaseOrderDetailsInput>();

  const configSplitType = localDeal?.dealDetails?.configSplitType.value;
  const thresholdValue = config.thresholdValue;

  const numHurdles = localDeal?.dealDetails?.dealConfigs?.value?.length || 0;

  const priorityOptions = useMemo(() => {
    const options: Record<string, string> = {};
    for (let i = 1; i <= numHurdles; i++) {
      options[i.toString()] = i.toString();
    }
    return options;
  }, [numHurdles]);

  return (
    <>
      <PosFormField
        label={
          <Stack justifyContent="spaceBetween">
            <div className={styles.SectionTitle}>
              <FormatContent
                id={FormatContentId.HurdleN}
                params={String(index + 1)}
              />
            </div>
            {index !== 0 && (
              <DeleteIcon
                fill={IconsFill.currentColor}
                withHoverEffect
                onClick={() => deleteLocalDealConfigHurdle(index)}
              />
            )}
          </Stack>
        }
        errors={
          formState.errors.deal?.value?.dealDetails?.dealConfigs?.value?.[index]
            ?.priority?.message
        }
      >
        <Stack className={styles.DealConfigHurdleSection} gap={'l'}>
          <PosEnumSelect
            valueOptionsContent={DEAL_CONFIG_TRESHOLD_SPLIT_DIRECTION_TO_CID}
            value={config.thresholdSplitDirection}
            onChange={(value) =>
              updateLocalDealConfigHurdle(index, {
                thresholdSplitDirection: value,
              })
            }
          />
          <PosTextField
            style={{ width: '100%' }}
            type={'number'}
            inputMode="numeric"
            prefixDisplay={configSplitType === DealSplitType.Sales && '$'}
            postfixDisplay={
              configSplitType === DealSplitType.Quantity && 'Tickets'
            }
            onChange={(e) =>
              updateLocalDealConfigHurdle(index, {
                thresholdValue: Number(e.target.value),
              })
            }
            value={thresholdValue || 0}
          />
          <PosSelect
            style={{ width: '20%' }}
            value={config.priority?.toString()}
            valueOptionsContent={priorityOptions}
            onChange={(value) => {
              updateLocalDealConfigHurdlePriority(Number(value), index);
            }}
            placeholderText={ContentId.SetPriority}
          />
        </Stack>
      </PosFormField>

      <Stack direction="column" style={{ marginLeft: '5%' }}>
        <DealConfigHurdleSplit
          index={index}
          config={config}
          vendorCommission={vendorCommission}
          onChangeVendorCommission={onChangeVendorCommission}
          onAddNewCommission={onAddNewCommission}
          onDeleteCommission={onDeleteCommission}
          onChangeCommission={onChangeCommission}
        />
      </Stack>
    </>
  );
};

const NonHurdleConfig = ({
  config,
  vendorCommission,
  onChangeVendorCommission,
  onChangeCommission,
  onDeleteCommission,
  index,
}: {
  config: PurchaseOrderDealConfig;
  vendorCommission: PurchaseOrderDealCommission;
  onChangeVendorCommission: (input: number) => void;
  onChangeCommission: ({
    changedUserId,
    changedValue,
    commissionIndex,
  }: ChangeCommissionInput) => void;
  onDeleteCommission: (removeIndex: number) => void;
  index: number;
}) => {
  return (
    <Stack direction="column">
      <PosFormField
        label={
          <div className={styles.SectionTitle}>
            <Content id={ContentId.Split} />
          </div>
        }
      >
        <DealConfigHurdleSplit
          index={index}
          config={config}
          vendorCommission={vendorCommission}
          onChangeVendorCommission={onChangeVendorCommission}
          onDeleteCommission={onDeleteCommission}
          onChangeCommission={onChangeCommission}
        />
      </PosFormField>
    </Stack>
  );
};
