import { MouseEvent, useCallback } from 'react';
import { useToggle } from 'react-use';
import { EntitySearchConfigActions } from 'src/components/EntitySearch/EntitySearchConfigActions';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { PurchaseColumnSettingsModal } from 'src/modals/ColumnSettings/PurchaseColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  PurchaseOrderQuery,
  PurchaseSearchResult,
  PurchaseViewMode,
  SearchClient,
} from 'src/WebApiController';

import { container } from './PurchaseSearchToolbar.css';

export const PurchaseSearchToolbar = ({
  disabled,
  setDisabled,
  activeSearchConfig,
  setActiveSearchConfig,
  onSaveSearchResult,
}: {
  disabled?: boolean;
  setDisabled?: (d: boolean) => void;
  activeSearchConfig?: PurchaseSearchResult;
  setActiveSearchConfig: (sr?: PurchaseSearchResult) => void;
  onSaveSearchResult: (sr?: PurchaseSearchResult) => void;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const onDeleteSearchResult = useCallback(
    (searchResult: PurchaseSearchResult) => {
      if (!searchResult.id) return;

      setDisabled?.(true);
      tryInvokeApi(
        async () => {
          await new SearchClient(
            activeAccountWebClientConfig
          ).deleteSearchConfig(searchResult.id!);

          setActiveSearchConfig(undefined);
        },
        (error) => {
          showErrorDialog('SearchClient.onDeleteSearchResult', error, {
            trackErrorData: {
              searchResult,
            },
          });
        },
        () => setDisabled?.(false)
      );
    },
    [
      activeAccountWebClientConfig,
      setActiveSearchConfig,
      setDisabled,
      showErrorDialog,
    ]
  );

  return (
    <Stack
      gap="m"
      alignItems="center"
      justifyContent="spaceBetween"
      className={container}
    >
      <EntitySearchConfigActions
        disabled={disabled}
        activeSearchConfig={activeSearchConfig}
        onSaveSearchResult={onSaveSearchResult}
        onDeleteSearchResult={onDeleteSearchResult}
      />
      <Stack gap="m" alignItems="center" justifyContent="end">
        <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      {isColumnModalOpen ? (
        <PurchaseColumnSettingsModal
          sectionType={SectionType.Purchases}
          onClose={toggleColumnModal}
          viewMode={PurchaseViewMode.FlattenedView}
        />
      ) : null}
    </Stack>
  );
};
