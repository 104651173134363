import { cloneElement, useMemo } from 'react';
import { FilterToolbarItem } from 'src/components/Filters';
import {
  filterItemLabel,
  filterItemWrapper,
} from 'src/components/FilterToolbar/FilterDialogV2/FiltersList/FiltersList.css';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { IconsFill, LockIcon } from 'src/svgs/Viagogo';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

import { FiltersListProp } from './FiltersList.types';

const FILTER_ITEM_WIDTH = '190px';

export const FiltersListEmbedded = ({
  filters,
  selectedDropdownItems,
  performerVenueFilter,
  embeddedResetButton,
}: FiltersListProp) => {
  const filterItemsToShow = useMemo(() => {
    const selectedFilters = filters.filter(
      (filterToolbarGroup) =>
        filterToolbarGroup.titleContentId &&
        !!selectedDropdownItems[filterToolbarGroup.titleContentId]
    );

    return selectedFilters.flatMap((filterToolbarGroup) => {
      const dropdownFilterIds = selectedDropdownItems[
        filterToolbarGroup.titleContentId!
      ].map((dropdownFilter) => dropdownFilter.filterId);

      return filterToolbarGroup.items.filter(
        (filterItem) =>
          dropdownFilterIds.includes(filterItem.filterId) &&
          (filterItem.reportFilterEditability ??
            DEFAULT_REPORT_FILTER_EDITABILITY) !==
            ReportFilterEditability.Hidden
      );
    });
  }, [filters, selectedDropdownItems]);

  return (
    <Stack
      direction="row"
      gap="l"
      alignItems="center"
      style={{ flexGrow: '1', overflowX: 'auto' }}
    >
      <Stack gap="xl" direction="row" flexWrap="wrap">
        {performerVenueFilter != null && (
          <FilterToolBarButtonWrapper lineBreak={filterItemsToShow.length > 0}>
            {performerVenueFilter}
          </FilterToolBarButtonWrapper>
        )}
        {filterItemsToShow.map((item, i) => (
          <FilterToolbarListItemEmbedded item={item} key={`fi-embedded-${i}`} />
        ))}
      </Stack>
      {(performerVenueFilter != null || filterItemsToShow.length > 0) && (
        <FilterToolBarButtonWrapper lineBreak={filterItemsToShow.length > 0}>
          {embeddedResetButton}
        </FilterToolBarButtonWrapper>
      )}
    </Stack>
  );
};

const FilterToolbarListItemEmbedded = ({
  item,
}: {
  item: FilterToolbarItem;
}) => {
  const disabled =
    item.reportFilterEditability !== ReportFilterEditability.Edit;

  const filterItem = disabled
    ? cloneElement(item.filterItem, { disabled })
    : item.filterItem;

  return (
    <div
      className={filterItemWrapper}
      style={{ width: FILTER_ITEM_WIDTH, minWidth: 'min-content' }}
    >
      <span className={filterItemLabel}>
        <Content id={item.labelContentId} />
      </span>
      <Stack gap="s" direction="row" alignItems="center">
        <Stack gap="s" width="full">
          {filterItem}
        </Stack>
        {disabled && (
          <div
            style={{ minWidth: vars.iconSize.s, minHeight: vars.iconSize.s }}
          >
            <LockIcon size={vars.iconSize.s} fill={IconsFill.textDisabled} />
          </div>
        )}
      </Stack>
    </div>
  );
};

const FilterToolBarButtonWrapper = ({
  lineBreak,
  children,
}: {
  /**
   * This prop is used to add a line break before the children to align with FilterToolbarListItemEmbedded
   */
  lineBreak?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className={filterItemWrapper} style={{ width: FILTER_ITEM_WIDTH }}>
      <span className={filterItemLabel}>{lineBreak && <br />}</span>
      <Stack gap="s" direction="row" alignItems="center">
        <Stack gap="s" width="full">
          {children}
        </Stack>
      </Stack>
    </div>
  );
};
