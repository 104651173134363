import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { SALE_LINE_ITEM_CHARGE_REASON_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SaleLineItem } from 'src/WebApiController';

import * as styles from './ChargeOrCreditReasonSelector.css';

export const ChargeReasonSelector = () => {
  const { control, formState, watch, setValue } =
    useFormContext<SaleLineItem>();
  const chargeReason = watch('saleLineItemChargeReason');
  const requiredText = useContent(ContentId.Required);

  useEffect(() => {
    return () => {
      setValue('saleLineItemChargeReason', null);
    };
  }, [setValue]);

  return (
    <PosFormField
      label={<Content id={ContentId.ChargeReasons} />}
      errors={formState.errors.saleLineItemChargeReason?.message}
    >
      <Controller
        name={'saleLineItemChargeReason'}
        rules={{ required: !chargeReason ? requiredText : undefined }}
        control={control}
        render={({ field }) => (
          <PosEnumSelect
            className={styles.fullWidth}
            {...field}
            valueOptionsContent={SALE_LINE_ITEM_CHARGE_REASON_TO_CID}
          />
        )}
      />
    </PosFormField>
  );
};
