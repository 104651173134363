import { Content } from 'src/contexts/ContentContext';
import { PosPill } from 'src/core/POS/PosPill';
import { PosPillVariant } from 'src/core/POS/PosPill/PosPill';
import { MARKETPLACE_SALE_PAYMENT_STATE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  MarketplacePaymentState,
  PointOfSalePaymentState,
} from 'src/WebApiController';

const getStateVariant = (
  state: MarketplacePaymentState | PointOfSalePaymentState
): PosPillVariant => {
  switch (state) {
    case MarketplacePaymentState.Paid:
      return PosPillVariant.Success;
    case MarketplacePaymentState.Queued:
      return PosPillVariant.Warning;
    case MarketplacePaymentState.PartiallyPaid:
      return PosPillVariant.Info;
    case MarketplacePaymentState.Unpaid:
    case MarketplacePaymentState.Ineligible:
    default:
      return PosPillVariant.Neutral;
  }
};

interface MarketplacePaymentStatePillProps {
  state: MarketplacePaymentState | PointOfSalePaymentState;
}

export const MarketplacePaymentStatePill = ({
  state,
}: MarketplacePaymentStatePillProps) => {
  return (
    <PosPill variant={getStateVariant(state)}>
      <Content id={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[state]} />
    </PosPill>
  );
};
