import { Dispatch, SetStateAction } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { ContentId } from 'src/utils/constants/contentId';

export const EventEntitySearchBox = ({
  onIdLookup,
  onEventSearch,
  minWidth = 250,
  maxWidth,
  disabled,
  searchText,
  setSearchText,
  clearSearchEntity,
  placeholderContentId = ContentId.SearchEventOrLookupId,
  disableSearchMode,
}: {
  onIdLookup: (id: string) => void;
  onEventSearch: (searchText: string) => void;
  minWidth?: number | string;
  maxWidth?: number | string;
  disabled?: boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  clearSearchEntity?: () => void;
  placeholderContentId?: ContentId;
  disableSearchMode?: boolean;
}) => {
  const searchPlaceholder = useContent(placeholderContentId);
  return (
    <PosSearchBox
      value={searchText}
      disabled={disabled}
      minWidth={minWidth}
      maxWidth={maxWidth}
      placeholder={searchPlaceholder}
      onSearchChange={(searchText) => {
        setSearchText(searchText);

        if (!searchText && clearSearchEntity) {
          clearSearchEntity();
        } else if (!disableSearchMode) {
          onEventSearch(searchText);
        }
      }}
      onEnterKeyPressed={(curValue) => {
        onIdLookup(curValue);

        // If user press enter - they're interested in look-up, not text-search, so clear the search
        // tex so the tables doesn't get empty while the modal opens
        // But only if it's a single-id lookup, if multiple, we keep the search filter because
        // the table needs to display the result set
        if (!curValue.includes(',')) {
          onEventSearch('');
        }
      }}
    />
  );
};
