import { useEffect } from 'react';
import { BackSection } from 'src/components/common/BackSection';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import {
  saleMandatoryFiltersToShow,
  useSaleFilters,
  ViewModeSelector,
} from 'src/components/MainFilterBar';
import * as styles from 'src/components/MainFilterBar/MainFilterBar.css';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { SALES_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  ActionOutboxEntityType,
  Feature,
  SaleQuery,
  SalesViewMode,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import { SwiperButton } from '../../Buttons/SwiperButton';
import { SalesGlobalActionDropdown } from '../SalesActionDropdown';
import { useDefaultSaleFilters } from './hooks/useDefaultSaleFilters';
import { SALE_VIEW_MODE_TO_ICON } from './SaleFilterBar.types';
import { SaleSearchBox } from './SaleSearchBox';

export function SaleFilterBar({
  isSearchOnly,
  isEventPage = false,
  returnUrl,
  posEventId,
  swiperRef,
}: {
  isSearchOnly?: boolean;
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const hasNewSearchFeature = useUserHasFeature(Feature.SearchView_Sale);

  const {
    filterQuery: saleQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<SaleQuery>();

  useEffect(() => {
    setTempQuery(saleQuery);
  }, [saleQuery, setTempQuery]);

  const defaultQuickFilters = useDefaultSaleFilters({
    saleQuery,
    initialQuery,
  });

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<SalesViewMode>({
      id: UserSetting.SalePageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateSales,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomSales,
    defaultQuickFilters,
    currentQuery: saleQuery,
    initialQuery,
    onSelect: ({ query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }
      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const { filters: saleFilters } = useSaleFilters();

  const isMobile = useMatchMedia('mobile');

  const { appliedFilters } = useFiltersHelpers({
    filters: saleFilters,
    activeQuery: saleQuery,
    mandatoryFiltersToShow: saleMandatoryFiltersToShow,
    initialQuery,
  });

  const filterToolbar = saleQuery && (
    <FilterToolbar
      {...filterToolbarProps}
      filterAppliedCounts={appliedFilters.length}
      filterAppliedIds={appliedFilters}
      onSubmitFilter={() => {
        setFilterQuery(tempQuery);
      }}
      filters={saleFilters}
      mandatoryFiltersToShow={saleMandatoryFiltersToShow}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
    />
  );

  return isSearchOnly ? (
    <div className={styles.mainFilterBarDiv}>
      <div className={styles.dockLeftDiv}>
        <div className={styles.mainFilterBarLeftChild}>
          <SwiperButton dir="left" swiperRef={swiperRef} />
        </div>
      </div>
      <div className={styles.dockRightDiv}>
        <div className={styles.mainFilterBarRightChild}>
          {!isEventPage && (
            <>
              <BulkEditStatusPopover entityType={ActionOutboxEntityType.Sale} />
              {!isMobile && (
                <ViewModeSelector
                  query={saleQuery}
                  initialQuery={initialQuery}
                  setFilterQuery={setFilterQuery}
                  viewModeSettingsId={UserSetting.SalePageViewMode}
                  viewModeCidMap={SALES_VIEW_MODE_TO_CID}
                  viewModeIconMap={SALE_VIEW_MODE_TO_ICON}
                />
              )}
              <MultiSelectionToggleGlobal />
              <SalesGlobalActionDropdown />

              <SaleSearchBox />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.mainFilterBarDiv}>
      {isMobile ? (
        filterToolbar
      ) : (
        <>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              {isEventPage && (
                <BackSection
                  returnUrl={returnUrl ?? '/sales'}
                  state={{
                    fromPosEventId: posEventId,
                  }}
                />
              )}
              {filterToolbar}
            </div>
          </div>
          <div className={styles.dockRightDiv}>
            <div className={styles.mainFilterBarRightChild}>
              {!isEventPage && (
                <>
                  <BulkEditStatusPopover
                    entityType={ActionOutboxEntityType.Sale}
                  />
                  <ViewModeSelector
                    query={saleQuery}
                    initialQuery={initialQuery}
                    setFilterQuery={setFilterQuery}
                    viewModeSettingsId={UserSetting.SalePageViewMode}
                    viewModeCidMap={SALES_VIEW_MODE_TO_CID}
                    viewModeIconMap={SALE_VIEW_MODE_TO_ICON}
                  />
                  <MultiSelectionToggleGlobal />
                  <SalesGlobalActionDropdown />
                </>
              )}

              {(!hasNewSearchFeature || isEventPage) && (
                <SaleSearchBox isEventPage={isEventPage} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
