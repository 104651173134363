import {
  functionalUpdate,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { MarketplacePaymentLineWithSaleAndEventInfo } from 'src/hooks/useGetMarketplacePaymentLineTableData';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useMarketplacePaymentLinesTableColumnsConfig } from 'src/tables/MarketplacePaymentLinesTable/useMarketplacePaymentLinesTableColumnsConfig';
import { Table } from 'src/tables/Table';
import { MarketplacePaymentLinesTableColumnId } from 'src/utils/columns/marketplacePaymentLines/marketplacePaymentLinesColumnsUtils.types';
import { Feature } from 'src/WebApiController';

const getRowId = (
  row: MarketplacePaymentLineWithSaleAndEventInfo | null,
  index: number
) => (row?.id ?? index).toString();

interface PaymentsTableProps {
  tableData: MarketplacePaymentLineWithSaleAndEventInfo[] | null;
}

export const MarketplacePaymentLinesTable = ({
  tableData,
}: PaymentsTableProps) => {
  const paymentsTableColumnConfig =
    useMarketplacePaymentLinesTableColumnsConfig();
  const [sorting, setSorting] = useState<SortingState>([]);
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  const options: Partial<
    TableOptions<MarketplacePaymentLineWithSaleAndEventInfo | null>
  > = useMemo(
    () => ({
      data: tableData ?? [],
      columns: Object.values(paymentsTableColumnConfig),
      state: {
        sorting,
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [MarketplacePaymentLinesTableColumnId.Actions]
            : undefined,
        },
      },
      getRowId: getRowId,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      sortingFns: {},
    }),
    [
      tableData,
      paymentsTableColumnConfig,
      sorting,
      hasTablePinActionColumnFeature,
    ]
  );

  return (
    <Table
      options={options}
      tableLayout="fixed"
      withOuterPadding={false}
      useVirtuoso={false}
      usePaginationFooter={false}
      roundBorders={true}
    />
  );
};
