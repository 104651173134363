import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { NavLink } from 'reactstrap';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { UseGetUserInfo, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { AttrLabel } from 'src/modals/common';
import { GenericFeatureIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import {
  Feature,
  SaleClient,
  SaleDetails,
  SeatingForAlloc,
  TicketInfoForAllocation,
} from 'src/WebApiController';

import * as styles from './SeatingAllocationInput.css';

export type FlattenedSeatingAllocation = TicketInfoForAllocation &
  Pick<SeatingForAlloc, 'section' | 'row' | 'recAlloc'>;

export const SeatingSingleTicketAllocationInput = ({
  selectedSeatings,
  setSelectedSeatings,
}: {
  selectedSeatings: FlattenedSeatingAllocation[];
  setSelectedSeatings: React.Dispatch<
    React.SetStateAction<FlattenedSeatingAllocation[]>
  >;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { getValues } = useFormContext<SaleDetails>();

  const [allPossibleSeatings, setAllPossibleSeatings] = useState(false);

  const sale = getValues();

  const selectedListingId = useMemo(() => {
    if (selectedSeatings.length) {
      return selectedSeatings[0].listingId;
    }

    return null;
  }, [selectedSeatings]);

  const ticketsForAllocationQuery = useQuery({
    queryKey: [
      'SaleClient.getSeatingsForSaleAllocation',
      sale.id,
      allPossibleSeatings,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const client = new SaleClient(activeAccountWebClientConfig);
      const data = await client.getSeatingsForSaleAllocation(
        sale.id,
        allPossibleSeatings,
        true,
      );
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('SaleClient.getSeatingsForSaleAllocation', error, {
          trackErrorData: {
            saleId: sale.id,
            allPossibleSeatings: allPossibleSeatings,
          },
        });
      },
    },
  });

  const possibleSeatings: FlattenedSeatingAllocation[] | undefined =
    useMemo(() => {
      const possibleSeatings = ticketsForAllocationQuery.data?.possibleSeatings;
      if (!possibleSeatings) {
        return possibleSeatings;
      }
      const seatingTickets: FlattenedSeatingAllocation[] = [];
      const ticketIds = new Set();

      const possibleSeatingsConsecutive = possibleSeatings.filter(
        (seating) => !seating.nonConsecSeats
      );

      const possibleSeatingsNonConsecutive = possibleSeatings.filter(
        (seating) => seating.nonConsecSeats
      );

      const filteredPossibleSeating = [
        ...possibleSeatingsConsecutive,
        ...possibleSeatingsNonConsecutive,
      ];

      filteredPossibleSeating.forEach((seating) => {
        seating.tktsForAlloc.forEach((ticket) => {
          if (!ticketIds.has(ticket.id)) {
            ticketIds.add(ticket.id);
            seatingTickets.push({
              section: seating.section,
              recAlloc: seating.recAlloc,
              ...ticket,
            });
          }
        });
      });

      return seatingTickets;
    }, [ticketsForAllocationQuery.data?.possibleSeatings]);

  const handleToggleSeating = (seating: FlattenedSeatingAllocation) => {
    setSelectedSeatings((prevSelected) => {
      const isAlreadySelected = prevSelected.some((s) => s.id === seating.id);

      if (isAlreadySelected) {
        const newSelected = prevSelected.filter((s) => s.id !== seating.id);
        return newSelected;
      } else {
        if (prevSelected.length >= sale.qtySold) {
          return prevSelected;
        } else {
          const newSelected = [...prevSelected, seating];
          return newSelected;
        }
      }
    });
  };

  const userIds = possibleSeatings
    ?.map((seating) => seating.purchasedBy)
    .filter((id): id is string => id !== null);

  const purchasedByUsers = useGetUserInfos(userIds);

  if (ticketsForAllocationQuery.isFetching && !possibleSeatings?.length) {
    return <PosSpinner size={vars.iconSize.xxs} />;
  }

  return (
    <>
      {possibleSeatings?.length ? (
        <Stack direction="column" gap="l" width="full" height="full">
          <div className={styles.tableContainerVirtuoso}>
            <TableVirtuoso
              data={possibleSeatings}
              style={{ width: '100%', height: '100%' }}
              overscan={{ main: 10, reverse: 10 }}
              components={{
                Table: SimpleTable.Table,
                TableHead: SimpleTable.Thead,
                TableBody: SimpleTable.Tbody,
                TableRow: SimpleTable.Tr,
              }}
              fixedHeaderContent={() => (
                <SimpleTable.Tr
                  style={{
                    backgroundColor: vars.color.backgroundPrimary,
                  }}
                >
                  <SimpleTable.Th>
                    <AttrLabel>&nbsp;</AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Section} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Row} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Seats} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.ListingId} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.PurchasedBy} />
                    </AttrLabel>
                  </SimpleTable.Th>{' '}
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.Vendor} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>
                      <Content id={ContentId.VendorAccount} />
                    </AttrLabel>
                  </SimpleTable.Th>
                  <SimpleTable.Th>
                    <AttrLabel>&nbsp;</AttrLabel>
                  </SimpleTable.Th>
                </SimpleTable.Tr>
              )}
              itemContent={(index, seating) => (
                <PossibleSeatingTableRow
                  seating={seating}
                  isSelected={selectedSeatings.some((s) => s.id === seating.id)}
                  handleSelection={handleToggleSeating}
                  purchasedBy={
                    seating.purchasedBy
                      ? purchasedByUsers?.data
                        ? purchasedByUsers.data[seating.purchasedBy]
                        : null
                      : null
                  }
                  disabled={
                    selectedListingId != null &&
                    seating.listingId !== selectedListingId
                  }
                />
              )}
            />
          </div>
          {ticketsForAllocationQuery.data?.hasMoreAllocations && (
            <Button
              variant="link"
              onClick={() => setAllPossibleSeatings(!allPossibleSeatings)}
            >
              <Content
                id={
                  allPossibleSeatings
                    ? ContentId.AllocateToListingTickets
                    : ContentId.AllocateToDifferentTickets
                }
              />
            </Button>
          )}
        </Stack>
      ) : (
        <Stack direction="column" gap="l">
          <Content id={ContentId.NoSeatingsAvailableForAllocation} />
          <div className={styles.noAllocationContainer}>
            <NavLink
              title={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
              tag={Link}
              to={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
            >
              <Content id={ContentId.AddPurchase} />
            </NavLink>
          </div>
        </Stack>
      )}
    </>
  );
};

const PossibleSeatingTableRow = ({
  seating,
  isSelected,
  handleSelection,
  purchasedBy,
  disabled,
}: {
  seating: FlattenedSeatingAllocation;
  handleSelection: (seating: FlattenedSeatingAllocation) => void;
  isSelected: boolean;
  purchasedBy?: UseGetUserInfo | null;
  disabled?: boolean;
}) => {
  const recommended = useContent(ContentId.Recommended);
  const deactivatedText = useContent(ContentId.Deactivated);
  const hasDisplayDeactivatedUser = useUserHasFeature(
    Feature.DisplayDeactivatedUser
  );
  const {
    seat,
    section,
    row,
    vendorId,
    vendorName,
    vendorAccEmail,
    vendorAccName,
    recAlloc,
    listingId,
  } = seating;

  const { availableVendors } = usePurchaseVendorSelector({});
  return (
    <>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        <Checkbox
          checked={isSelected}
          onChange={() => handleSelection(seating)}
          label={null}
          disabled={disabled}
        />
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {section}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {row}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {seat}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {`#${listingId}`}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {`${purchasedBy?.name} ${
          purchasedBy?.isDeactivated && !hasDisplayDeactivatedUser
            ? ` (${deactivatedText})`
            : ''
        }`}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {vendorId ? availableVendors[vendorId]?.name : vendorName}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        {vendorAccEmail || vendorAccName}
      </SimpleTable.Td>
      <SimpleTable.Td
        className={clsx(styles.tableCell, {
          [styles.disabledCell]: disabled,
        })}
      >
        <div
          title={recommended}
          // doing this so the table does not change widths of the column as the value disappear
          // another side-effect of virtuoso
          style={{ opacity: recAlloc ? '1' : '0' }}
        >
          <GenericFeatureIcon fill={IconsFill.textBrand} />
        </div>
      </SimpleTable.Td>
    </>
  );
};
