import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ContentId } from 'src/utils/constants/contentId';
import { GridActionType } from 'src/utils/tableUtils';

import { ReportsTable } from './ReportsTable';
import * as styles from './ReportsTableContainer.css';

export function ReportsTableContainer({
  reports,
  onReportAction,
}: {
  reports?: ReportConfig[];
  onReportAction: (
    report: ReportConfig,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
}) {
  const reportsDefault = reports?.filter((r) => r.globalReportTypeId) ?? [];
  const reportsCustom = reports?.filter((r) => !r.globalReportTypeId) ?? [];

  return (
    <div className={styles.mainContent}>
      <div className={styles.reportTableContainer}>
        {reportsDefault.length > 0 && (
          <Stack direction="column">
            <h2
              className={styles.subHeader}
              style={{
                paddingTop: vars.spacing['lg'],
                paddingBottom: vars.spacing['lg'],
              }}
            >
              <Content id={ContentId.DefaultReports} />
            </h2>
            <ReportsTable
              reports={reportsDefault}
              onReportAction={onReportAction}
              isGlobalSection
              initState={{
                sorting: [{ id: 'reportName', desc: false }],
              }}
            />
          </Stack>
        )}

        <Stack direction="column">
          <h2
            className={styles.subHeader}
            style={{
              paddingTop: vars.spacing['xl'],
              paddingBottom: vars.spacing['lg'],
            }}
          >
            <Content id={ContentId.CustomReports} />
          </h2>
          <ReportsTable
            reports={reportsCustom}
            onReportAction={onReportAction}
            initState={{
              sorting: [{ id: 'reportName', desc: false }],
            }}
          />
        </Stack>
      </div>
    </div>
  );
}
