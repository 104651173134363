import { formatInTimeZone } from 'date-fns-tz';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { MarketplacePaymentStatePill } from 'src/components/common/MarketplacePaymentStatePill/MarketplacePaymentStatePill';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { DEFAULT_TIMEZONE_LOCAL } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext.constants';
import { Stack } from 'src/core/ui';
import { useHasSaleDetailsMarketplaceTermsFeature } from 'src/hooks/features/useHasSaleDetailsMarketplaceTermsFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_PAYMENT_TERM_TO_CID } from 'src/utils/constants/contentIdMaps';
import { MarketplaceSalePayment } from 'src/WebApiController';

import * as styles from './MarketplacePaymentHeaderDetails.css';

interface MarketplacePaymentHeaderDetailsProps {
  marketplaceSalePayment: MarketplaceSalePayment;
}

export const MarketplacePaymentHeaderDetails = ({
  marketplaceSalePayment,
}: MarketplacePaymentHeaderDetailsProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const hasSaleDetailsMarketplaceTermsFeature =
    useHasSaleDetailsMarketplaceTermsFeature();

  return (
    <Stack
      justifyContent="spaceBetween"
      width="full"
      className={styles.wrapper}
    >
      <Stack direction="column" gap="m">
        <div>
          <span className={styles.title}>
            <Content id={ContentId.PaymentId} />:
          </span>
          <span>{marketplaceSalePayment.extPmtId}</span>
        </div>
        {marketplaceSalePayment.pmtDate && (
          <div>
            <span className={styles.title}>
              <Content id={ContentId.PaymentDate} />:
            </span>
            <span>
              {formatInTimeZone(
                new Date(marketplaceSalePayment.pmtDate),
                timeZone ?? DEFAULT_TIMEZONE_LOCAL,
                'do LLL yyyy'
              )}
            </span>
          </div>
        )}
        {hasSaleDetailsMarketplaceTermsFeature &&
          marketplaceSalePayment.mkpPmtTerm && (
            <div>
              <span className={styles.title}>
                <Content id={ContentId.MarketplacePaymentTerm} />:
              </span>
              <span>
                <Content
                  id={
                    MARKETPLACE_PAYMENT_TERM_TO_CID[
                      marketplaceSalePayment.mkpPmtTerm
                    ]
                  }
                />
              </span>
            </div>
          )}
      </Stack>
      <Stack alignItems="center" gap="m" alignSelf="start">
        <MarketplacePaymentStatePill state={marketplaceSalePayment.state} />
        <MarketplaceLogo marketplace={marketplaceSalePayment.mkp} />
      </Stack>
    </Stack>
  );
};
