import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useContent } from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType } from 'src/WebApiController';

import { TagValueFilterInput } from './TagValueFilterInput';

export type EventTagInfoFilter = {
  eventTagName: string | null;
  eventTagValue: string | null;
};

export const useEventTagFilters = <TFilter extends EventTagInfoFilter>(
  query: TFilter,
  setQuery: (query: TFilter) => void
): FilterToolbarGroup => {
  const { tagsMetadata: existingTagsMetadata = [] } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent
  );

  const noTagsMsg = useContent(ContentId.NoTagsExist);

  const selectedTagMetadata = useMemo(() => {
    return (existingTagsMetadata ?? []).find(
      (r) => r.key === query.eventTagName
    );
  }, [existingTagsMetadata, query.eventTagName]);

  const keyOptionsContent = useMemo(() => {
    const keyOptions = (existingTagsMetadata ?? [])
      .sort((r1, r2) => r1.key.localeCompare(r2.key))
      .reduce(
        (re, r) => {
          re[r.key] = r.key;
          return re;
        },
        {} as Record<string, string>
      );

    return keyOptions ?? {};
  }, [existingTagsMetadata]);

  const noTagsExist = useMemo(() => {
    return existingTagsMetadata?.length === 0;
  }, [existingTagsMetadata]);

  return {
    titleContentId: ContentId.EventTag,
    type: 'row',
    items: [
      {
        filterId: 'eventTagName' as const,
        labelContentId: ContentId.Name,
        filterQueryKeys: ['eventTagName'] as FilterToolbarItemId[],
        filterItem: (
          <PosSelect
            style={{ width: '100%' }}
            onChange={(newValue: string) => {
              if (query.eventTagName !== newValue) {
                setQuery({
                  ...query,
                  eventTagName: newValue,
                  eventTagValue: null,
                });
              }
            }}
            value={query.eventTagName ?? ''}
            displayText={noTagsExist ? noTagsMsg : query.eventTagName ?? ''}
            searchable
            disabled={noTagsExist}
            valueOptionsContent={keyOptionsContent}
            placeholderText={ContentId.None}
            enableEmptySelection
          />
        ),
      },
      {
        filterId: 'eventTagValue' as const,
        labelContentId: ContentId.Value,
        filterQueryKeys: ['eventTagValue'] as FilterToolbarItemId[],
        filterItem: (
          <TagValueFilterInput
            valueType={selectedTagMetadata?.valueType}
            disabled={selectedTagMetadata == null || noTagsExist}
            value={query.eventTagValue ?? ''}
            onChange={(newValue) => {
              if (query.eventTagValue !== newValue) {
                setQuery({
                  ...query,
                  eventTagValue: newValue,
                });
              }
            }}
          />
        ),
      },
    ].filter((f) => f!),
  };
};
