import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Radio, RadioGroup, Stack } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common';
import { setValueAsNumber } from 'src/navigations/Routes/Settings/Views/SyncCenter/SyncCenter.utils';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  MarketplacePaymentLineType,
  SaleDetails,
  SiteMarketplacePaymentLineInput,
} from 'src/WebApiController';

import { EMPTY_MARKETPLACE_PAYMENT_LINE } from './MarketplacePaymentLineDialog.utils';

export type AddMarketplaceChargeDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (
    siteMarketplacePaymentLineInput: SiteMarketplacePaymentLineInput
  ) => void;
  onCancel: () => void;
  siteMarketplacePaymentLineInput?: SiteMarketplacePaymentLineInput;
  canSelectProceeds: boolean;
  sale: SaleDetails;
  disabledPaymentTypeInput?: boolean;
  disabledAmountInput?: boolean;
};

export const MarketplacePaymentLineDialog = ({
  onCancel,
  onSave,
  sale,
  siteMarketplacePaymentLineInput,
  canSelectProceeds,
  disabledPaymentTypeInput,
  disabledAmountInput,
  ...dialogProps
}: AddMarketplaceChargeDialogProps) => {
  const isEditingPaymentLine =
    !!siteMarketplacePaymentLineInput?.marketplacePaymentId;
  const requiredText = useContent(ContentId.Required);
  const { getUiCurrency } = useLocalizationContext();
  const { register, formState, watch, setValue, handleSubmit, control } =
    useForm<SiteMarketplacePaymentLineInput>({
      defaultValues: siteMarketplacePaymentLineInput
        ? siteMarketplacePaymentLineInput
        : {
            ...EMPTY_MARKETPLACE_PAYMENT_LINE,
            externalPaymentLineId: `paymentline-${new Date().getTime()}`,
            externalSaleId: sale.idOnMkp ?? undefined,
            saleId: sale.id,
            currency: sale.currency,
          },
    });

  const paymentDateStr = watch('paymentDate');
  const receivedDateStr = watch('receivedDate');
  const isCredit = watch('isCredit');
  const paymentLineType = watch('paymentLineType');
  const currency = watch('currency');
  const isPrimaryPaymentLineType =
    paymentLineType === MarketplacePaymentLineType.Proceeds ||
    paymentLineType === MarketplacePaymentLineType.Charge ||
    paymentLineType === MarketplacePaymentLineType.Credit;

  const uiCurrency = getUiCurrency(currency);

  useEffect(() => {
    if (
      paymentLineType === MarketplacePaymentLineType.Proceeds ||
      paymentLineType === MarketplacePaymentLineType.Credit
    ) {
      setValue('isCredit', true);
    }
    if (paymentLineType === MarketplacePaymentLineType.Charge) {
      setValue('isCredit', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLineType]);

  const paymentDate = useMemo(
    () => (paymentDateStr ? new Date(paymentDateStr) : undefined),
    [paymentDateStr]
  );

  const receivedDate = useMemo(
    () => (receivedDateStr ? new Date(receivedDateStr) : undefined),
    [receivedDateStr]
  );

  const onSubmit = useCallback(
    (formValues: SiteMarketplacePaymentLineInput) => {
      onSave(formValues);
    },
    [onSave]
  );

  const valueOptionsContent = useMemo(() => {
    return Object.entries(MARKETPLACE_PAYMENT_LINE_TYPE_TO_CID).reduce(
      (acc, [key, value]) => {
        if (canSelectProceeds || key !== MarketplacePaymentLineType.Proceeds) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, string>
    );
  }, [canSelectProceeds]);

  return (
    <GenericDialog
      {...dialogProps}
      size="m"
      header={<Content id={ContentId.AddPayment} />}
      footer={
        <>
          <Button variant="outline" onClick={onCancel}>
            <Content id={ContentId.Cancel} />
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>
            <Content id={ContentId.Apply} />
          </Button>
        </>
      }
      onCancel={onCancel}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FieldWrapper>
            <PosFormField
              errors={formState.errors.paymentLineType?.message}
              label={<Content id={ContentId.PaymentType} />}
            >
              <Controller
                name={'paymentLineType'}
                rules={{ required: paymentLineType ? undefined : requiredText }} //
                control={control}
                render={({ field: { value } }) => (
                  <PosSelect
                    showFullWidth={true}
                    value={value}
                    valueOptionsContent={valueOptionsContent}
                    searchable
                    onChange={(paymentLineType) => {
                      setValue(
                        'paymentLineType',
                        paymentLineType as MarketplacePaymentLineType,
                        { shouldValidate: true }
                      );
                    }}
                    sortBy="key"
                    disabled={disabledPaymentTypeInput}
                  />
                )}
              />
            </PosFormField>
          </FieldWrapper>

          {!isPrimaryPaymentLineType && (
            <FieldWrapper>
              <PosFormField
                errors={formState.errors.isCredit?.message}
                label={<Content id={ContentId.Type} />}
              >
                <Controller
                  name={'isCredit'}
                  rules={{
                    required: isCredit != null ? undefined : requiredText,
                  }}
                  control={control}
                  render={({ field: { value } }) => (
                    <RadioGroup
                      onValueChange={(value: string) => {
                        setValue('isCredit', value === 'true', {
                          shouldValidate: true,
                        });
                      }}
                      value={String(value)}
                    >
                      <Stack gap="l">
                        <Radio
                          value={'true'}
                          label={<Content id={ContentId.Credit} />}
                        />
                        <Radio
                          value={'false'}
                          label={<Content id={ContentId.Charge} />}
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </PosFormField>
            </FieldWrapper>
          )}
          <FieldWrapper>
            <PosFormField
              errors={formState.errors.amount?.message}
              label={<Content id={ContentId.Amount} />}
            >
              <PosTextField
                type="number"
                {...register('amount', {
                  validate: {
                    required: (amount) => {
                      return amount == null ? requiredText : undefined;
                    },
                  },

                  setValueAs: setValueAsNumber,
                })}
                prefixDisplay={uiCurrency.sym}
                disabled={disabledAmountInput}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={formState.errors.externalPaymentId?.message}
              label={<Content id={ContentId.PaymentId} />}
            >
              <PosTextField
                {...register('externalPaymentId', {
                  validate: {
                    required: (externalPaymentId) => {
                      return !externalPaymentId?.length
                        ? requiredText
                        : undefined;
                    },
                  },
                })}
                disabled={isEditingPaymentLine}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={formState.errors.externalPaymentLineId?.message}
              label={<Content id={ContentId.PaymentLineId} />}
            >
              <PosTextField
                {...register('externalPaymentLineId', {
                  validate: {
                    required: (externalPaymentLineId) => {
                      return !externalPaymentLineId?.length
                        ? requiredText
                        : undefined;
                    },
                  },
                })}
                disabled={isEditingPaymentLine}
              />
            </PosFormField>
          </FieldWrapper>

          <Stack gap="l">
            <FieldWrapper>
              <PosFormField
                errors={formState.errors.paymentDate?.message}
                label={<Content id={ContentId.DateIncurred} />}
              >
                <Controller
                  name={'paymentDate'}
                  rules={{ required: paymentDate ? undefined : requiredText }}
                  control={control}
                  render={() => (
                    <DatePickerInput
                      date={paymentDate}
                      onDateChange={(date: Date) => {
                        setValue('paymentDate', date.toISOString(), {
                          shouldValidate: true,
                        });
                      }}
                      textFieldStyles={{ width: '100px' }}
                      disabled={isEditingPaymentLine}
                    />
                  )}
                />
              </PosFormField>
            </FieldWrapper>
            <FieldWrapper>
              <PosFormField
                errors={formState.errors.receivedDate?.message}
                label={<Content id={ContentId.DateDue} />}
              >
                <Controller
                  name={'receivedDate'}
                  control={control}
                  render={() => (
                    <DatePickerInput
                      date={receivedDate}
                      onDateChange={(date: Date) => {
                        setValue('receivedDate', date.toISOString(), {
                          shouldValidate: true,
                        });
                      }}
                      textFieldStyles={{ width: '100px' }}
                      disabled={isEditingPaymentLine}
                    />
                  )}
                />
              </PosFormField>
            </FieldWrapper>
          </Stack>
        </div>
      </form>
    </GenericDialog>
  );
};
