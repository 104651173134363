import { HubConnectionState } from '@microsoft/signalr';
import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { FieldWrapper } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  PurchaseOrderDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkUpdatePurchaseCurrencyCodeDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    supportBackgroundProcess: boolean,
    onPreviewReceived:
      | ((preview: BulkEditPreviewWithDetails) => void)
      | undefined,
    preview: BulkEditPreviewWithDetails | undefined,
    currencyCode: string
  ) => void;
  onCancel: () => void;
};

export const BulkUpdatePurchaseCurrencyCodeDialog = ({
  purchases,
  ...rest
}: BulkUpdatePurchaseCurrencyCodeDialogProps) => {
  const methods = useForm<PurchaseOrderDetailsInput>();

  return (
    <FormProvider {...methods}>
      <BulkUpdatePurchaseCurrencyCodeDialogContent
        purchases={purchases}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function BulkUpdatePurchaseCurrencyCodeDialogContent({
  updateKey,
  onOkay,
  onCancel,
  watch,
  reset,
  setValue,
  formState,
  ...rest
}: BulkUpdatePurchaseCurrencyCodeDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<PurchaseOrderDetailsInput, unknown>>,
    'children'
  >) {
  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const [currencyCode, setCurrencyCode] = useState<string | undefined>();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshData,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Purchase,
    BulkActionType.UpdatePurchaseCurrency,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmitHandler = useCallback(
    async (
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void
    ) => {
      if (!currencyCode) {
        return;
      }
      if (onPreviewReceived) {
        setIsLoading(true);
        onOkay(false, onPreviewReceived, undefined, currencyCode);
        setIsLoading(false);
      } else {
        onOkay(hasBackgroundBulkEditFeature, undefined, preview, currencyCode);
      }
    },
    [onOkay, currencyCode, hasBackgroundBulkEditFeature, preview]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.BulkUpdatePurchaseCurrency} />}
        />
      }
      onOpened={() => {
        reset();
        initJob();
      }}
      onKeyUp={(e) => {
        if (submitButtonRef.current && e.key === 'Enter') {
          submitButtonRef.current.click();
        }
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Purchase}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submitButtonRef}
          disabled={!currencyCode}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Purchase}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="m">
          <span>
            <Content id={ContentId.BulkUpdatePurchaseCurrencyActionText} />
          </span>
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.Currency} />}>
              <CurrencyFilterSelector
                style={{ width: '100%' }}
                value={currencyCode ?? undefined}
                enableEmptySelection={true}
                onChange={(newCurrencyCode) => {
                  if (newCurrencyCode && newCurrencyCode !== currencyCode) {
                    setCurrencyCode(newCurrencyCode);
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>
          <InfoMessage
            message={
              <Content id={ContentId.BulkUpdatePurchaseCurrencyInfoText} />
            }
          />
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
