import { HubConnectionState } from '@microsoft/signalr';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ModalBody } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY } from 'src/components/Listings/InventoryActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditListingClient,
  Event,
  RowGroupSetting,
  VenueZoneConfigType,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { Summary } from '../common/Summary';
import { ModalFooter } from '../Modal';
import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { UngroupedListingGroupId } from './components/GroupsPreview/GroupListingsPreviewTable.constants';
import { GroupListingsBody } from './GroupListingsBody';
import { useGroupListingsAction } from './GroupListingsV2.hooks';
import {
  GroupListingsFormProps,
  GroupListingsState,
  ListingGroupType,
} from './GroupListingsV2.types';

type GroupListingsV2Props = {
  event: Event;
};

export const GroupListingsV2: React.FC<GroupListingsV2Props> = ({ event }) => {
  const methods = useForm<GroupListingsFormProps>({
    defaultValues: {
      venueZoneConfigType: VenueZoneConfigType.ConcertsSectionsBehindStage,
      rowGroupSetting: RowGroupSetting.SingleTwoToFiveSixPlus,
      desiredActiveListings: undefined,
      deprioritizedQuantities: undefined,
      groupUndercutSettings: null,
      marketplaceSettings: null,
      groupBys: [
        {
          groupingType: ListingGroupType.ZoneMap,
        },
      ],
      mergeListingGroupInputs: [],
      rowRanges: [
        { min: 1, max: 1 },
        { min: 2, max: 5 },
        { min: 6, max: Number.MAX_VALUE },
      ],
    },
  });
  return (
    <FormProvider {...methods}>
      <EventMapContextProvider event={event}>
        <GroupListingV2Content event={event} />
      </EventMapContextProvider>
    </FormProvider>
  );
};

const GroupListingV2Content: React.FC<GroupListingsV2Props> = ({ event }) => {
  const { showErrorDialog } = useErrorBoundaryContext();

  const { formState, reset } = useFormContext<GroupListingsFormProps>();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;
  const { setActivePosEntity } = useActivePosEntityContext();
  const { setProgress, setPreview, setStage, setMainDialogOpened } =
    useBulkEditHubContext();

  const { closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { bulkEditHub, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.GroupListings,
    INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
    async () => {
      setActivePosEntity(0);
      await refreshExpandedListItems();

      closeModal();
    }
  );
  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmit = useCallback(
    (inputFormData: GroupListingsFormProps) => {
      setIsLoading(true);

      tryInvokeApi(
        async () => {
          initJob();
          const filteredListingGroupInputs =
            inputFormData.mergeListingGroupInputs
              .map((groupInput) => ({
                ...groupInput,
                desiredActiveListings: groupInput.desiredActiveListings ?? 0,
                listingGroupItems: groupInput.listingGroupItems.filter(
                  (x) => x.listingId > 0
                ),
              }))
              .filter(
                (m) =>
                  m.listingGroupItems.length &&
                  m.listingGroupId !== UngroupedListingGroupId
              );

          const succeeded = await new BulkEditListingClient(
            activeAccountWebClientConfig
          ).bulkCreateListingGroups(
            {
              mergeListingGroupInputs: filteredListingGroupInputs,
            },
            INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
            hasBackgroundBulkEditFeature
          );

          reset(inputFormData);
          if (!hasBackgroundBulkEditFeature) {
            if (succeeded) {
              setActivePosEntity(0);
              await refreshExpandedListItems();

              closeModal();
            }
          }
        },
        (error) => {
          showErrorDialog(
            'BulkEditListingClient.bulkCreateListingGroups',
            error,
            {
              trackErrorData: inputFormData,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      closeModal,
      hasBackgroundBulkEditFeature,
      initJob,
      refreshExpandedListItems,
      reset,
      setActivePosEntity,
      showErrorDialog,
    ]
  );

  const { state, onFooterNav, eventListings } = useGroupListingsAction(
    event,
    onSubmit
  );

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || isSubmitting}
        onBeforeClose={() => {
          setProgress(undefined);
          setPreview(undefined);
          setStage(BulkEditStage.Idle);
          setMainDialogOpened(false);
        }}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.GroupListings} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyDataContainer>
          <div style={{ paddingBottom: vars.spacing.sm }}>
            <Summary event={event} />
          </div>
          <BulkEditStatus
            entityType={ActionOutboxEntityType.Listing}
            updateKey={INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY}
          >
            <GroupListingsBody
              event={event}
              state={state}
              eventListings={eventListings}
            />
          </BulkEditStatus>
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        <Stack width="full" direction="row" justifyContent="spaceBetween">
          {state === GroupListingsState.Configure && (
            <CancellableFormFooter
              disabled={isLoading || isSubmitting}
              onBeforeClose={() => {
                setProgress(undefined);
                setPreview(undefined);
                setStage(BulkEditStage.Idle);
                setMainDialogOpened(false);
              }}
            />
          )}
          {state === GroupListingsState.Review && (
            <CancelButton
              textContentId={ContentId.Back}
              onClick={() => onFooterNav(true)}
            />
          )}
          <Button disabled={isLoading} onClick={() => onFooterNav()}>
            {state === GroupListingsState.Configure ? (
              <Content id={ContentId.Next} />
            ) : (
              <Content id={ContentId.Save} />
            )}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  );
};
