import { MainRoute } from 'src/navigations/Routes/MainRoute';
import {
  Feature,
  FeatureDetail,
  FeatureState,
  MarketplaceAccountSetting,
  Permission,
  SellerAccount,
  UserDetail,
} from 'src/WebApiController';

import { hasAnyOfPermissions } from './userPermissionUtils';

export const ALL_INVENTORY_VIEW_PERMISSIONS = [
  Permission.Inventory_ViewAll,
  Permission.Inventory_ViewRecent,
  Permission.Inventory_ViewRecentFromExternalMarketplace,
  Permission.Inventory_ViewListingOnIsPricerOf,
  Permission.Inventory_ViewListingsOnPurchasedBy,
  Permission.Inventory_ViewFulfillmentArtifactsOnPurchasedBy,
  Permission.Inventory_ViewFulfillmentArtifactsOnIsPricerOf,
];

export const ALL_SALES_VIEW_PERMISSIONS = [
  Permission.Sales_ViewAll,
  Permission.Sales_ViewRecent,
  Permission.Sales_ViewSalesOnIsFulfillerOf,
  Permission.Sales_ViewSalesOnIsPricerOf,
  Permission.Sales_ViewSalesOnIsPricerOf,
];

const checkFeatureState = (
  feature: Feature,
  featureDetails: FeatureDetail[] | null | undefined
) => {
  const fDetails = featureDetails?.find((fd) => fd.id === feature);
  if (fDetails) {
    if (fDetails.state === FeatureState.Disabled) return false;
    else if (fDetails.state === FeatureState.GeneralAvailability) return true;
    else {
      return undefined;
    }
  }
};

export const isMarketplaceActive = (
  marketplaceSetting: MarketplaceAccountSetting | null | undefined
) => {
  if (marketplaceSetting == null) {
    return false;
  }

  return (
    marketplaceSetting.isEnabled &&
    (marketplaceSetting.hasApiToken || marketplaceSetting.hasApiRefreshToken) &&
    !marketplaceSetting.tokenExpired
  );
};

export const hasAccessToRoute = (
  userDetail: UserDetail | null | undefined,
  featureDetails: FeatureDetail[] | null | undefined,
  route: MainRoute
): boolean => {
  let state = undefined;
  switch (route) {
    case MainRoute.Inventory:
    case MainRoute.InventorySearch:
      if (
        !hasAnyOfPermissions(userDetail, [
          Permission.Inventory_ViewAll,
          Permission.Inventory_ViewRecent,
          Permission.Inventory_ViewRecentFromExternalMarketplace,
          Permission.Inventory_ViewListingOnIsPricerOf,
          Permission.Inventory_ViewListingsOnPurchasedBy,
        ])
      ) {
        return false;
      }
      if (route === MainRoute.InventorySearch) {
        return hasFeature(
          userDetail,
          featureDetails,
          Feature.SearchView_Inventory
        );
      }
      return true;

    case MainRoute.Sales:
    case MainRoute.SalesSearch:
      if (
        !hasAnyOfPermissions(userDetail, [
          Permission.Sales_ViewAll,
          Permission.Sales_ViewRecent,
          Permission.Sales_ViewRecentFromExternalMarketplace,
          Permission.Sales_ViewSalesOnIsFulfillerOf,
          Permission.Sales_ViewSalesOnIsPricerOf,
          Permission.Sales_ViewSalesOnPurchasedBy,
        ])
      ) {
        return false;
      }

      if (route === MainRoute.SalesSearch) {
        return hasFeature(userDetail, featureDetails, Feature.SearchView_Sale);
      }
      return true;

    case MainRoute.Purchases:
    case MainRoute.PurchaseSearch:
      if (
        !hasAnyOfPermissions(userDetail, [
          Permission.Purchases_ViewAll,
          Permission.Purchases_ViewOwned,
          Permission.Purchases_ViewRecent,
        ])
      ) {
        return false;
      }
      if (route === MainRoute.PurchaseSearch) {
        return hasFeature(
          userDetail,
          featureDetails,
          Feature.SearchView_Purchase
        );
      }
      return true;

    case MainRoute.SyncCenter:
      if (!hasAnyOfPermissions(userDetail, [Permission.SellerAccount_Admin])) {
        return false;
      }
      return true;

    case MainRoute.Reports:
      if (
        !hasAnyOfPermissions(userDetail, [
          Permission.Reports_ViewAll,
          Permission.Reports_ViewGlobalReports,
          Permission.Reports_ViewInventoryReports,
          Permission.Reports_ViewSalesReports,
        ])
      ) {
        return false;
      }
      return true;

    case MainRoute.Email: {
      if (
        !hasAnyOfPermissions(userDetail, [
          Permission.Purchases_ViewAll,
          Permission.Purchases_ViewOwned,
          Permission.Purchases_ViewRecent,
          Permission.SellerSupportCase_View,
        ])
      ) {
        return false;
      }

      return true;
    }

    case MainRoute.Home: {
      const hasInventoryView = hasAnyOfPermissions(
        userDetail,
        ALL_INVENTORY_VIEW_PERMISSIONS
      );
      const hasSalesView = hasAnyOfPermissions(
        userDetail,
        ALL_SALES_VIEW_PERMISSIONS
      );

      return hasInventoryView || hasSalesView;
    }
    case MainRoute.Notifications: {
      const hasInventoryView = hasAnyOfPermissions(
        userDetail,
        ALL_INVENTORY_VIEW_PERMISSIONS
      );
      const hasSalesView = hasAnyOfPermissions(
        userDetail,
        ALL_SALES_VIEW_PERMISSIONS
      );

      // Notifications no show changes to any Inv, Sales so only show if has access to both (until we fix ticket below)
      // https://thestubhub.atlassian.net/browse/POS-4047
      return hasInventoryView && hasSalesView;
    }
    case MainRoute.SponsoredListings: {
      return hasFeature(userDetail, featureDetails, Feature.SponsoredListings);
    }
    case MainRoute.Payments: {
      if (!hasAnyOfPermissions(userDetail, [Permission.Accounting_ViewAll])) {
        return false;
      }

      return hasFeature(
        userDetail,
        featureDetails,
        Feature.MarketplacePaymentsTab
      );
    }
    case MainRoute.SellerAccountSetting:
    case MainRoute.Login:
    case MainRoute.SignUp:
    case MainRoute.LoginCallback:
    case MainRoute.LoginFailure:
    case MainRoute.Logout:
    case MainRoute.RedirectToUK:
    case MainRoute.Activity:
    case MainRoute.Settings:
    case MainRoute.ReleaseNotes:
      return true;

    case MainRoute.ReportsV2: {
      state = checkFeatureState(Feature.ReportingV2, featureDetails);
      const reportingV2FeatureAvailable =
        state === undefined
          ? Boolean(userDetail?.features?.includes(Feature.ReportingV2))
          : Boolean(state);
      return reportingV2FeatureAvailable;
    }

    default:
      console.log(`Unhandled MainRoute: ${route}`);
      return false;
  }
};

export const getHasAccessToRoutes = (
  userDetail?: UserDetail | null,
  featureDetails?: FeatureDetail[] | null
) => {
  const possibleRoutes = [
    MainRoute.Home,
    MainRoute.Inventory,
    MainRoute.Sales,
    MainRoute.Purchases,
    MainRoute.Payments,
    MainRoute.Reports,
    MainRoute.Email,
    MainRoute.Settings,
    MainRoute.SponsoredListings,
    MainRoute.ReleaseNotes,
  ];
  return possibleRoutes.filter((r) =>
    hasAccessToRoute(userDetail, featureDetails, r)
  );
};

export const hasFeature = (
  userDetail: UserDetail | null | undefined,
  featureDetails: FeatureDetail[] | null | undefined,
  feature: Feature
): boolean => {
  const state = checkFeatureState(feature, featureDetails);

  const featuresOverride: Feature[] = [];

  // Comment this out to enable these features locally
  // *** NOTE - always comment out this line before check-in ***
  // featuresOverride.push(...[Feature.PosUiVersion2]);

  if (featuresOverride.includes(feature)) {
    return true;
  }

  return state === undefined
    ? Boolean(userDetail?.features?.includes(feature))
    : Boolean(state);
};

export const sortSellerAccount = (accounts: SellerAccount[]) => {
  return accounts.sort((a, b) => {
    const aTag = `${a.impersonatedUsername ? '2' : '1'} - ${a.accountName}`;
    const bTag = `${b.impersonatedUsername ? '2' : '1'} - ${b.accountName}`;

    return aTag.localeCompare(bTag);
  });
};

export const hasFeatures = (
  userDetail: UserDetail | null | undefined,
  featureDetails: FeatureDetail[] | null | undefined,
  features: Readonly<Feature[]>
): boolean => {
  return features.every((f) => hasFeature(userDetail, featureDetails, f));
};
