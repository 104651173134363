import { FilterToolbarItemId } from 'src/components/Filters';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { shared, vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import {
  REPORT_FILTER_EDITABILITY_TO_CID,
  REPORT_FILTER_EDITABILITY_TO_DESC_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

export const ReportFilterEditabilityDropdown = ({
  filterId,
  reportFilterEditability,
  onEditabilityChange,
}: {
  filterId: FilterToolbarItemId;
  reportFilterEditability?: ReportFilterEditability;
  onEditabilityChange: (
    filterId: FilterToolbarItemId,
    editability: ReportFilterEditability
  ) => void;
}) => {
  const handleEditabilityChange = (editability: string) => {
    onEditabilityChange(filterId, editability as ReportFilterEditability);
  };

  const title = useContent(
    REPORT_FILTER_EDITABILITY_TO_DESC_CID[
      reportFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY
    ]
  );

  return (
    <PosDropdown
      key="report-filter-editability-dropdown"
      trigger={
        <Button variant="text" title={title}>
          <Content
            id={
              REPORT_FILTER_EDITABILITY_TO_CID[
                reportFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY
              ]
            }
          />
        </Button>
      }
      align="start"
    >
      {Object.values(ReportFilterEditability).map((editability) => (
        <PosDropdownItem
          key={editability}
          onClick={() => handleEditabilityChange(editability)}
        >
          <Stack gap="s" direction="column">
            <span
              style={
                editability ===
                (reportFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY)
                  ? { color: vars.color.textBrand }
                  : {}
              }
            >
              <Content id={REPORT_FILTER_EDITABILITY_TO_CID[editability]} />
            </span>
            <span className={shared.typography.body2}>
              <Content
                id={REPORT_FILTER_EDITABILITY_TO_DESC_CID[editability]}
              />
            </span>
          </Stack>
        </PosDropdownItem>
      ))}
    </PosDropdown>
  );
};
