import { isEqual } from 'lodash';
import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/Filters';
import { useInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter';
import { useMergedTagFilters } from 'src/components/MainFilterBar/Tags/useMergedTagFilters';
import { usePurchaseInfoFilters } from 'src/components/MainFilterBar/usePurchaseInfoFilters';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Slider } from 'src/core/ui/Slider';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchasePaymentMethodSelectionInput } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PAYMENT_METHOD_TYPE_TO_CID,
  PURCHASE_PAYMENT_STATUS_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { StandardDateRangePresetNames } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberFormatter';
import {
  ActionOutboxEntityType,
  EventTimeFrameFilter,
  Feature,
  PurchaseOrderQuery,
  PurchaseOrderState,
} from 'src/WebApiController';

import { EventDateSelector } from '../Events/EventDateSelector/EventDateSelector';
import { useLocationFilter } from '../Filters/LocationFilter';
import {
  CurrencyFilterMultiSelector,
  CurrencyFilterSelector,
} from '../Selectors/CurrencyFilterSelector';
import { useEventStateFilters } from './useEventStateFilters';

export const purchaseMandatoryFiltersToShow: FilterToolbarItemId[] = [
  'purchaseDates',
];

interface PurchaseFiltersParams {
  isHorizontalLayout?: boolean;
  showCommissionerFilter?: boolean;
}

export const usePurchaseFilters = ({
  isHorizontalLayout,
  showCommissionerFilter,
}: PurchaseFiltersParams) => {
  const hasFilterByCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByCurrencyCodeMulti
  );
  const hasFilterByCurrencyCodeFeature =
    useUserHasFeature(Feature.FilterByCurrencyCode) &&
    !hasFilterByCurrencyCodeMultiFeature;
  const hasFilterByPaymentCurrencyCodeMultiFeature = useUserHasFeature(
    Feature.FilterByPaymentCurrencyCodeMulti
  );
  const hasFilterPOByPaymentMethodIdFeature = useUserHasFeature(
    Feature.FilterPOByPaymentMethodId
  );
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );

  const internalNotesFilter = useInternalNotesFilter<PurchaseOrderQuery>({
    entityType: ActionOutboxEntityType.Purchase,
    isHorizontalLayout,
  });
  const locationFilters = useLocationFilter();

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const reportType = undefined;
  const purchaseInfoFilters = usePurchaseInfoFilters(
    tempQuery,
    setTempQuery,
    reportType,
    showCommissionerFilter
  );

  const tagsFilter = useMergedTagFilters({
    query: tempQuery,
    setQuery: setTempQuery,
    entityType: ActionOutboxEntityType.Purchase,
    includeEventTags: false,
  });

  const eventStateFilters = useEventStateFilters({
    query: tempQuery,
    setQuery: setTempQuery,
  });

  const eventFilter = useMemo<FilterToolbarGroup>(
    () => ({
      titleContentId: ContentId.Events,
      type: 'row',
      items: [
        {
          labelContentId: ContentId.EventDate,
          filterId: 'eventDates',
          filterQueryKeys: ['eventDates', 'eventTimeFrameFilter'],
          filterItem: (
            <EventDateSelector
              useRelativePresets={hasDynamicDateTimeFiltersFeature}
              eventTimeFrame={tempQuery.eventTimeFrameFilter}
              value={tempQuery.eventDates}
              onChange={(eventTimeFrameFilter, dateRange) => {
                if (
                  eventTimeFrameFilter !== tempQuery.eventTimeFrameFilter ||
                  !isEqual(dateRange, tempQuery.eventDates)
                ) {
                  let isSortDescending = tempQuery.isSortDescending;
                  if (eventTimeFrameFilter !== EventTimeFrameFilter.Future) {
                    // When sorting by past or by all - we do not want to sort by date asc (as that would put the oldest event first)
                    // Changing this to desc
                    isSortDescending = true;
                  } else {
                    isSortDescending = initialQuery.isSortDescending;
                  }

                  setTempQuery({
                    ...tempQuery,
                    eventTimeFrameFilter: eventTimeFrameFilter ?? null,
                    eventDates: dateRange ?? null,
                    isSortDescending,
                  });
                }
              }}
            />
          ),
        },
        ...locationFilters,
        ...eventStateFilters,
      ],
    }),
    [
      hasDynamicDateTimeFiltersFeature,
      tempQuery,
      locationFilters,
      eventStateFilters,
      setTempQuery,
      initialQuery.isSortDescending,
    ]
  );

  return useMemo(() => {
    const costHandle: [number, number] = [
      tempQuery.totalCostLow || 0,
      tempQuery.totalCostHigh || 50000,
    ];

    return [
      {
        titleContentId: ContentId.WhenWhereWho,
        type: 'row',
        items: [...purchaseInfoFilters.items],
      },
      {
        titleContentId: ContentId.Payments,
        type: 'row',
        items: [
          {
            filterId: 'paymentDates' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentDate,
            filterQueryKeys: ['paymentDates'] as FilterToolbarItemId[],
            filterItem: (
              <DateRangeSelector
                useRelativePresets={hasDynamicDateTimeFiltersFeature}
                presetNames={StandardDateRangePresetNames}
                value={tempQuery.paymentDates}
                defaultValue={initialQuery.paymentDates}
                onBlur={(value) =>
                  setTempQuery({
                    ...tempQuery,
                    paymentDates: value,
                  })
                }
              />
            ),
          },
          {
            filterId: 'paymentStatus' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentState,
            filterQueryKeys: ['paymentStatus'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.paymentStatus}
                placeholderText={ContentId.AllPaymentStates}
                enableEmptySelection
                onChange={(paymentStatus) => {
                  if (paymentStatus !== tempQuery.paymentStatus) {
                    setTempQuery({
                      ...tempQuery,
                      paymentStatus: paymentStatus,
                    });
                  }
                }}
                valueOptionsContent={PURCHASE_PAYMENT_STATUS_TO_CID}
              />
            ),
          },
          {
            filterId: 'paymentMethod' as FilterToolbarItemId,
            labelContentId: ContentId.PaymentType,
            filterQueryKeys: ['paymentMethod'] as FilterToolbarItemId[],
            filterItem: (
              <PosEnumSelect
                style={{ width: '100%' }}
                value={tempQuery.paymentMethod}
                placeholderText={ContentId.AllPaymentTypes}
                enableEmptySelection
                onChange={(paymentMethod) => {
                  if (paymentMethod !== tempQuery.paymentMethod) {
                    setTempQuery({
                      ...tempQuery,
                      paymentMethod: paymentMethod,
                    });
                  }
                }}
                valueOptionsContent={PAYMENT_METHOD_TYPE_TO_CID}
              />
            ),
          },
          ...(hasFilterByPaymentCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'paymentCurrencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.PaymentCurrency,
                  filterQueryKeys: [
                    'paymentCurrencyCodes',
                  ] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      values={tempQuery.paymentCurrencyCodes ?? []}
                      triggerProps={{ style: { width: '100%' } }}
                      placeholderText={ContentId.All}
                      enableEmptySelection
                      onChange={(newPaymentCurrencyCodes) => {
                        if (
                          !isEqual(
                            tempQuery.paymentCurrencyCodes,
                            newPaymentCurrencyCodes
                          )
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            paymentCurrencyCodes:
                              newPaymentCurrencyCodes?.length
                                ? newPaymentCurrencyCodes
                                : null,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterPOByPaymentMethodIdFeature
            ? [
                {
                  filterId: 'paymentMethodId' as FilterToolbarItemId,
                  labelContentId: ContentId.PaymentMethodUsed,
                  filterQueryKeys: ['paymentMethodId'] as FilterToolbarItemId[],
                  filterItem: (
                    <PurchasePaymentMethodSelectionInput
                      style={{ width: '100%' }}
                      value={tempQuery.paymentMethodId?.toString()}
                      placeholderText={ContentId.AllPaymentMethods}
                      enableEmptySelection
                      hideAdditionalOptions
                      onChange={(paymentMethodId) => {
                        if (
                          paymentMethodId !==
                          tempQuery.paymentMethodId?.toString()
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            paymentMethodId: paymentMethodId?.length
                              ? parseInt(paymentMethodId)
                              : null,
                          });
                        }
                      }}
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      eventFilter,
      {
        titleContentId: ContentId.Delivery,
        type: 'row',
        items: [
          {
            filterId: 'deliveryType' as FilterToolbarItemId,
            labelContentId: ContentId.Delivery,
            filterQueryKeys: ['deliveryType'] as FilterToolbarItemId[],
            filterItem: (
              <DeliveryTypeSelector
                style={{ width: '100%' }}
                value={tempQuery.deliveryType}
                enableEmptySelection
                onChange={(deliveryType) => {
                  if (deliveryType !== tempQuery.deliveryType) {
                    setTempQuery({
                      ...tempQuery,
                      deliveryType: deliveryType,
                    });
                  }
                }}
                allowCustomDeliveryType
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Status,
        type: 'row',
        items: [
          {
            filterId: 'purchaseOrderState' as FilterToolbarItemId,
            labelContentId: ContentId.ShowVoidCancelledPurchases,
            filterQueryKeys: ['purchaseOrderState'] as FilterToolbarItemId[],
            filterItem: (
              <Checkbox
                checked={
                  tempQuery.purchaseOrderState !== PurchaseOrderState.Active
                }
                onChange={(e) => {
                  const showCancelledAndVoided = e.target.checked;
                  const newPurchaseOrderState = showCancelledAndVoided
                    ? null
                    : PurchaseOrderState.Active;

                  if (newPurchaseOrderState !== tempQuery.purchaseOrderState) {
                    setTempQuery({
                      ...tempQuery,
                      purchaseOrderState: newPurchaseOrderState,
                    });
                  }
                }}
                labelPosition="right"
                label={
                  isHorizontalLayout ? undefined : (
                    <Content id={ContentId.ShowVoidCancelledPurchases} />
                  )
                }
              />
            ),
          },
        ],
      },
      {
        titleContentId: ContentId.Cost,
        items: [
          {
            filterId: 'totalCostHigh' as FilterToolbarItemId,
            labelContentId: ContentId.TotalCost,
            filterQueryKeys: [
              'totalCostLow',
              'totalCostHigh',
            ] as FilterToolbarItemId[],
            filterItem: (
              <Slider.Root
                min={0}
                max={50000}
                step={1}
                value={costHandle}
                renderHandle={(n) => (
                  <Slider.Handle>{formatNumber(n)}</Slider.Handle>
                )}
                onValueChange={([low, high]: [number, number]) => {
                  if (
                    low !== (tempQuery.totalCostLow || 0) ||
                    high !== tempQuery.totalCostHigh
                  ) {
                    const newQuery = {
                      ...tempQuery,
                      totalCostLow: low === 0 ? null : low,
                      totalCostHigh: high === 50000 ? null : high,
                    };
                    setTempQuery(newQuery);
                  }
                }}
              />
            ),
          },
          ...(hasFilterByCurrencyCodeFeature
            ? [
                {
                  filterId: 'currencyCode' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCode'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterSelector
                      value={tempQuery.currencyCode ?? ''}
                      style={{ width: '100%' }}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCode) => {
                        if (tempQuery.currencyCode !== newCurrencyCode) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCode: newCurrencyCode?.length
                              ? newCurrencyCode
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
          ...(hasFilterByCurrencyCodeMultiFeature
            ? [
                {
                  filterId: 'currencyCodes' as FilterToolbarItemId,
                  labelContentId: ContentId.Currency,
                  filterQueryKeys: ['currencyCodes'] as FilterToolbarItemId[],
                  filterItem: (
                    <CurrencyFilterMultiSelector
                      values={tempQuery.currencyCodes ?? []}
                      triggerProps={{ style: { width: '100%' } }}
                      placeholderText={ContentId.All}
                      onChange={(newCurrencyCodes) => {
                        if (
                          !isEqual(tempQuery.currencyCodes, newCurrencyCodes)
                        ) {
                          setTempQuery({
                            ...tempQuery,
                            currencyCodes: newCurrencyCodes?.length
                              ? newCurrencyCodes
                              : null,
                          });
                        }
                      }}
                      enableEmptySelection
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      ...tagsFilter,
      ...(internalNotesFilter ? [internalNotesFilter] : []),
    ];
  }, [
    tempQuery,
    purchaseInfoFilters.items,
    hasDynamicDateTimeFiltersFeature,
    initialQuery.paymentDates,
    hasFilterByPaymentCurrencyCodeMultiFeature,
    hasFilterPOByPaymentMethodIdFeature,
    eventFilter,
    isHorizontalLayout,
    hasFilterByCurrencyCodeFeature,
    hasFilterByCurrencyCodeMultiFeature,
    tagsFilter,
    internalNotesFilter,
    setTempQuery,
  ]);
};
