import { DateTimeRange } from 'src/WebApiController';

export const MAX_ENTITIES_IN_GROUP_FOR_LOAD = 1000;

export type EventIdGroup = {
  viagVirtualId: string;
  performerId: string | null;
  venueId: number;
  name: string;
  date: DateTimeRange;
};
