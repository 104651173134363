export enum MarketplacePaymentLinesTableColumnId {
  Id = 'id',
  Type = 'type',
  Event = 'event',
  Seating = 'seating',
  Sale = 'sale',
  Paid = 'paid',
  SaleStatus = 'saleStatus',
  Actions = 'actions',
}
