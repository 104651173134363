import { ConnectedComparableListings } from 'src/components/Listings/ConnectedComparableListings';
import { CompListing, ListingDetails } from 'src/WebApiController';

export const ViewCompsBody = ({
  listing,
  compListings,
  ...rest
}: {
  listing?: ListingDetails | null;
  compListings?: CompListing[] | null;
}) => {
  return (
    <ConnectedComparableListings
      currentListing={listing ?? undefined}
      comparableListings={compListings ?? []}
      {...rest}
    />
  );
};
