import clsx from 'clsx';
import endOfDay from 'date-fns/endOfDay';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateRange as ReactDateRange,
  DateRangePickerProps,
} from 'react-date-range';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import {
  DateRange,
  DateRangePresets,
} from 'src/core/POS/DateRangeSelector/DateRangePresets';
import { DateRangeRelativePresets } from 'src/core/POS/DateRangeSelector/DateRangePresets/DateRangeRelativePresets';
import {
  CustomDateRangeButton,
  DateErrorContainer,
  DateRangePickerContainer,
  DateRangeTrigger,
  DateRangeTriggerContent,
  IconContainer,
} from 'src/core/POS/DateRangeSelector/DateRangeSelector.styled';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { DividerLine, Popover, Stack, TooltipPopover } from 'src/core/ui';
import {
  CalendarIcon,
  CheckIcon,
  CrossIcon,
  ExpandIcon,
  IconsFill,
  WarningOutlineIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_TIME_FRAME_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  formatDate,
  getEventTimeFrameFromDateTimeRange,
  NextDateRangePresetNames,
  PastDateRangePresetNames,
  tryGetDateRangePresetSuggestionText,
  tryGetDateRangeRelativePresetSuggestionText,
  validateDate,
} from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  DateTimeRangeWithRelative,
  EventTimeFrameFilter,
} from 'src/WebApiController';

import * as styles from './EventDateSelector.css';

export type EventDateSelectorProps = {
  disabled?: boolean;
  eventTimeFrame?: EventTimeFrameFilter | null;
  value?: DateTimeRangeWithRelative | null;
  onChange?: (
    timeFrame?: EventTimeFrameFilter | null,
    value?: DateTimeRangeWithRelative | null
  ) => void;
  useRelativePresets?: boolean;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
  timeFrameLabelRecord?: Record<EventTimeFrameFilter, ContentId>;
  allEventsLabel?: ContentId;
} & Omit<DateRangePickerProps, 'range' | 'onChange'>;

export const EventDateSelector = ({
  eventTimeFrame,
  value,
  startDatePlaceholder,
  endDatePlaceholder,
  className,
  dateDisplayFormat = 'MM/dd/yyyy',
  retainEndDateOnFirstSelection = true,
  rangeColors = [vars.color.backgroundHighlight],
  locale = getLocaleFromLanguageOrCurrent(),
  onChange,
  disabled,
  useRelativePresets,
  useSiteTimeZone = true,
  onPreviewChange,
  timeFrameLabelRecord = EVENT_TIME_FRAME_FILTER_TO_CID,
  allEventsLabel = ContentId.AllEvents,
}: EventDateSelectorProps) => {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();
  const contentContext = useContentContext();

  const currentLocale = getLocaleFromLanguageOrCurrent();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState({
    start: formatDate(value?.start || '', currentLocale.code),
    end: formatDate(value?.end || '', currentLocale.code),
  });
  const [dateErrors, setDateErrors] = useState<string[]>([]);

  const defaultDateRangeDisplayText = useContent(
    ContentId.DateRangeDisplayText
  );
  const defaultFromlabel = useContent(ContentId.FromDate);
  const defaultToLabel = useContent(ContentId.ToDate);
  const endDateBeforeStartDate = useContent(ContentId.EndDateBeforeStartDate);
  const invalidEndDate = useContent(ContentId.InvalidEndDate);
  const invalidStartDate = useContent(ContentId.InvalidStartDate);
  const dateErrorConfig = useMemo(
    () => ({
      endDateBeforeStartDate,
      invalidEndDate,
      invalidStartDate,
    }),
    [endDateBeforeStartDate, invalidEndDate, invalidStartDate]
  );

  const highlightDropBox = !(
    value?.start == null &&
    value?.end == null &&
    value?.relativeStartSecs == null &&
    value?.relativeEndSecs == null
  );

  const getDisplayText = useCallback(() => {
    if (
      !value?.start &&
      !value?.end &&
      value?.relativeStartSecs == null &&
      value?.relativeEndSecs == null
    ) {
      return eventTimeFrame == null
        ? getContent(allEventsLabel, contentContext)
        : getContent(timeFrameLabelRecord[eventTimeFrame], contentContext);
    }

    const suggestionText = tryGetDateRangePresetSuggestionText(
      value,
      useSiteTimeZone ? toZonedTime : undefined
    );

    if (suggestionText) {
      return suggestionText;
    }

    const suggestionTextRelative =
      tryGetDateRangeRelativePresetSuggestionText(value);

    if (suggestionTextRelative) {
      return suggestionTextRelative;
    }

    const { end, start } = dateValue;
    if (value && start) {
      if (useSiteTimeZone && value.start && value.end) {
        const startZoned = formatDate(
          toZonedTime(new Date(value.start)).toISOString(),
          currentLocale.code
        );
        const endZoned = formatDate(
          toZonedTime(new Date(value.end)).toISOString(),
          currentLocale.code
        );

        return `${startZoned} - ${endZoned}`;
      }
      return `${start} - ${end}`;
    }

    return defaultDateRangeDisplayText;
  }, [
    allEventsLabel,
    contentContext,
    currentLocale.code,
    dateValue,
    defaultDateRangeDisplayText,
    eventTimeFrame,
    timeFrameLabelRecord,
    toZonedTime,
    useSiteTimeZone,
    value,
  ]);

  const onDateRangePickerChange = useCallback(
    (
      from: Date | undefined,
      to: Date | undefined,
      timeFrame?: EventTimeFrameFilter | null
    ) => {
      if (!from && !to) {
        onChange?.(EventTimeFrameFilter.Future, null);
        setDateValue({
          start: formatDate('', currentLocale.code),
          end: formatDate('', currentLocale.code),
        });
      }

      timeFrame = timeFrame ?? getEventTimeFrameFromDateTimeRange(from, to);
      const newValue: DateTimeRangeWithRelative = value
        ? { ...value }
        : {
            start: null,
            end: null,
            relativeStartSecs: null,
            relativeEndSecs: null,
            roundingMode: null,
          };

      if (from == null) {
        newValue.start = null;
      } else {
        newValue.start = from.toISOString();
      }
      if (to == null) {
        newValue.end = null;
      } else {
        newValue.end = to.toISOString();
      }

      newValue.relativeStartSecs = null;
      newValue.relativeEndSecs = null;

      onChange?.(timeFrame, newValue);
      setDateValue({
        start: formatDate(newValue.start || '', currentLocale.code),
        end: formatDate(newValue.end || '', currentLocale.code),
      });
    },
    [currentLocale.code, onChange, value]
  );

  const onEventTimeFrameChanged = useCallback(
    (timeFrame?: EventTimeFrameFilter | null) => {
      onChange?.(timeFrame, null);
      setDateValue({
        start: formatDate('', currentLocale.code),
        end: formatDate('', currentLocale.code),
      });
    },
    [currentLocale.code, onChange]
  );

  const onClear = useCallback(() => {
    onDateRangePickerChange(undefined, undefined);
  }, [onDateRangePickerChange]);

  const onSuggestionClick = useCallback(
    (range: DateRange, timeRange: EventTimeFrameFilter) => {
      // All the available time-frames are in the past
      onDateRangePickerChange(range.from, range.to, timeRange);
      setIsOpen(false);
    },
    [onDateRangePickerChange]
  );

  const onSuggestionRelativeClick = useCallback(
    (range: DateTimeRangeWithRelative) => {
      onChange?.(null, range);
      setDateValue({
        start: formatDate('', currentLocale.code),
        end: formatDate('', currentLocale.code),
      });
      setIsOpen(false);
    },
    [currentLocale.code, onChange]
  );

  useEffect(() => {
    const dateErrors = validateDate(dateValue, currentLocale, dateErrorConfig);
    setDateErrors(dateErrors);
  }, [dateValue, dateErrorConfig, currentLocale]);

  const isCustomDateRangeSelected = !value?.start || !value?.end;

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          setShowDatePicker(false);
        }
      }}
    >
      <Popover.Trigger asChild>
        <DateRangeTrigger isActive={highlightDropBox} disabled={disabled}>
          <DateRangeTriggerContent
            className={clsx(styles.dateRangeTriggerContent, {
              [styles.dateRangeTriggerDisabled]: disabled,
            })}
          >
            <div>{getDisplayText()}</div>
            <div className={styles.dateRangeTriggerContentRight}>
              {!disabled &&
                (!!dateValue.start || !!value) &&
                highlightDropBox && (
                  <IconContainer>
                    <CrossIcon size={vars.iconSize.xs} onClick={onClear} />
                  </IconContainer>
                )}
              <ExpandIcon size={vars.iconSize.xs} />
            </div>
          </DateRangeTriggerContent>
        </DateRangeTrigger>
      </Popover.Trigger>
      <Popover.Content align="start" className={styles.dateRangePopoverContent}>
        <DateRangePickerContainer>
          <PosDropdownItem
            className={clsx(styles.selectionItem, {
              [styles.selectionItemSelected]:
                eventTimeFrame === EventTimeFrameFilter.Future &&
                isCustomDateRangeSelected,
            })}
            disabled={disabled}
            onClick={() => onEventTimeFrameChanged(EventTimeFrameFilter.Future)}
          >
            <Content id={timeFrameLabelRecord[EventTimeFrameFilter.Future]} />
            {eventTimeFrame === EventTimeFrameFilter.Future &&
              isCustomDateRangeSelected && (
                <CheckIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                />
              )}
          </PosDropdownItem>
          {useRelativePresets ? (
            <DateRangeRelativePresets
              presetNames={NextDateRangePresetNames}
              disabled={disabled}
              onClick={onSuggestionRelativeClick}
              selectedDateRange={value}
              hideSuggestionTitle
            />
          ) : (
            <DateRangePresets
              presetNames={NextDateRangePresetNames}
              disabled={disabled}
              onClick={(r) => onSuggestionClick(r, EventTimeFrameFilter.Future)}
              selectedDateRange={value}
              hideSuggestionTitle
              useSiteTimeZone={useSiteTimeZone}
            />
          )}
          {useRelativePresets ? (
            <DateRangeRelativePresets
              presetNames={PastDateRangePresetNames}
              disabled={disabled}
              onClick={onSuggestionRelativeClick}
              selectedDateRange={value}
              hideSuggestionTitle
            />
          ) : (
            <DateRangePresets
              presetNames={PastDateRangePresetNames}
              disabled={disabled}
              onClick={(r) => onSuggestionClick(r, EventTimeFrameFilter.Past)}
              selectedDateRange={value}
              hideSuggestionTitle
            />
          )}
          <PosDropdownItem
            className={clsx(styles.selectionItem, {
              [styles.selectionItemSelected]:
                eventTimeFrame === EventTimeFrameFilter.Past &&
                isCustomDateRangeSelected,
            })}
            disabled={disabled}
            onClick={() => onEventTimeFrameChanged(EventTimeFrameFilter.Past)}
          >
            <Stack gap="m">
              <Content id={timeFrameLabelRecord[EventTimeFrameFilter.Past]} />
              <TooltipPopover
                triggerContent={
                  <WarningOutlineIcon fill={IconsFill.textWarning} />
                }
              >
                <Content id={ContentId.TooManyResultPerformanceWarning} />
              </TooltipPopover>
            </Stack>
            {eventTimeFrame === EventTimeFrameFilter.Past &&
              isCustomDateRangeSelected && (
                <CheckIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                />
              )}
          </PosDropdownItem>
          <PosDropdownItem
            className={clsx(styles.selectionItem, {
              [styles.selectionItemSelected]:
                eventTimeFrame == null &&
                value?.relativeEndSecs == null &&
                value?.relativeStartSecs == null &&
                isCustomDateRangeSelected,
            })}
            disabled={disabled}
            onClick={() => onEventTimeFrameChanged(null)}
          >
            <Stack gap="m">
              <Content id={allEventsLabel} />
              <TooltipPopover
                triggerContent={
                  <WarningOutlineIcon fill={IconsFill.textWarning} />
                }
              >
                <Content id={ContentId.TooManyResultPerformanceWarning} />
              </TooltipPopover>
            </Stack>
            {eventTimeFrame == null &&
              value?.relativeEndSecs == null &&
              value?.relativeStartSecs == null &&
              isCustomDateRangeSelected && (
                <CheckIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                />
              )}
          </PosDropdownItem>
          <div style={{ paddingTop: vars.spacing['sm'] }}>
            <DividerLine />
          </div>
          <CustomDateRangeButton
            className={styles.customDateRangeButton}
            disabled={disabled}
            onClick={() => setShowDatePicker((val) => !val)}
          >
            <div className={styles.optionText}>
              <Content id={ContentId.CustomDateRange} />
            </div>{' '}
            <CalendarIcon size={vars.iconSize.m} withHoverEffect />
          </CustomDateRangeButton>
          {showDatePicker && (
            <>
              <div className={styles.divider} />
              <DateErrorContainer>
                {dateErrors.map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </DateErrorContainer>
              <ReactDateRange
                onPreviewChange={onPreviewChange}
                editableDateInputs={true}
                className={clsx(className, styles.dateRangePicker)}
                dateDisplayFormat={dateDisplayFormat}
                retainEndDateOnFirstSelection={retainEndDateOnFirstSelection}
                ranges={[
                  {
                    startDate: useSiteTimeZone
                      ? toZonedTime(
                          value?.start ? new Date(value.start) : new Date()
                        )
                      : value?.start
                      ? new Date(value.start)
                      : new Date(),
                    endDate: useSiteTimeZone
                      ? toZonedTime(
                          value?.end ? new Date(value.end) : new Date()
                        )
                      : value?.end
                      ? new Date(value.end)
                      : new Date(),
                    key: 'range',
                  },
                ]}
                startDatePlaceholder={startDatePlaceholder ?? defaultFromlabel}
                endDatePlaceholder={endDatePlaceholder ?? defaultToLabel}
                onChange={(range) => {
                  const startDate =
                    range.range.startDate && useSiteTimeZone
                      ? fromZonedTime(range.range.startDate)
                      : range.range.startDate;
                  const endDate = range.range.endDate
                    ? useSiteTimeZone
                      ? fromZonedTime(endOfDay(range.range.endDate))
                      : endOfDay(range.range.endDate)
                    : undefined;
                  onDateRangePickerChange(startDate, endDate);
                }}
                rangeColors={rangeColors}
                locale={locale}
              />
            </>
          )}
        </DateRangePickerContainer>
      </Popover.Content>
    </Popover.Root>
  );
};
