import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { SimpleTable } from 'src/core/ui';
import { useHasPaymentsSectionsV2Feature } from 'src/modals/SaleDetails/hooks/useHasPaymentsSectionsV2Feature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SALE_LINE_ITEM_CHARGE_REASON_TO_CID,
  SALE_LINE_ITEM_CREDIT_REASON_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { formatCurrency } from 'src/utils/numberFormatter';
import { MarketplacePaymentLineType, SaleCostType } from 'src/WebApiController';

import { LinkMarketplacePaymentToSaleInput } from './LinkMarketplacePaymentToSaleDialog';

export function SaleChargesTable() {
  const { watch } = useFormContext<LinkMarketplacePaymentToSaleInput>();
  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();

  const saleLineItems = watch('saleLineItems') ?? [];
  const marketplacePaymentLine = watch('marketplacePaymentLine');
  const marketplacePaymentLineId = marketplacePaymentLine.id;
  const isCredit =
    marketplacePaymentLine.type === MarketplacePaymentLineType.Credit;

  const saleLineItemIndexesToDisplay = saleLineItems
    .map((saleLineItem, index) => {
      let shouldDisplay = true;

      if (isCredit && saleLineItem.costType !== SaleCostType.Credit) {
        shouldDisplay = false;
      }

      if (!isCredit && saleLineItem.costType !== SaleCostType.Charge) {
        shouldDisplay = false;
      }

      // Only charge/credit that is unlinked or current payment id
      if (
        saleLineItem.marketplacePaymentLineId != null &&
        saleLineItem.marketplacePaymentLineId !== marketplacePaymentLineId
      ) {
        shouldDisplay = false;
      }

      return { index, shouldDisplay };
    })
    .filter(({ shouldDisplay }) => shouldDisplay)
    .map(({ index }) => index);

  return (
    <SimpleTable.Table>
      <SimpleTable.Thead>
        <SimpleTable.Tr>
          <SimpleTable.Th colSpan={1}>
            <div>
              <Content id={ContentId.Name} />
            </div>
          </SimpleTable.Th>
          <SimpleTable.Th colSpan={1}>
            <div>
              <Content id={ContentId.Amount} />
            </div>
          </SimpleTable.Th>
          {hasPaymentsSectionsV2Feature && (
            <SimpleTable.Th colSpan={1}>
              <div>
                <Content id={ContentId.Reason} />
              </div>
            </SimpleTable.Th>
          )}
          <SimpleTable.Th colSpan={1}>
            <div>
              <Content id={ContentId.Link} />
            </div>
          </SimpleTable.Th>
        </SimpleTable.Tr>
      </SimpleTable.Thead>
      <SimpleTable.Tbody>
        {saleLineItemIndexesToDisplay.map((index, i) => (
          <SaleLineItemRow key={`sale-line-item-row-${i}`} index={index} />
        ))}
      </SimpleTable.Tbody>
    </SimpleTable.Table>
  );
}

function SaleLineItemRow({ index }: { index: number }) {
  const { watch, setValue } =
    useFormContext<LinkMarketplacePaymentToSaleInput>();
  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();
  const { getUiCurrency } = useLocalizationContext();

  const saleLineItems = watch('saleLineItems');
  const saleLineItem = saleLineItems![index];
  const marketplacePaymentLineId = watch('marketplacePaymentLine').id;

  const isSelected =
    saleLineItem.marketplacePaymentLineId === marketplacePaymentLineId;

  const reasonContentId =
    saleLineItem.costType === SaleCostType.Credit
      ? saleLineItem.saleLineItemCreditReason
        ? SALE_LINE_ITEM_CREDIT_REASON_TO_CID[
            saleLineItem.saleLineItemCreditReason
          ]
        : undefined
      : saleLineItem.saleLineItemChargeReason
      ? SALE_LINE_ITEM_CHARGE_REASON_TO_CID[
          saleLineItem.saleLineItemChargeReason
        ]
      : undefined;
  const reasonMsg = useContent(reasonContentId);

  const onSelect = useCallback(() => {
    if (isSelected) {
      setValue(
        'saleLineItems',
        saleLineItems?.map((item, i) => {
          return i === index
            ? { ...item, marketplacePaymentLineId: null }
            : item;
        }) ?? null
      );
    } else {
      setValue(
        'saleLineItems',
        saleLineItems?.map((item, i) => {
          return i === index ? { ...item, marketplacePaymentLineId } : item;
        }) ?? null
      );
    }
  }, [index, isSelected, marketplacePaymentLineId, saleLineItems, setValue]);

  const uiCurrency = useMemo(
    () => getUiCurrency(saleLineItem.currencyCode),
    [getUiCurrency, saleLineItem.currencyCode]
  );

  return (
    <SimpleTable.Tr>
      <SimpleTable.Td colSpan={1}>{saleLineItem.description}</SimpleTable.Td>
      <SimpleTable.Td colSpan={1}>
        {formatCurrency(saleLineItem.cost, uiCurrency.code, uiCurrency.dec)}
      </SimpleTable.Td>
      {hasPaymentsSectionsV2Feature && (
        <SimpleTable.Td colSpan={1}>{reasonMsg}</SimpleTable.Td>
      )}
      <SimpleTable.Td colSpan={1}>
        <Checkbox checked={isSelected} onChange={onSelect} />
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
}
