import { MouseEvent, useContext } from 'react';
import { useToggle } from 'react-use';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { LaunchBulkUploadPurchaseDialog } from 'src/dialogs/BulkEdits/BulkUploadPurchaseDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MessagesAddSourceModal } from 'src/modals/MessagesAddSourceModal/MessagesAddSourceModal';
import { PurchaseWizardModalConfig } from 'src/modals/PurchaseWizard/PurchaseWizardModalConfig';
import { LayoutIcon } from 'src/svgs';
import { IconsFill, SettingsIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PURCHASE_SORT_TO_CID,
  TIME_PERIOD_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  EventUiSort,
  getEventSort,
  getEventUiSort,
} from 'src/utils/eventQueryUtils';
import {
  EventSort,
  Feature,
  Permission,
  PurchaseOrderQuery,
  PurchaseViewMode,
  TimePeriodType,
} from 'src/WebApiController';

import * as styles from './PurchaseToolbar.css';
import {
  FilterBarLeftSide,
  FilterBarRightSide,
  PurchaseToolbarDiv,
} from './PurchaseToolbar.styled';

export type PurchaseToolbarType = {
  disabled?: boolean;
  viewMode?: PurchaseViewMode | null;
  onColumnSettingButtonClickHandler?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
};

export function PurchaseToolbar({
  viewMode,
  onColumnSettingButtonClickHandler,
  disabled,
}: PurchaseToolbarType) {
  const { setActivePosEntity } = useActivePosEntityContext();
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useToggle(false);
  const { filterQuery, setFilterQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();
  const { setModal } = useContext(ModalContext);

  const hasGroupPurchaseOrdersByTimePeriodFeature = useUserHasFeature(
    Feature.GroupPurchaseOrdersByTimePeriod
  );
  const { purchaseGroupTimePeriod, setPurchaseGroupTimePeriod } =
    usePurchaseDataContext();

  const onAddPurchase = () => {
    setActivePosEntity(0);
    setModal(PurchaseWizardModalConfig);
  };

  const onCloseAddSourceModal = () => {
    setIsAddSourceModalOpen(false);
  };

  const hasCreatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Create
  );

  return (
    <PurchaseToolbarDiv className={styles.purchaseFilterBar}>
      <FilterBarLeftSide>
        {hasCreatePermission && (
          <>
            <Button
              className="add-purchase"
              variant="regular"
              onClick={onAddPurchase}
            >
              <Content id={ContentId.AddPurchase} />
            </Button>

            <LaunchBulkUploadPurchaseDialog
              className="add-purchase"
              variant="outline"
            />

            <Button variant="outline" onClick={setIsAddSourceModalOpen}>
              <SettingsIcon
                withHoverEffect
                size={vars.iconSize.s}
                fill={IconsFill.currentColor}
              />
              <Content id={ContentId.AutoSetup} />
            </Button>

            <MessagesAddSourceModal
              isOpen={isAddSourceModalOpen}
              onClose={onCloseAddSourceModal}
            />
          </>
        )}
      </FilterBarLeftSide>
      <FilterBarRightSide>
        {viewMode === PurchaseViewMode.FlattenedView && (
          <Button
            variant="textPlain"
            onClick={onColumnSettingButtonClickHandler}
          >
            <LayoutIcon size={vars.iconSize.m} />
            <Content id={ContentId.Columns} />
          </Button>
        )}
        {hasGroupPurchaseOrdersByTimePeriodFeature && (
          <PosEnumSelect
            disabled={disabled}
            variant="outline"
            shape="pill"
            value={purchaseGroupTimePeriod}
            defaultValue={TimePeriodType.Weekly}
            onChange={(tp) => {
              if (!tp) return;
              setPurchaseGroupTimePeriod(tp);
            }}
            valueOptionsContent={TIME_PERIOD_TO_CID}
            style={{ marginRight: '12px' }}
          />
        )}
        {viewMode !== PurchaseViewMode.EventTileView && (
          <PosEnumSelect
            disabled={disabled}
            variant="textPlain"
            shape="none"
            value={
              filterQuery
                ? getEventUiSort(EventSort.Date, filterQuery?.isSortDescending)
                : EventUiSort.DateDesc
            }
            defaultValue={getEventUiSort(EventSort.Date, true)}
            onChange={(sort) => {
              const [, isSortDescending] = getEventSort(sort);
              if (isSortDescending !== filterQuery?.isSortDescending) {
                setFilterQuery({
                  ...(filterQuery ?? {}),
                  isSortDescending,
                });
              }
            }}
            valueOptionsContent={PURCHASE_SORT_TO_CID}
          />
        )}
      </FilterBarRightSide>
    </PurchaseToolbarDiv>
  );
}
