import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkWasteTicketsDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  onOkay: (
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export function BulkWasteTicketsDialog({
  updateKey,
  onOkay,
  onCancel,
  ...rest
}: BulkWasteTicketsDialogProps) {
  const entityType = ActionOutboxEntityType.Listing;

  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.WasteTickets,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature = useMemo(
    () => bulkEditHub?.state === HubConnectionState.Connected,
    [bulkEditHub?.state]
  );

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(false, onPreviewReceived);
      } else {
        onOkay(hasBackgroundBulkEditFeature, undefined, preview);
      }
      setIsLoading(false);
    },
    [hasBackgroundBulkEditFeature, onOkay, preview]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.WasteUnsoldTickets} />}
        />
      }
      onOpened={initJob}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submitButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" width="full">
          <Content id={ContentId.BulkWasteTicketsConfirmation} />
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
