import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GET_PAYMENT_LINES_WITH_SALES_QUERY_KEY } from 'src/contexts/MarketplacePaymentDataContext';
import { useConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog/useConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { LinkMarketplacePaymentToSaleDialog } from 'src/dialogs/LinkMarketplacePaymentToSaleDialog';
import { UnlinkMarketplacePaymentLineToSaleDialog } from 'src/dialogs/UnlinkMarketplacePaymentLineDialog/UnlinkMarketplacePaymentLineToSaleDialog';
import { MarketplacePaymentLineWithSaleAndEventInfo } from 'src/hooks/useGetMarketplacePaymentLineTableData';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CheckIcon, IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import {
  LinkMarketplacePaymentLineRefActions,
  LinkMarketplacePaymentLineToSale,
} from 'src/tables/MarketplacePaymentLinesTable/Cell/LinkMarketplacePaymentLineToSale';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  MarketplacePaymentClient,
  MarketplacePaymentLineType,
  SaleLineItem,
} from 'src/WebApiController';

export function MarketplacePaymentLinesActionsCell({
  marketplacePaymentLine,
}: {
  marketplacePaymentLine: MarketplacePaymentLineWithSaleAndEventInfo;
}) {
  const linkActionsRef = useRef<LinkMarketplacePaymentLineRefActions | null>(
    null
  );
  const unlinkActionsRef = useRef<LinkMarketplacePaymentLineRefActions | null>(
    null
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();
  const hasAddMarketplaceChargeCreditToSaleFeature = useUserHasFeature(
    Feature.AddMarketplaceChargeCreditToSale
  );

  const refreshData = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PAYMENT_LINES_WITH_SALES_QUERY_KEY],
    });
  }, [queryClient]);

  const actionsMsg = useContent(ContentId.Actions);
  const addedToSaleMsg = useContent(ContentId.AddedToSale);
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();

  const userHasLinkPaymentLineFeature = useUserHasFeature(
    Feature.LinkPaymentLine
  );
  const canLinkPaymentLine =
    userHasLinkPaymentLineFeature && !marketplacePaymentLine.saleId;

  const canUnlinkMarketplaceLine =
    userHasLinkPaymentLineFeature && !!marketplacePaymentLine.saleId;

  const canManuallyAddToSale =
    hasAddMarketplaceChargeCreditToSaleFeature &&
    marketplacePaymentLine.saleId != null &&
    (marketplacePaymentLine.type === MarketplacePaymentLineType.Credit ||
      marketplacePaymentLine.type === MarketplacePaymentLineType.Charge);

  const isAddedToSale = marketplacePaymentLine?.isAddedToSale ?? false;

  const {
    openConfirmDialog: openRemoveFromSaleDialog,
    closeConfirmDialog: closeRemoveFromSaleDialog,
  } = useConfirmDialog();

  const { openDialog: openAddToSaleDialog, closeDialog: closeAddToSaleDialog } =
    useDialog(
      DialogId.AddMarketplacePaymentLineToSale,
      LinkMarketplacePaymentToSaleDialog
    );

  const marketplacePaymentLineId = marketplacePaymentLine.id;

  const onRemoveFromSale = useCallback(() => {
    closeRemoveFromSaleDialog();

    setIsLoading(true);
    tryInvokeApi(
      async () => {
        await new MarketplacePaymentClient(
          activeAccountWebClientConfig
        ).unlinkMarketplacePaymentLineFromSaleLineItems(
          marketplacePaymentLineId!
        );

        refreshData();
      },
      (error) => {
        showErrorDialog(
          'MarketplacePaymentClient.unlinkMarketplacePaymentLineFromSaleLineItems',
          error,
          {
            trackErrorData: { marketplacePaymentLineId },
          }
        );
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    closeRemoveFromSaleDialog,
    marketplacePaymentLineId,
    refreshData,
    showErrorDialog,
  ]);

  const onSubmitAddToSale = useCallback(
    (marketplacePaymentLineId: number, saleLineItems: SaleLineItem[]) => {
      closeAddToSaleDialog();
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          await new MarketplacePaymentClient(
            activeAccountWebClientConfig
          ).linkMarketplacePaymentLineToSaleLineItems(
            marketplacePaymentLineId,
            saleLineItems
          );
          refreshData();
        },
        (error) => {
          showErrorDialog(
            'MarketplacePaymentClient.linkMarketplacePaymentLineToSaleLineItems',
            error,
            {
              trackErrorData: { marketplacePaymentLineId },
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      closeAddToSaleDialog,
      refreshData,
      showErrorDialog,
    ]
  );

  return (
    <Stack direction="row" gap="m" alignItems="center">
      {isAddedToSale && (
        <CheckIcon
          size={vars.iconSize.s}
          fill={IconsFill.textBrand}
          title={addedToSaleMsg}
        />
      )}
      <PosDropdown
        key={`mkp-pmt-line-action-dropdown-${marketplacePaymentLineId}`}
        trigger={
          <div
            title={actionsMsg}
            style={{
              padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}`,
            }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
              disabled={isLoading}
            />
          </div>
        }
        align="end"
      >
        <>
          {canManuallyAddToSale && (
            <>
              {isAddedToSale && (
                <PosDropdownItem
                  key="RemoveFromSale"
                  onClick={() =>
                    openRemoveFromSaleDialog({
                      size: 'm',
                      headerText: <Content id={ContentId.RemoveFromSale} />,
                      bodyText: <Content id={ContentId.AreYouSure} />,
                      onOkay: onRemoveFromSale,
                      onCancel: closeRemoveFromSaleDialog,
                      okText: ContentId.Yes,
                      cancelText: ContentId.No,
                    })
                  }
                >
                  <Stack alignItems="end" gap="m" width="full">
                    <Content id={ContentId.RemoveFromSale} />
                  </Stack>
                </PosDropdownItem>
              )}
              <PosDropdownItem
                key="AddToSale"
                onClick={() => {
                  openAddToSaleDialog({
                    marketplacePaymentLine,
                    onSubmit: onSubmitAddToSale,
                    onClosed: () => {
                      setIsLoading(false);
                    },
                    onCancel: closeAddToSaleDialog,
                  });
                }}
              >
                <Stack alignItems="end" gap="m" width="full">
                  <Content id={ContentId.AddToSale} />
                </Stack>
              </PosDropdownItem>
            </>
          )}
        </>
        {canLinkPaymentLine && (
          <PosDropdownItem
            key="link-payment-line"
            onClick={() => linkActionsRef.current?.showDialog()}
          >
            <Content id={ContentId.LinkSale} />
          </PosDropdownItem>
        )}
        {canUnlinkMarketplaceLine && (
          <PosDropdownItem
            key="unlink-payment-line"
            onClick={() => unlinkActionsRef.current?.showDialog()}
          >
            <Content id={ContentId.UnlinkSale} />
          </PosDropdownItem>
        )}
      </PosDropdown>
      {canLinkPaymentLine && (
        <LinkMarketplacePaymentLineToSale
          refActions={linkActionsRef}
          marketplacePaymentLineId={marketplacePaymentLine.id}
          onLinkSuccess={refreshData}
        />
      )}
      {canUnlinkMarketplaceLine && (
        <UnlinkMarketplacePaymentLineToSaleDialog
          refActions={unlinkActionsRef}
          marketplacePaymentLineId={marketplacePaymentLine.id}
          onUnlinkSuccess={refreshData}
        />
      )}
    </Stack>
  );
}
