import { useContext, useEffect } from 'react';
import { useToggle } from 'react-use';
import { BackSection } from 'src/components/common/BackSection';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { ViewModeSelector } from 'src/components/MainFilterBar';
import * as styles from 'src/components/MainFilterBar/MainFilterBar.css';
import {
  purchaseMandatoryFiltersToShow,
  usePurchaseFilters,
} from 'src/components/MainFilterBar/usePurchaseFilters';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { LaunchBulkUploadPurchaseDialog } from 'src/dialogs/BulkEdits/BulkUploadPurchaseDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { MessagesAddSourceModal } from 'src/modals/MessagesAddSourceModal/MessagesAddSourceModal';
import { PurchaseWizardModalConfig } from 'src/modals/PurchaseWizard/PurchaseWizardModalConfig';
import { IconsFill, SettingsIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PURCHASE_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  ActionOutboxEntityType,
  Feature,
  Permission,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
  UserSetting,
} from 'src/WebApiController';

import { PurchaseGlobalActionDropdown } from '../PurchaseActionDropdown';
import { useDefaultPurchaseFilters } from './hooks/useDefaultPurchaseFilters';
import { PURCHASE_VIEW_MODE_TO_ICON } from './PurchaseFilterBar.types';
import { PurchaseSearchBox } from './PurchaseSearchBox';

export function PurchaseFilterBar({
  isEventPage = false,
  returnUrl,
  posEventId,
}: {
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
}) {
  const { setModal } = useContext(ModalContext);
  const { selectionMode, setSelectionMode } = useMultiSelectionContext();
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useToggle(false);
  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const hasCreatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Create
  );

  const hasBulkEditInPurchaseEventFeature = useUserHasFeature(
    Feature.BulkEditInPurchaseEvent
  );

  const hasNewSearchFeature = useUserHasFeature(Feature.SearchView_Purchase);

  const {
    filterQuery: purchaseQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<PurchaseOrderQuery>();

  const onCloseAddSourceModal = () => {
    setIsAddSourceModalOpen(false);
  };

  useEffect(() => {
    setTempQuery(purchaseQuery);
  }, [purchaseQuery, setTempQuery]);

  const defaultQuickFilters = useDefaultPurchaseFilters({
    purchaseQuery,
    initialQuery,
  });

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<PurchaseViewMode>({
      id: UserSetting.PurchasePageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStatePurchases,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomPurchases,
    defaultQuickFilters,
    currentQuery: purchaseQuery,
    initialQuery,
    onSelect: ({ id, query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }
      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const isMobile = useMatchMedia('mobile');

  const onAddPurchase = () => {
    setActivePosEntity(0);
    setModal(PurchaseWizardModalConfig);
  };

  const purchaseFilters = usePurchaseFilters({});

  const { appliedFilters } = useFiltersHelpers({
    filters: purchaseFilters,
    activeQuery: purchaseQuery,
    mandatoryFiltersToShow: purchaseMandatoryFiltersToShow,
    initialQuery,
  });

  useEffect(() => {
    if (
      purchaseQuery.viewMode === PurchaseViewMode.EventTileView &&
      selectionMode &&
      !hasBulkEditInPurchaseEventFeature
    ) {
      setSelectionMode(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseQuery.viewMode]);

  return (
    <>
      <div className={styles.mainFilterBarDiv}>
        <div className={styles.dockLeftDiv}>
          <div className={styles.mainFilterBarLeftChild}>
            {isEventPage && (
              <BackSection
                returnUrl={returnUrl ?? '/purchases'}
                state={{
                  fromPosEventId: posEventId,
                }}
              />
            )}
            {purchaseQuery && (
              <FilterToolbar
                {...filterToolbarProps}
                filterAppliedCounts={appliedFilters.length}
                onSubmitFilter={() => {
                  setFilterQuery(tempQuery);
                }}
                mandatoryFiltersToShow={purchaseMandatoryFiltersToShow}
                filters={purchaseFilters}
                filterAppliedIds={appliedFilters}
                resetTempQuery={resetTempQuery}
                tempQuery={tempQuery}
              />
            )}
          </div>
        </div>
        <div className={styles.dockRightDiv}>
          <div className={styles.mainFilterBarRightChild}>
            {!isMobile && (
              <>
                {!isEventPage && (
                  <>
                    <BulkEditStatusPopover
                      entityType={ActionOutboxEntityType.Purchase}
                    />
                    <ViewModeSelector
                      query={purchaseQuery}
                      initialQuery={initialQuery}
                      setFilterQuery={setFilterQuery}
                      viewModeSettingsId={UserSetting.PurchasePageViewMode}
                      viewModeCidMap={PURCHASE_VIEW_MODE_TO_CID}
                      viewModeIconMap={PURCHASE_VIEW_MODE_TO_ICON}
                    />
                    {(purchaseQuery.viewMode !==
                      PurchaseViewMode.EventTileView ||
                      hasBulkEditInPurchaseEventFeature) && (
                      <PurchaseGlobalActionsPermissionsWrapper>
                        <MultiSelectionToggleGlobal />
                      </PurchaseGlobalActionsPermissionsWrapper>
                    )}
                  </>
                )}

                {(!hasNewSearchFeature || isEventPage) && (
                  <PurchaseSearchBox isEventPage={isEventPage} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={styles.mainFilterBarDiv}>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              <Stack alignItems="center" gap="m">
                {hasCreatePermission && (
                  <>
                    <Button
                      variant="regular"
                      onClick={onAddPurchase}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <Content
                        id={
                          isMobile ? ContentId.Purchase : ContentId.AddPurchase
                        }
                      />
                    </Button>

                    {!isMobile && (
                      <LaunchBulkUploadPurchaseDialog
                        className="add-purchase"
                        variant="outline"
                      />
                    )}

                    {!isMobile && (
                      <>
                        <Button
                          variant="outline"
                          onClick={setIsAddSourceModalOpen}
                        >
                          <SettingsIcon
                            withHoverEffect
                            size={vars.iconSize.s}
                            fill={IconsFill.currentColor}
                          />
                          <Content id={ContentId.AutoSetup} />
                        </Button>

                        <MessagesAddSourceModal
                          isOpen={isAddSourceModalOpen}
                          onClose={onCloseAddSourceModal}
                        />
                      </>
                    )}
                  </>
                )}
              </Stack>

              <Stack alignItems="center" gap="m">
                <BulkEditStatusPopover
                  entityType={ActionOutboxEntityType.Purchase}
                />
                <ViewModeSelector
                  query={purchaseQuery}
                  initialQuery={initialQuery}
                  setFilterQuery={setFilterQuery}
                  viewModeSettingsId={UserSetting.PurchasePageViewMode}
                  viewModeCidMap={PURCHASE_VIEW_MODE_TO_CID}
                  viewModeIconMap={PURCHASE_VIEW_MODE_TO_ICON}
                />
                <PurchaseGlobalActionsPermissionsWrapper showBulkUpload>
                  <MultiSelectionToggleGlobal />
                  <PurchaseGlobalActionDropdown showBulkUpload />
                </PurchaseGlobalActionsPermissionsWrapper>

                <PurchaseSearchBox />
              </Stack>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
