import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  DealSplitType,
  DealThresholdSplitDirection,
  PurchaseOrderDealConfig,
} from 'src/WebApiController';

import { DealConfigHurdle } from './DealConfigHurdle/DealConfigHurdle';
import { createDefaultDealCommission } from './DealConfigHurdle/DealConfigHurdleSplitCommissions';
import * as styles from './Deals.css';

export const createDefaultDealConfig = (
  userId?: string | null,
  buyerUserId?: string | null,
  priority?: number | null
): PurchaseOrderDealConfig => {
  return {
    dealConfigHurdleId: null,
    thresholdSplitDirection: DealThresholdSplitDirection.Above,
    thresholdValue: 0,
    vendorCommission: createDefaultDealCommission(userId || ''),
    purchaserCommissions: [createDefaultDealCommission(buyerUserId || '')],
    priority: priority || 1,
  };
};

export const DealConfigSection = ({
  dealConfigs,
}: {
  dealConfigs: PurchaseOrderDealConfig[] | null;
}) => {
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const { localDeal, updateLocalDealDetails } = useDealContext();
  const buyerUserId = watch('buyerUserId')?.value;

  const vendorAccount = watch('vendorAccount');
  const configSplitType = localDeal?.dealDetails?.configSplitType.value;

  if (!dealConfigs) {
    return null;
  }

  return (
    <>
      {dealConfigs.map((dealConfig: PurchaseOrderDealConfig, i: number) => {
        return (
          <>
            {i !== 0 && <div className={styles.Divider} />}
            <DealConfigHurdle key={i} config={dealConfig} index={i} />
          </>
        );
      })}
      {configSplitType !== DealSplitType.None && (
        <Button
          variant={'link'}
          onClick={() => {
            updateLocalDealDetails({
              dealConfigs: posChangedField([
                ...dealConfigs,
                createDefaultDealConfig(
                  vendorAccount?.value?.id || null,
                  buyerUserId,
                  dealConfigs.length + 1
                ),
              ]),
            });
          }}
          style={{ alignSelf: 'end' }}
        >
          <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
          <Content id={ContentId.AddHurdle} />
        </Button>
      )}
    </>
  );
};
