import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { AccountDisabledNotification } from 'src/components/common/AccountDisabledNotification';
import { EventListing } from 'src/components/Events/EventListing';
import { PerformerVenueList } from 'src/components/Events/PerformerVenueList';
import { ListingFilterBar } from 'src/components/Listings/ListingFilterBar';
import { ListingSearchBox } from 'src/components/Listings/ListingFilterBar/ListingSearchBox';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { ExplorerLayout } from 'src/core/layouts';
import { Button, Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { createInventorySearchPageUrl } from 'src/utils/advancedSearchUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  CatalogEntity,
  Event,
  EventGroupingType,
  Feature,
  InventoryViewMode,
  ListingQuery,
} from 'src/WebApiController';
import Swiper from 'swiper';

import { MainRoute } from '../MainRoute';

export function InventoryEventsExplorer({
  onPerformerVenueRendered,
  onEventsRendered,
}: {
  onPerformerVenueRendered?: (
    items: CatalogEntity[],
    itemType: EventGroupingType
  ) => void;
  onEventsRendered?: (items: Event[]) => void;
}) {
  const routeTitle = useContent(ContentId.Inventory);
  const { filterQuery } = useFilterQueryContext<ListingQuery>();

  const onItemsRendered = (items: EventAccordionItemProps[]) => {
    onEventsRendered?.(items.flatMap((i) => i?.event ?? []));
  };

  const swiperRef = useRef<Swiper>();
  const mobile = useMatchMedia('mobile');

  const hasNewSearchFeature = useUserHasFeature(Feature.SearchView_Inventory);

  const navigate = useNavigate();

  const onAdvancePurchaseSearch = useCallback(() => {
    const searchUrl = createInventorySearchPageUrl();

    setTimeout(() => {
      navigate(searchUrl, {
        state: {
          returnUrl: `${location.pathname}${location.search}`,
        },
      });
    }, 10);
  }, [navigate]);

  return (
    <CatalogMultiSelectionContextProvider type="listing">
      <MultiSelectionContextProvider>
        <DialogProvider>
          <LayoutContent
            mainRoute={MainRoute.Inventory}
            routeTitle={routeTitle}
            centerContent={
              hasNewSearchFeature && (
                <Stack gap="m" alignItems="center" justifyContent="center">
                  <ListingSearchBox />
                  <Button variant="text" onClick={onAdvancePurchaseSearch}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <Content id={ContentId.AdvancedSearch} />
                    </div>
                  </Button>
                </Stack>
              )
            }
          >
            <AccountDisabledNotification />
            <MainFilterBar swiperRef={swiperRef} />
            <ExplorerLayout
              swiperRef={swiperRef}
              showExpandButton
              leftPanelContent={
                <PerformerVenueList
                  onItemsRendered={onPerformerVenueRendered}
                  swiperRef={swiperRef}
                />
              }
              leftPanelFlyover={
                filterQuery.viewMode === InventoryViewMode.MetricView
              }
            >
              <Stack direction="column" width="full" height="full">
                {mobile && (
                  <ListingFilterBar swiperRef={swiperRef} isSearchOnly />
                )}
                <EventListing onItemsRendered={onItemsRendered} />
              </Stack>
            </ExplorerLayout>
          </LayoutContent>
        </DialogProvider>
      </MultiSelectionContextProvider>
    </CatalogMultiSelectionContextProvider>
  );
}
