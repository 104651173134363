import { ReloadButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  FilterToolbarGroup,
  useFilterFiltersByPermission,
} from 'src/components/Filters';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { Button, Popover } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { hasAnyOfPermissions } from 'src/utils/userPermissionUtils';

import * as styles from './FilterDialog.css';
import {
  FilterDialogBody,
  FilterDialogDisplaySection,
  FilterDialogDisplaySectionContent,
  FilterDialogDisplaySectionHeader,
  FilterDialogFooter,
  FilterDialogFooterButtons,
  FilterDialogFooterMessage,
  FilterDialogItemContainer,
  FilterDialogItemDiv,
  FilterSectionsContainer,
} from './FilterDialog.styled';

export type FilterDialogProps = {
  onResetAll: () => void;
  onSubmitFilter?: () => void;
  filters: FilterToolbarGroup[];
  hasFiltersApplied?: boolean;
  onSaveQuickFilter?: () => void;
  filterAppliedMessage?: React.ReactNode;
};

export const FilterDialog = ({
  filters,
  onResetAll,
  onSubmitFilter,
  onSaveQuickFilter,
  filterAppliedMessage,
  hasFiltersApplied,
}: FilterDialogProps) => {
  const { loginContext } = useAppContext();
  const disabled = !hasFiltersApplied;

  const filteredByPermissionsFilters = useFilterFiltersByPermission({
    filters,
  });

  return (
    <FilterSectionsContainer>
      <div className={styles.filterDialogHeader}>
        <Content id={ContentId.SelectYourFilters} />
      </div>
      <FilterDialogBody>
        {filteredByPermissionsFilters.map((g, i) => (
          <FilterDialogDisplaySection key={`fg-${i}`}>
            {g.titleContentId && (
              <FilterDialogDisplaySectionHeader
                className={styles.filterDialogDisplaySectionHeader}
              >
                <Content id={g.titleContentId} />
              </FilterDialogDisplaySectionHeader>
            )}
            <FilterDialogDisplaySectionContent type={g.type}>
              {g.items
                ?.filter(
                  (fi) =>
                    fi.requiredPermissions == null ||
                    hasAnyOfPermissions(
                      loginContext?.user,
                      fi.requiredPermissions
                    )
                )
                .map((c, i) => (
                  <FilterDialogItemContainer
                    key={`fi-${i}`}
                    className={styles.filterDialogItemContainer}
                  >
                    <Content id={c.labelContentId} />
                    <FilterDialogItemDiv key={i}>
                      {c.filterItem}
                    </FilterDialogItemDiv>
                  </FilterDialogItemContainer>
                ))}
            </FilterDialogDisplaySectionContent>
          </FilterDialogDisplaySection>
        ))}
      </FilterDialogBody>
      <FilterDialogFooter>
        <FilterDialogFooterMessage>
          {filterAppliedMessage}
        </FilterDialogFooterMessage>
        <FilterDialogFooterButtons>
          {onSaveQuickFilter && (
            <Popover.Close asChild>
              <Button
                variant={'outline'}
                disabled={disabled}
                onClick={() => {
                  onSaveQuickFilter();
                }}
              >
                <Content id={ContentId.SaveThisFilter} />
              </Button>
            </Popover.Close>
          )}
          <ReloadButton
            textContentId={ContentId.ResetAll}
            variant={'outline'}
            disabled={disabled}
            onClick={onResetAll}
          />
          {onSubmitFilter && (
            <Popover.Close asChild>
              <OkButton
                textContentId={ContentId.ApplyFilter}
                onClick={onSubmitFilter}
                disabled={disabled}
              />
            </Popover.Close>
          )}
        </FilterDialogFooterButtons>
      </FilterDialogFooter>
    </FilterSectionsContainer>
  );
};
