import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import {
  SaleMarketplaceIdDisplay,
  SaleMarketplaceIdDisplayProps,
} from 'src/components/Sales/SaleMarketplaceIdDisplay';
import { TagValueDisplay } from 'src/components/TagsFormBody/TagValueCell';
import {
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  NO_GROUP_ID,
  TableRow,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { vars } from 'src/core/themes';
import { Button, Stack, TooltipPopover } from 'src/core/ui';
import { CopyToClipboardV2 } from 'src/core/ui/CopyToClipboard/CopyToClipboardV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { getUserDisplayName, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  FormatOption,
  FormatOption_Seating,
  FormatOptionEntries,
} from 'src/modals/EditTableColumns';
import { CustomColumnName } from 'src/modals/InventoryTableColumns/CustomColumnName';
import { IconsFill, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { SEATING_SORTING_FN_NAME } from 'src/tables/common/hooks/useSeatingColumnSorting';
import { CustomNumericCell } from 'src/tables/ListingTable/configs/CustomNumericCell';
import { ProceedsCell } from 'src/tables/SalesTable/configs/ProceedsCell';
import { SeatInfoContainer, TableShimmeringDiv } from 'src/tables/Table';
import { DateCell, InHandCell } from 'src/tables/Table/TableCells';
import { evaluateFormula } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
  SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  TICKET_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import {
  getSaleStatusDisplayContentId,
  getSaleStatusIcon,
} from 'src/utils/saleUtils';
import { safeSortDateTime, sortGeneric, sortMoney } from 'src/utils/tableUtils';
import { getTicketTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  SECTION_TYPE_TO_PRECISION_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';
import {
  Feature,
  ListingNote,
  MarketplaceFulfillmentState,
  MarketplacePaymentState,
  PointOfSalePaymentState,
  PointOfSaleSaleStatus,
  Seating,
  TagsValueType,
  TicketType,
  UiMoney,
  UserSetting,
} from 'src/WebApiController';

import { SalesTableColumnId } from '../../../utils/columns/sales/salesColumnUtils.types';
import {
  SaleWithEvent,
  SHIMMERING_DIV_HEIGHT_SINGLE_SALE,
} from '../SalesTable.type';
import { SalesActionsCell } from './SalesActionsCell';

const COST_COLUMN_MIN_SIZE = 75;
const COST_COLUMN_MAX_SIZE = 125;

const SECTION_TYPE = SectionType.Sales;

export const tagColumnDef = (
  tagName: string
): ColumnDef<SaleWithEvent | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.sale?.tags?.find((t) => t.key === tagName);
    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      return tag ? parseFloat(tag.value) : null;
    }
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const tag = original?.sale?.tags?.find((t) => t.key === tagName);

    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      const userDefinedPrecision = storedColumnNumberPrecisions[tagName];

      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.sale?.currency}
        />
      );
    }

    if (!original || !original.sale) {
      return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />;
    }

    if (!tag) {
      return null;
    }

    return (
      <TableCellDiv align="right">
        <TagValueDisplay tag={tag} displayMode />
      </TableCellDiv>
    );
  },
});

export const customColumnDef = (
  columnId: string,
  formula?: string | null
): ColumnDef<SaleWithEvent | null> => ({
  id: columnId,
  header: ({ table }) => (
    <TableHeaderCellDiv align="right">
      <CustomColumnName
        id={columnId}
        sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
      />
    </TableHeaderCellDiv>
  ),
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    return formula && data ? evaluateFormula(formula, data.sale) ?? null : null;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const { value: customColumns = [] } = useServerUserSetting<
      CustomSalesColumn[]
    >({
      id: UserSetting.SaleCustomColumns,
    });

    const userDefinedPrecision = storedColumnNumberPrecisions[columnId];

    return (
      <CustomNumericCell
        columnId={columnId}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={Boolean(!original || !original.sale)}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
        sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        currencyCode={original?.sale?.currency}
      />
    );
  },
});

export const SALE_TABLE_COLUMNS_CONFIG: Record<
  SalesTableColumnId,
  ColumnDef<SaleWithEvent | null>
> = {
  [SalesTableColumnId.Checkbox]: {
    id: SalesTableColumnId.Checkbox,
    header: '',
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_SM,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    enableSorting: false,
    enableResizing: false,
    cell: function Cell({
      row: { id, original, getIsSelected, depth, getCanSelectSubRows },
      table,
    }) {
      const { setShiftKeyRowSelectionState, isNoGroupIdSet } =
        useMultiSelectionContext();

      if (!original || !original.sale) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const isSelected = getIsSelected();
      const canSelect = getCanSelectSubRows() || depth === 0;
      const groupId = isNoGroupIdSet()
        ? NO_GROUP_ID
        : original.event.viagVirtualId;
      return canSelect ? (
        <TableCellDiv
          align="left"
          showTooltip={false}
          style={{ paddingLeft: vars.spacing.sm }}
        >
          <Checkbox
            checked={isSelected}
            onChange={() => {
              setShiftKeyRowSelectionState(
                id,
                table.getRowModel().rows as unknown as TableRow[],
                groupId
              );
            }}
          />
        </TableCellDiv>
      ) : null;
    },
  },
  [SalesTableColumnId.Seating]: {
    id: SalesTableColumnId.Seating,
    header: function Header({
      table: {
        options: { meta },
      },
      column,
    }) {
      const seatingText = useContent(
        SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[SalesTableColumnId.Seating]
      );
      const sectionText = useContent(ContentId.Section);
      const { userDefinedPrecision } = useColumnUserSetting(
        column.id,
        meta?.sectionType || SectionType.Sales
      );

      const isSectionOnly =
        userDefinedPrecision === FormatOption_Seating.Seating_SectionOnly;
      return (
        <TableHeaderCellDiv align="left">
          <span>
            {isSectionOnly ? `${seatingText} (${sectionText})` : seatingText}
          </span>
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) =>
      data
        ? {
            seating: data.sale.seating,
            listingNotes: data.sale.seatTraits,
            isSeatSaver: data.sale.isSeatSaver,
            status: data.sale.status,
          }
        : null,
    cell: function Cell({
      getValue,
      table: {
        options: { meta },
      },
      column,
    }) {
      const hasWasteSaleFeature = useUserHasFeature(Feature.WasteSale);
      const contentContext = useContentContext();
      const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
        Feature.ListingNotesAdditionalInfo
      );
      const { userDefinedPrecision } = useColumnUserSetting(
        column.id,
        meta?.sectionType || SectionType.Sales
      );

      const value = getValue<{
        seating: Seating;
        listingNotes: ListingNote[];
        isSeatSaver: boolean;
        status: PointOfSaleSaleStatus;
      } | null>();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const { seating, listingNotes, isSeatSaver, status } = value;
      const adjustedSeating =
        userDefinedPrecision === FormatOption_Seating.Seating_SectionOnly
          ? {
              ...seating,
              row: null,
              seatFr: null,
              seatTo: null,
            }
          : seating;
      return (
        <TableCellDiv align="left">
          <SeatInfoContainer style={{ maxWidth: '100%', width: '100%' }}>
            <SeatingInfo
              {...adjustedSeating}
              isSeatSaver={isSeatSaver}
              justifyIconContent="spaceBetween"
              isWasted={
                hasWasteSaleFeature && status === PointOfSaleSaleStatus.Wasted
              }
            />
            {listingNotes?.length > 0 && (
              <HorizontalTextList
                className={'listing-notes'}
                preIcon={getListingNoteIcon(
                  listingNotes[0],
                  contentContext,
                  hasListingNotesAdditionalInfoFeature
                )}
                texts={listingNotes.map((n) => n.listingNoteText)}
              />
            )}
          </SeatInfoContainer>
        </TableCellDiv>
      );
    },
    sortingFn: SEATING_SORTING_FN_NAME,
  },
  [SalesTableColumnId.QuantitySold]: {
    id: SalesTableColumnId.QuantitySold,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => (data ? data.sale.qtySold || 0 : null),
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<number>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.OrderDate]: {
    id: SalesTableColumnId.OrderDate,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.sale?.saleDate,
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<string>();
      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
        useColumnUserSetting(
          columnId,
          table.options.meta?.sectionType ?? SECTION_TYPE
        );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="left">
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="left"
            enableUtcFallback
            hideRelativeTerms
          />
        </TableCellDiv>
      );
    },
    sortingFn: safeSortDateTime<SaleWithEvent>,
  },
  [SalesTableColumnId.InHandDate]: {
    id: SalesTableColumnId.InHandDate,
    header: () => <TableHeaderCellDiv align="left">IH</TableHeaderCellDiv>,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.sale.inHandAt,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue<string | null>();
      if (!original?.sale) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }
      return (
        <TableCellDiv align="left">
          <InHandCell
            date={value ? new Date(value) : null}
            needsToFulfill={Boolean(
              original?.sale.status &&
                ![
                  PointOfSaleSaleStatus.ProcessingFulfillment,
                  PointOfSaleSaleStatus.Fulfilled,
                  PointOfSaleSaleStatus.CancelledHold,
                  PointOfSaleSaleStatus.Rejected,
                  PointOfSaleSaleStatus.PendingRejection,
                ].includes(original?.sale.status)
            )}
            handleFulfillmentState
          />
        </TableCellDiv>
      );
    },
    sortingFn: safeSortDateTime<SaleWithEvent>,
  },
  [SalesTableColumnId.Status]: {
    id: SalesTableColumnId.Status,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => (data ? data.sale.status : null),
    cell: function Cell({ getValue, column: { id: columnId }, row, table }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      const sale = row.original?.sale;
      const value = getValue() as PointOfSaleSaleStatus;

      const statusContent = useContent(
        getSaleStatusDisplayContentId(value, sale?.mkpContrState, sale?.mkp)
      );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const SaleStatusIcon = getSaleStatusIcon(
        value,
        sale?.mkpContrState,
        sale?.mkp
      );

      return (
        <TableCellDiv align="left">
          <Stack direction="row" gap="m" alignItems="center">
            {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
              <SaleStatusIcon fill={'transparent'} title={statusContent} />
            ) : (
              <div>{statusContent}</div>
            )}

            {
              // if the Sale is fulfilled in both POS and Marketplace, but the version is mismatched, show the warning
              value === PointOfSaleSaleStatus.Fulfilled &&
              sale?.isFulfillVerMis ? (
                <Stack>
                  <TooltipPopover
                    variant="link"
                    triggerContent={
                      <WarningOutlineIcon
                        fill={IconsFill.textWarning}
                        withHoverEffect
                      />
                    }
                  >
                    <WarningMessage
                      message={
                        <Content id={ContentId.VersionMismatchExplanation} />
                      }
                    />
                  </TooltipPopover>
                </Stack>
              ) : value !== PointOfSaleSaleStatus.Fulfilled &&
                sale?.mkpFulfState === MarketplaceFulfillmentState.Fulfilled ? (
                <Stack>
                  <TooltipPopover
                    variant="link"
                    triggerContent={
                      <WarningOutlineIcon
                        fill={IconsFill.textWarning}
                        withHoverEffect
                      />
                    }
                  >
                    <WarningMessage
                      message={
                        <Content id={ContentId.SaleIsFulfilledOnMarketplace} />
                      }
                    />
                  </TooltipPopover>
                </Stack>
              ) : undefined
            }
          </Stack>
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.TicketType]: {
    id: SalesTableColumnId.TicketType,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => (data ? data.sale.ticketType : null),
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );
      const value = getValue() as TicketType;
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }
      const ticketTypeIcon = getTicketTypeIcon(value, IconsFill.currentColor);
      return (
        <TableCellDiv align="left" title={value}>
          {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
            <span style={{ color: vars.color.textStrong }}>
              {ticketTypeIcon}
            </span>
          ) : (
            <Content id={TICKET_TYPE_TO_CID[value]} />
          )}
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.Proceeds]: {
    id: SalesTableColumnId.Proceeds,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return !data
        ? null
        : {
            proceeds: data.sale.ttlNetProcs,
            margin: undefined, // TODO - provide this when the data is actually available
          };
    },
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<{
        proceeds: UiMoney | null;
        margin?: number;
      } | null>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const displayValue =
        applyNumberFormatting({
          precision: userDefinedPrecision,
          inputNumber: value.proceeds?.amt,
          currencyCode: value.proceeds?.currency,
          currencyDecimalDigits: value.proceeds?.dec,
        }) ?? undefined;

      return (
        <TableCellDiv align="right">
          <ProceedsCell
            displayValue={displayValue as string | undefined}
            margin={value?.margin}
          />
        </TableCellDiv>
      );
    },
    sortingFn: (rowA, rowB, colId) => {
      type ValueType = {
        proceeds: UiMoney | null;
        margin?: number;
      };
      const a = rowA.getValue<ValueType>(colId);
      const b = rowB.getValue<ValueType>(colId);
      if (!a?.proceeds) return b ? -1 : 1;
      if (!b?.proceeds) return 1;

      return a.proceeds.amt < b.proceeds.amt ? -1 : 1;
    },
  },
  [SalesTableColumnId.SoldTicketCost]: {
    id: SalesTableColumnId.SoldTicketCost,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.soldTktCost?.amt;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
      table,
    }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      return (
        <CustomNumericCell
          columnId={columnId}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          currencyCode={original?.sale?.currency}
        />
      );
    },
  },
  [SalesTableColumnId.PandL]: {
    id: SalesTableColumnId.PandL,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.pandL?.amt;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
      table,
    }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      return (
        <CustomNumericCell
          columnId={columnId}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          currencyCode={original?.sale?.currency}
        />
      );
    },
  },
  [SalesTableColumnId.Margin]: {
    id: SalesTableColumnId.Margin,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.margin;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
      table,
    }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      return (
        <CustomNumericCell
          columnId={columnId}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
        />
      );
    },
  },
  [SalesTableColumnId.Charges]: {
    id: SalesTableColumnId.Charges,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.charges?.amt;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
      table,
    }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      return (
        <CustomNumericCell
          columnId={columnId}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          currencyCode={original?.sale?.currency}
        />
      );
    },
  },
  [SalesTableColumnId.Credits]: {
    id: SalesTableColumnId.Credits,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.credits?.amt;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
      table,
    }) {
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SECTION_TYPE
      );

      return (
        <CustomNumericCell
          columnId={columnId}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.sale)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_SALE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          currencyCode={original?.sale?.currency}
        />
      );
    },
  },
  [SalesTableColumnId.Sale]: {
    id: SalesTableColumnId.Sale,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) =>
      !data
        ? null
        : {
            marketplaceSaleId: data.sale.idOnMkp,
            saleId: data.sale.id,
            isExternal: Boolean(data.sale.idOnMkp),
            marketplace: data.sale.mkp,
          },
    cell: ({ getValue }) => {
      const value = getValue();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const props = value as SaleMarketplaceIdDisplayProps;
      return (
        <TableCellDiv align="right" style={{ height: '100%' }}>
          <Stack
            gap="s"
            alignItems="center"
            justifyContent="end"
            width="full"
            height="full"
            style={{ wordBreak: 'break-all' }}
          >
            <CopyToClipboardV2
              textToCopy={props.marketplaceSaleId || props.saleId}
            >
              <SaleMarketplaceIdDisplay {...props} />
            </CopyToClipboardV2>
          </Stack>
        </TableCellDiv>
      );
    },
    // sort by market, then order
    sortingFn: (rowA, rowB, colId) => {
      const a = rowA.getValue(colId) as SaleMarketplaceIdDisplayProps;
      const b = rowB.getValue(colId) as SaleMarketplaceIdDisplayProps;
      if (!a) return b ? -1 : 1;
      if (!b) return 1;

      if (a.marketplace !== b.marketplace) {
        return a.marketplace < b.marketplace ? -1 : 1;
      }

      // Same marketplace, sort by sale id
      return (a.marketplaceSaleId || '') < (b.marketplaceSaleId || '') ? -1 : 1;
    },
  },
  [SalesTableColumnId.Actions]: {
    id: SalesTableColumnId.Actions,
    enableSorting: false,
    enableResizing: true,
    header: function Cell() {
      const hasTablePinActionColumnFeature = useUserHasFeature(
        Feature.TablePinActionColumn
      );

      if (hasTablePinActionColumnFeature) {
        return null;
      }

      return (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_DEFAULT_SIZE_LG,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ row: { original }, table }) => {
      const linkOnly =
        table.options.meta?.formatOptionOverrides?.[
          SalesTableColumnId.Actions
        ] === FormatOption.Actions_LinkOnly;

      return !original ? (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
      ) : (
        <SalesActionsCell
          linkOnly={linkOnly}
          saleId={original.sale.id}
          marketplaceSaleId={original.sale.idOnMkp}
          marketplace={original.sale.mkp}
          saleStatus={original.sale.status}
          actions={original.sale.actions}
          ticketType={original.sale.ticketType}
          isNoFulfill={original.sale.isNoFulfill}
        />
      );
    },
  },
  [SalesTableColumnId.FulfillmentDate]: {
    id: SalesTableColumnId.FulfillmentDate,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.fulfillDate;
    },
    cell: function Cell({
      getValue,
      column: { id: columnId },
      row: { original },
      table,
    }) {
      const value = getValue<string>();
      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
        useColumnUserSetting(
          columnId,
          table.options.meta?.sectionType ?? SECTION_TYPE
        );

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <DateCell
          precision={userDefinedPrecision}
          date={value}
          align="left"
          enableUtcFallback
          hideRelativeTerms
        />
      );
    },
    sortingFn: safeSortDateTime<SaleWithEvent>,
  },
  [SalesTableColumnId.AutoFulfill]: {
    id: SalesTableColumnId.AutoFulfill,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.isNoFulfill;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<string>();

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="left">
          {/* AutoFulfill is negative of IsNoFulfill */}
          <Content id={value ? ContentId.No : ContentId.Yes} />
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.PaymentState]: {
    id: SalesTableColumnId.PaymentState,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return {
        pmtState: data?.sale?.pmtState,
        mkpPmtState: data?.sale?.mkpPmtState,
      };
    },
    cell: function Cell({ getValue }) {
      const value = getValue() as {
        mkpPmtState: MarketplacePaymentState;
        pmtState: PointOfSalePaymentState;
      };
      if (value.mkpPmtState == null && value.pmtState == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="left">
          {value.mkpPmtState ? (
            <Content
              id={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[value.mkpPmtState]}
            />
          ) : (
            <Content id={SALE_PAYMENT_STATE_TO_CID[value.pmtState]} />
          )}
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.PaymentReceived]: {
    id: SalesTableColumnId.PaymentReceived,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale ? data.sale.pmtRcv ?? null : undefined;
    },
    cell: function Cell({ getValue }) {
      const value = getValue();
      if (value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="left">
          <Content id={value ? ContentId.Yes : ContentId.No} />
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.PaymentReferenceId]: {
    id: SalesTableColumnId.PaymentReferenceId,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale ? data.sale.pmtRefId ?? null : undefined;
    },
    cell: function Cell({ getValue }) {
      const value = getValue<string>();
      if (value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  },
  [SalesTableColumnId.PurchaseCreatedBy]: {
    id: SalesTableColumnId.PurchaseCreatedBy,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.owners?.poInsertBys;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const { allActiveUserInfos } = useSellerAccountContext();

      const sellerUserIds = getValue<string[] | null>();

      const displayNamesFormatted = useMemo(
        () =>
          sellerUserIds
            ? [...new Set(sellerUserIds)]
                .map(
                  (sellerUserId) =>
                    allActiveUserInfos?.[sellerUserId]?.displayName
                )
                .filter((name) => name != null)
                .join(', ')
            : null,
        [allActiveUserInfos, sellerUserIds]
      );

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>;
    },
  },
  [SalesTableColumnId.PurchasedBy]: {
    id: SalesTableColumnId.PurchasedBy,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.owners?.buyerIds;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const { allActiveUserInfos } = useSellerAccountContext();

      const sellerUserIds = getValue<string[] | null>();

      const displayNamesFormatted = useMemo(
        () =>
          sellerUserIds
            ? [...new Set(sellerUserIds)]
                .map(
                  (sellerUserId) =>
                    allActiveUserInfos?.[sellerUserId]?.displayName
                )
                .filter((name) => name != null)
                .join(', ')
            : null,
        [allActiveUserInfos, sellerUserIds]
      );

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>;
    },
  },
  [SalesTableColumnId.PurchaseDate]: {
    id: SalesTableColumnId.PurchaseDate,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.poDate;
    },
    cell: function Cell({ getValue, row: { original }, table }) {
      const value = getValue<string>();
      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
        useColumnUserSetting(
          SalesTableColumnId.PurchaseDate,
          table.options.meta?.sectionType ?? SECTION_TYPE
        );

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="left">
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            enableUtcFallback
            hideRelativeTerms
          />
        </TableCellDiv>
      );
    },
    sortingFn: safeSortDateTime<SaleWithEvent>,
  },
  [SalesTableColumnId.VendorOrderId]: {
    id: SalesTableColumnId.VendorOrderId,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.poVendOrdId;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const onViewClick = useCallback(async () => {
        if (!original || !original.sale?.poId) {
          return;
        }
      }, [original]);

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      if (!original?.sale?.poId) {
        return '';
      }

      const externalPoID = getValue<string>();

      return (
        <TableCellDiv
          align={'left'}
          style={{ textTransform: 'unset' }}
          title={externalPoID}
        >
          <Stack
            gap="s"
            alignItems="center"
            justifyContent="end"
            width="full"
            height="full"
            style={{ wordBreak: 'break-all' }}
          >
            <CopyToClipboardV2 textToCopy={externalPoID}>
              <Link to={getPurchaseOrderRelativeUrl(original?.sale?.poId)}>
                <Button
                  variant="link"
                  size="unset"
                  shape="inline"
                  style={{ fontSize: vars.typography.fontSize['sm'] }}
                  onClick={onViewClick}
                >
                  {getValue<string>()}
                </Button>
              </Link>
            </CopyToClipboardV2>
          </Stack>
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.VendorAccountEmail]: {
    id: SalesTableColumnId.VendorAccountEmail,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.poVendAccEmail;
    },
    cell: function Cell({ getValue, row: { original } }) {
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align={'left'} style={{ textTransform: 'unset' }}>
          <div>{getValue<string>()}</div>
        </TableCellDiv>
      );
    },
  },
  [SalesTableColumnId.OriginatedBy]: {
    id: SalesTableColumnId.OriginatedBy,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="left">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.origBy;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const deactivatedText = useContent(ContentId.Deactivated);
      const insertByUserId = getValue() as string;
      const getUserInfosQuery = useGetUserInfos([insertByUserId]);

      const displayUserName = useMemo(() => {
        const user = insertByUserId
          ? getUserInfosQuery.data?.[insertByUserId]
          : undefined;

        return user ? getUserDisplayName(user, deactivatedText) : '';
      }, [deactivatedText, getUserInfosQuery.data, insertByUserId]);

      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return <TableCellDiv align="left">{displayUserName}</TableCellDiv>;
    },
  },
  [SalesTableColumnId.Row]: {
    id: SalesTableColumnId.Row,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.seating?.row;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<string>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }
      if (!original.sale) return null;

      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
    sortingFn: sortGeneric,
  },
  [SalesTableColumnId.Seats]: {
    id: SalesTableColumnId.Seats,
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.seating?.seatFr ?? '';
    },
    cell: function Cell({ row: { original } }) {
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }
      if (!original.sale) return null;

      const { seatFr, seatTo } = original.sale.seating;

      return (
        <TableCellDiv align="right">
          <SeatingInfo
            section={''}
            row={null}
            seatFr={seatFr}
            seatTo={seatTo}
            showSeatPrefix={false}
            justifyIconContent="spaceBetween"
          />
        </TableCellDiv>
      );
    },
    sortingFn: sortGeneric,
  },
  [SalesTableColumnId.TotalPaymentsAmount]: {
    id: SalesTableColumnId.TotalPaymentsAmount,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.totalPmt;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const totalAmount = getValue<UiMoney | null>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return <TableCellDiv align="right">{totalAmount?.disp}</TableCellDiv>;
    },
    sortingFn: sortMoney,
  },
  [SalesTableColumnId.TotalConvertedPaymentsAmount]: {
    id: SalesTableColumnId.TotalConvertedPaymentsAmount,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.totalConvertedPmt;
    },
    cell: function Cell({ getValue, row: { original } }) {
      const totalConvertedAmount = getValue<UiMoney | null>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="right">{totalConvertedAmount?.disp}</TableCellDiv>
      );
    },
    sortingFn: sortMoney,
  },
  [SalesTableColumnId.WastedQuantity]: {
    id: SalesTableColumnId.WastedQuantity,
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    header: ({ column: { id } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId]}
        />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return data?.sale?.qtyWasted;
    },
    cell: function Cell({ getValue, row: { original } }) {
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const wastedQty = getValue<number | null>();

      return <TableCellDiv align="right">{wastedQty}</TableCellDiv>;
    },
  },
};
