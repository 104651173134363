import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventEntitySearchBox } from 'src/components/Accordions/EventEntitySearchBox';
import { ListingBasicInfo } from 'src/components/Listings/ListingBasicInfo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultListingQuery,
  getQueryFromUrl,
} from 'src/utils/eventQueryUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  InventoryViewMode,
  Listing,
  ListingClient,
  ListingDetailDataField,
  ListingDetails,
  ListingQuery,
} from 'src/WebApiController';

export function ListingSearchBox({
  isEventPage = false,
}: {
  isEventPage?: boolean;
}) {
  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  const [searchId, setSearchId] = useState<string>();

  const [searchResults, setSearchResults] = useState<Listing[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const {
    filterQuery: listingQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
  } = useFilterQueryContext<ListingQuery>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== listingQuery.searchText) {
        setFilterQuery({
          ...listingQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [listingQuery]
  );

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const location = useLocation();

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    // Since we only support id searching in flattened view for Listings, reset filters when view mode changes
    if (
      listingQuery.viewMode !== InventoryViewMode.FlattenedView &&
      !isEventPage &&
      listingQuery.marketplaceEntityIds
    ) {
      setFilterQuery({
        ...listingQuery,
        marketplaceEntityIds: null,
        searchText: null,
      });
      setSearchText('');
    }
  }, [
    listingQuery.marketplaceEntityIds,
    listingQuery.viewMode,
    isEventPage,
    listingQuery,
    setFilterQuery,
    hasFullPageEventViewEntitySelection,
  ]);

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<ListingQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setSearchText((prev) =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : prev
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFullPageEventViewEntitySelection, initialQuery, setFilterQuery]);

  const onLookupById = useCallback(
    async (id: string, isSearching: boolean) => {
      if (
        !id &&
        tempQuery.marketplaceEntityIds &&
        hasFullPageEventViewEntitySelection
      ) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }

      if (id && !isLoadingSearch) {
        if (listing?.idOnMkp === id || listing?.id === Number(id)) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            setModal(getListingDetailsModalConfigWithDeepLink(listing.id));
          }

          // Else, just don't do anything
          return;
        }

        if (hasFullPageEventViewEntitySelection) {
          const matchedIds = id
            .split(',')
            .filter((s) => s.trim())
            .map((s) => s.trim()); // split by commas and remove all empty strings

          if (matchedIds.length > 1) {
            const newQuery = {
              ...DefaultListingQuery,
              entityIds: null,
              marketplaceEntityIds: matchedIds,
              viewMode: InventoryViewMode.FlattenedView,
            };
            setFilterQuery(newQuery);
            return;
          } else if (tempQuery.marketplaceEntityIds) {
            const newQuery = {
              ...tempQuery,
              entityIds: null,
              marketplaceEntityIds: null,
            };
            setFilterQuery(newQuery);
          }
        }

        setSearchId(id);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the listing id or unique id
          // since 99% of the time they'll be entering a listing id, we try to lookup by that first
          const listings = isSearching
            ? await tryInvokeApi(
                () =>
                  new ListingClient(
                    activeAccountWebClientConfig
                  ).getListingByMarketplaceListingId(id),
                (error) => {
                  showErrorDialog(
                    'ListingClient.getListingByMarketplaceListingId',
                    error,
                    { trackErrorData: { listingId: id } }
                  );
                }
              )
            : null;

          if (!listings || listings.length === 0) {
            const idNum = Number(id);

            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any listings by market-place-listing-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true, [
              ListingDetailDataField.Basic,
            ]);
            setModal(getListingDetailsModalConfigWithDeepLink(idNum));

            return true;
          } else {
            // We have listings using the market place id
            // 99% of the time, the id should be unique, but if we support more than 1 marketplaces
            // the ids may collide and return more than one result, therefore we need to present to the user and ask them what they want
            if (listings.length === 1) {
              const listingInfo = listings[0];
              // happy path
              setActivePosEntity(listingInfo.id, listingInfo.idOnMkp, true, [
                ListingDetailDataField.Basic,
              ]);
              setModal(
                getListingDetailsModalConfigWithDeepLink(listingInfo.id, true)
              );

              return true;
            } else {
              setSearchResults(listings);
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      hasFullPageEventViewEntitySelection,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      listing?.id,
      listing?.idOnMkp,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  const onResultItemPicked = useCallback(
    (listing: Listing) => {
      setActivePosEntity(listing.id, listing.idOnMkp, true, [
        ListingDetailDataField.Basic,
      ]);
      setModal(getListingDetailsModalConfigWithDeepLink(listing.id, true));

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  useEffect(() => {
    if (
      listingQuery.marketplaceEntityIds &&
      hasFullPageEventViewEntitySelection
    ) {
      return;
    }
    setSearchText(listingQuery.searchText ?? '');
  }, [
    hasFullPageEventViewEntitySelection,
    listingQuery.marketplaceEntityIds,
    listingQuery.searchText,
  ]);

  useEffect(() => {
    if (activeId?.length) {
      onLookupById(activeId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  return (
    <>
      <EventEntitySearchBox
        placeholderContentId={
          isEventPage ? ContentId.LookupByListingId : undefined
        }
        maxWidth={350}
        disabled={isLoadingSearch}
        onIdLookup={(value) => onLookupById(value, true)}
        onEventSearch={(value) => debouncedOnSearchChange(value)}
        searchText={searchText}
        setSearchText={setSearchText}
        clearSearchEntity={clearSearchEntity}
        disableSearchMode={isEventPage}
      />

      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindListingId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(listing: Listing) => (
          <ListingBasicInfo listing={listing} />
        )}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
