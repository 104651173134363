import { MarketplacePaymentStatePill } from 'src/components/common/MarketplacePaymentStatePill/MarketplacePaymentStatePill';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { Detail } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_PAYMENT_TERM_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  MarketplacePaymentState,
  MarketplacePaymentTerm,
} from 'src/WebApiController';

interface MarketplacePaymentTermsProps {
  marketplacePaymentTerm: MarketplacePaymentTerm | null | undefined;
  marketplacePaymentState: MarketplacePaymentState | null | undefined;
}

export const MarketplacePaymentTerms = ({
  marketplacePaymentTerm,
  marketplacePaymentState,
}: MarketplacePaymentTermsProps) => {
  if (!marketplacePaymentTerm || !marketplacePaymentState) {
    return null;
  }

  return (
    <div>
      <Stack gap="m">
        <Detail
          label={<Content id={ContentId.MarketplaceTerms} />}
          detail={
            <Content
              id={MARKETPLACE_PAYMENT_TERM_TO_CID[marketplacePaymentTerm]}
            />
          }
        />
        <Detail
          label={<Content id={ContentId.PaymentState} />}
          detail={
            <MarketplacePaymentStatePill state={marketplacePaymentState} />
          }
        />
      </Stack>
    </div>
  );
};
