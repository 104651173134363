import { useMemo } from 'react';
import { useFlexSearchIndex } from 'src/hooks/useFlexSearchIndex';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature, SellerUserAccountInfo } from 'src/WebApiController';

export const useGetFilteredUsers = ({
  users,
  searchText,
}: {
  users: SellerUserAccountInfo[];
  searchText: string;
}) => {
  const hasUserSearchBar = useUserHasFeature(Feature.UserSearchBar);

  const { userOptionsContent, userIdsMap } = useMemo(() => {
    const userOptionsContent: Record<string, string> = {};
    const userIdsMap: Record<string, SellerUserAccountInfo> = {};
    users?.forEach((user: SellerUserAccountInfo) => {
      const userId = user.userId;
      userOptionsContent[userId] = user.email;
      userIdsMap[userId] = user;
    });

    return {
      userOptionsContent,
      userIdsMap,
    };
  }, [users]);

  const { flexSearchIndex } = useFlexSearchIndex(
    userOptionsContent,
    hasUserSearchBar && Object.keys(userOptionsContent).length > 0,
    true
  );

  const filterdUserIds = useMemo<string[]>(() => {
    if (!searchText || !flexSearchIndex || !users || !hasUserSearchBar) {
      return [];
    }

    return flexSearchIndex.search(searchText) as string[];
  }, [flexSearchIndex, hasUserSearchBar, searchText, users]);

  return useMemo(() => {
    if (!searchText || !hasUserSearchBar) {
      return users;
    }

    if (filterdUserIds.length === 0) {
      return [];
    }

    const filteredUsers: SellerUserAccountInfo[] = [];
    for (const vendorId of filterdUserIds) {
      filteredUsers.push(userIdsMap[vendorId]);
    }
    return filteredUsers;
  }, [filterdUserIds, hasUserSearchBar, searchText, userIdsMap, users]);
};
