import { MutableRefObject, useImperativeHandle } from 'react';
import { LinkMarketplacePaymentLineToSaleDialog } from 'src/dialogs/LinkMarketplacePaymentLineDialog/LinkMarketplacePaymentLineToSaleDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';

export interface LinkMarketplacePaymentLineRefActions {
  showDialog: () => void;
}

interface LinkMarketplacePaymentLineToSaleProps {
  refActions: MutableRefObject<LinkMarketplacePaymentLineRefActions | null>;
  marketplacePaymentLineId: number;
  onLinkSuccess: () => void;
}

export const LinkMarketplacePaymentLineToSale = ({
  refActions,
  marketplacePaymentLineId,
  onLinkSuccess,
}: LinkMarketplacePaymentLineToSaleProps) => {
  const { launchDialog, dialogProps, closeDialog } = useBasicDialog();

  useImperativeHandle(
    refActions,
    () => {
      return {
        showDialog: launchDialog,
      };
    },
    [launchDialog]
  );

  if (!dialogProps.isOpen) {
    return null;
  }

  return (
    <LinkMarketplacePaymentLineToSaleDialog
      {...dialogProps}
      closeDialog={closeDialog}
      marketplacePaymentLineId={marketplacePaymentLineId}
      onLinkSuccess={onLinkSuccess}
    />
  );
};
