import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Button, Stack, Switch } from 'src/core/ui';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ListingTab } from 'src/modals/ListingDetails/components/listingDetailsUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Listing,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { AutoPricePreview } from '../components/AutoPricePreview';
import { SimplifiedAutopricingGuidedPrice } from '../components/SimplifiedAutopricingGuidedPrice';
import { useAutoPricingSettings } from '../useAutoPricingSettings';
import { AdvancePricingSettings } from './AdvancePricingSection';
import { AutoPricingSettingsSection } from './AutoPricingSettingsSection';
import { AutoPricingStrategySection } from './AutoPricingStrategySection';
import { PricingProtection } from './PricingProtection';

export const AutoPricingSection = ({
  listing,
  disabled,
  onSwitchToTab,
}: {
  listing?: Listing | null;
  disabled?: boolean;
  onSwitchToTab?: (tabName: ListingTab) => void;
}) => {
  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const {
    canSetPrice,
    canAutoPrice,
    autoPricingEnabled,
    setAutoPricingDefaults,
    showAutoPricingSettings,
    skipAutoPricing,
    event,
  } = useAutoPricingSettings(listing);

  const [showDetailedSettings, setShowDetailedSettings] = useState(false);

  const turnOffSkipSetting = useCallback(
    () => setValue('skipAutoPricing', false),
    [setValue]
  );

  const { mapExists, hasVenueMapInfo } = useListingHasVenueMapInfo(listing);

  if (!canSetPrice || !canAutoPrice) {
    return null;
  }

  return (
    <>
      <DetailSection name={<Content id={ContentId.Autopricing} />}>
        <SectionContent numOfColumns={1}>
          {hasVenueMapInfo && canAutoPrice ? (
            <>
              <DetailGroup style={{ gridColumn: '1' }}>
                <Stack gap="l" justifyContent="spaceBetween">
                  <Stack gap="l" alignItems="start" flexWrap="wrap">
                    <PosFormField
                      label={<Content id={ContentId.EnableAutoPricing} />}
                      style={{ width: 'auto' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Switch
                          disabled={
                            disabled || listing?.isDeleted || !canAutoPrice
                          }
                          checked={Boolean(autoPricingEnabled)}
                          onChange={(e) => e.stopPropagation()}
                          onCheckedChange={(isChecked) => {
                            turnOffSkipSetting();
                            if (isChecked !== autoPricingEnabled) {
                              setValue('autoPricingEnabled', isChecked);

                              if (isChecked) {
                                // If enabled, set defaults where necessary
                                setAutoPricingDefaults();
                              }
                            }
                          }}
                        />
                      </div>
                    </PosFormField>
                    <AutoPricingStrategySection
                      listing={listing}
                      disabled={disabled}
                      isMarketListingsView
                    />
                  </Stack>
                  <PosFormField
                    style={{ textAlign: 'right', width: 'auto' }}
                    label={<Content id={ContentId.ShowDetailedSettings} />}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Switch
                        disabled={
                          disabled || listing?.isDeleted || !canAutoPrice
                        }
                        checked={Boolean(showDetailedSettings)}
                        onChange={(e) => e.stopPropagation()}
                        onCheckedChange={(isChecked) => {
                          setShowDetailedSettings(isChecked);
                        }}
                      />
                    </div>
                  </PosFormField>
                </Stack>
              </DetailGroup>

              {showDetailedSettings ? (
                <>
                  {event?.viagId && listing?.id && (
                    <DetailGroup style={{ gridColumn: '1' }}>
                      <AutoPricePreview
                        viagogoEventId={event.viagId}
                        listingId={listing.id}
                      />
                    </DetailGroup>
                  )}
                  <AutoPricingSettingsSection
                    listing={listing!}
                    disabled={disabled || Boolean(skipAutoPricing)}
                    listingHasParent={!!listing?.ltGrpParentId}
                  />
                </>
              ) : (
                <>
                  <SimplifiedAutopricingGuidedPrice
                    listing={listing}
                    listingHasParent={!!listing?.ltGrpParentId}
                  />
                  {event?.viagId && listing?.id && (
                    <DetailGroup style={{ gridColumn: '1' }}>
                      <AutoPricePreview
                        viagogoEventId={event.viagId}
                        listingId={listing.id}
                      />
                    </DetailGroup>
                  )}
                </>
              )}
            </>
          ) : (
            <DetailGroup style={{ gridColumn: '1' }}>
              <Stack direction="column" gap="l">
                {!canAutoPrice ? (
                  <Content id={ContentId.NoAutoPricing_MissingPermission} />
                ) : mapExists && !hasVenueMapInfo ? (
                  <>
                    <Content id={ContentId.NoAutoPricing_MissingRowId} />

                    <Button
                      variant="link"
                      onClick={() => onSwitchToTab?.(ListingTab.Broadcast)}
                      style={{ width: 'max-content', padding: '0' }}
                    >
                      <Content id={ContentId.UpdateSeatingInformation} />
                    </Button>
                  </>
                ) : (
                  <Content id={ContentId.NoAutoPricing_NoVenueConfig} />
                )}
              </Stack>
            </DetailGroup>
          )}
        </SectionContent>
      </DetailSection>
      {showDetailedSettings && (
        <PricingProtection
          listing={listing as ListingDetails}
          disabled={disabled}
        />
      )}
      {showDetailedSettings && hasVenueMapInfo && showAutoPricingSettings && (
        <AdvancePricingSettings disabled={disabled} />
      )}
    </>
  );
};
