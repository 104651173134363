import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { vars } from 'src/core/themes';
import { typography } from 'src/core/themes/shared.css';
import { Button, Stack } from 'src/core/ui';
import { InventoryMetricsSelectionInput } from 'src/dialogs/ReportBuilderDialog/MetricsSelectionInputModal/InventoryMetricsSelectionInputModalV2';
import { SalesMetricsSelectionInput } from 'src/dialogs/ReportBuilderDialog/MetricsSelectionInputModal/SalesMetricsSelectionInputModalV2';
import { ReportBuilderFilterInputV2 } from 'src/dialogs/ReportBuilderDialog/ReportBuilderFilterInputV2';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { LayoutIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  Permission,
  ReportV2ConfigOverride,
} from 'src/WebApiController';

import * as styles from './ReportsFilterToolbar.css';

export const ReportsFilterToolbarV2 = ({
  reportConfig,
}: {
  reportConfig: ReportConfigV2;
}) => {
  const methods = useForm<ReportConfigV2>({
    defaultValues: reportConfig,
  });
  const { handleSubmit } = methods;
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isColumnModalOpen, setColumnModalOpen] = useState(false);

  const {
    upsertReportConfig: upsertReportConfigInventory,
    upsertReportOverride: upsertReportOverrideInventory,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });

  const {
    upsertReportConfig: upsertReportConfigSale,
    upsertReportOverride: upsertReportOverrideSale,
  } = useReportConfigsV2<ReportTypesV2.SaleV2>({
    reportType: ReportTypesV2.SaleV2,
  });

  const hasUpdateReportFeature = useUserHasAnyOfPermissions(
    Permission.Reports_UpdateReports
  );

  const hasReportSeparateBuilderFiltersFeature = useUserHasFeature(
    Feature.ReportSeparateBuilderFilters
  );

  const hasUpdateOwnReportFeature =
    useUserHasAnyOfPermissions(Permission.Reports_UpdateReportsOwned) &&
    reportConfig.isOwner;

  const invokeUpsertReport = useCallback(
    async (reportConfigNew: ReportConfigV2) => {
      await tryInvokeApi(
        async () => {
          if (reportConfigNew!.globalReportTypeId) {
            if (!hasUpdateReportFeature) {
              // Don't update since the user has no config
              return;
            }

            if (reportConfigNew.reportType === ReportTypesV2.InventoryV2) {
              return await upsertReportOverrideInventory(
                {
                  request: {
                    aggregations: reportConfigNew.request.aggregations,
                    groupings: reportConfigNew.request.groupings,
                  },
                } as ReportV2ConfigOverride,
                reportConfig!
              );
            } else {
              // report.reportType === ReportTypesV2.SaleV2
              return await upsertReportOverrideSale(
                {
                  request: {
                    aggregations: reportConfigNew.request.aggregations,
                    groupings: reportConfigNew.request.groupings,
                  },
                } as ReportV2ConfigOverride,
                reportConfig!
              );
            }
          } else {
            if (!hasUpdateReportFeature && !hasUpdateOwnReportFeature) {
              // Don't update since the user has no config
              return;
            }

            if (reportConfigNew.reportType === ReportTypesV2.InventoryV2) {
              return await upsertReportConfigInventory(
                reportConfigNew.reportId,
                reportConfigNew,
                !reportConfigNew.isOwner
              );
            } else {
              // report.reportType === ReportTypesV2.Sale
              return await upsertReportConfigSale(
                reportConfigNew.reportId,
                reportConfigNew,
                !reportConfigNew.isOwner
              );
            }
          }
        },
        (error) => {
          showErrorDialog('ReportClient.mergeReport', error, {
            trackErrorData: reportConfigNew,
          });
        }
      );
    },
    [
      hasUpdateOwnReportFeature,
      hasUpdateReportFeature,
      reportConfig,
      showErrorDialog,
      upsertReportConfigInventory,
      upsertReportConfigSale,
      upsertReportOverrideInventory,
      upsertReportOverrideSale,
    ]
  );

  const onReportSaveSubmit = useCallback(
    (report: ReportConfigV2) => {
      invokeUpsertReport(report);
    },
    [invokeUpsertReport]
  );

  const onColumnSettingButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) setColumnModalOpen(true);
    },
    [isColumnModalOpen, setColumnModalOpen]
  );

  const onMetricsChange = useCallback(() => {
    handleSubmit(onReportSaveSubmit)();
  }, [handleSubmit, onReportSaveSubmit]);

  const filterChangeMessageContentId = useMemo(() => {
    // if (reportConfig.globalReportTypeId != null) {
    //   if (
    //     reportConfig.groupBy === ReportGroupBy.SaleIdTicketIdCommissionUser ||
    //     reportConfig.groupBy === ReportGroupBy.ArAgingMarketplace
    //   ) {
    //     return ContentId.GlobalReportFilterChangeMessage;
    //   }
    //   return ContentId.GlobalReportFilterChangeCopyMessage;
    // }
    return undefined;
  }, []);

  return (
    (hasUpdateReportFeature || hasUpdateOwnReportFeature) && (
      <FormProvider {...methods}>
        <div className={styles.toolbarContainer}>
          <Stack gap="l" justifyContent="spaceBetween" width="full">
            <ReportBuilderFilterInputV2
              reportType={reportConfig.reportType}
              showAllButton={false}
              filterAppliedMessage={
                filterChangeMessageContentId && (
                  <WarningMessage
                    message={<Content id={filterChangeMessageContentId} />}
                  />
                )
              }
              embeddedDisplayOnly={hasReportSeparateBuilderFiltersFeature}
            />

            <Button
              variant="textPlain"
              onClick={onColumnSettingButtonClickHandler}
            >
              <LayoutIcon size={vars.iconSize.m} />
              <Content id={ContentId.Columns} />
            </Button>
          </Stack>
        </div>
        {isColumnModalOpen && (
          <Modal
            isOpen={isColumnModalOpen}
            onClose={() => setColumnModalOpen((prev) => !prev)}
            clickOutsideToClose
            unmountOnClose
            centered
            size="sm"
          >
            <ModalHeader onClose={() => setColumnModalOpen((prev) => !prev)}>
              <div className={styles.headerBar}>
                <h5 className={clsx(typography.title5, styles.headerText)}>
                  <Content id={ContentId.ColumnSettings} />
                </h5>
              </div>
            </ModalHeader>
            <ModalBody>
              <div style={{ padding: vars.spacing['m'] }}>
                {reportConfig.reportType === ReportTypesV2.InventoryV2 ? (
                  <InventoryMetricsSelectionInput onSaved={onMetricsChange} />
                ) : (
                  <SalesMetricsSelectionInput onSaved={onMetricsChange} />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <CancelButton
                textContentId={ContentId.Close}
                onClick={() => setColumnModalOpen((prev) => !prev)}
              />
            </ModalFooter>
          </Modal>
        )}
      </FormProvider>
    )
  );
};
