import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import {
  AutoPricingSettingsCollectionInput,
  AutoPricingStrategyDialog,
  AutoPricingStrategyDialogProps,
} from 'src/dialogs/AutoPricingStrategyDialog';
import { useAutoPricingStrategies } from 'src/hooks/api/useAutoPricingStrategies';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { useHasListingPricePermissions } from 'src/modals/ListingDetails/components/useHasListingPricePermissions';
import { EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPricingSettingsCollection,
  Listing,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { useAutoPricingSettings } from '../useAutoPricingSettings';

export const AutoPricingStrategySection = ({
  listing,
  disabled,
  isMarketListingsView = false,
}: {
  listing?: Listing | null;
  disabled?: boolean;
  isMarketListingsView?: boolean;
}) => {
  const {
    openDialog: openAutoPricingStrategyDialog,
    closeDialog: closeAutoPricingStrategyDialog,
  } = useDialog<AutoPricingStrategyDialogProps>(
    DialogId.AutoPricingStrategy,
    AutoPricingStrategyDialog
  );
  const { canAutoPrice } = useHasListingPricePermissions(listing, false);

  const isMobile = useMatchMedia('mobile');

  const { setValue, watch } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const { autoPricingStrategiesQuery } = useAutoPricingStrategies(listing);

  const {
    autoPricingEnabled,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingQuantityScoreAdjustmentEnabled,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,

    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
  } = watch();

  const {
    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
  } = useAutoPricingSettings(listing);

  const [selectedAutoPricingStrategyId, setSelectedAutoPricingStrategyId] =
    useState<number | null>(null);

  const keyOptionsContent = useMemo(() => {
    return (
      autoPricingStrategiesQuery.data?.reduce(
        (re, r) => {
          re[r.autoPricingSettingsCollectionId?.toString() ?? ''] =
            r.strategyName;
          return re;
        },
        {} as Record<string, string>
      ) ?? {}
    );
  }, [autoPricingStrategiesQuery.data]);

  useEffect(() => {
    const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
      (r) => r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
    );

    if (selectedAutoPricingStrategyId) {
      if (!autoPricingStrategy) {
        // On strategy delete, reset the form
        setSelectedAutoPricingStrategyId(null);
      } else {
        // on strategy change, apply the settings to the form
        applyAutoPricingStrategy(autoPricingStrategy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPricingStrategiesQuery.data]);

  const applyAutoPricingStrategy = useCallback(
    (autoPricingStrategy: AutoPricingSettingsCollection) => {
      // Apply the settings to the form
      setValue('autoPricingEnabled', true);
      setValue('skipAutoPricing', false);
      setValue('skipAdvancePricing', false);
      setValue('skipCompListing', false);
      setValue('skipUndercut', false);

      onUndercutChange(
        autoPricingStrategy.undercutMode,
        autoPricingStrategy.undercutAbsoluteAmount,
        autoPricingStrategy.undercutRelativeAmount
      );
      onCompListingChange({
        compListingModeNew: autoPricingStrategy.compListingMode,
        compListingFloorNew: autoPricingStrategy.compListingFloor,
        compListingCeilingNew: autoPricingStrategy.compListingCeiling,
        compListingOnlyForSameZoneEnabledNew:
          autoPricingStrategy.compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabledNew:
          autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled,
        compListingQuantityScoreAdjustmentEnabledNew:
          autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled,
        compListingExcludeFanInventoryNew:
          autoPricingStrategy.compListingExcludeFanInventory,
        compListingQuantityScoreAdjustmentOverrideJsonNew:
          autoPricingStrategy.compListingQuantityScoreAdjustmentOverrideJson,
      });
      onCircuitBreakerChange(
        autoPricingStrategy.circuitBreakerRelativeCeiling,
        autoPricingStrategy.circuitBreakerRelativeFloor,
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent,
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours,
        autoPricingStrategy.circuitBreakerMinCompListingCount
      );
      onOutlierChange({
        outlierModeNew: autoPricingStrategy.outlierMode,
        outlierStandardDeviationsNew:
          autoPricingStrategy.outlierStandardDeviations,
        outlierKthLowestLimitNew: autoPricingStrategy.outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacingNew:
          autoPricingStrategy.outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacingNew:
          autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing,
      });

      setSelectedAutoPricingStrategyId(
        autoPricingStrategy.autoPricingSettingsCollectionId
      );
    },
    [
      onCircuitBreakerChange,
      onCompListingChange,
      onOutlierChange,
      onUndercutChange,
      setValue,
    ]
  );

  const onAutoPricingStrategyChange = useCallback(
    (value: string) => {
      if (!value) return;

      const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
        (r) => r.autoPricingSettingsCollectionId === parseInt(value, 10)
      );
      if (!autoPricingStrategy) {
        return;
      }

      applyAutoPricingStrategy(autoPricingStrategy);
    },
    [applyAutoPricingStrategy, autoPricingStrategiesQuery.data]
  );

  const currentValueEqualsSelectedStrategy = useMemo(() => {
    const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
      (r) => r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
    );

    if (!autoPricingStrategy) return true;

    return (
      autoPricingEnabled === true &&
      autoPricingStrategy &&
      (autoPricingStrategy.undercutMode == null ||
        autoPricingStrategy.undercutMode === undercutMode) &&
      (autoPricingStrategy.undercutAbsoluteAmount == null ||
        autoPricingStrategy.undercutAbsoluteAmount ===
          undercutAbsoluteAmount) &&
      (autoPricingStrategy.undercutRelativeAmount == null ||
        autoPricingStrategy.undercutRelativeAmount ===
          undercutRelativeAmount) &&
      (autoPricingStrategy.compListingMode == null ||
        autoPricingStrategy.compListingMode === compListingMode) &&
      (autoPricingStrategy.compListingFloor == null ||
        autoPricingStrategy.compListingFloor === compListingFloor) &&
      (autoPricingStrategy.compListingCeiling == null ||
        autoPricingStrategy.compListingCeiling === compListingCeiling) &&
      (autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled == null ||
        autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled ===
          compListingQuantityScoreAdjustmentEnabled) &&
      (autoPricingStrategy.compListingOnlyForSameZoneEnabled == null ||
        autoPricingStrategy.compListingOnlyForSameZoneEnabled ===
          compListingOnlyForSameZoneEnabled) &&
      (autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled == null ||
        autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled ===
          compListingOnlyForSelectedSectionsEnabled) &&
      (autoPricingStrategy.compListingExcludeFanInventory == null ||
        autoPricingStrategy.compListingExcludeFanInventory ===
          compListingExcludeFanInventory) &&
      (autoPricingStrategy.circuitBreakerRelativeCeiling == null ||
        autoPricingStrategy.circuitBreakerRelativeCeiling ===
          circuitBreakerRelativeCeiling) &&
      (autoPricingStrategy.circuitBreakerRelativeFloor == null ||
        autoPricingStrategy.circuitBreakerRelativeFloor ===
          circuitBreakerRelativeFloor) &&
      (autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent == null ||
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent ===
          circuitBreakerMaxDiscountVelocityPercent) &&
      (autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours ==
        null ||
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours ===
          circuitBreakerMaxDiscountVelocityTicksInHours) &&
      (autoPricingStrategy.circuitBreakerMinCompListingCount == null ||
        autoPricingStrategy.circuitBreakerMinCompListingCount ===
          circuitBreakerMinCompListingCount) &&
      (autoPricingStrategy.outlierMode == null ||
        autoPricingStrategy.outlierMode === outlierMode) &&
      (autoPricingStrategy.outlierStandardDeviations == null ||
        autoPricingStrategy.outlierStandardDeviations ===
          outlierStandardDeviations) &&
      (autoPricingStrategy.outlierKthLowestLimit == null ||
        autoPricingStrategy.outlierKthLowestLimit === outlierKthLowestLimit) &&
      (autoPricingStrategy.outlierKthLowestLimitRelativeSpacing == null ||
        autoPricingStrategy.outlierKthLowestLimitRelativeSpacing ===
          outlierKthLowestLimitRelativeSpacing) &&
      (autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing == null ||
        autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing ===
          outlierKthLowestLimitAbsoluteSpacing)
    );
  }, [
    autoPricingEnabled,
    autoPricingStrategiesQuery.data,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingQuantityScoreAdjustmentEnabled,
    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    selectedAutoPricingStrategyId,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const isStrategyApplied =
    currentValueEqualsSelectedStrategy && selectedAutoPricingStrategyId;

  const defaultValuesForStrategy = useMemo(() => {
    const autoPricingStrategy = isStrategyApplied
      ? autoPricingStrategiesQuery.data?.find(
          (r) =>
            r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
        )
      : undefined;

    return {
      autoPricingSettingsCollectionId:
        autoPricingStrategy?.autoPricingSettingsCollectionId,
      strategyName: autoPricingStrategy?.strategyName,

      skipAutoPricing: false,
      skipAdvancePricing: false,
      skipCompListing: false,
      skipUndercut: false,

      autoPricingEnabled,

      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,

      compListingMode,
      compListingFloor,
      compListingCeiling,
      compListingQuantityScoreAdjustmentEnabled,
      compListingOnlyForSameZoneEnabled,
      compListingOnlyForSelectedSectionsEnabled,
      compListingExcludeFanInventory,

      circuitBreakerRelativeCeiling,
      circuitBreakerRelativeFloor,
      circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerMinCompListingCount,

      outlierMode,
      outlierStandardDeviations,
      outlierKthLowestLimit,
      outlierKthLowestLimitRelativeSpacing,
      outlierKthLowestLimitAbsoluteSpacing,
    } as AutoPricingSettingsCollectionInput;
  }, [
    autoPricingEnabled,
    autoPricingStrategiesQuery.data,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingQuantityScoreAdjustmentEnabled,
    isStrategyApplied,
    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    selectedAutoPricingStrategyId,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  if (!canAutoPrice && !isMarketListingsView) {
    return null;
  }

  const sectionContent = (
    <SectionContent numOfColumns={isMobile ? 1 : 2}>
      <DetailGroup style={{ gridColumn: '1' }}>
        <PosSelect
          onChange={onAutoPricingStrategyChange}
          value={
            isStrategyApplied
              ? selectedAutoPricingStrategyId.toString()
              : undefined
          }
          displayText={
            isStrategyApplied
              ? keyOptionsContent[selectedAutoPricingStrategyId.toString()]
              : ContentId.Custom
          }
          searchable
          disabled={disabled}
          valueOptionsContent={keyOptionsContent}
          placeholderText={ContentId.Custom}
          onClick={(e) => e.stopPropagation()}
        />
      </DetailGroup>
      <DetailGroup
        style={{ gridColumn: '2', height: '100%', placeContent: 'center' }}
      >
        <ButtonWithIcon
          variant="textPlain"
          shape="none"
          icon={
            isStrategyApplied ? (
              <EditIcon
                fill={IconsFill.textBrand}
                size={vars.iconSize.s}
                withHoverEffect
              />
            ) : (
              <PlusIcon
                fill={IconsFill.textBrand}
                size={vars.iconSize.s}
                withHoverEffect
              />
            )
          }
          textContentId={
            isStrategyApplied
              ? isMarketListingsView
                ? ContentId.Edit
                : ContentId.EditPricingStrategy
              : ContentId.CreatePricingStrategy
          }
          onClick={() =>
            openAutoPricingStrategyDialog({
              defaultValues: defaultValuesForStrategy,
              listing: listing!,
              isLoading: autoPricingStrategiesQuery.isLoading,
              onClosed: closeAutoPricingStrategyDialog,
              onStrategyUpdated: (strategyId: number) => {
                if (strategyId !== selectedAutoPricingStrategyId) {
                  // An insert
                  setSelectedAutoPricingStrategyId(strategyId);
                  onAutoPricingStrategyChange(strategyId.toString());
                }
                closeAutoPricingStrategyDialog();
              },
              isBulkEdit: false,
              listingHasParent: false,
              disabled,
            })
          }
          disabled={disabled}
          style={{ padding: 0, textAlign: 'start' }}
        />
      </DetailGroup>
    </SectionContent>
  );

  return (
    <>
      {isMarketListingsView ? (
        sectionContent
      ) : (
        <DetailSection name={<Content id={ContentId.AutopricingStrategy} />}>
          {sectionContent}
        </DetailSection>
      )}
    </>
  );
};
