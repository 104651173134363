import { Content } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { DealType } from 'src/WebApiController';

export const DealTypeSection = ({ dealType }: { dealType?: DealType }) => {
  const { setLocalDeal } = useDealContext();

  return (
    <RadioGroup
      onValueChange={(value: DealType) => {
        setLocalDeal((prev) => {
          if (!prev) return prev;
          return { ...prev, dealType: value };
        });
      }}
      value={dealType}
    >
      <Stack gap="m">
        <Radio value={DealType.Sor} label={<Content id={ContentId.SOR} />} />
        <Radio
          value={DealType.Guaranteed}
          label={<Content id={ContentId.GUR} />}
        />
      </Stack>
    </RadioGroup>
  );
};
