import {
  FilterToolbarGroup,
  FilterToolbarItemId,
  SelectedFilters,
} from 'src/components/Filters';

interface UseMapFiltersToFiltersStateProps {
  filters: FilterToolbarGroup[];
  appliedFilterIds: FilterToolbarItemId[];
  mandatoryFiltersToShow: FilterToolbarItemId[];
}

export const mapFiltersToFiltersState = ({
  filters,
  appliedFilterIds,
  mandatoryFiltersToShow,
}: UseMapFiltersToFiltersStateProps): SelectedFilters => {
  const selectedFilters: SelectedFilters = {};

  filters.forEach((filterToolbarGroup) => {
    const groupId = filterToolbarGroup.titleContentId;
    if (!groupId) {
      return;
    }
    filterToolbarGroup.items.forEach((filterToolbarItem) => {
      const { filterId } = filterToolbarItem;
      if (
        appliedFilterIds.includes(filterId) ||
        mandatoryFiltersToShow.includes(filterId)
      ) {
        selectedFilters[groupId] = selectedFilters[groupId] ?? [];
        selectedFilters[groupId].push({
          filterId: filterId,
          titleContentId: filterToolbarGroup.titleContentId,
        });
      }
    });
  });

  return selectedFilters;
};
