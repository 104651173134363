import { clsx } from 'clsx';
import { format } from 'date-fns';
import { Content } from 'src/contexts/ContentContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';
import { Seating, Ticket } from 'src/WebApiController';
import * as styles from './SaleWasteInfo.css';

interface SaleWasteInfoProps {
  eventDate: string | null | undefined;
  tickets: Ticket[] | undefined;
  seating?: Seating;
  canBeWasted: boolean;
}

export const SaleWasteInfo = ({
  eventDate,
  tickets,
  seating,
  canBeWasted,
}: SaleWasteInfoProps) => {
  const isMobile = useMatchMedia('mobile');
  const ticketsToWaste = (tickets ?? []).filter((t) => !t.isWasted);

  if (
    !eventDate ||
    !tickets ||
    !seating ||
    ticketsToWaste.length === 0 ||
    !canBeWasted
  ) {
    return (
      <div className={styles.noTicketsToWaste}>
        <Content id={ContentId.NoTicketsToWaste} />
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.grid, { [styles.gridMobileSpacing]: isMobile })}
    >
      <span className={styles.section}>
        <Content id={ContentId.SalesSummary} />
      </span>
      <div>
        <div className={styles.sectionTitle}>
          <Content id={ContentId.SaleDate} />
        </div>
        <div className={styles.sectionContent}>
          {format(new Date(eventDate), 'MMM dd, yyyy')}
        </div>
      </div>
      <div className={styles.expectedProceeds}>
        <div className={styles.sectionTitle}>
          <Content id={ContentId.ExpectedProceeds} />
        </div>
        <div className={styles.sectionContent}>$0</div>
      </div>
      <div className={styles.section}>
        <Content id={ContentId.Tickets} />
      </div>

      <div className={styles.quantity}>
        <div className={styles.sectionTitle}>
          <Content id={ContentId.Quantity} />
        </div>
        <div className={styles.sectionContent}>x {ticketsToWaste.length}</div>
      </div>

      {ticketsToWaste.map((ticket, index) => {
        const isFirst = index === 0;
        return (
          <>
            <div className={styles.span2}>
              {isFirst && (
                <div className={styles.sectionTitle}>
                  <Content id={ContentId.Section} />
                </div>
              )}
              <div className={styles.sectionContent}>{seating.section}</div>
            </div>
            <div>
              {isFirst && (
                <div className={styles.sectionTitle}>
                  <Content id={ContentId.Row} />
                </div>
              )}
              <div className={styles.sectionContent}> {ticket.row}</div>
            </div>
            <div>
              {isFirst && (
                <div className={styles.sectionTitle}>
                  <Content id={ContentId.Seat} />
                </div>
              )}
              <div className={styles.sectionContent}>{ticket.seat}</div>
            </div>
          </>
        );
      })}
    </div>
  );
};
