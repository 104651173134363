import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { SALE_LINE_ITEM_CREDIT_REASON_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SaleLineItem } from 'src/WebApiController';
import * as styles from './ChargeOrCreditReasonSelector.css';

export const CreditReasonSelector = () => {
  const { control, formState, watch, setValue } =
    useFormContext<SaleLineItem>();
  const creditReason = watch('saleLineItemCreditReason');
  const requiredText = useContent(ContentId.Required);

  useEffect(() => {
    return () => {
      setValue('saleLineItemCreditReason', null);
    };
  }, [setValue]);

  return (
    <PosFormField
      label={<Content id={ContentId.CreditReasons} />}
      errors={formState.errors.saleLineItemCreditReason?.message}
    >
      <Controller
        name={'saleLineItemCreditReason'}
        rules={{ required: !creditReason ? requiredText : undefined }}
        control={control}
        render={({ field: { ...field } }) => (
          <PosEnumSelect
            className={styles.fullWidth}
            value={creditReason}
            valueOptionsContent={SALE_LINE_ITEM_CREDIT_REASON_TO_CID}
            onChange={(creditReason) => {
              setValue('saleLineItemCreditReason', creditReason);
            }}
          />
        )}
      />
    </PosFormField>
  );
};
