import { IconsProps } from 'src/svgs/Viagogo';
import { SvgWrapper } from 'src/svgs/Viagogo/svg-wrapper/icon-wrapper';

export const LockedIcon = (props: IconsProps) => {
  return (
    <SvgWrapper {...props} fill="none">
      <path
        d="M13.3334 8.69332V5.35998C13.3334 3.51915 11.8409 2.02665 10.0001 2.02665C8.15925 2.02665 6.66675 3.51915 6.66675 5.35998V8.69332"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.8333 8.69331H4.16667C3.24619 8.69331 2.5 9.43951 2.5 10.36V17.0266C2.5 17.9471 3.24619 18.6933 4.16667 18.6933H15.8333C16.7538 18.6933 17.5 17.9471 17.5 17.0266V10.36C17.5 9.43951 16.7538 8.69331 15.8333 8.69331Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10 12.86V15.36"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.0001 13.6933C10.4603 13.6933 10.8334 13.3202 10.8334 12.86C10.8334 12.3997 10.4603 12.0266 10.0001 12.0266C9.53984 12.0266 9.16675 12.3997 9.16675 12.86C9.16675 13.3202 9.53984 13.6933 10.0001 13.6933Z"
        fill="#3F1D75"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgWrapper>
  );
};
