import { ContentIdAndDefaultValue } from 'src/contexts/ContentContext';

import { FormatContentId } from './formatContentId';

// Please keep this map in alphabetical order
export const FormatContentIds: Record<
  FormatContentId,
  ContentIdAndDefaultValue
> = {
  [FormatContentId.ActivityLogChangeFromTo]: {
    id: FormatContentId.ActivityLogChangeFromTo,
    defaultValue: '{0} changed from {1} to {2}',
  },
  [FormatContentId.ActivityLogChangeFrom]: {
    id: FormatContentId.ActivityLogChangeFrom,
    defaultValue: '{0} changed from {1} to nothing.',
  },
  [FormatContentId.ActivityLogChangeTo]: {
    id: FormatContentId.ActivityLogChangeTo,
    defaultValue: '{0} changed to {1}',
  },
  [FormatContentId.AddAnother]: {
    id: FormatContentId.AddAnother,
    defaultValue: 'Add Another {0}',
  },
  [FormatContentId.AndXMore]: {
    id: FormatContentId.AndXMore,
    defaultValue: '& {0} more',
  },
  [FormatContentId.ApplyFilterToReduceCountsToSeeData]: {
    id: FormatContentId.ApplyFilterToReduceCountsToSeeData,
    defaultValue:
      'Your current filter returns more than {0} items. Please select additional filters to view your data.',
  },
  [FormatContentId.ApplyToAllEventsConfirmation]: {
    id: FormatContentId.ApplyToAllEventsConfirmation,
    defaultValue:
      'Do you want to apply the heat map to all {0} {1} events at {2} or only to this event?',
  },
  [FormatContentId.BulkEditMaxItemPreviewDisplayMessage]: {
    id: FormatContentId.BulkEditMaxItemPreviewDisplayMessage,
    defaultValue: 'Only a maximum of {0} of {1} is displayed for preview.',
  },
  [FormatContentId.BulkEditSkippedItemsPrompt]: {
    id: FormatContentId.BulkEditSkippedItemsPrompt,
    defaultValue:
      '{0} items were skipped because their associated entities were changed. Click Retry to try editing them again.',
  },
  [FormatContentId.ClassifyAs]: {
    id: FormatContentId.ClassifyAs,
    defaultValue: 'Classify as {0}',
  },
  [FormatContentId.CompletedNSecondsAgo]: {
    id: FormatContentId.CompletedNSecondsAgo,
    defaultValue: 'Completed {0} seconds ago.',
  },
  [FormatContentId.CompletedNMinutesAgo]: {
    id: FormatContentId.CompletedNMinutesAgo,
    defaultValue: 'Completed {0} minutes ago.',
  },
  [FormatContentId.CompletedNHoursAgo]: {
    id: FormatContentId.CompletedNHoursAgo,
    defaultValue: 'Completed {0} hours ago.',
  },
  [FormatContentId.CopyOf]: {
    id: FormatContentId.CopyOf,
    defaultValue: 'Copy of {0}',
  },
  [FormatContentId.CouldNotFindListingId]: {
    id: FormatContentId.CouldNotFindListingId,
    defaultValue: "Could not find Listing Id: '{0}'",
  },
  [FormatContentId.CouldNotFindOrderId]: {
    id: FormatContentId.CouldNotFindOrderId,
    defaultValue: "Could not find Order Id: '{0}'",
  },
  [FormatContentId.CouldNotFindSaleId]: {
    id: FormatContentId.CouldNotFindSaleId,
    defaultValue: "Could not find Sale Id: '{0}'",
  },
  [FormatContentId.DaysBeforeEvent]: {
    id: FormatContentId.DaysBeforeEvent,
    defaultValue: '{0} Days Before Event',
  },
  [FormatContentId.DuplicateListingDetails]: {
    id: FormatContentId.DuplicateListingDetails,
    defaultValue:
      'This listing appears to be a duplicate of listing {0}. We suggest removing it.',
  },
  [FormatContentId.DuplicatingSeats]: {
    id: FormatContentId.DuplicatingSeats,
    defaultValue: 'Duplicating seat found for Seat {0}',
  },
  [FormatContentId.EmailAddressFrom]: {
    id: FormatContentId.EmailAddressFrom,
    defaultValue: 'From: {0}',
  },
  [FormatContentId.EmailAddressTo]: {
    id: FormatContentId.EmailAddressTo,
    defaultValue: 'To: {0}',
  },
  [FormatContentId.EmptyContent]: {
    id: FormatContentId.EmptyContent,
    defaultValue: '',
  },
  [FormatContentId.EventsSelected]: {
    id: FormatContentId.EventsSelected,
    defaultValue: '{0} event(s) selected',
  },
  [FormatContentId.ExtraBarcodes]: {
    id: FormatContentId.ExtraBarcodes,
    defaultValue: '{0} extra barcode(s).',
  },
  [FormatContentId.ExtraTicketIds]: {
    id: FormatContentId.ExtraTicketIds,
    defaultValue: '{0} extra ticket id(s).',
  },
  [FormatContentId.ExtraTicketUrls]: {
    id: FormatContentId.ExtraTicketUrls,
    defaultValue: '{0} extra URL(s).',
  },
  [FormatContentId.Filled]: {
    id: FormatContentId.Filled,
    defaultValue: '{0} Filled',
  },
  [FormatContentId.FirstTimeVenueEventSetup]: {
    id: FormatContentId.FirstTimeVenueEventSetup,
    defaultValue: 'The venue for this event is setup as {0}',
  },
  [FormatContentId.FulfilledExternalMarketplace]: {
    id: FormatContentId.FulfilledExternalMarketplace,
    defaultValue:
      'This order is fulfilled on {0}. To fulfill with new tickets, you will need to get {0} to reset the fulfilment state of the order.',
  },
  [FormatContentId.GoTo]: {
    id: FormatContentId.GoTo,
    defaultValue: 'Go to {0}',
  },
  [FormatContentId.HurdleN]: {
    id: FormatContentId.HurdleN,
    defaultValue: 'Hurdle {0}',
  },
  [FormatContentId.InNDays]: {
    id: FormatContentId.InNDays,
    defaultValue: 'In {0} days',
  },
  [FormatContentId.InNHours]: {
    id: FormatContentId.InNHours,
    defaultValue: 'In {0} hours',
  },
  [FormatContentId.InNMinutes]: {
    id: FormatContentId.InNMinutes,
    defaultValue: 'In {0} minutes',
  },
  [FormatContentId.InvalidTagCharacter]: {
    id: FormatContentId.InvalidTagCharacter,
    defaultValue: 'Special characters not allowed: {0}',
  },
  [FormatContentId.ItemsSelected]: {
    id: FormatContentId.ItemsSelected,
    defaultValue: '{0} items selected',
  },
  [FormatContentId.LastNDays]: {
    id: FormatContentId.LastNDays,
    defaultValue: 'last {0} days',
  },
  [FormatContentId.LastNHours]: {
    id: FormatContentId.LastNHours,
    defaultValue: 'last {0} hours',
  },
  [FormatContentId.LastNMinutes]: {
    id: FormatContentId.LastNMinutes,
    defaultValue: 'last {0} minutes',
  },
  [FormatContentId.LastNMonths]: {
    id: FormatContentId.LastNMonths,
    defaultValue: 'last {0} months',
  },
  [FormatContentId.ListingInUKNotification]: {
    id: FormatContentId.ListingInUKNotification,
    defaultValue:
      'Direct listing on {0} not supported. Redirecting to {0} in {1} seconds...',
  },
  [FormatContentId.LoadingListingId]: {
    id: FormatContentId.LoadingListingId,
    defaultValue: "Loading Listing Id '{0}'",
  },
  [FormatContentId.LoadingOrderId]: {
    id: FormatContentId.LoadingOrderId,
    defaultValue: "Loading Order Id '{0}'",
  },
  [FormatContentId.LoadingSaleId]: {
    id: FormatContentId.LoadingSaleId,
    defaultValue: "Loading Sale Id '{0}'",
  },
  [FormatContentId.Margin]: {
    id: FormatContentId.Margin,
    defaultValue: '{0} Margin',
  },
  [FormatContentId.MaxAllowedFileSizeMb]: {
    id: FormatContentId.MaxAllowedFileSizeMb,
    defaultValue: 'File maximum size allowed is {0} MB',
  },
  [FormatContentId.MaxReportSizeWarningMessage]: {
    id: FormatContentId.MaxReportSizeWarningMessage,
    defaultValue:
      'Your report has {0} rows but only {1} is returned per maximum recommended. To view your entire report, either download it or bypass the recommended limit.',
  },
  [FormatContentId.MissingBarcodes]: {
    id: FormatContentId.MissingBarcodes,
    defaultValue: '{0} missing barcode(s).',
  },
  [FormatContentId.MissingTicketIds]: {
    id: FormatContentId.MissingTicketIds,
    defaultValue: '{0} missing ticket id(s).',
  },
  [FormatContentId.MissingTicketUrls]: {
    id: FormatContentId.MissingTicketUrls,
    defaultValue: '{0} missing URL(s).',
  },
  [FormatContentId.NCharactersRemaining]: {
    id: FormatContentId.NCharactersRemaining,
    defaultValue: '{0} characters remaining',
  },
  [FormatContentId.NCompleted]: {
    id: FormatContentId.NCompleted,
    defaultValue: '{0} completed',
  },
  [FormatContentId.NDays]: {
    id: FormatContentId.NDays,
    defaultValue: '{0} days',
  },
  [FormatContentId.NFailed]: {
    id: FormatContentId.NFailed,
    defaultValue: '{0} failed',
  },
  [FormatContentId.NHours]: {
    id: FormatContentId.NHours,
    defaultValue: '{0} hours',
  },
  [FormatContentId.NIncreaseBy]: {
    id: FormatContentId.NIncreaseBy,
    defaultValue: 'Increase by {0}',
  },
  [FormatContentId.NMinutes]: {
    id: FormatContentId.NMinutes,
    defaultValue: '{0} minutes',
  },
  [FormatContentId.NDaysBefore]: {
    id: FormatContentId.NDaysBefore,
    defaultValue: '{0} days before event',
  },
  [FormatContentId.NHoursBefore]: {
    id: FormatContentId.NHoursBefore,
    defaultValue: '{0} hours before event',
  },
  [FormatContentId.NMinutesBefore]: {
    id: FormatContentId.NMinutesBefore,
    defaultValue: '{0} minutes before event',
  },
  [FormatContentId.NoDataAvailable]: {
    id: FormatContentId.NoDataAvailable,
    defaultValue: 'No {0} Available',
  },
  [FormatContentId.NotificationTypeListingBroadcastFailed]: {
    id: FormatContentId.NotificationTypeListingBroadcastFailed,
    defaultValue: 'Failed to broadcast listing to {0}',
  },
  [FormatContentId.NotificationTypeListingUpdateAllInPriceFailed]: {
    id: FormatContentId.NotificationTypeListingUpdateAllInPriceFailed,
    defaultValue: '{0} price broadcast failed',
  },
  [FormatContentId.NotificationTypeListingUpdateInHandDateFailed]: {
    id: FormatContentId.NotificationTypeListingUpdateInHandDateFailed,
    defaultValue: '{0} In Hand Date broadcast failed',
  },
  [FormatContentId.NotificationTypeListingUpdateSplitTypeFailed]: {
    id: FormatContentId.NotificationTypeListingUpdateSplitTypeFailed,
    defaultValue: '{0} Split Type update broadcast failed',
  },
  [FormatContentId.NotificationTypeListingUpdateTicketTypeFailed]: {
    id: FormatContentId.NotificationTypeListingUpdateTicketTypeFailed,
    defaultValue: '{0} Ticket Type update broadcast failed',
  },
  [FormatContentId.NotificationTypeListingUpdateUnitNetProceedsFailed]: {
    id: FormatContentId.NotificationTypeListingUpdateUnitNetProceedsFailed,
    defaultValue: '{0} price broadcast failed',
  },
  [FormatContentId.NotificationTypeListingUnbroadcastFailed]: {
    id: FormatContentId.NotificationTypeListingUnbroadcastFailed,
    defaultValue: 'Failed to unbroadcast listing from {0}',
  },
  [FormatContentId.NotificationTypeSellerSupportCaseNewReply]: {
    id: FormatContentId.NotificationTypeSellerSupportCaseNewReply,
    defaultValue: 'New message from {0}',
  },
  [FormatContentId.NotUnique]: {
    id: FormatContentId.NotUnique,
    defaultValue: '{0} is not unique',
  },
  [FormatContentId.NSelectedSections]: {
    id: FormatContentId.NSelectedSections,
    defaultValue: '{0} selected sections',
  },
  [FormatContentId.NUpdated]: {
    id: FormatContentId.NUpdated,
    defaultValue: '{0} updated',
  },
  [FormatContentId.NWarnings]: {
    id: FormatContentId.NWarnings,
    defaultValue: '{0} warnings',
  },
  [FormatContentId.OnlyForMarketplaces]: {
    id: FormatContentId.OnlyForMarketplaces,
    defaultValue: '(for {0} only)',
  },
  [FormatContentId.PacksOf]: {
    id: FormatContentId.PacksOf,
    defaultValue: 'Packs of {0}',
  },
  [FormatContentId.PageNum]: {
    id: FormatContentId.PageNum,
    defaultValue: 'Page {0}',
  },
  [FormatContentId.PercentageRelativeTo]: {
    id: FormatContentId.PercentageRelativeTo,
    defaultValue: 'Percentage Relative to {0}',
  },
  [FormatContentId.ProofOfPurchasedRequiredDetailMessage]: {
    id: FormatContentId.ProofOfPurchasedRequiredDetailMessage,
    defaultValue:
      'Seated tickets must either have row or seat information, or proof submitted that you do not have this information available to you, before they can appear on {0}.',
  },
  [FormatContentId.RequestState]: {
    id: FormatContentId.RequestState,
    defaultValue: 'Request State: {0}',
  },
  [FormatContentId.Row]: {
    id: FormatContentId.Row,
    defaultValue: 'Row {0}',
  },
  [FormatContentId.RowRangeInputError]: {
    id: FormatContentId.RowRangeInputError,
    defaultValue: 'Upper bond of the row range must be no less than {0}.',
  },
  [FormatContentId.SalesForEditTickets]: {
    id: FormatContentId.SalesForEditTickets,
    defaultValue:
      'If your edits result in one or more tickets removed, your {0} sales for the removed tickets will be deallocated and put back to Pending Confirmation, where you can either source new tickets or reject the orders.',
  },
  [FormatContentId.SalesForRemovedTickets]: {
    id: FormatContentId.SalesForRemovedTickets,
    defaultValue:
      'Your {0} sales for the removed tickets will be deallocated and put back to Pending Confirmation, where you can either source new tickets or reject the orders.',
  },
  [FormatContentId.SearchItem]: {
    id: FormatContentId.SearchItem,
    defaultValue: 'Search {0}',
  },
  [FormatContentId.SearchingForListingId]: {
    id: FormatContentId.SearchingForListingId,
    defaultValue: "Searching for Listing Id: '{0}'",
  },
  [FormatContentId.SearchingForOrderId]: {
    id: FormatContentId.SearchingForOrderId,
    defaultValue: "Searching for Order Id: '{0}'",
  },
  [FormatContentId.SearchingForSaleId]: {
    id: FormatContentId.SearchingForSaleId,
    defaultValue: "Searching for Sale Id: '{0}'",
  },
  [FormatContentId.SearchResultPick]: {
    id: FormatContentId.SearchResultPick,
    defaultValue:
      "Searching for '{0}' found {1} results. Click on an item to open it.",
  },
  [FormatContentId.SeatAllocationChangeWarning]: {
    id: FormatContentId.SeatAllocationChangeWarning,
    defaultValue:
      'If you change section by {0} or more, or row by {1} or more, you might need to confirm the change with the marketplace first.',
  },
  [FormatContentId.SeatNumber]: {
    id: FormatContentId.SeatNumber,
    defaultValue: 'Seat {0}',
  },
  [FormatContentId.Section]: {
    id: FormatContentId.Section,
    defaultValue: 'Section {0}',
  },
  [FormatContentId.ShareQuoted]: {
    id: FormatContentId.ShareQuoted,
    defaultValue: 'Share "{0}"',
  },
  [FormatContentId.SwitchTo]: {
    id: FormatContentId.SwitchTo,
    defaultValue: 'Switch To {0}',
  },
  [FormatContentId.TakeMeThereInSeconds]: {
    id: FormatContentId.TakeMeThereInSeconds,
    defaultValue: 'Take me there in {0} seconds...',
  },
  [FormatContentId.TheseAreThePastSalesOnTheWebsiteAndApp]: {
    id: FormatContentId.TheseAreThePastSalesOnTheWebsiteAndApp,
    defaultValue: 'These are the past {0} sales on the website and app',
  },
  [FormatContentId.TicketCount]: {
    id: FormatContentId.TicketCount,
    defaultValue: '{0} ticket(s)',
  },
  [FormatContentId.TicketNumber]: {
    id: FormatContentId.TicketNumber,
    defaultValue: 'Ticket {0}',
  },
  [FormatContentId.TicketPricePercentile]: {
    id: FormatContentId.TicketPricePercentile,
    defaultValue: 'Ticket Price {0}',
  },
  [FormatContentId.TicketTypePriority]: {
    id: FormatContentId.TicketTypePriority,
    defaultValue: '{0} Ticket Type Priority',
  },
  [FormatContentId.Uploading]: {
    id: FormatContentId.Uploading,
    defaultValue: 'Uploading {0}...',
  },
  [FormatContentId.UpToXTagsCanBeIncluded]: {
    id: FormatContentId.UpToXTagsCanBeIncluded,
    defaultValue: 'Up to {0} tags can be included in columns.',
  },
  [FormatContentId.UrlNumber]: {
    id: FormatContentId.UrlNumber,
    defaultValue: 'URL {0}',
  },
  [FormatContentId.WelcomeUserMessage]: {
    id: FormatContentId.WelcomeUserMessage,
    defaultValue: 'Hello, {0}',
  },
  [FormatContentId.WillDeliverToSecondaryAccount]: {
    id: FormatContentId.WillDeliverToSecondaryAccount,
    defaultValue: 'Tickets will be delivered to my {0} account',
  },
  [FormatContentId.YourSaleMetricsPart2]: {
    id: FormatContentId.YourSaleMetricsPart2,
    defaultValue:
      'are {0} tickets sold with {1} net proceeds and {2} sales margin.',
  },
  [FormatContentId.YourSaleWithStatus]: {
    id: FormatContentId.YourSaleWithStatus,
    defaultValue: 'You have {0} sales {1} for upcoming events.',
  },
};
