import { endOfDay, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import {
  DealProvider,
  useDealContext,
} from 'src/contexts/DealContext/DealContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DeleteIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  DealSplitType,
  DealType,
  Feature,
  PurchaseOrderDealDetailsInput,
  PurchaseOrderDealInput,
} from 'src/WebApiController';

import { DealFallbackSection } from './DealConfigHurdle/DealConfigFallbackHurdle';
import {
  createDefaultDealConfig,
  DealConfigSection,
} from './DealConfigSection';
import { DealDateRangeSection } from './DealDateRangeSection';
import * as styles from './Deals.css';
import { DealSplitTypeSection } from './DealSplitTypeSection';
import { DealTypeSection } from './DealTypeSection';

export const createDefaultPODeal = (
  userId: string,
  buyerUserId?: string | null
): PurchaseOrderDealInput => ({
  dealId: null,
  dealType: DealType.Sor,
  dealDetails: {
    dealConfigId: null,
    dealFallbackConfig: createDefaultDealConfig(userId, buyerUserId),
    dealConfigs: posField([createDefaultDealConfig(userId, buyerUserId)]),
    dealStartDate: startOfDay(new Date()).toISOString(),
    dealEndDate: endOfDay(new Date()).toISOString(),
    configSplitType: posField(DealSplitType.Sales),
    guaranteedAmountPaid: 0,
  },
});

const DealSectionInput = ({
  dealDetails,
}: {
  dealDetails?: PurchaseOrderDealDetailsInput;
}) => {
  if (!dealDetails) {
    return;
  }

  return (
    <Stack direction="column" gap="xl">
      <DealDateRangeSection
        dealStartDate={dealDetails.dealStartDate}
        dealEndDate={dealDetails.dealEndDate}
      />
      <DealSplitTypeSection
        dealSplitType={dealDetails.configSplitType?.value ?? DealSplitType.None}
      />
      <DealConfigSection dealConfigs={dealDetails.dealConfigs?.value || null} />
      {dealDetails.dealFallbackConfig && (
        <DealFallbackSection
          dealFallbackConfig={dealDetails.dealFallbackConfig}
        />
      )}
    </Stack>
  );
};

const DealSectionContent = () => {
  const [isEditDeal, setIsEditDeal] = useState(false);
  const { watch, setValue } = useFormContext<PurchaseOrderDetailsInput>();
  const hasConsignmentDealFeature = useUserHasFeature(Feature.ConsignmentDeal);
  const dealField = watch('deal');
  const buyerUserId = watch('buyerUserId')?.value;
  const { localDeal, setLocalDeal, isValidDeal } = useDealContext();

  if (!hasConsignmentDealFeature) {
    return null;
  }

  const { value: deal } = dealField || {};

  // Show Collapsed Deal UI if deal is present
  if (deal && !isEditDeal) {
    return (
      <Stack justifyContent="spaceBetween" className={styles.DealContainer}>
        <Stack direction="column">
          <div className={styles.DealHeader}>
            <Content id={ContentId.Deal} />
          </div>
          {deal?.dealType === DealType.Sor && <Content id={ContentId.SOR} />}
          {deal?.dealType === DealType.Guaranteed && (
            <Content id={ContentId.GUR} />
          )}
        </Stack>
        <Stack style={{ alignItems: 'center' }} gap="m">
          <DeleteIcon
            fill={IconsFill.currentColor}
            withHoverEffect
            onClick={() => setValue('deal', posChangedField(null))}
          />
          <Button
            variant={'outline'}
            onClick={() => {
              setIsEditDeal(true);
              setLocalDeal(deal);
            }}
            style={{ alignSelf: 'center' }}
          >
            <EditIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              size={vars.iconSize.m}
            />
            <Content id={ContentId.UpdateDeal} />
          </Button>
        </Stack>
      </Stack>
    );
  }

  return isEditDeal ? (
    <Stack direction="column" className={styles.DealContainer} gap="xl">
      <DealTypeSection dealType={localDeal?.dealType} />
      <DealSectionInput dealDetails={localDeal?.dealDetails} />
      <Stack gap="m" justifyContent="end">
        <Button
          variant="outline"
          onClick={() => {
            setIsEditDeal(false);
          }}
          style={{ alignSelf: 'end' }}
        >
          <Content id={ContentId.Cancel} />
        </Button>
        <Button
          onClick={() => {
            setIsEditDeal(false);
            setValue('deal', posChangedField(localDeal || null));
          }}
          style={{ alignSelf: 'end' }}
          disabled={!isValidDeal}
        >
          <Content id={ContentId.Save} />
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack
      justifyContent="spaceBetween"
      className={styles.CollapsedDealContainer}
    >
      <div className={styles.DealHeader}>
        <Content id={ContentId.Deal} />
      </div>
      <Button
        variant={'link'}
        onClick={() => {
          setIsEditDeal(true);
          setLocalDeal(createDefaultPODeal('userId', buyerUserId));
        }}
        style={{ alignSelf: 'end' }}
      >
        <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
        <Content id={ContentId.AddNewDeal} />
      </Button>
    </Stack>
  );
};

export const DealSection = () => {
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const hasConsignmentDealFeature = useUserHasFeature(Feature.ConsignmentDeal);

  if (!hasConsignmentDealFeature) {
    return null;
  }

  const dealField = watch('deal');
  const { value: deal } = dealField || {};

  return (
    <DealProvider deal={deal ?? undefined}>
      <DealSectionContent />
    </DealProvider>
  );
};
