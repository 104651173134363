import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

export const LaunchBulkWasteTickets = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const canBulkWasteTickets = useUserHasFeature(Feature.BulkWasteTickets);

  if (!canBulkWasteTickets) {
    return null;
  }

  return (
    <PosDropdownItem
      key="WasteTicketsItem"
      onClick={onClick}
      disabled={disabled}
    >
      <Content id={ContentId.WasteTickets} />
    </PosDropdownItem>
  );
};
