import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { AutoPoRestictionForm } from './AutoPoRestictionForm';
import { PurchaseOrderGeneralSettingForm } from './PurchaseOrderGeneralSettingForm';
import * as styles from './PurchaseOrderSetting.css';

export function PurchaseOrderSettingsContent() {
  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.Purchases} />
        </h1>
        <PurchaseOrderGeneralSettingForm />
        <AutoPoRestictionForm />
      </div>
    </div>
  );
}
