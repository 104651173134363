import { useQueryClient } from '@tanstack/react-query';
import { StatusCodes } from 'http-status-codes';
import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { EmailBody } from 'src/components/Messages/EmailBody';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { EmailPurchaseOrderContext } from 'src/contexts/EmailPurchaseOrderContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import {
  AlertWithSuppressionDialog,
  useCancelTicketGroupDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import {
  InternationalComplianceDialog,
  useInternationalComplianceDialog,
} from 'src/dialogs/InternationalComplianceDialog';
import { SecondaryVendorAccountDialog } from 'src/dialogs/SecondaryVendorAccountDialog/SecondaryVendorAccountDialog';
import { TicketGroupIntentionDialog } from 'src/dialogs/TicketGroupIntentionDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetIsPurchaseIdsInUsedForVendorId } from 'src/hooks/useGetIsPurchaseIdsInUsedForVendorId';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanUpdatePurchase } from 'src/hooks/useUserHasPurchasePermissions';
import { TicketGroupActionParams } from 'src/tables/PurchaseTicketTable/configs/TicketGroupTableColumnsConfig';
import {
  EventIdQueryParam,
  PurchaseDeeplinkQueryParam,
  PurchaseFromEmailPoQueryParam,
  US_COUNTRY_CODES,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import {
  getDeepLinkIdFromUrl,
  isInFullPageEventView,
  useRemoveDeepLinkUrlPart,
} from 'src/utils/deepLinkUtils';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { newBigIntId } from 'src/utils/idUtils';
import {
  ListingCatalogMetricsQueryKey,
  ListingCatalogQueryKey,
} from 'src/utils/inventoryUtils';
import { hasChanged, posChangedField } from 'src/utils/posFieldUtils';
import {
  cleanTicketGroupContextFromDeepLink,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  getPurchaseOrderRelativeUrl,
  PurchaseOrderDetailsInput,
  PurchaseTicketsInput,
  validateEventSelection,
  validateTicketGroups,
  validateVendorInfo,
} from 'src/utils/purchaseUtils';
import { tagFieldHasChanges } from 'src/utils/tagUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  AutoPoClient,
  CatalogClient,
  DeliveryType,
  Feature,
  Permission,
  PosFieldArrayOfTag,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderState,
  PurchaseOrderTicketGroup,
  TicketClient,
  TicketGroupEditReason,
  TicketGroupInput,
  TicketGroupIntention,
  TicketGroupMergeInput,
} from 'src/WebApiController';

import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { EventEntityHeader } from '../common/EventEntityHeader';
import {
  EmailBodyWrapper,
  EmailPreviewContainer,
  EmailPreviewTitle,
  PurchaseMainDetails,
  PurchaseVendorHeader,
  StyledPurchaseWizardBody,
} from '../common/Purchase';
import { DealSection } from '../common/Purchase/Deal/DealSection';
import {
  PurchaseWizardFooter,
  PurchaseWizardFooterProps,
} from '../common/Purchase/PurchaseWizardFooter';
import { ModalBody } from '../Modal';
import { ModalBodyHeaderContainer } from '../Modal/Modal.styled';
import { PurchaseTicketsFlow } from './PurchaseTicketsFlow/PurchaseTicketsFlow';
import {
  AddActions,
  Steps,
  TicketGroupEditContext,
  TicketGroupInputSteps,
} from './PurchaseWizard.types';

export function PurchaseWizardBody({
  formState,
  handleSubmit,
  setError,
  clearErrors,
  setValue,
  watch,
  reset,
  getValues,
  purchaseHasMergedListings,
  highlightTicketGroupIds,
  initialTicketGroupEditContext,
}: Omit<
  ComponentProps<typeof FormProvider<PurchaseOrderDetailsInput, unknown>>,
  'children'
> & {
  purchaseHasMergedListings?: boolean | null;
  highlightTicketGroupIds?: number[];
  initialTicketGroupEditContext?: Partial<TicketGroupEditContext>;
}) {
  const initialTicketGroupEditReason =
    initialTicketGroupEditContext?.editIntention;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const removeDeepLinkUrlPart = useRemoveDeepLinkUrlPart();
  const { posEntity: purchaseDetails } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const purchaseOrderId = watch('id');
  const vendor = watch('vendor');
  const vendorAccount = watch('vendorAccount');
  const buyerUserId = watch('buyerUserId');
  const secondaryVendorAccount = watch('secondaryVendorAccount');
  const vendorOrderId = watch('vendorOrderId');
  const purchaseDate = watch('purchaseDate');
  const ticketGroups = watch('ticketGroups');
  const updateSalesOfListings = watch('updateSalesOfListings');
  const ticketGroupIntentions = watch('ticketGroupIntentions');
  const currencyCode = watch('currencyCode');
  const { refreshData } = usePurchaseDataContext();
  const { updateItemInEvent, refreshCatalog } = useCatalogDataContext();

  const [debouncedOrderId, setDebouncedOrderId] = useState<string | null>();
  useDebounce(
    () => {
      setDebouncedOrderId(vendorOrderId?.value);
    },
    300,
    [vendorOrderId]
  );

  const { purchasingIdInUseQuery } = useGetIsPurchaseIdsInUsedForVendorId(
    debouncedOrderId,
    vendor?.value?.id
  );

  const requiredMsg = useContent(ContentId.Required);
  const duplicateSeatMsg = useContent(ContentId.DuplicateSeat);
  const orderIdUsedErrorMsg = useContent(ContentId.OrderIdInUseSameVendor);
  const eventRequiredMsg = useContent(ContentId.SearchAndSelectAnEvent);
  const inhandDateMustBeforeEventDateMsg = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );
  const autoBroadcastRequireFaceValueMsg = useContent(
    ContentId.AutoBroadcastRequiredFaceValue
  );

  const { closeModal } = useContext(ModalContext);
  const { inboundEmailId, classificationType } = useContext(
    EmailPurchaseOrderContext
  );
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig, loginContext } = useAppContext();
  const { refetch: refreshTagMetadata, tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase
  );
  const [currentStep, setCurrentStep] = useState<keyof typeof Steps>(
    purchaseOrderId < 0
      ? Steps.PurchaseVendorInfoStep
      : Steps.PurchaseWizardMainStep
  );

  useEffect(() => {
    if (purchaseOrderId > 0 && currentStep === Steps.PurchaseVendorInfoStep) {
      setCurrentStep(Steps.PurchaseWizardMainStep);
    }
  }, [currentStep, purchaseOrderId]);

  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMatchMedia('mobile');
  const { setModal } = useContext(ModalContext);
  const canCreate = useUserHasAnyOfPermissions(Permission.Purchases_Create);
  const canEdit = useUserCanUpdatePurchase(purchaseDetails);

  // if this is true - Everything in the PO is readonly
  // use this if an input can be writeable when PO is cancelled (like payments)
  const absoluteReadOnly =
    purchaseDetails?.poState === PurchaseOrderState.Void ||
    (purchaseOrderId > 0 ? !canEdit : !canCreate);

  // if this is true, either the entire PO is readonly, or only parts of it (in the case where the PO is cancelled, some parts can be Writeable - like Payments)
  // use this on a component if you know it will always be readonly whether the PO is void or cancelled
  const readOnly =
    absoluteReadOnly ||
    purchaseDetails?.poState === PurchaseOrderState.Cancelled;

  useEffect(() => {
    if (inboundEmailId && currentStep === Steps.PurchaseVendorInfoStep) {
      validateVendorInfo(
        purchaseOrderId,
        clearErrors,
        purchaseDate,
        requiredMsg,
        orderIdUsedErrorMsg,
        setError,
        vendor,
        vendorAccount,
        vendorOrderId,
        purchasingIdInUseQuery.data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inboundEmailId,
    currentStep,
    vendor,
    vendorAccount,
    vendorOrderId,
    purchasingIdInUseQuery.data,
  ]);

  const removeEventIdQueryIfExists = useCallback(() => {
    const posEventId = getDeepLinkIdFromUrl(
      EventIdQueryParam,
      window.location.href
    );

    const inFullPageEventView = isInFullPageEventView();

    if (posEventId && !inFullPageEventView) {
      removeDeepLinkUrlPart(EventIdQueryParam);
    }
  }, [removeDeepLinkUrlPart]);

  const onCloseModal = useCallback(
    (force?: boolean) => {
      removeEventIdQueryIfExists();
      removeDeepLinkUrlPart(PurchaseFromEmailPoQueryParam);
      closeModal(force);
    },
    [closeModal, removeDeepLinkUrlPart, removeEventIdQueryIfExists]
  );

  const [ticketGroupEditContext, setTicketGroupEditContext] =
    useState<TicketGroupEditContext>({
      startWithEventSearch: true,
      ticketGroupId: newBigIntId(),
      editIntention: TicketGroupEditReason.AddTickets,
      editInfo: '',
      ...initialTicketGroupEditContext,
    });
  const [ticketGroupActionParams, setTicketGroupActionParams] = useState<
    TicketGroupActionParams | undefined
  >();

  const ticketGroupIntentionDialog = useBasicDialog();
  const secondaryVendorAccountDialog = useBasicDialog();
  const updateMergedListingDialog = useBasicDialog();
  const ticketGroupCancelConfirmDialog = useBasicDialog();

  const hasPurchasePaymentConversionFeature = useUserHasFeature(
    Feature.PurchasePaymentConversion
  );

  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const onSubmit = useCallback(
    async (purchaseDetailsForm: PurchaseOrderDetailsInput) => {
      if (absoluteReadOnly) {
        // Don't save
        return;
      }

      tryInvokeApi(
        async () => {
          setIsLoading(true);

          const client = new PurchaseClient(activeAccountWebClientConfig);
          if (purchaseDetailsForm.id < 0) {
            const result = await client.insertPurchaseOrder(
              purchaseDetailsForm,
              true // isManual
            );

            const savePurchaseOrderId = result.entityId;

            if (savePurchaseOrderId == null) {
              let errorMessage = 'Failed to insert purchase order';

              if (result.message != null) {
                errorMessage += `: ${result.message}`;
              }

              showErrorDialog(
                'PurchaseClient.insertPurchaseOrder',
                {
                  message: errorMessage,
                  status: result.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
                },
                {
                  trackErrorData: purchaseDetailsForm,
                }
              );
            } else {
              if (inboundEmailId != null) {
                new AutoPoClient(
                  activeAccountWebClientConfig
                ).classifyInboundEmail(
                  inboundEmailId,
                  classificationType,
                  savePurchaseOrderId,
                  null
                );

                navigate(
                  `${getPurchaseOrderRelativeUrl(
                    savePurchaseOrderId
                  )}&fromAutoPo=true`
                );
              } else if (setActivePosEntity != null) {
                // Avoid calling navigate(getPurchaseOrderRelativeUrl()) because
                // the modal is used outside the purchases, and this will make
                // a non-wanted navigation.
                setModal(
                  getPurchaseOrderDetailsModalConfigWithDeepLink(
                    savePurchaseOrderId
                  )
                );

                // Refetch all the inventory related queries because it needs to pick up the latest created inventories/events
                // Or else we have to wait until the next refetch cycle
                queryClient.refetchQueries({
                  queryKey: [ListingCatalogQueryKey],
                  type: 'all',
                });
                queryClient.refetchQueries({
                  queryKey: [ListingCatalogQueryKey],
                  type: 'all',
                });
                queryClient.refetchQueries({
                  queryKey: [ListingCatalogMetricsQueryKey],
                  type: 'all',
                });
              }
            }
          } else {
            if (purchaseHasMergedListings) {
              updateMergedListingDialog.closeDialog();
            }

            let reloadWizard = false;

            let ticketGroupsChanged = !isEqual(
              purchaseDetailsForm.ticketGroups,
              formState.defaultValues?.ticketGroups
            );
            const costsChanged = !isEqual(
              purchaseDetailsForm.additionalCosts,
              formState.defaultValues?.additionalCosts
            );
            const paymentsChanged = !isEqual(
              purchaseDetailsForm.payments,
              formState.defaultValues?.payments
            );

            const dealChanged = !isEqual(
              purchaseDetailsForm.deal,
              formState.defaultValues?.deal
            );

            // If we have any change from ticket-groups or costs, that could affect the PO main props (total-cost, unit cost, num of tickets and ticket-groups, etc.)
            // Or if we made any changes explicitly on the PO, then call updatePurchaseOrder to update those first-class props
            if (
              ticketGroupsChanged ||
              costsChanged ||
              paymentsChanged ||
              dealChanged ||
              hasChanged(
                purchaseDetailsForm.currencyCode,
                formState.defaultValues?.currencyCode
              ) ||
              hasChanged(
                purchaseDetailsForm.buyerUserId,
                formState.defaultValues?.buyerUserId
              ) ||
              hasChanged(
                purchaseDetailsForm.purchaseDate,
                formState.defaultValues?.purchaseDate
              ) ||
              hasChanged(
                purchaseDetailsForm.vendor,
                formState.defaultValues?.vendor
              ) ||
              hasChanged(
                purchaseDetailsForm.vendorAccount,
                formState.defaultValues?.vendorAccount
              ) ||
              hasChanged(
                purchaseDetailsForm.vendorOrderId,
                formState.defaultValues?.vendorOrderId
              ) ||
              hasChanged(
                purchaseDetailsForm.secondaryVendorAccount,
                formState.defaultValues?.secondaryVendorAccount
              ) ||
              hasChanged(
                purchaseDetailsForm.transferId,
                formState.defaultValues?.transferId
              ) ||
              tagFieldHasChanges(
                purchaseDetailsForm.tags,
                (formState.defaultValues?.tags ?? {}) as PosFieldArrayOfTag,
                tagsMetadata ?? []
              ) ||
              hasChanged(
                purchaseDetailsForm.privateNotes,
                formState.defaultValues?.privateNotes
              ) ||
              hasChanged(
                purchaseDetailsForm?.commissions,
                formState.defaultValues?.commissions
              ) ||
              hasChanged(
                purchaseDetailsForm?.qcState,
                formState.defaultValues?.qcState
              ) ||
              hasChanged(
                purchaseDetailsForm?.isCommissionsLocked,
                formState.defaultValues?.isCommissionsLocked
              )
            ) {
              reloadWizard = true;
              const result =
                await client.updatePurchaseOrder(purchaseDetailsForm);

              if (result.status !== 200) {
                let errorMessage = 'Failed to update purchase order';

                if (result.message != null) {
                  errorMessage += `: ${result.message}`;
                }

                showErrorDialog(
                  'PurchaseClient.updatePurchaseOrder',
                  { message: errorMessage, status: result.status },
                  {
                    trackErrorData: {
                      purchaseDetailsForm,
                      result,
                    },
                  }
                );
                return;
              }
            }

            if (ticketGroupsChanged || paymentsChanged || costsChanged) {
              reloadWizard = true;

              const tgIntentionsMap = getValues('ticketGroupIntentions');
              if (
                (paymentsChanged || costsChanged) &&
                hasPurchasePaymentConversionFeature
              ) {
                ticketGroups.forEach(({ ticketGroupId }) => {
                  if (!tgIntentionsMap[ticketGroupId]?.reason) {
                    tgIntentionsMap[ticketGroupId] = {
                      ticketGroupId: ticketGroupId,
                      reason: TicketGroupEditReason.AmendTickets,
                      additionalInfo: 'Update payment and cost',
                    };
                  }
                });
              }

              // New ad groups (negative ids) can be edited/ after being added, so we want to change it back to add
              // so the backend can handle it properly
              // We don't care about the Cancel intention because it will result in no-op
              const intentions = Object.values(tgIntentionsMap).map((tgi) => {
                if (
                  tgi.ticketGroupId < 0 &&
                  tgi.reason === TicketGroupEditReason.AmendTickets
                ) {
                  tgi.reason = TicketGroupEditReason.AddTickets;
                }

                return tgi;
              });
              ticketGroupsChanged = await new TicketClient(
                activeAccountWebClientConfig
              ).mergeTicketGroups(
                {
                  purchaseOrderId: purchaseDetailsForm.id,
                  intentions: intentions,
                  ticketGroups: purchaseDetailsForm.ticketGroups,
                  updateSalesOfListings:
                    purchaseDetailsForm.updateSalesOfListings,
                  updatePredeliveryETicketArtifacts:
                    purchaseDetailsForm.updatePredeliveryETicketArtifacts,
                } as TicketGroupMergeInput,
                true // isManual
              );

              // If the ticket group is the same as the one we are editing, we need to update the context
              if (
                purchaseOrderId > 0 &&
                updateItemInEvent != null &&
                intentions.length === 1 &&
                highlightTicketGroupIds?.length === 1
              ) {
                if (
                  highlightTicketGroupIds[0] === intentions[0].ticketGroupId
                ) {
                  const purchaseOrderDetails =
                    await client.getPurchaseOrderByPurchaseOrderId(
                      purchaseOrderId,
                      false
                    );
                  if (purchaseOrderDetails) {
                    updateItemInEvent(
                      {
                        ...purchaseOrderDetails,
                        ...purchaseOrderDetails.ticketGroups[0],
                        ticketGroupItems: [],
                        isTktGrp: true,
                        id: purchaseOrderDetails.id,
                        tgId: purchaseOrderDetails.ticketGroups[0].id,
                        isMultEv: false,
                      } as PurchaseOrderTicketGroup,
                      ActionOutboxEntityType.Purchase
                    );
                  }
                }
              }
            }

            if (reloadWizard) {
              // Refresh the active data so the SaleDetail dialog and table will have the new content
              setActivePosEntity(purchaseDetailsForm.id, null, true);
              reset(purchaseDetailsForm);
              // Update the tags metadata
              refreshTagMetadata();
            }
          }

          await refreshCatalog();

          refreshData(true);
        },
        (error) => {
          showErrorDialog(
            purchaseDetailsForm.id < 0
              ? 'PurchaseClient.insertPurchaseOrder'
              : 'PurchaseClient.updatePurchaseOrder',
            error,
            {
              trackErrorData: purchaseDetailsForm,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      absoluteReadOnly,
      activeAccountWebClientConfig,
      refreshCatalog,
      refreshData,
      showErrorDialog,
      inboundEmailId,
      setActivePosEntity,
      classificationType,
      navigate,
      setModal,
      queryClient,
      purchaseHasMergedListings,
      formState.defaultValues?.ticketGroups,
      formState.defaultValues?.additionalCosts,
      formState.defaultValues?.payments,
      formState.defaultValues?.deal,
      formState.defaultValues?.currencyCode,
      formState.defaultValues?.buyerUserId,
      formState.defaultValues?.purchaseDate,
      formState.defaultValues?.vendor,
      formState.defaultValues?.vendorAccount,
      formState.defaultValues?.vendorOrderId,
      formState.defaultValues?.secondaryVendorAccount,
      formState.defaultValues?.transferId,
      formState.defaultValues?.tags,
      formState.defaultValues?.privateNotes,
      formState.defaultValues?.commissions,
      formState.defaultValues?.qcState,
      formState.defaultValues?.isCommissionsLocked,
      tagsMetadata,
      updateMergedListingDialog,
      getValues,
      hasPurchasePaymentConversionFeature,
      purchaseOrderId,
      updateItemInEvent,
      highlightTicketGroupIds,
      ticketGroups,
      reset,
      refreshTagMetadata,
    ]
  );

  const onNextFromVendorInfoStep = useCallback(async () => {
    if (
      validateVendorInfo(
        purchaseOrderId,
        clearErrors,
        purchaseDate,
        requiredMsg,
        orderIdUsedErrorMsg,
        setError,
        vendor,
        vendorAccount,
        vendorOrderId,
        purchasingIdInUseQuery.data
      )
    ) {
      const viagVirtualId =
        purchaseOrderId < 0 &&
        getDeepLinkIdFromUrl(EventIdQueryParam, window.location.href);

      let additionalData = undefined;
      if (viagVirtualId) {
        // We have a viagVirtualId specified - we should go get that Event before moving on with the add
        const catalog = await new CatalogClient(
          activeAccountWebClientConfig
        ).getCatalogForPosEventIds([viagVirtualId]);

        const { event, venue, performer } = getEventPerformerVenue(
          viagVirtualId,
          catalog
        );
        if (event) {
          additionalData = {
            event: event.event,
            performer,
            venue,
          };
        }
      }

      setTicketGroupEditContext({
        startWithEventSearch: !additionalData,
        ticketGroupId: newBigIntId(),
        editIntention: TicketGroupEditReason.AddTickets,
        editInfo: '',
        additionalData: additionalData,
      });
      setCurrentStep(Steps.TicketGroupInputStep);
    }
  }, [
    activeAccountWebClientConfig,
    clearErrors,
    orderIdUsedErrorMsg,
    purchaseOrderId,
    purchaseDate,
    purchasingIdInUseQuery.data,
    requiredMsg,
    setError,
    vendor,
    vendorAccount,
    vendorOrderId,
  ]);

  const {
    launchDialog: launchInternationalComplianceDialog,
    closeDialog: closeInternationalComplianceDialog,
    dialogProps: internationalComplianceDialogProps,
  } = useBasicDialog();

  const internationalComplianceHookProps = useInternationalComplianceDialog(
    () => {
      closeInternationalComplianceDialog();
      handleSubmit(onSubmit)();
    }
  );

  const onNextFromPurchaseWizardMainStep = useCallback(() => {
    if (
      validateVendorInfo(
        purchaseOrderId,
        clearErrors,
        purchaseDate,
        requiredMsg,
        orderIdUsedErrorMsg,
        setError,
        vendor,
        vendorAccount,
        vendorOrderId,
        purchasingIdInUseQuery.data
      ) &&
      validateEventSelection(
        clearErrors,
        setError,
        ticketGroups,
        eventRequiredMsg
      ) &&
      validateTicketGroups(
        false,
        clearErrors,
        setError,
        ticketGroups,
        requiredMsg,
        duplicateSeatMsg,
        inhandDateMustBeforeEventDateMsg,
        autoBroadcastRequireFaceValueMsg
      )
    ) {
      // Find any ticket group that is new and are for events not in US
      const internationalNewTgs = ticketGroups.filter(
        (tg) =>
          tg.ticketGroupId < 0 &&
          !US_COUNTRY_CODES.includes(
            tg.venue?.country?.code?.toUpperCase() ?? ''
          )
      );

      if (
        // If we have any, and there is a settings that last time we used it compare to now is more than 24 hrs, ask again
        internationalNewTgs.length > 0 &&
        isDatePassedHours(
          internationalComplianceHookProps.value?.doNotAskAgainTimestamp,
          24
        )
      ) {
        launchInternationalComplianceDialog();
      } else {
        if (purchaseHasMergedListings) {
          updateMergedListingDialog.launchDialog();
        } else {
          handleSubmit(onSubmit)();
        }
      }
    }
  }, [
    purchaseOrderId,
    clearErrors,
    purchaseDate,
    requiredMsg,
    orderIdUsedErrorMsg,
    setError,
    vendor,
    vendorAccount,
    vendorOrderId,
    purchasingIdInUseQuery.data,
    ticketGroups,
    eventRequiredMsg,
    duplicateSeatMsg,
    inhandDateMustBeforeEventDateMsg,
    autoBroadcastRequireFaceValueMsg,
    internationalComplianceHookProps.value?.doNotAskAgainTimestamp,
    launchInternationalComplianceDialog,
    purchaseHasMergedListings,
    updateMergedListingDialog,
    handleSubmit,
    onSubmit,
  ]);

  const handleTicketGroupEdit = useCallback(
    (intention: TicketGroupIntention, tgContext: TicketGroupEditContext) => {
      if (intention.reason === TicketGroupEditReason.CancelTickets) {
        const newTgs = ticketGroups.filter(
          (tg) => tg.ticketGroupId !== tgContext.ticketGroupId
        );

        setValue('ticketGroups', newTgs);

        ticketGroupIntentions[intention.ticketGroupId.toString()] = intention;
        setValue('ticketGroupIntentions', { ...ticketGroupIntentions });
      } else if (intention.reason === TicketGroupEditReason.AmendTickets) {
        setTicketGroupEditContext({
          ...tgContext,
          editInfo: intention.additionalInfo,
        });

        // We just want to edit - go straight there
        setCurrentStep(Steps.TicketGroupInputStep);
      } else if (AddActions.includes(intention.reason)) {
        // Adding a new ticket-groups for the current-event
        // If it's an exchange, on Submit of the PurchaseTicketFlow, the current ticket group will be deleted
        const { additionalData } = tgContext;
        if (additionalData?.event) {
          setTicketGroupEditContext({
            ticketGroupId: intention.ticketGroupId,
            startWithEventSearch: false,
            ticketGroupIdToRemove: tgContext.ticketGroupIdToRemove,
            editIntention: intention.reason,
            additionalData,
            editInfo: intention.additionalInfo,
          });
        } else {
          setTicketGroupEditContext({
            startWithEventSearch: true,
            ticketGroupId: newBigIntId(),
            editIntention: TicketGroupEditReason.AddTickets,
            editInfo: intention.additionalInfo,
          });
        }

        // We just want to edit (but keep fields not pre-filled) - go straight there
        setCurrentStep(Steps.TicketGroupBlankInputStep);
      }

      ticketGroupIntentionDialog.closeDialog();
    },
    [setValue, ticketGroupIntentionDialog, ticketGroupIntentions, ticketGroups]
  );

  const onTicketGroupIntentionDialogSave = useCallback(
    (intention: TicketGroupIntention) => {
      cleanTicketGroupContextFromDeepLink(PurchaseDeeplinkQueryParam);

      handleTicketGroupEdit(intention, ticketGroupEditContext);

      ticketGroupIntentionDialog.closeDialog();
    },
    [handleTicketGroupEdit, ticketGroupEditContext, ticketGroupIntentionDialog]
  );

  const isDisabledAbsolutely =
    absoluteReadOnly || isLoading || formState.isSubmitting;

  const isDisabled = readOnly || isLoading || formState.isSubmitting;

  const onPurchaseTicketsFlowSubmit = useCallback(
    (formData: PurchaseTicketsInput) => {
      if (!isDisabled) {
        const newTicketGroups = formData.ticketGroups;
        let finalTicketGroups = [] as TicketGroupInput[];
        if (
          ticketGroupEditContext.editIntention ===
          TicketGroupEditReason.AddTickets
        ) {
          finalTicketGroups = [...(ticketGroups || []), ...newTicketGroups];
          newTicketGroups.forEach((tg) => {
            ticketGroupIntentions[tg.ticketGroupId.toString()] = {
              ticketGroupId: tg.ticketGroupId,
              reason: ticketGroupEditContext.editIntention,
              additionalInfo: ticketGroupEditContext.editInfo,
            };
          });
        } else if (
          ticketGroupEditContext.editIntention ===
          TicketGroupEditReason.AmendTickets
        ) {
          const index = ticketGroups.findIndex(
            (tg) => tg.ticketGroupId === ticketGroupEditContext.ticketGroupId
          );
          const editedTicketGroup = newTicketGroups[0];
          finalTicketGroups = [...(ticketGroups || [])];
          finalTicketGroups[index] = editedTicketGroup;

          ticketGroupIntentions[editedTicketGroup.ticketGroupId.toString()] = {
            ticketGroupId: editedTicketGroup.ticketGroupId,
            reason: ticketGroupEditContext.editIntention,
            additionalInfo: ticketGroupEditContext.editInfo,
          };
        } else if (
          ticketGroupEditContext.editIntention ===
          TicketGroupEditReason.ExchangeTickets
        ) {
          const ticketGroupsWithoutTheRemoved = (finalTicketGroups =
            ticketGroups.filter(
              (tg) =>
                tg.ticketGroupId !==
                ticketGroupEditContext.ticketGroupIdToRemove
            ));
          finalTicketGroups = [
            ...ticketGroupsWithoutTheRemoved,
            ...newTicketGroups,
          ];

          ticketGroupIntentions[
            ticketGroupEditContext.ticketGroupIdToRemove!.toString()
          ] = {
            ticketGroupId: ticketGroupEditContext.ticketGroupIdToRemove!,
            reason: ticketGroupEditContext.editIntention,
            additionalInfo: ticketGroupEditContext.editInfo,
          };

          newTicketGroups.forEach((tg) => {
            ticketGroupIntentions[tg.ticketGroupId.toString()] = {
              ticketGroupId: tg.ticketGroupId,
              reason: ticketGroupEditContext.editIntention,
              additionalInfo: ticketGroupEditContext.editInfo,
            };
          });
        }

        setValue('ticketGroups', finalTicketGroups);
        setValue('ticketGroupIntentions', { ...ticketGroupIntentions });
        setValue('updateSalesOfListings', formData.updateSalesOfListings);
        setValue(
          'updatePredeliveryETicketArtifacts',
          formData.updatePredeliveryETicketArtifacts
        );
      }
      setCurrentStep(Steps.PurchaseWizardMainStep);
      cleanTicketGroupContextFromDeepLink(PurchaseDeeplinkQueryParam);
    },
    [
      isDisabled,
      ticketGroupEditContext,
      setValue,
      ticketGroupIntentions,
      ticketGroups,
    ]
  );

  const onPurchaseTicketsFlowCancel = useCallback(() => {
    setCurrentStep(Steps.PurchaseWizardMainStep);
    cleanTicketGroupContextFromDeepLink(PurchaseDeeplinkQueryParam);
  }, []);

  const getFooterActions = useCallback((): PurchaseWizardFooterProps => {
    const hasChanges = !isEqual(formState.defaultValues, getValues());
    switch (currentStep) {
      case Steps.PurchaseVendorInfoStep:
        return {
          onPreviousLabel: hasChanges ? ContentId.Cancel : ContentId.Close,
          onPrevious: onCloseModal,
          onNextLabel: ContentId.Next,
          onNext: onNextFromVendorInfoStep,
        };
      case Steps.PurchaseWizardMainStep:
        return {
          onPreviousLabel: hasChanges ? ContentId.Cancel : ContentId.Close,
          onPrevious: onCloseModal, // Once we're on the main page, going back means cancelling
          onNextLabel:
            purchaseOrderId < 0 || hasChanges ? ContentId.Save : undefined,
          onNext:
            purchaseOrderId < 0 || hasChanges
              ? onNextFromPurchaseWizardMainStep
              : undefined,
        };
      case Steps.TicketGroupInputStep:
      case Steps.TicketGroupBlankInputStep:
        return {
          onPreviousLabel: undefined,
          onPrevious: undefined,
          onNextLabel: undefined,
          onNext: undefined,
        };
    }
  }, [
    formState.defaultValues,
    getValues,
    currentStep,
    onCloseModal,
    onNextFromVendorInfoStep,
    purchaseOrderId,
    onNextFromPurchaseWizardMainStep,
  ]);
  const footerActions = getFooterActions();

  const getTicketGroupsForEditing = useCallback((): TicketGroupInput[] => {
    if (ticketGroupEditContext.startWithEventSearch) {
      // If we want to start with search, then we want to create new ticket groups for new event
      // so just return nothing here
      return [];
    }

    const existingTicketGroup = ticketGroups?.find(
      (tg) => tg.ticketGroupId === ticketGroupEditContext.ticketGroupId
    );

    if (existingTicketGroup) {
      return [existingTicketGroup];
    }

    // Return a new one that is copy of an existing one if exist for the same event
    const existingTgs = (ticketGroups ?? [])?.filter(
      (tg) =>
        tg.viagogoVirtualId ===
          ticketGroupEditContext.additionalData!.event!.viagVirtualId ||
        tg.event?.viagVirtualId ===
          ticketGroupEditContext.additionalData!.event!.viagVirtualId
    );
    return [
      {
        ...(existingTgs.length ? existingTgs[existingTgs.length - 1] : {}),
        ticketGroupId: ticketGroupEditContext.ticketGroupId,
        viagogoVirtualId:
          ticketGroupEditContext.additionalData!.event?.viagVirtualId,
        viagogoEventId: ticketGroupEditContext.additionalData!.event?.viagId,
        eventMappingId: ticketGroupEditContext.additionalData!.event?.mappingId,
        posEventId: ticketGroupEditContext.additionalData!.event?.posId,
        deliveryType: posChangedField(DeliveryType.InApp),
        event: ticketGroupEditContext.additionalData!.event,
        performer: ticketGroupEditContext.additionalData!.performer,
        venue: ticketGroupEditContext.additionalData!.venue,
        currencyCode: posChangedField(currencyCode?.value),
      } as TicketGroupInput,
    ];
  }, [
    currencyCode?.value,
    ticketGroupEditContext.additionalData,
    ticketGroupEditContext.startWithEventSearch,
    ticketGroupEditContext.ticketGroupId,
    ticketGroups,
  ]);

  const emailPreview = useMemo(() => {
    return inboundEmailId != null && !isMobile ? (
      <EmailPreviewContainer>
        <EmailPreviewTitle>
          <Content id={ContentId.EmailPreview} />
        </EmailPreviewTitle>
        <EmailBodyWrapper>
          <EmailBody inboundEmailId={inboundEmailId} />
        </EmailBodyWrapper>
      </EmailPreviewContainer>
    ) : null;
  }, [inboundEmailId, isMobile]);

  const onTicketGroupAction = useCallback(
    ({ ticketGroupId, action, additionalData }: TicketGroupActionParams) => {
      const newTGNav: TicketGroupEditContext = {
        startWithEventSearch: false,
        ticketGroupId: AddActions.includes(action)
          ? newBigIntId()
          : ticketGroupId,
        editIntention: action,
        editInfo: '',
        additionalData: additionalData,
        ticketGroupIdToRemove:
          action === TicketGroupEditReason.ExchangeTickets
            ? ticketGroupId
            : undefined,
      };

      setTicketGroupEditContext(newTGNav);
      if (
        isDisabled ||
        purchaseOrderId < 0 ||
        (ticketGroupId < 0 && action !== TicketGroupEditReason.AddTickets) ||
        additionalData?.amendActionData?.uploadPredeliveryAction
      ) {
        // If this is a new order, or
        // Cancelling or Amending a newly added ticket, or
        // it's amend dialog for uploading artifacts
        // should just do it without launching the dialog
        handleTicketGroupEdit(
          {
            ticketGroupId: newTGNav.ticketGroupId,
            reason: action,
            additionalInfo: '',
          },
          newTGNav
        );
      } else {
        ticketGroupIntentionDialog.launchDialog();
      }
    },
    [
      handleTicketGroupEdit,
      isDisabled,
      purchaseOrderId,
      ticketGroupIntentionDialog,
    ]
  );

  const cancelTicketGroupHookProps = useCancelTicketGroupDialog(
    () => {
      if (ticketGroupActionParams) {
        onTicketGroupAction(ticketGroupActionParams);
        setTicketGroupActionParams(undefined);
        ticketGroupCancelConfirmDialog.closeDialog();
      }
    },
    () => {
      setTicketGroupActionParams(undefined);
      ticketGroupCancelConfirmDialog.closeDialog();
    }
  );

  const onTicketGroupActionAttempt = useCallback(
    (
      { ticketGroupId, action, additionalData }: TicketGroupActionParams,
      showConfirmationDialog?: boolean
    ) => {
      if (
        showConfirmationDialog &&
        isDatePassedHours(cancelTicketGroupHookProps.lastTimeStamp, 1)
      ) {
        setTicketGroupActionParams({ ticketGroupId, action, additionalData });
        ticketGroupCancelConfirmDialog.launchDialog();
        return;
      }

      onTicketGroupAction({ ticketGroupId, action, additionalData });
    },
    [
      cancelTicketGroupHookProps.lastTimeStamp,
      onTicketGroupAction,
      ticketGroupCancelConfirmDialog,
    ]
  );

  const saleIds = useMemo(() => {
    const ticketGroup =
      ticketGroupEditContext.ticketGroupId >= 0
        ? ticketGroups.find(
            (tg) => tg.ticketGroupId === ticketGroupEditContext.ticketGroupId
          )
        : undefined;
    return new Set(
      ticketGroup?.tickets?.value
        ?.filter((t) => t.saleId)
        ?.map((t) => t.saleId!) || []
    );
  }, [ticketGroupEditContext.ticketGroupId, ticketGroups]);

  useEffect(() => {
    if (highlightTicketGroupIds?.length === 1 && initialTicketGroupEditReason) {
      onTicketGroupAction({
        ticketGroupId: highlightTicketGroupIds[0],
        action: initialTicketGroupEditReason,
        additionalData: initialTicketGroupEditContext?.additionalData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ticketGroupFromPartialPurchaseOrder = watch(
    'ticketGroupFromPartialPurchaseOrder'
  );
  const eventSearchTextFromPartialPurchaseOrder = watch(
    'eventSearchTextFromPartialPurchaseOrder'
  );

  if (TicketGroupInputSteps.includes(currentStep)) {
    return (
      <PurchaseTicketsFlow
        disabled={isDisabled}
        currencyCode={
          currencyCode?.value ??
          loginContext?.user?.activeAccount?.currencyCode ??
          'USD'
        }
        purchaseOrderInfo={{
          vendor: vendor?.value ?? null,
          vendAcc: vendorAccount?.value ?? null,
          id: purchaseOrderId,
          buyerId: buyerUserId?.value ?? null,
        }}
        ticketGroups={getTicketGroupsForEditing()}
        ticketGroupFromPartialPurchaseOrder={
          ticketGroupFromPartialPurchaseOrder
        }
        eventSearchTextFromPartialPurchaseOrder={
          eventSearchTextFromPartialPurchaseOrder
        }
        avoidPrefill={currentStep === Steps.TicketGroupBlankInputStep}
        startIndex={ticketGroupEditContext.startWithEventSearch ? -1 : 0}
        onCancel={onPurchaseTicketsFlowCancel}
        onSubmit={onPurchaseTicketsFlowSubmit}
        emailPreview={emailPreview}
        updateSalesOfListings={updateSalesOfListings}
        uploadPredeliveryAction={
          ticketGroupEditContext.additionalData?.amendActionData
            ?.uploadPredeliveryAction
        }
      />
    );
  }

  return (
    <StyledPurchaseWizardBody>
      <CancellableFormHeader
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={!isEqual(formState.defaultValues, getValues())}
        onClose={() => onCloseModal(true)}
      >
        <EventEntityHeader
          title={
            <Content
              id={
                currentStep === Steps.PurchaseVendorInfoStep
                  ? ContentId.WhereTicketsWerePurchased
                  : ContentId.ViewDetails
              }
            />
          }
        />
      </CancellableFormHeader>
      <Stack
        direction="row"
        style={{ height: '100%', width: '100%', overflowX: 'auto' }}
      >
        {!isLoading && emailPreview}
        <ModalBody>
          {currentStep === Steps.PurchaseVendorInfoStep && (
            <ModalBodyHeaderContainer>
              {/* this is only for add - which means there are no sales or listings */}
              <Stack direction="column" gap="xl">
                <PurchaseVendorHeader
                  editMode={true}
                  disabled={isDisabled}
                  numOfSales={0}
                  numOfListings={0}
                />
                <DealSection />
              </Stack>
            </ModalBodyHeaderContainer>
          )}
          {isLoading ? (
            <PosSpinner />
          ) : (
            currentStep === Steps.PurchaseWizardMainStep && (
              <PurchaseMainDetails
                disabled={isDisabled}
                allowPaymentEdits={!isDisabledAbsolutely}
                onSecondaryAccountOpen={
                  secondaryVendorAccountDialog.launchDialog
                }
                onTicketGroupAction={onTicketGroupActionAttempt}
                highlightTicketGroupIds={highlightTicketGroupIds}
              />
            )
          )}
        </ModalBody>
      </Stack>
      <PurchaseWizardFooter
        {...footerActions}
        disabled={
          absoluteReadOnly ||
          (currentStep === Steps.PurchaseVendorInfoStep &&
            purchasingIdInUseQuery.isLoading)
        }
        isSubmitting={isLoading || formState.isSubmitting}
      />
      <TicketGroupIntentionDialog
        {...ticketGroupIntentionDialog.dialogProps}
        salesCount={saleIds.size}
        intention={{
          ticketGroupId: ticketGroupEditContext.ticketGroupId,
          reason: ticketGroupEditContext.editIntention,
          additionalInfo:
            ticketGroupIntentions[ticketGroupEditContext.ticketGroupId]
              ?.additionalInfo ?? '',
        }}
        onClosed={() => {
          cleanTicketGroupContextFromDeepLink(PurchaseDeeplinkQueryParam);
          ticketGroupIntentionDialog.closeDialog();
        }}
        onSave={onTicketGroupIntentionDialogSave}
      />
      {vendorAccount && (
        <SecondaryVendorAccountDialog
          {...secondaryVendorAccountDialog.dialogProps}
          onCancel={secondaryVendorAccountDialog.closeDialog}
          onSave={(secondaryVendor, secondaryVendorAccount) => {
            setValue(
              'secondaryVendor',
              posChangedField(secondaryVendor ?? null)
            );
            setValue(
              'secondaryVendorAccount',
              posChangedField(secondaryVendorAccount ?? null)
            );
            secondaryVendorAccountDialog.closeDialog();
          }}
          vendorAccountFrom={vendorAccount.value}
          vendorAccountTo={secondaryVendorAccount?.value ?? null}
        />
      )}
      <InternationalComplianceDialog
        {...internationalComplianceDialogProps}
        {...internationalComplianceHookProps}
      />
      <ConfirmDialog
        {...updateMergedListingDialog.dialogProps}
        size="lg"
        headerText={<Content id={ContentId.UpdatePurchaseWithMergedListing} />}
        bodyText={
          <Stack direction="column" gap="l">
            <span>
              <Content id={ContentId.UpdatePurchaseWithMergedListingWarning} />
            </span>
            <span>
              <Content id={ContentId.AreYouSure} />
            </span>
          </Stack>
        }
        onOkay={handleSubmit(onSubmit)}
        disabled={isLoading}
        onCancel={() => updateMergedListingDialog.closeDialog()}
      />
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.CancelTickets} />}
        size="md"
        {...ticketGroupCancelConfirmDialog.dialogProps}
        alerts={[cancelTicketGroupHookProps]}
        onOkay={() => {
          if (ticketGroupActionParams) {
            onTicketGroupAction(ticketGroupActionParams);
            setTicketGroupActionParams(undefined);
            ticketGroupCancelConfirmDialog.closeDialog();
          }
        }}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        onCancel={() => {
          setTicketGroupActionParams(undefined);
          ticketGroupCancelConfirmDialog.closeDialog();
        }}
      />
    </StyledPurchaseWizardBody>
  );
}
