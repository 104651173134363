import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import React from 'react';
import { vars } from 'src/core/themes';
import { ThemeColors } from 'src/core/themes/legacy/theme-types';
import styled from 'styled-components';

import { IconsFill } from '../enums';

type WrapperPropType = {
  size?: string;
  fill?: IconsFill | string;
  title?: string;
  disabled?: boolean;
  align?: 'initial' | 'top' | 'bottom' | 'middle';
  withHoverEffect?: boolean;
  children: JSX.Element | JSX.Element[];
  onClick?: React.MouseEventHandler<SVGElement>;
  onMouseDown?: React.MouseEventHandler<SVGElement>;
  onMouseEnter?: React.MouseEventHandler<SVGElement>;
  onMouseLeave?: React.MouseEventHandler<SVGElement>;
  role?: React.AriaRole;
  ariaHidden?: React.AriaAttributes['aria-hidden'];
  className?: string;
  color?: string;
  stroke?: string;
} & Pick<React.SVGProps<SVGSVGElement>, 'aria-label'>;

export const SvgWrapper = ({
  children,
  size = vars.iconSize.l,
  fill = IconsFill.textPrimary,
  title,
  disabled = false,
  align = 'initial',
  withHoverEffect = false,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  role,
  ariaHidden,
  className,
  color,
  stroke,
  ...props
}: WrapperPropType) => {
  const newUniqueId = uniqueId();

  return (
    <StyledSvg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      role={role ?? 'img'}
      aria-labelledby={title}
      aria-hidden={ariaHidden}
      width={24}
      height={24}
      fill={fill}
      stroke={stroke}
      xlinkTitle={title}
      withHoverEffect={withHoverEffect}
      disabled={disabled}
      align={align}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={clsx(`custom-sizing-${newUniqueId}`, className)}
      {...props}
    >
      {size && (
        <defs>
          <style type="text/css">{`.custom-sizing-${newUniqueId} {width: ${size}; height: ${size};}`}</style>
        </defs>
      )}
      {children}
      {title && <title>{title}</title>}
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{
  withHoverEffect: boolean;
  disabled: boolean;
  align: string;
}>`
  fill: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.textDisabled;
    }
    if (props.fill === 'currentColor') {
      return 'currentColor';
    }
    return props.theme.colors[props.fill as keyof ThemeColors];
  }};
  cursor: ${(props) => (props.withHoverEffect ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

  &:hover {
    fill: ${(props) => {
      if (props.withHoverEffect && props.fill === 'textPrimary') {
        return props.theme.colors.textPrimaryHover;
      }
      if (props.withHoverEffect && props.fill === 'textBrand') {
        return props.theme.colors.textBrandHover;
      }
      return props.theme.colors[props.fill as keyof ThemeColors];
    }};
  }

  vertical-align: ${(props) => props.align};
`;
