import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { ReportBuilderDialog } from 'src/dialogs/ReportBuilderDialog';
import { ReportHistoryDialog } from 'src/dialogs/ReportHistoryDialog';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ReportsTableContainer } from 'src/tables/ReportsTable/ReportsTableContainer';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypes } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Permission, ReportGroupBy } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Reports.css';

const DEFAULT_GROUP_BY_INVENTORY = ReportGroupBy.ListingId;
const DEFAULT_GROUP_BY_SALE = ReportGroupBy.SaleId;

const emptyReport: ReportConfig = {
  schemaVersion: '1',
  reportId: null,
  reportName: '',
  reportType: ReportTypes.Inventory,
  ownerDisplayName: '',
  widgets: null,
  groupBy: ReportGroupBy.Performer,
  filter: null,
  metrics: [],
  numberOfItemsPerPage: null,
  sortBy: null,
  isSortDescending: null,
  globalReportTypeId: null,
  isOwner: true,
  roleIdsToShare: [],
  sellerUserIdsToShare: [],
  allowReaderEditMetrics: false,
  hasFilterOverride: null,
  hasMetricsOverride: null,
  arAgingTimeBuckets: null,
  viewableFilterItemIds: null,
  editableFilterItemIds: null,
  hiddenFilterItemIds: null,
};

export function Reports() {
  const reportsTitle = useContent(ContentId.Reports);
  const canCreateReport = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );
  const navigate = useNavigate();
  const { loginContext } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [currentReport, setCurrentReport] = useState<ReportConfig>();
  const reportBuilderDialogInventory = useBasicDialog();
  const reportBuilderDialogSale = useBasicDialog();
  const shareDialog = useBasicDialog();

  const deleteDialog = useBasicDialog();
  const reportHistoryDialog = useBasicDialog();

  const onAddNewReport = useCallback(
    (reportType: ReportTypes) => {
      const defaultMetrics =
        reportType === ReportTypes.Inventory
          ? [GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING[DEFAULT_GROUP_BY_INVENTORY]]
          : [GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES[DEFAULT_GROUP_BY_SALE]];

      setCurrentReport({
        ...emptyReport,
        reportType,
        ownerDisplayName: loginContext?.user?.displayName ?? '',
        groupBy:
          reportType === ReportTypes.Inventory
            ? DEFAULT_GROUP_BY_INVENTORY
            : DEFAULT_GROUP_BY_SALE,
        metrics: defaultMetrics,
      });

      if (reportType === ReportTypes.Inventory) {
        reportBuilderDialogInventory.launchDialog();
      } else if (reportType === ReportTypes.Sale) {
        reportBuilderDialogSale.launchDialog();
      }
    },
    [
      loginContext?.user?.displayName,
      reportBuilderDialogInventory,
      reportBuilderDialogSale,
    ]
  );

  const {
    deleteReportConfig: deleteReportConfigInventory,
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });

  const {
    deleteReportConfig: deleteReportConfigSale,
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigs<ReportTypes.Sale>({
    reportType: ReportTypes.Sale,
  });

  const invokeUpsertReport = useCallback(
    async (report: ReportConfig) => {
      await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypes.Inventory) {
            upsertReportConfigInventory(report.reportId, report);
          } else if (report.reportType === ReportTypes.Sale) {
            upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportClient.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...currentReport!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await invokeUpsertReport(updatedReport);
      shareDialog.closeDialog();
    },
    [currentReport, invokeUpsertReport, shareDialog]
  );

  const onReportSaveSubmit = useCallback(
    async (report: ReportConfig) => {
      await invokeUpsertReport(report);
      if (report.reportType === ReportTypes.Inventory) {
        reportBuilderDialogInventory.closeDialog();
      } else if (report.reportType === ReportTypes.Sale) {
        reportBuilderDialogSale.closeDialog();
      }
    },
    [reportBuilderDialogInventory, reportBuilderDialogSale, invokeUpsertReport]
  );

  const onReportAction = (
    reportConfig: ReportConfig,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => {
    switch (actionType) {
      case GridActionType.Select:
        if (reportConfig.reportId) {
          const navUrl =
            reportConfig.reportType === ReportTypes.Inventory
              ? `/reports/inventory/${reportConfig.reportId}`
              : `/reports/sale/${reportConfig.reportId}`;
          if (event?.metaKey || event?.ctrlKey) {
            // Open in new tab when holding down the meta/control key
            window.open(navUrl, '_blank', 'ref=noopener noreferrer');
          } else {
            navigate(navUrl);
          }
        }
        break;
      case GridActionType.Edit:
        setCurrentReport(reportConfig);
        if (reportConfig.reportType === ReportTypes.Inventory) {
          reportBuilderDialogInventory.launchDialog();
        } else if (reportConfig.reportType === ReportTypes.Sale) {
          reportBuilderDialogSale.launchDialog();
        }
        break;
      case GridActionType.Delete:
        setCurrentReport(reportConfig);
        deleteDialog.launchDialog();
        break;
      case GridActionType.Share:
        setCurrentReport(reportConfig);
        shareDialog.launchDialog();
        break;
      case GridActionType.Duplicate:
        invokeUpsertReport({
          ...reportConfig,
          reportId: null,
          reportName:
            reportConfig!.reportType === ReportTypes.Inventory
              ? getReportConfigNameForDuplicateInventory(
                  reportConfig.reportName
                )
              : getReportConfigNameForDuplicateSale(reportConfig!.reportName),
          isOwner: true,
          globalReportTypeId: null,
        });
        break;
      case GridActionType.History:
        setCurrentReport(reportConfig);
        reportHistoryDialog.launchDialog();
        break;
      default:
        break;
    }
  };

  const onReportDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        if (currentReport) {
          if (currentReport.reportType === ReportTypes.Inventory) {
            deleteReportConfigInventory(currentReport.reportId!);
          } else if (currentReport.reportType === ReportTypes.Sale) {
            deleteReportConfigSale(currentReport.reportId!);
          }
        }
        deleteDialog.closeDialog();
      },
      (error) => {
        showErrorDialog('UserSetting.deleteReport', error, {
          trackErrorData: currentReport,
        });
      }
    );
  }, [
    currentReport,
    deleteDialog,
    deleteReportConfigInventory,
    deleteReportConfigSale,
    showErrorDialog,
  ]);

  return (
    <LayoutContent
      mainRoute={MainRoute.Reports}
      routeTitle={reportsTitle}
      rightContent={
        canCreateReport && (
          <PosDropdown
            key="create-report-dropdown"
            trigger={
              <Button style={{ height: 'min-content', width: '100%' }}>
                <Content id={ContentId.CreateNewReport} />
              </Button>
            }
            align="end"
          >
            <PosDropdownItem
              key="AddInventoryReport"
              onClick={() => onAddNewReport(ReportTypes.Inventory)}
            >
              <Content id={ContentId.CreateNewInventoryReport} />
            </PosDropdownItem>
            <PosDropdownItem
              key="AddSalesReport"
              onClick={() => onAddNewReport(ReportTypes.Sale)}
            >
              <Content id={ContentId.CreateNewSaleReport} />
            </PosDropdownItem>
          </PosDropdown>
        )
      }
    >
      <ReportsPage onReportAction={onReportAction} />

      {currentReport && (
        <>
          <ReportBuilderDialog<ReportTypes.Inventory>
            {...reportBuilderDialogInventory.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogInventory.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ReportBuilderDialog<ReportTypes.Sale>
            {...reportBuilderDialogSale.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogSale.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ShareReportDialog
            {...shareDialog.dialogProps}
            reportName={currentReport?.reportName ?? ''}
            roleIdsShared={currentReport?.roleIdsToShare ?? []}
            sellerUserIdsShared={currentReport?.sellerUserIdsToShare ?? []}
            onOkay={onReportShareSubmit}
            onClosed={() => {
              setCurrentReport(undefined);
              shareDialog.closeDialog();
            }}
          />
        </>
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteReport} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onReportDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
      <ReportHistoryDialog
        {...reportHistoryDialog.dialogProps}
        reportConfig={currentReport}
        onClosed={() => {
          setCurrentReport(undefined);
          reportHistoryDialog.closeDialog();
        }}
      />
    </LayoutContent>
  );
}

function ReportsPage(props: {
  onReportAction: (
    report: ReportConfig,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => void;
}) {
  const {
    reportConfigs: reportConfigsInventory,
    isLoading: reportInventoryLoading,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });
  const { reportConfigs: reportConfigsSale, isLoading: reportSaleLoading } =
    useReportConfigs<ReportTypes.Sale>({
      reportType: ReportTypes.Sale,
    });

  const reports: ReportConfig[] = useMemo(() => {
    const reportsInventoryWithType = reportConfigsInventory.map((r) => ({
      ...r,
      reportType: ReportTypes.Inventory,
    }));
    const reportsSaleWithType = reportConfigsSale.map((r) => ({
      ...r,
      reportType: ReportTypes.Sale,
    }));
    return [...reportsInventoryWithType, ...reportsSaleWithType];
  }, [reportConfigsInventory, reportConfigsSale]);

  return (
    <div className={styles.root}>
      {reportInventoryLoading || reportSaleLoading ? (
        <PosSpinner />
      ) : (
        <ReportsTableContainer reports={reports} {...props} />
      )}
    </div>
  );
}
