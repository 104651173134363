import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog/useConfirmDialog';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { SaleCommissionOverrideDialog } from 'src/dialogs/SaleCommissionOverrideDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import * as styles from 'src/modals/SaleDetails/components/Commissions/Commissions.css';
import { CommissionsOverridesTable } from 'src/modals/SaleDetails/components/Commissions/CommissionsOverrideTable/CommissionsOverridesTable';
import { CommissionsTable } from 'src/modals/SaleDetails/components/Commissions/CommissionsTable/CommissionsTable';
import { EditIcon, IconsFill, ResetIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { Commission, SaleDetails, SaleInput } from 'src/WebApiController';

interface CommissionsSectionProps {
  sale: SaleDetails | null | undefined;
}

export const CommissionsSection = ({ sale }: CommissionsSectionProps) => {
  const { setValue, watch } = useFormContext<SaleInput>();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const commissions = watch('commissions')?.value ?? [];

  const onSave = useCallback(
    (commissions: Commission[]) => {
      setValue('commissions', posChangedField(commissions), {
        shouldDirty: true,
      });
      closeDialog();
    },
    [closeDialog, setValue]
  );

  const {
    openConfirmDialog: openConfirmDeleteDialog,
    closeConfirmDialog: closeConfirmDeleteDialog,
  } = useConfirmDialog();

  const resetOverride = useCallback(() => {
    openConfirmDeleteDialog({
      size: 's',
      headerText: <Content id={ContentId.ResetOverride} />,
      bodyText: <Content id={ContentId.ConfirmResetOverride} />,
      onOkay: () => {
        const emptyCommissions: Commission[] = [];
        onSave(emptyCommissions);
        closeConfirmDeleteDialog();
      },
      onCancel: closeConfirmDeleteDialog,
      okText: ContentId.Yes,
      cancelText: ContentId.No,
    });
  }, [closeConfirmDeleteDialog, onSave, openConfirmDeleteDialog]);

  if (!sale) {
    return null;
  }

  const hasCommissions = commissions.length > 0;
  const ActionIcon = hasCommissions ? ResetIcon : EditIcon;

  return (
    <>
      <Stack justifyContent="end">
        <ButtonWithIcon
          variant="textPlain"
          textContentId={
            hasCommissions ? ContentId.ResetOverride : ContentId.AddOverride
          }
          onClick={() => (hasCommissions ? resetOverride() : launchDialog())}
          icon={
            <ActionIcon
              fill={IconsFill.textBrand}
              size={vars.iconSize.m}
              withHoverEffect
            />
          }
          className={styles.addCommissionButton}
        />
      </Stack>

      {hasCommissions ? (
        <CommissionsOverridesTable
          commissions={commissions}
          onEditAction={launchDialog}
        />
      ) : (
        <CommissionsTable sale={sale} />
      )}

      {dialogProps.isOpen && (
        <SaleCommissionOverrideDialog
          {...dialogProps}
          onCancel={closeDialog}
          onSave={onSave}
          header={<Content id={ContentId.AddOverride} />}
        />
      )}
    </>
  );
};
