import { useMemo } from 'react';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
  SelectedFilters,
} from 'src/components/Filters';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { CrossIcon } from 'src/svgs/Viagogo';

import {
  buttonsContainer,
  filterContainer,
  filterItemLabel,
  filterItemWrapper,
  filterValue,
} from './FiltersList.css';

export interface FiltersListProp {
  selectedDropdownItems: SelectedFilters;
  filters: FilterToolbarGroup[];
  onFilterRemoved: (filterDropdownItem: FilterDropdownItem) => void;
  mandatoryFiltersToShow: FilterToolbarItemId[];
  performerVenueFilter?: React.ReactNode;
  resetButton?: React.ReactNode;
  addFilterButton?: React.ReactNode;
}

export const FiltersList = ({
  filters,
  onFilterRemoved,
  mandatoryFiltersToShow,
  selectedDropdownItems,
  performerVenueFilter,
  resetButton,
  addFilterButton,
}: FiltersListProp) => {
  const filterItemsToShow = useMemo(() => {
    const selectedFilters = filters.filter(
      (filterToolbarGroup) =>
        filterToolbarGroup.titleContentId &&
        !!selectedDropdownItems[filterToolbarGroup.titleContentId]
    );

    return selectedFilters.flatMap((filterToolbarGroup) => {
      const dropdownFilterIds = selectedDropdownItems[
        filterToolbarGroup.titleContentId!
      ].map((dropdownFilter) => dropdownFilter.filterId);

      return filterToolbarGroup.items
        .filter((filterItem) => dropdownFilterIds.includes(filterItem.filterId))
        .map((x) => ({ group: filterToolbarGroup, item: x }));
    });
  }, [filters, selectedDropdownItems]);

  return (
    <Stack
      className={filterContainer}
      direction="row"
      gap="l"
      alignItems="center"
      justifyContent="spaceBetween"
    >
      {Boolean(filterItemsToShow?.length) && (
        <ScrollableToolbar>
          <Stack gap="l" direction="row">
            {performerVenueFilter != null && performerVenueFilter}
            {filterItemsToShow.map((item, i) => (
              <FilterToolbarListItem
                item={item.item}
                key={`fi-embedded-${i}`}
                onFilterRemoved={onFilterRemoved}
                mandatoryFiltersToShow={mandatoryFiltersToShow}
                selectedDropdownItems={selectedDropdownItems}
                filterToolbarGroup={item.group}
              />
            ))}
          </Stack>
        </ScrollableToolbar>
      )}
      <Stack
        className={buttonsContainer}
        gap="m"
        alignItems="center"
        justifyContent="spaceBetween"
      >
        {addFilterButton && <div>{addFilterButton}</div>}
        {resetButton &&
          (performerVenueFilter != null || filterItemsToShow.length > 0) && (
            <div>{resetButton}</div>
          )}
      </Stack>
    </Stack>
  );
};

const FilterToolbarListItem = ({
  item,
  onFilterRemoved,
  filterToolbarGroup,
  mandatoryFiltersToShow,
  selectedDropdownItems,
}: {
  filterToolbarGroup: FilterToolbarGroup;
  item: FilterToolbarItem;
  selectedDropdownItems: FiltersListProp['selectedDropdownItems'];
  mandatoryFiltersToShow: FiltersListProp['mandatoryFiltersToShow'];
  onFilterRemoved: FiltersListProp['onFilterRemoved'];
}) => {
  const filterItem = item.filterItem;

  const titleContentId = filterToolbarGroup.titleContentId ?? '';

  const canBeRemoved = !mandatoryFiltersToShow.includes(item.filterId);

  const onRemoveFilterHandler = () => {
    const filterDropdownItem = selectedDropdownItems[titleContentId].find(
      (filterDropdownItem) => filterDropdownItem.filterId === item.filterId
    );
    if (filterDropdownItem) {
      onFilterRemoved(filterDropdownItem);
    }
  };

  return (
    <Stack className={filterItemWrapper}>
      <Stack className={filterItemLabel} alignItems="center" gap="m">
        <Content id={item.labelContentId} />

        {canBeRemoved && (
          <CrossIcon
            onClick={onRemoveFilterHandler}
            withHoverEffect
            size={vars.iconSize.s}
          />
        )}
      </Stack>
      <Stack gap="s" direction="row" alignItems="center">
        <Stack gap="s" width="full" className={filterValue}>
          {filterItem}
        </Stack>
      </Stack>
    </Stack>
  );
};
