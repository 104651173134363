import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  PurchaseOrderDealConfig,
  PurchaseOrderDealDetailsInput,
  PurchaseOrderDealInput,
  PurchaseOrderDetailsInput,
} from 'src/WebApiController';

import { useSellerAccountContext } from '../SellerAccountContext';

interface DealContextValue {
  localDeal: PurchaseOrderDealInput | null;
  setLocalDeal: Dispatch<SetStateAction<PurchaseOrderDealInput | null>>;
  updateLocalDealDetails: (
    value: Partial<PurchaseOrderDealDetailsInput>
  ) => void;
  updateLocalDealConfigHurdle: (
    index: number,
    value: Partial<PurchaseOrderDealConfig>
  ) => void;
  updateLocalDealConfigHurdlePriority: (value: number, index: number) => void;
  deleteLocalDealConfigHurdle: (index: number) => void;
  isEditDeal: boolean;
  setIsEditDeal: Dispatch<SetStateAction<boolean>>;
  activeUserIds: string[];
  isValidDeal: boolean;
  assignedPriorities: Set<number>;
}
const DealContext = createContext<DealContextValue | undefined>(undefined);

export const DealProvider: React.FC<{
  deal?: PurchaseOrderDealInput;
  defaultEdit?: boolean;
  children?: React.ReactNode;
}> = ({ deal, defaultEdit = false, children }) => {
  const [localDeal, setLocalDeal] = useState<PurchaseOrderDealInput | null>(
    deal || null
  );

  const { clearErrors, setError } = useFormContext<PurchaseOrderDetailsInput>();

  const [isEditDeal, setIsEditDeal] = useState(defaultEdit);
  const { allActiveUserInfos } = useSellerAccountContext();

  const updateLocalDealDetails = useCallback(
    (value: Partial<PurchaseOrderDealDetailsInput>) => {
      setLocalDeal((prev) => {
        if (!prev) return prev;
        return { ...prev, dealDetails: { ...prev.dealDetails, ...value } };
      });
    },
    []
  );

  const updateLocalDealConfigHurdle = useCallback(
    (index: number, value: Partial<PurchaseOrderDealConfig>) => {
      setLocalDeal((prev) => {
        if (!prev || !prev.dealDetails.dealConfigs?.value) return prev;

        const updatedConfigs = prev.dealDetails.dealConfigs.value.map(
          (config, i) => (i === index ? { ...config, ...value } : config)
        );

        return {
          ...prev,
          dealDetails: {
            ...prev.dealDetails,
            dealConfigs: posChangedField(updatedConfigs),
          },
        };
      });
    },
    []
  );

  const deleteLocalDealConfigHurdle = useCallback((index: number) => {
    setLocalDeal((prev) => {
      if (!prev || !prev.dealDetails.dealConfigs?.value) return prev;

      const updatedConfigs = prev.dealDetails.dealConfigs.value
        .filter((_, i) => i !== index)
        .map((config) => ({ ...config }));

      // Recalculate config priorities to ensure contiguous and uniqueness
      const deletedPriority =
        prev.dealDetails.dealConfigs.value[index].priority;

      const seenPriorities = new Set();
      const expectedPriorities = new Set(
        Array.from({ length: updatedConfigs.length }, (_, i) => i + 1)
      );

      // Only decrement priorities > deleted priority. Ensure uniqueness by tracking decremented priority
      updatedConfigs.forEach((config) => {
        if (
          deletedPriority &&
          config.priority &&
          config.priority >= deletedPriority
        ) {
          if (!seenPriorities.has(config.priority - 1)) {
            config.priority -= 1;
          } else if (!expectedPriorities.has(config.priority)) {
            config.priority = null;
          }
        }
        seenPriorities.add(config.priority);
      });

      clearErrors();
      checkDealConfigHurdlePriorities(updatedConfigs);

      return {
        ...prev,
        dealDetails: {
          ...prev.dealDetails,
          dealConfigs: posChangedField(updatedConfigs),
        },
      };
    });
  }, []);

  const updateLocalDealConfigHurdlePriority = useCallback(
    (value: number, index: number) => {
      updateLocalDealConfigHurdle(index, {
        priority: Number(value),
      });

      const updatedConfigs = localDeal?.dealDetails?.dealConfigs?.value;
      if (!updatedConfigs) return;

      updatedConfigs[index].priority = value;

      checkDealConfigHurdlePriorities(updatedConfigs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localDeal?.dealDetails?.dealConfigs?.value]
  );

  const checkDealConfigHurdlePriorities = useCallback(
    (configs: PurchaseOrderDealConfig[]) => {
      const priorityMap = configs.reduce(
        (map, config, i) => {
          if (config.priority !== null) {
            if (!map[config.priority]) {
              map[config.priority] = [];
            }
            map[config.priority].push(i);
          }
          return map;
        },
        {} as Record<number, number[]>
      );

      Object.entries(priorityMap).forEach(([priority, indices]) => {
        if (indices.length > 1) {
          indices.forEach((i) => {
            setError(`deal.value.dealDetails.dealConfigs.value.${i}.priority`, {
              message: 'Priority must be unique',
            });
          });
        } else {
          clearErrors(
            `deal.value.dealDetails.dealConfigs.value.${indices[0]}.priority`
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const assignedPriorities = useMemo(() => {
    return new Set(
      localDeal?.dealDetails.dealConfigs?.value
        ?.map((config) => config.priority)
        .filter((priority): priority is number => priority !== null)
    );
  }, [localDeal?.dealDetails]);

  const isValidDeal = useMemo(() => {
    if (
      !localDeal ||
      !localDeal.dealDetails.dealConfigs?.value ||
      !localDeal.dealDetails.dealFallbackConfig
    )
      return true;

    const validHurdles = localDeal.dealDetails.dealConfigs.value.every(
      (config) => {
        const vendorSplit = config.vendorCommission.splitValue;
        const totalPurchaserSplit = config.purchaserCommissions.reduce(
          (acc, split) => acc + split.splitValue,
          0
        );
        return vendorSplit + totalPurchaserSplit === 100;
      }
    );

    const validFallbackHurdle =
      localDeal.dealDetails.dealFallbackConfig.vendorCommission.splitValue +
        localDeal.dealDetails.dealFallbackConfig.purchaserCommissions.reduce(
          (acc, split) => acc + split.splitValue,
          0
        ) ===
      100;

    return (
      validHurdles &&
      validFallbackHurdle &&
      assignedPriorities.size === localDeal.dealDetails.dealConfigs.value.length
    );
  }, [assignedPriorities.size, localDeal]);

  return (
    <DealContext.Provider
      value={{
        localDeal,
        setLocalDeal,
        updateLocalDealDetails,
        updateLocalDealConfigHurdle,
        updateLocalDealConfigHurdlePriority,
        deleteLocalDealConfigHurdle,
        isEditDeal,
        setIsEditDeal,
        activeUserIds,
        isValidDeal,
        assignedPriorities,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};

export const useDealContext = (): DealContextValue => {
  const context = useContext(DealContext);
  if (!context) {
    throw new Error('useDealContext must be used within a DealProvider');
  }
  return context;
};
