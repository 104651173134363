export const enum SaleTab {
  YourSale = 'YourSale',
  Tickets = 'Tickets',
  Notes = 'Notes',
  Economics = 'Economics',
  Delivery = 'Delivery',
  Fulfillment = 'Fulfillment',
  Purchase = 'Purchase',
  Tags = 'Tags',
}

export enum SaleDetailsActionKey {
  Refresh = 'Refresh',
  UnwasteSale = 'Unwaste',
}
