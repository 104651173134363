import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { DetailSection } from 'src/modals/common';
import { TicketsSection } from 'src/modals/SaleDetails/components/TicketsSection';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails, Seating } from 'src/WebApiController';

import { SeatingAllocationInput } from './SeatingAllocationInput';

export const SeatingAllocationInputV1 = ({
  originalSeating,
}: {
  originalSeating: Seating;
}) => {
  const { getValues } = useFormContext<SaleDetails>();

  const sale = getValues();

  return (
    <Stack direction="column" gap="l" width="full" height="full">
      <>
        <h5>
          <Content id={ContentId.AllocateTickets} />
        </h5>

        <Content id={ContentId.WhatSeatToFulfill} />

        <DetailSection name={<Content id={ContentId.SoldAs} />}>
          <TicketsSection
            {...originalSeating}
            quantity={sale!.qtySold}
            listingId={sale!.listingId}
          />
        </DetailSection>
      </>

      <DetailSection name={<Content id={ContentId.Allocate} />} fullHeight>
        <SeatingAllocationInput />
      </DetailSection>
    </Stack>
  );
};
