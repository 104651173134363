import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { SectionDisplay } from 'src/components/Events/VenueMap';
import { Content } from 'src/contexts/ContentContext';
import { DealUndercutIcon } from 'src/svgs/DealUndercutIcon';
import { ParentListingModeIcon } from 'src/svgs/ParentListingModeIcon';
import { QualityScoreModeIcon } from 'src/svgs/QualityScoreModeIcon';
import { RowExtrapolationUndercutIcon } from 'src/svgs/RowExtrapolationUndercutIcon';
import { SameEventModeIcon } from 'src/svgs/SameEventModeIcon';
import { SameSectionModeIcon } from 'src/svgs/SameSectionModeIcon';
import { SameZoneModeIcon } from 'src/svgs/SameZoneModeIcon';
import { SimpleUndercutIcon } from 'src/svgs/SimpleUndercutIcon';
import {
  AutoPricingCompListingMode,
  AutoPricingUndercutMode,
  CompListing,
  CompListingQuantity,
  ListingDetailsUpdateInput,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { formatCurrency, roundToPrecision } from './numberFormatter';
import { isSeatingMatchSectionInfo } from './venueConfigUtils';

export enum UndercutPriceSettingsMode {
  Discount = 'Discount',
  Premium = 'Premium',
}

export const UNDERCUT_PRICE_MODE_OPTIONS = [
  {
    value: UndercutPriceSettingsMode.Discount,
    children: <Content id={ContentId.Reduce} />,
  },
  {
    value: UndercutPriceSettingsMode.Premium,
    children: <Content id={ContentId.Increase} />,
  },
];

export enum PriceFloorCeilingMode {
  Proceeds = 'Proceeds',
  Website = 'Website',
}

export const PRICE_FLOOR_CEILING_MODE_OPTIONS = [
  {
    value: PriceFloorCeilingMode.Proceeds,
    children: <Content id={ContentId.Proceeds} />,
  },
  {
    value: PriceFloorCeilingMode.Website,
    children: <Content id={ContentId.WebsitePrice} />,
  },
];

export enum OutlierModeKthLowestStrategy {
  Relative = 'Relative',
  Absolute = 'Absolute',
}

export const OUTLIER_MODE_KTH_LOWEST_STRATEGY = [
  {
    value: OutlierModeKthLowestStrategy.Relative,
    children: <>%</>,
  },
  {
    value: OutlierModeKthLowestStrategy.Absolute,
    children: <>$</>,
  },
];

export type PricingSettingsFieldNumeric =
  | 'compListingFloor'
  | 'compListingCeiling'
  | 'undercutAbsoluteAmount'
  | 'undercutRelativeAmount'
  | 'netProceedsCeiling'
  | 'netProceedsFloor';

export type PricingSettingsField =
  | PricingSettingsFieldNumeric
  | 'undercutMode'
  | 'compListingMode';

export const validateCompListingSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsUpdateInput>,
  setError: UseFormSetError<ListingDetailsUpdateInput>,
  setValue: UseFormSetValue<ListingDetailsUpdateInput>,
  listForm: ListingDetailsUpdateInput,
  compListingFloorCeilingError: string,
  requiredMsg: string,
  errorField?: PricingSettingsField,
  isStrategy?: boolean
) => {
  let hasErrors = false;

  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('compListingFloor');
    clearErrors('compListingSelectedSectionSettings');
  }

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSelectedSectionsEnabled,
    compListingSelectedSectionSettings,
    autoPricingEnabled,
    skipCompListing,
  } = listForm;

  if (!autoPricingEnabled || skipCompListing) {
    return true;
  }

  if (
    compListingMode === AutoPricingCompListingMode.QualityScore ||
    compListingMode === AutoPricingCompListingMode.SameSection
  ) {
    if (!compListingFloor) {
      listForm.compListingFloor = compListingFloor;
      setValue('compListingFloor', DEFAULT_COMP_LISTING_FLOOR);
    } else if (compListingCeiling && compListingFloor >= compListingCeiling) {
      setError(
        errorField ?? 'compListingFloor',
        {
          message: compListingFloorCeilingError,
        },
        { shouldFocus: true }
      );

      hasErrors = true;
    }
  }

  if (
    compListingMode === AutoPricingCompListingMode.QualityScore ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.SameZone
  ) {
    if (
      compListingOnlyForSelectedSectionsEnabled &&
      !isStrategy &&
      !compListingSelectedSectionSettings?.sectionIdFilter?.length
    ) {
      setError(
        errorField ?? 'compListingSelectedSectionSettings',
        {
          message: requiredMsg,
        },
        { shouldFocus: true }
      );

      hasErrors = true;
    }
  }

  return !hasErrors;
};

export const validateUndercutSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsUpdateInput>,
  setValue: UseFormSetValue<ListingDetailsUpdateInput>,
  listForm: ListingDetailsUpdateInput,
  errorField?: PricingSettingsField
) => {
  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('undercutAbsoluteAmount');
    clearErrors('undercutRelativeAmount');
  }

  const {
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,
    autoPricingEnabled,
    skipUndercut,
  } = listForm;

  if (!autoPricingEnabled || skipUndercut) {
    return true;
  }

  if (undercutMode) {
    if (undercutAbsoluteAmount == null) {
      listForm.undercutAbsoluteAmount = DEFAULT_UNDERCUT_ABSOLUTE_AMT;
      setValue('undercutAbsoluteAmount', DEFAULT_UNDERCUT_ABSOLUTE_AMT);
    }

    if (undercutRelativeAmount == null) {
      listForm.undercutRelativeAmount = DEFAULT_UNDERCUT_RELATIVE_AMT;
      setValue('undercutRelativeAmount', DEFAULT_UNDERCUT_RELATIVE_AMT);
    }
  }

  return true;
};

export const validateNetProceedsSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsUpdateInput>,
  setError: UseFormSetError<ListingDetailsUpdateInput>,
  listForm: ListingDetailsUpdateInput,
  floorMustBeLessThanCeilingError: string,
  errorField?: PricingSettingsField
) => {
  let hasErrors = false;

  if (errorField) {
    clearErrors(errorField);
  } else {
    clearErrors('netProceedsCeiling');
    clearErrors('netProceedsFloor');
  }

  const { netProceedsFloor, netProceedsCeiling } = listForm;

  if ((netProceedsFloor ?? 0) > 0 && (netProceedsCeiling ?? 0) > 0) {
    if (netProceedsFloor! >= netProceedsCeiling!) {
      setError(errorField ?? 'netProceedsFloor', {
        message: floorMustBeLessThanCeilingError,
      });
      hasErrors = true;
    }
  }

  return !hasErrors;
};

export const validateAutoPricingSettings = (
  clearErrors: UseFormClearErrors<ListingDetailsUpdateInput>,
  setError: UseFormSetError<ListingDetailsUpdateInput>,
  setValue: UseFormSetValue<ListingDetailsUpdateInput>,
  listForm: ListingDetailsUpdateInput,
  floorMustBeLessThanCeilingError: string,
  compListingFloorCeilingError: string,
  requiredMsg: string,
  isStrategy?: boolean
) => {
  let hasErrors = false;

  if (
    !validateCompListingSettings(
      clearErrors,
      setError,
      setValue,
      listForm,
      compListingFloorCeilingError,
      requiredMsg,
      undefined,
      isStrategy
    )
  ) {
    hasErrors = true;
  }

  if (!validateUndercutSettings(clearErrors, setValue, listForm)) {
    hasErrors = true;
  }

  if (
    !isStrategy &&
    !validateNetProceedsSettings(
      clearErrors,
      setError,
      listForm,
      floorMustBeLessThanCeilingError
    )
  ) {
    hasErrors = true;
  }

  return !hasErrors;
};

export const getUndercutPriceSettingMode = (value?: number | null) => {
  return !value || value >= 0
    ? UndercutPriceSettingsMode.Discount
    : UndercutPriceSettingsMode.Premium;
};

export const getUndercutPriceRepresentingMode = (
  absValue: number,
  mode: UndercutPriceSettingsMode
) => {
  return mode === UndercutPriceSettingsMode.Premium ? -absValue : absValue;
};

export const DEFAULT_COMP_LISTING_FLOOR = 1;
export const DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR = 5;

export const DEFAULT_UNDERCUT_ABSOLUTE_AMT = 0.01;
export const DEFAULT_UNDERCUT_RELATIVE_AMT = 0;

export const SLIDER_MIN = -1;
export const SLIDER_MAX = 5;

export const convertSliderValueToServer = (val: number) =>
  roundToPrecision(val / 100 + 1, 2);
export const convertSliderValueFromServer = (val: number) => (val - 1) * 100;

export const getCompListingModeIcon = (
  mode?: AutoPricingCompListingMode | null
) => {
  switch (mode) {
    case AutoPricingCompListingMode.QualityScore:
      return QualityScoreModeIcon;
    case AutoPricingCompListingMode.SameSection:
      return SameSectionModeIcon;
    case AutoPricingCompListingMode.SameZone:
      return SameZoneModeIcon;
    case AutoPricingCompListingMode.SameEvent:
      return SameEventModeIcon;
    default:
      return ParentListingModeIcon;
  }
};

export const getUndercutModeIcon = (mode?: AutoPricingUndercutMode | null) => {
  switch (mode) {
    case AutoPricingUndercutMode.Simple:
      return SimpleUndercutIcon;
    case AutoPricingUndercutMode.RowExtrapolation:
      return RowExtrapolationUndercutIcon;
    default:
      return DealUndercutIcon;
  }
};

export const getSectionInfoDisplay = (
  hoveredSection: SectionInfo,
  compListings: CompListing[],
  scoreOverrides?: SectionScoreOverride[] | null
) => {
  let lowestPrice: string | undefined | null = undefined;
  let medianPrice: string | undefined | null = undefined;
  let highestPrice: string | undefined | null = undefined;

  // If we have no selected listing or owned listings, show comp-listings
  const filteredCompListings = compListings
    .filter(
      (l) =>
        isSeatingMatchSectionInfo(hoveredSection, l.section, l.rowId) &&
        l.sellerAllInPrice?.amt
    )
    .sort(
      (l1, l2) =>
        (l1.sellerNetProceeds?.amt ?? 0) - (l2.sellerNetProceeds?.amt ?? 0)
    );

  if (filteredCompListings.length > 0) {
    if (filteredCompListings.length === 1) {
      medianPrice = filteredCompListings[0].sellerNetProceeds?.disp;
    } else {
      const medianIndex = Math.floor(filteredCompListings.length / 2);
      medianPrice = filteredCompListings[medianIndex].sellerNetProceeds?.disp;
      if (filteredCompListings.length % 2 === 0) {
        // even, need to get the avg of the 2 medians
        // This assume all the comp listings are displayed using the same currency code
        const median1 = filteredCompListings[medianIndex - 1].sellerNetProceeds;
        const median2 = filteredCompListings[medianIndex].sellerNetProceeds;
        if (median1 != null || median2 != null) {
          const avgMedian = ((median1?.amt ?? 0) + (median2?.amt ?? 0)) / 2;

          medianPrice = formatCurrency(
            avgMedian,
            median1?.currency ?? median2?.currency,
            median1?.dec ?? median2?.dec
          );
        }
      }

      lowestPrice = filteredCompListings[0].sellerNetProceeds?.disp;

      highestPrice =
        filteredCompListings[filteredCompListings.length - 1].sellerNetProceeds
          ?.disp;
    }
  }

  return (
    <SectionDisplay
      section={hoveredSection}
      scoreOverrides={scoreOverrides}
      lowestPrice={lowestPrice}
      medianPrice={medianPrice}
      highestPrice={highestPrice}
    />
  );
};

export const quantityFiltersFromJson = (
  quantitiesFilterJson: string | null | undefined
): CompListingQuantity | null => {
  if (!quantitiesFilterJson) {
    return null;
  }

  const { quantitiesFilter } = JSON.parse(quantitiesFilterJson);

  return {
    exactQuantities: quantitiesFilter?.exact ?? null,
    greaterThanOrEqualToQuantity: quantitiesFilter?.gte ?? null,
  };
};
