import { vars } from 'src/core/themes';
import { SECTION_TYPES_WITH_ROWS } from 'src/utils/venueConfigUtils';
import { SectionInfo } from 'src/WebApiController';

export const isMissiongSectionData = ({ rows, sectionType }: SectionInfo) => {
  // Only check on sections with rows, others are considered valid always
  if (!SECTION_TYPES_WITH_ROWS.includes(sectionType)) {
    return false;
  }

  // If all rows are speculative, we consider it missing data
  if (rows.every((r) => r.isSpeculative)) {
    return true;
  }

  // If any row is not speculative and has no ordinal, we consider it missing data
  if (
    rows.some(({ isSpeculative, ordinal }) => !isSpeculative && ordinal == null)
  ) {
    return true;
  }

  return false;
};

export type DrawingPoint = { x: number; y: number };

const checkIntersection = (
  rect: DOMRect,
  pointA: DrawingPoint,
  pointB: DrawingPoint
) => {
  // Get the corners of the bounding box
  const topLeft = { x: rect.left, y: rect.top };
  const topRight = { x: rect.right, y: rect.top };
  const bottomLeft = { x: rect.left, y: rect.bottom };
  const bottomRight = { x: rect.right, y: rect.bottom };

  // Check if the line intersects any of the four sides of the box
  return (
    lineIntersects(pointA, pointB, topLeft, topRight) || // Top side
    lineIntersects(pointA, pointB, topRight, bottomRight) || // Right side
    lineIntersects(pointA, pointB, bottomRight, bottomLeft) || // Bottom side
    lineIntersects(pointA, pointB, bottomLeft, topLeft) // Left side
  );
};

// Helper function to check if two line segments intersect
const lineIntersects = (
  pointA: DrawingPoint,
  pointB: DrawingPoint,
  compareA: DrawingPoint,
  compareB: DrawingPoint
) => {
  // Calculate the direction of the lines
  const denominator =
    (pointA.x - pointB.x) * (compareA.y - compareB.y) -
    (pointA.y - pointB.y) * (compareA.x - compareB.x);
  if (denominator === 0) return false; // Lines are parallel

  const t =
    ((pointA.x - compareA.x) * (compareA.y - compareB.y) -
      (pointA.y - compareA.y) * (compareA.x - compareB.x)) /
    denominator;
  const u =
    -(
      (pointA.x - pointB.x) * (pointA.y - compareA.y) -
      (pointA.y - pointB.y) * (pointA.x - compareA.x)
    ) / denominator;

  // Check if the intersection point is within both line segments
  return t >= 0 && t <= 1 && u >= 0 && u <= 1;
};

// Check if the DOM rect crosses any of the lines
export const crossesPoints = (points: DrawingPoint[], rect: DOMRect) => {
  if (points.length < 2) {
    return false;
  }
  for (let index = 1; index < points.length; index++) {
    if (checkIntersection(rect, points[index - 1], points[index])) {
      return true;
    }
  }
  return false;
};

// Draw the lines in the canvas
const drawLines = (
  context: CanvasRenderingContext2D,
  points: DrawingPoint[]
) => {
  if (points.length < 2) {
    return;
  }
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  context.strokeStyle = vars.color.backgroundBrandActive;
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  for (let index = 1; index < points.length; index++) {
    context.lineTo(points[index].x, points[index].y);
  }
  context.stroke();
  context.closePath();
};

export const registerDrawLine = (
  svgElement: HTMLDivElement | undefined | null,
  onDoubleClickStart: () => void,
  onDoubleClickDone: (points: DrawingPoint[]) => void
) => {
  if (svgElement == null || svgElement.parentElement === null) {
    return () => {};
  }

  const parent = svgElement.parentElement as HTMLDivElement;
  // Trick to make the parent focusable so that it can receive keyboard events
  parent.setAttribute('tabindex', '0');
  // Remove the outline when the parent is focused
  parent.addEventListener('focus', () => {
    parent.style.outline = 'none';
  });

  const offsetX = svgElement.getBoundingClientRect().left;
  const offsetY = svgElement.getBoundingClientRect().top;

  let element: HTMLCanvasElement | null = null;
  let context: CanvasRenderingContext2D | null = null;
  // Points in the canvas to draw
  const points: DrawingPoint[] = [];
  // Points in the screen to report
  const rectPoints: DrawingPoint[] = [];

  const resetCanvas = () => {
    if (parent != null && element !== null) {
      parent.removeChild(element);
      parent.style.cursor = 'default';
      element = null;
      points.length = 0;
      rectPoints.length = 0;
    }
    if (context !== null) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context = null;
    }
  };

  const onDoubleClick = (event: MouseEvent) => {
    if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }
    // Double click not started, start it
    if (element == null) {
      onDoubleClickStart();
      points.push({ x: event.clientX - offsetX, y: event.clientY - offsetY });
      rectPoints.push({ x: event.clientX, y: event.clientY });
      element = document.createElement('canvas');
      element.width = parent.clientWidth;
      element.height = parent.clientHeight;
      element.style.position = 'absolute';
      element.style.left = `0px`;
      element.style.top = `0px`;
      context = element.getContext('2d');
      parent.appendChild(element);
      parent.style.cursor = 'crosshair';
      return;
    }

    onDoubleClickDone(rectPoints);
    resetCanvas();
  };
  const onClick = (event: MouseEvent) => {
    if (element !== null && context !== null) {
      points.push({ x: event.clientX - offsetX, y: event.clientY - offsetY });
      rectPoints.push({ x: event.clientX, y: event.clientY });
      if (points.length > 1) {
        drawLines(context, points);
      }
    }
  };

  const onMouseMove = (event: MouseEvent) => {
    const currentPoint = {
      x: event.clientX - offsetX,
      y: event.clientY - offsetY,
    };
    if (element !== null && context !== null) {
      drawLines(context, [...points, currentPoint]);
    }
  };
  const onMouseLeave = (_: MouseEvent) => {
    if (element !== null && context !== null) {
      onDoubleClickDone(rectPoints);
    }
    resetCanvas();
  };

  parent.addEventListener('dblclick', onDoubleClick);
  parent.addEventListener('click', onClick);
  parent.addEventListener('mousemove', onMouseMove);
  parent.addEventListener('mouseleave', onMouseLeave);

  // Escape key to cancel the drawing
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onDoubleClickDone(rectPoints);
      resetCanvas();
    }
  };
  parent.addEventListener('keydown', onKeyDown);

  return () => {
    parent?.removeEventListener('dblclick', onDoubleClick);
    parent?.removeEventListener('click', onClick);
    parent?.removeEventListener('mousemove', onMouseMove);
    parent?.removeEventListener('mouseleave', onMouseLeave);
    parent?.removeEventListener('keydown', onKeyDown);

    resetCanvas();
  };
};

// Relax the panning detection, panning when moved at least 1 pixel
const moved = (a: MouseEvent | null, b: MouseEvent | null): boolean => {
  if (a === null || b === null) {
    return false;
  }
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)) > 1;
};

export const registerPanningListners = (
  svgElement: HTMLElement | undefined | null,
  isPanning: React.MutableRefObject<boolean>
) => {
  // This is a hack to check if user is panning or clicking
  let willClick = false;
  let downEvent: MouseEvent | null = null;

  const panningHandlers = {
    mousedown: (event: Event) => {
      willClick = true;
      downEvent = event as MouseEvent;
    },
    mousemove: (event: Event) => {
      if (
        willClick &&
        !isPanning.current &&
        moved(downEvent as MouseEvent, event as MouseEvent)
      ) {
        isPanning.current = true;
      }
    },
    mouseup: (_: Event) => {
      downEvent = null;
      if (willClick) {
        // Click event is always fired after mouseup,
        // reserve a small time on the panning flag to avoid click and pan at the same time
        setTimeout(() => {
          isPanning.current = false;
          willClick = false;
        }, 50);
      }
    },
  } as Record<string, (event: Event) => void>;

  for (const eventName in panningHandlers) {
    svgElement?.addEventListener(eventName, panningHandlers[eventName]);
  }

  return () => {
    for (const eventName in panningHandlers) {
      svgElement?.removeEventListener(eventName, panningHandlers[eventName]);
    }
  };
};
