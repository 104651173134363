import { FilterToolbarItem, FilterToolbarItemId } from 'src/components/Filters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getDisabledDatesForTimeFrameFilter,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { Feature, ListingQuery } from 'src/WebApiController';

export const useEventLastReviewedFilters = (): FilterToolbarItem[] => {
  const hasFilterOnEventLastReviewedFeature = useUserHasFeature(
    Feature.FilterOnEventLastReviewed
  );
  const hasEventLastReviewedFeature = useUserHasFeature(
    Feature.EventLastReviewed
  );
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );

  const { initialQuery, tempQuery, setTempQuery } =
    useFilterQueryContext<ListingQuery>();

  if (!hasFilterOnEventLastReviewedFeature || !hasEventLastReviewedFeature) {
    return [];
  }

  return [
    {
      filterId: 'eventNotReviewedSinceDate' as FilterToolbarItemId,
      labelContentId: ContentId.EventNotReviewedSince,
      filterQueryKeys: ['eventNotReviewedSinceDate'] as FilterToolbarItemId[],
      filterItem: (
        <DateRangeSelector
          useRelativePresets={hasDynamicDateTimeFiltersFeature}
          presetNames={StandardDateRangePresetNames}
          value={tempQuery.eventNotReviewedSinceDate}
          defaultValue={initialQuery.eventNotReviewedSinceDate}
          onBlur={(value) =>
            setTempQuery({
              ...tempQuery,
              eventNotReviewedSinceDate: value,
            })
          }
          disabledDay={(d) =>
            getDisabledDatesForTimeFrameFilter(
              d,
              tempQuery.eventTimeFrameFilter
            )
          }
        />
      ),
    },
  ];
};
