import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';

import * as styles from './DividerLine.css';

export const DividerLine = forwardRef<
  HTMLDivElement,
  Omit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'onKeyDown' | 'onKeyUp'>
>(function Root(props, ref): ReactElement {
  return <div className={styles.dividerLine} {...props} />;
});
