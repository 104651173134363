import { SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { DetailSection } from 'src/modals/common';
import { TicketsSection } from 'src/modals/SaleDetails/components/TicketsSection';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails, Seating } from 'src/WebApiController';

import { SeatingAllocationInput } from './SeatingAllocationInput';
import * as styles from './SeatingAllocationInput.css';
import {
  FlattenedSeatingAllocation,
  SeatingSingleTicketAllocationInput,
} from './SeatingSingleTicketAllocationInput';

export const enum SeatingAllocationInputTab {
  Listings = 'listings',
  Tickets = 'tickets',
}

export const SeatingAllocationInputV2 = ({
  selectedSeatings,
  setSelectedSeatings,
  activeTab,
  setActiveTab,
  originalSeating,
}: {
  selectedSeatings: FlattenedSeatingAllocation[];
  setSelectedSeatings: React.Dispatch<
    React.SetStateAction<FlattenedSeatingAllocation[]>
  >;
  activeTab: SeatingAllocationInputTab;
  setActiveTab: React.Dispatch<SetStateAction<SeatingAllocationInputTab>>;
  originalSeating: Seating;
}) => {
  const { getValues } = useFormContext<SaleDetails>();

  const sale = getValues();

  return (
    <Stack direction="column" gap="l" width="full" height="full">
      <h5>
        <Content id={ContentId.AllocateTickets} />
      </h5>

      <Content id={ContentId.WhatSeatToFulfill} />

      <DetailSection name={<Content id={ContentId.SoldAs} />}>
        <TicketsSection
          {...originalSeating}
          quantity={sale!.qtySold}
          listingId={sale!.listingId}
        />
      </DetailSection>
      <DetailSection name={<Content id={ContentId.Allocate} />} fullHeight>
        <Tabs.Root
          value={activeTab}
          onValueChange={(value) => {
            setActiveTab(value as SeatingAllocationInputTab);
            setSelectedSeatings([]);
          }}
          className={styles.tabsSection}
        >
          {/* Do not remove this empty div or the tab-list will display a weird scroll - this is due to the virtuoso content body */}
          <div>
            <Tabs.List>
              <Tabs.Trigger
                key={SeatingAllocationInputTab.Listings}
                value={SeatingAllocationInputTab.Listings}
              >
                <Content id={ContentId.ChooseListing} />
              </Tabs.Trigger>
              <Tabs.Trigger
                key={SeatingAllocationInputTab.Tickets}
                value={SeatingAllocationInputTab.Tickets}
              >
                <Content id={ContentId.ChooseTickets} />
              </Tabs.Trigger>
            </Tabs.List>
          </div>
          <Tabs.Content
            key={SeatingAllocationInputTab.Listings}
            value={SeatingAllocationInputTab.Listings}
            style={{ height: '100%' }}
          >
            <SeatingAllocationInput />
          </Tabs.Content>
          <Tabs.Content
            key={SeatingAllocationInputTab.Tickets}
            value={SeatingAllocationInputTab.Tickets}
            style={{ height: '100%' }}
          >
            <SeatingSingleTicketAllocationInput
              selectedSeatings={selectedSeatings}
              setSelectedSeatings={setSelectedSeatings}
            />
          </Tabs.Content>
        </Tabs.Root>
      </DetailSection>
    </Stack>
  );
};
