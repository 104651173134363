import { useFormContext } from 'react-hook-form';
import { ChargeReasonSelector } from 'src/modals/SaleDetails/components/PaymentSection/SaleLineItemModal/ChargeOrCreditReason/ChargeReasonSelector';
import { CreditReasonSelector } from 'src/modals/SaleDetails/components/PaymentSection/SaleLineItemModal/ChargeOrCreditReason/CreditReasonSelector';
import { useHasPaymentsSectionsV2Feature } from 'src/modals/SaleDetails/hooks/useHasPaymentsSectionsV2Feature';
import { SaleCostType, SaleLineItem } from 'src/WebApiController';

export const ChargeOrCreditReasonSelector = () => {
  const { watch } = useFormContext<SaleLineItem>();
  const costType = watch('costType');
  const isChargeType = costType === SaleCostType.Charge;
  const isCreditType = costType === SaleCostType.Credit;
  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();

  if (!hasPaymentsSectionsV2Feature) {
    return null;
  }

  if (isChargeType) {
    return <ChargeReasonSelector />;
  }

  if (isCreditType) {
    return <CreditReasonSelector />;
  }

  return null;
};
