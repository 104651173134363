import { useMemo } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { Content } from 'src/contexts/ContentContext';
import { SimpleTable } from 'src/core/ui';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { shimmerHeight } from 'src/modals/SaleDetails/components/Commissions/Commissions.constants';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { SaleDetails } from 'src/WebApiController';
import * as styles from '../CommissionsTable.css';

interface CommissionsTableProps {
  sale: SaleDetails | null | undefined;
}

export const CommissionsTable = ({ sale }: CommissionsTableProps) => {
  const usersIds = useMemo(() => {
    if (
      !sale?.purchaseOrderCommissions ||
      sale?.purchaseOrderCommissions?.length === 0
    ) {
      return [];
    }

    const buyerUserIds = sale.purchaseOrderCommissions.flatMap((poc) =>
      poc.commissions.map((c) => c.buyerUserId)
    );
    return Array.from(new Set(buyerUserIds));
  }, [sale]);

  const usersQuery = useGetUserInfos(usersIds);

  if (!sale?.purchaseOrderCommissions || usersIds.length === 0) {
    return (
      <div className={styles.emptyState}>
        <Content id={ContentId.EmptyPurchaseCommissions} />
      </div>
    );
  }

  const users = usersQuery.data ?? {};

  return (
    <div className={styles.tableWrapper}>
      <SimpleTable.Table className={styles.table}>
        <SimpleTable.Thead>
          <SimpleTable.Tr>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Section} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Row} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Seats} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.QuantityAbbreviated} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Purchase} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Commissioner} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Commission} />
            </SimpleTable.Th>
          </SimpleTable.Tr>
        </SimpleTable.Thead>
        <SimpleTable.Tbody>
          {usersQuery.isLoading ? (
            <>
              {usersIds.map((userId) => (
                <SimpleTable.Tr key={userId}>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                </SimpleTable.Tr>
              ))}
            </>
          ) : (
            <>
              {sale.purchaseOrderCommissions.map(
                ({ commissions, purchaseOrderId }) => {
                  const tkInfo = sale.ticketGroupInfos.find(
                    (tkg) => tkg.purchaseOrderId === purchaseOrderId
                  );
                  if (!tkInfo) {
                    return null;
                  }
                  const { seating, ticketCount } = tkInfo;
                  return (
                    <SimpleTable.Tr key={purchaseOrderId}>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {seating.section}
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {seating.row}
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {seating.seatFr} - {seating.seatTo}
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {ticketCount}
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        <LinkAndCopy
                          relativeUrl={getPurchaseOrderRelativeUrl(
                            tkInfo.purchaseOrderId
                          )}
                          linkChildren={tkInfo.vendorOrderId}
                          valueToCopy={
                            tkInfo.vendorOrderId ?? tkInfo.purchaseOrderId
                          }
                        />
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {commissions.map(({ buyerUserId }) => (
                          <div key={buyerUserId}>{users[buyerUserId].name}</div>
                        ))}
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.commissionsCell}>
                        {commissions.map(
                          ({ buyerUserId, commissionPercentage }) => (
                            <div key={buyerUserId}>
                              {(commissionPercentage * 100).toFixed(0)}%
                            </div>
                          )
                        )}
                      </SimpleTable.Td>
                    </SimpleTable.Tr>
                  );
                }
              )}
            </>
          )}
        </SimpleTable.Tbody>
      </SimpleTable.Table>
    </div>
  );
};
