import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventEntitySearchBox } from 'src/components/Accordions/EventEntitySearchBox';
import { SaleBasicInfo } from 'src/components/Sales/SaleBasicInfo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { DefaultSaleQuery, getQueryFromUrl } from 'src/utils/eventQueryUtils';
import { getSaleDetailsModalConfigWithDeepLink } from 'src/utils/saleUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  Sale,
  SaleClient,
  SaleDetails,
  SaleQuery,
  SalesViewMode,
} from 'src/WebApiController';

export function SaleSearchBox({ isEventPage }: { isEventPage?: boolean }) {
  const { posEntity: sale, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();

  const [searchId, setSearchId] = useState<string>();
  const [searchResults, setSearchResults] = useState<Sale[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { showErrorDialog } = useErrorBoundaryContext();
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const {
    filterQuery: saleQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
  } = useFilterQueryContext<SaleQuery>();

  const location = useLocation();

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<SaleQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.entityIds) &&
      queryFromUrl?.entityIds.length
    ) {
      setSearchText((prev) =>
        queryFromUrl?.entityIds ? queryFromUrl?.entityIds.join(',') : prev
      );
    } else if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setSearchText((prev) =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : prev
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuery, hasFullPageEventViewEntitySelection]);

  const { marketplaceEntityIds, searchText: saleQuerySearchText } = saleQuery;
  useEffect(() => {
    if (marketplaceEntityIds && hasFullPageEventViewEntitySelection) {
      return;
    }

    if (saleQuerySearchText) {
      setSearchText(saleQuerySearchText);
    } else if (marketplaceEntityIds && Array.isArray(marketplaceEntityIds)) {
      const ids = marketplaceEntityIds.join(',');
      if (ids.length > 0) {
        setSearchText(ids);
      }
    }
  }, [
    hasFullPageEventViewEntitySelection,
    marketplaceEntityIds,
    saleQuerySearchText,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== saleQuery.searchText) {
        setFilterQuery({
          ...saleQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [saleQuery]
  );

  const activeDeepLinkSaleId = getDeepLinkIdFromUrl(
    SaleDeeplinkQueryParam,
    window.location.href
  );

  const onLookupById = useCallback(
    async (id: string, isSearching: boolean) => {
      if (!id && (tempQuery.entityIds || tempQuery.marketplaceEntityIds)) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }
      if (id && !isLoadingSearch) {
        if (
          sale?.idOnMkp === id ||
          String(sale?.id) === id ||
          activeDeepLinkSaleId === id
        ) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            if (activeDeepLinkSaleId) {
              setActivePosEntity(
                Number(activeDeepLinkSaleId),
                undefined,
                false
              );
            }
            setModal(getSaleDetailsModalConfigWithDeepLink(sale?.id));
          }

          // Else, just don't do anything
          return;
        }

        const matchedIds = id
          .split(',')
          .filter((s) => s.trim())
          .map((s) => s.trim()); // split by commas and remove all empty ones

        if (matchedIds.length > 1) {
          const newQuery = {
            ...DefaultSaleQuery,
            entityIds: null,
            marketplaceEntityIds: matchedIds,
            viewMode: SalesViewMode.FlattenedView,
          };
          setFilterQuery(newQuery);
          return;
        } else if (tempQuery.entityIds || tempQuery.marketplaceEntityIds) {
          const newQuery = {
            ...tempQuery,
            entityIds: null,
            marketplaceEntityIds: null,
          };
          setFilterQuery(newQuery);
        }

        setSearchId(id);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the sale id or unique id
          // since 99% of the time they'll be entering a sale id, we try to lookup by that first
          const sales = isSearching
            ? await tryInvokeApi(
                () =>
                  new SaleClient(
                    activeAccountWebClientConfig
                  ).getSalesByMarketplaceSaleId(id),
                (error) => {
                  showErrorDialog(
                    'SaleClient.getSalesByMarketplaceSaleId',
                    error,
                    { trackErrorData: { saleId: id } }
                  );
                }
              )
            : null;

          if (!sales || sales.length === 0) {
            const idNum = Number(id);
            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any sales by market-place-sale-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true);
            setModal(getSaleDetailsModalConfigWithDeepLink(idNum));

            return true;
          } else {
            // We have sales using the market place id
            // 99% of the time, the id should be unique, but if we support more than 1 marketplaces
            // the ids may collide and return more than one result, therefore we need to present to the user and ask them what they want
            if (sales.length === 1) {
              const saleInfo = sales[0];
              // happy path
              setActivePosEntity(saleInfo.id, saleInfo.idOnMkp, true);
              setModal(
                getSaleDetailsModalConfigWithDeepLink(saleInfo.id, true)
              );

              return true;
            } else {
              setSearchResults(sales);
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      activeDeepLinkSaleId,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      sale,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const onResultItemPicked = useCallback(
    (sale: Sale) => {
      setActivePosEntity(sale.id, sale.idOnMkp, true);
      setModal(getSaleDetailsModalConfigWithDeepLink(sale.id, true));

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  useEffect(() => {
    if (activeDeepLinkSaleId?.length) {
      onLookupById(activeDeepLinkSaleId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeepLinkSaleId]);

  return (
    <>
      <EventEntitySearchBox
        placeholderContentId={
          isEventPage ? ContentId.LookupBySaleId : undefined
        }
        maxWidth={350}
        disabled={isLoadingSearch}
        onIdLookup={(value) => onLookupById(value, true)}
        onEventSearch={(value) => debouncedOnSearchChange(value)}
        searchText={searchText}
        setSearchText={setSearchText}
        clearSearchEntity={clearSearchEntity}
        disableSearchMode={isEventPage}
      />

      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindSaleId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(sale: Sale) => <SaleBasicInfo sale={sale} />}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
