import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Stack } from 'src/core/ui';
import { onTabPanelKeyDown, TabPanel } from 'src/core/ui/TabPanel';
import { TabItem } from 'src/core/ui/TabPanel/TabPanel.types';
import {
  Alert,
  AlertWithSuppressionDialog,
  useSuspiciousPriceChangeDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import { useListingGroupPricingSettings } from 'src/hooks/api/useListingGroupPricingSettings';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useGetListingAutoPricingSettings } from 'src/hooks/useGetListingAutoPricingSettings';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailSection } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody } from 'src/modals/Modal';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { validateAutoPricingSettings } from 'src/utils/autoPricingUtils';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { isSuccess } from 'src/utils/errorUtils';
import {
  getListingDetailsUpdateInput,
  isListingDetailsInputChanged,
  isSuspiciousPriceChange,
} from 'src/utils/inventoryUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  ListingClient,
  ListingDetails,
  ListingDetailsUpdateInput,
  ListingMarketplacePriceUpdateInput,
  Marketplace,
  MarketplaceListing,
} from 'src/WebApiController';

import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import {
  DeliverySection,
  EconomicsSection,
  MarketplaceBroadcastSection,
  TicketsSection,
} from './components';
import { AdminHoldNotesSection } from './components/AdminHoldNotesSection';
import { HistoricalPricingGraphSection } from './components/HistoricalPricingGraphSection';
import {
  ListingDetailsModalSidePanel,
  ListingDetailsModalSidePanelContextProvider,
  useListingDetailsModalSidePanelContext,
} from './components/ListingDetailsModalSidePanel';
import { ListingTab } from './components/listingDetailsUtils';
import { NotesSection } from './components/NotesSection';
import { AutoPricingSection } from './components/PricingSection/AutoPricingSection';
import { PricingProtection } from './components/PricingSection/PricingProtection';
import { PurchaseSection } from './components/PurchaseSection';
import { SalesSection } from './components/SalesSection/SalesSection';
import { TagsSection } from './components/TagsSection';
import { useHasListingPricePermissions } from './components/useHasListingPricePermissions';
import { ListingDetailsFooter } from './ListingDetailsFooter';
import { ListingAutoPricingSettingsFormContentWrapper } from './ListingForms/AutoPricingForm/components/ListingAutoPricingSettingsFormContentWrapper/ListingAutoPricingSettingsFormContentWrapper';

export const ListingDetailsModal = () => {
  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  const { isLoading: listingIsLoading, loadingState } =
    useEventItemLoadingDisplay<ListingDetails>(
      FormatContentId.LoadingListingId,
      FormatContentId.SearchingForListingId,
      FormatContentId.CouldNotFindListingId,
      activeId
    );

  const { posEntity: listing } = useActivePosEntityContext<ListingDetails>();

  if (!listing) {
    // If we don't have a listing yet, either return loading state or nothing
    return loadingState;
  }

  // If we do have a listing already, only return loadingState if it is not loading (ie, it's something else wrong)
  if (!listingIsLoading && loadingState) return loadingState;

  return <ListingDetailsModalForm listing={listing} />;
};

const ListingDetailsModalForm = ({ listing }: { listing: ListingDetails }) => {
  const { event } = useActivePosEntityContext<ListingDetails>();

  const {
    pricingSettings: listingPricingSettings,
    loaded: listingSettingLoaded,
  } = useGetListingAutoPricingSettings(listing, event);
  const { pricingSettings: groupPricingSettings, loaded: groupSettingLoaded } =
    useListingGroupPricingSettings(listing.ltGrpId, event);
  const {
    pricingSettings: accountPricingSettings,
    loaded: accountSettingLoaded,
  } = useGetAccountAutoPricingSettings();
  const methods = useForm<ListingDetailsUpdateInput>({
    defaultValues: getListingDetailsUpdateInput(
      listing,
      null,
      undefined,
      accountPricingSettings,
      groupPricingSettings,
      undefined,
      listingPricingSettings
    ),
  });

  useEffect(() => {
    if (groupSettingLoaded || accountSettingLoaded || listingSettingLoaded) {
      methods.reset(
        getListingDetailsUpdateInput(
          listing,
          null,
          undefined,
          accountPricingSettings,
          groupPricingSettings,
          undefined,
          listingPricingSettings
        )
      );
    }
  }, [
    groupSettingLoaded,
    accountSettingLoaded,
    listingSettingLoaded,
    methods,
    listing,
    accountPricingSettings,
    groupPricingSettings,
    listingPricingSettings,
  ]);

  return (
    <EventMapContextProvider event={event}>
      <ListingDetailsModalSidePanelContextProvider>
        <FormProvider {...methods}>
          <ListingDetailsModalContent {...methods} />
        </FormProvider>
      </ListingDetailsModalSidePanelContextProvider>
    </EventMapContextProvider>
  );
};

const ListingDetailsModalContent = ({
  formState,
  handleSubmit,
  getValues,
  clearErrors,
  setValue,
  setError,
  reset,
}: Omit<
  ComponentProps<typeof FormProvider<ListingDetailsUpdateInput, unknown>>,
  'children'
>) => {
  const {
    event,
    posEntity: listing,
    setActivePosEntity,
  } = useActivePosEntityContext<ListingDetails>();
  const { watch } = useFormContext<ListingDetailsUpdateInput>();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const hasIgnoreDupFeature = useUserHasFeature(Feature.IgnoreDuplicate);

  const { refetch: refreshTagMetadata, tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.Listing
  );
  const { onUpdateListing } = useEventHubContext();

  const { canSetPrice, canAutoPrice, hasAutoPricingFeature } =
    useHasListingPricePermissions(listing);

  const { onShowContent, onCloseSidePanel } =
    useListingDetailsModalSidePanelContext();

  const [isLoading, setIsLoading] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const marketplacePriceUpdates = watch('marketplacePriceUpdates');
  const adminHoldNotes = watch('adminHoldNotes');
  const warningDialog = useBasicDialog();

  const suspiciousPriceChangeHookProps = useSuspiciousPriceChangeDialog(
    () => {
      handleSubmit(onSubmit)();
    },
    () => {
      // revert back
      setValue('listPrice', formState.defaultValues?.listPrice ?? null);
      setValue('allInPrice', formState.defaultValues?.allInPrice ?? null);
    }
  );

  const hasIntelligibleAutoPricingSettingsFeature =
    useUserHasAutopricingFeature(Feature.IntelligibleAutoPricingSettings);

  const hasPricesChange = useCallback(
    (listingForm: ListingDetailsUpdateInput) => {
      const defaultValues = formState.defaultValues;
      return (
        defaultValues?.listPrice !== listingForm.listPrice ||
        defaultValues?.allInPrice !== listingForm.allInPrice ||
        defaultValues?.netProceedsCeiling !== listingForm.netProceedsCeiling ||
        defaultValues?.netProceedsFloor !== listingForm.netProceedsFloor ||
        !isEqual(
          defaultValues?.marketplacePriceUpdates,
          listingForm.marketplacePriceUpdates
        )
      );
    },
    [formState.defaultValues]
  );

  const resetPricesData = useCallback(
    (listingForm: ListingDetailsUpdateInput): ListingDetailsUpdateInput => {
      const defaultValues = formState.defaultValues;
      return {
        ...listingForm,
        listPrice: defaultValues?.listPrice ?? null,
        allInPrice: defaultValues?.allInPrice ?? null,
        netProceedsCeiling: defaultValues?.netProceedsCeiling ?? null,
        netProceedsFloor: defaultValues?.netProceedsFloor ?? null,
        marketplacePriceUpdates:
          (defaultValues?.marketplacePriceUpdates as ListingMarketplacePriceUpdateInput[]) ??
          [],
      };
    },
    [formState.defaultValues]
  );

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () => getUiCurrency(listing?.currency),
    [listing?.currency, getUiCurrency]
  );
  const thirdPartyMPs = useMemo(
    () =>
      listing?.mkpListings
        ?.filter((ml) => ml.mkp && ml.mkp !== Marketplace.StubHub)
        .reduce(
          (r, m) => {
            r[m.mkp!] = m;
            return r;
          },
          {} as Record<Marketplace, MarketplaceListing>
        ) ?? ({} as Record<Marketplace, MarketplaceListing>),
    [listing?.mkpListings]
  );

  const updateMarketplaceListPrices = useCallback(
    (listPrice: number) => {
      // Adjust all auto-priced marketplaces to the list-price
      marketplacePriceUpdates.forEach((m, i) => {
        if (!m.priceByMarketplace) {
          const mpListPrice = roundToPrecision(
            listPrice * (1 + (thirdPartyMPs[m.marketplace].markup ?? 0)),
            uiCurrency.dec
          );

          if (m.listPrice !== mpListPrice) {
            setValue(`marketplacePriceUpdates.${i}.listPrice`, mpListPrice);
          }
        }
      });
    },
    [marketplacePriceUpdates, setValue, thirdPartyMPs, uiCurrency.dec]
  );

  const hasChanges = useCallback(
    (listingForm: ListingDetailsUpdateInput) => {
      // Make sure we only send back the settable props
      if (
        isListingDetailsInputChanged(
          listingForm,
          formState.defaultValues as ListingDetailsUpdateInput,
          tagsMetadata
        )
      ) {
        // If list price change - update marketplace list prices if auto-mode
        if (formState.defaultValues?.listPrice !== listingForm.listPrice) {
          updateMarketplaceListPrices(listingForm.listPrice!);
        }
        return true;
      }
    },
    [formState.defaultValues, tagsMetadata, updateMarketplaceListPrices]
  );

  const requiredMsg = useContent(ContentId.Required);
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const floorMustBeLessThanCeilingError = useContent(
    ContentId.FloorMustBeLessThanCeiling
  );

  const compListingFloorCeilingError = useContent(
    ContentId.CompListingFloorMustLessThanCeiling
  );

  const onSubmit = useCallback(
    async (listingFormValue: ListingDetailsUpdateInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          let success = false;

          if (hasChanges(resetPricesData(listingFormValue))) {
            const result = await new ListingClient(
              activeAccountWebClientConfig
            ).updateListing(listingFormValue);
            if (isSuccess(result)) {
              success = true;
            } else {
              showErrorDialog(
                'ListingClient.updateListing',
                { message: result.message, status: result.status },
                { trackErrorData: { listingFormValue } }
              );
            }
          }

          if (success) {
            // Refresh the active data so the SaleDetail dialog and table will have the new content
            setActivePosEntity(listingFormValue.id, listing?.idOnMkp, true);
            // Reset the form to latest changed
            reset(listingFormValue);
            // Update the tags metadata
            refreshTagMetadata();

            onUpdateListing(listingFormValue.id);
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListing', error, {
            trackErrorData: listingFormValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      hasChanges,
      listing?.idOnMkp,
      onUpdateListing,
      refreshTagMetadata,
      reset,
      resetPricesData,
      setActivePosEntity,
      showErrorDialog,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    const listForm = getValues();

    if (!hasChanges(listForm)) return;

    const { listPrice, marketplacePriceUpdates, section } = listForm;

    clearErrors('listPrice');

    let hasErrors = false;

    if (listPrice == null || listPrice < 0) {
      setError('listPrice', { message: requiredMsg });
      hasErrors = true;
    }

    if (listing?.isSeatSaver && !section) {
      setError('section', { message: requiredMsg });
    }

    marketplacePriceUpdates.forEach((mp, i) => {
      clearErrors(`marketplacePriceUpdates.${i}.listPrice`);

      if (mp.priceByMarketplace) {
        // If we're manually editing marketplace prices, validate them too

        if (!mp.listPrice) {
          setError(`marketplacePriceUpdates.${i}.listPrice`, {
            message: requiredMsg,
          });
          hasErrors = true;
        }
      }
    });

    if (
      !validateAutoPricingSettings(
        clearErrors,
        setError,
        setValue,
        listForm,
        floorMustBeLessThanCeilingError,
        compListingFloorCeilingError,
        requiredMsg
      )
    ) {
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const showWarning = isSuspiciousPriceChange(
      listPrice,
      formState.defaultValues?.listPrice
    );

    if (
      showWarning &&
      isDatePassedHours(suspiciousPriceChangeHookProps.lastTimeStamp, 1)
    ) {
      setAlerts([suspiciousPriceChangeHookProps]);
      warningDialog.launchDialog();
    } else {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    hasChanges,
    clearErrors,
    listing?.isSeatSaver,
    setError,
    setValue,
    floorMustBeLessThanCeilingError,
    compListingFloorCeilingError,
    requiredMsg,
    formState.defaultValues?.listPrice,
    suspiciousPriceChangeHookProps,
    warningDialog,
    handleSubmit,
    onSubmit,
  ]);

  const tabs: (TabItem | null)[] = useMemo(
    () => [
      {
        value: ListingTab.Broadcast,
        title: <Content id={ContentId.Broadcast} />,
        content: (
          <>
            <MarketplaceBroadcastSection
              listing={listing!}
              disabled={isLoading}
              setIsLoading={setIsLoading}
            />
            {listing?.isAdminHold && (
              <AdminHoldNotesSection adminHoldNotes={adminHoldNotes} />
            )}
          </>
        ),
      },
      {
        value: ListingTab.Tickets,
        title: <Content id={ContentId.Tickets} />,
        content: (
          <>
            <TicketsSection listing={listing!} disabled={isLoading} />
            <DetailSection name={<Content id={ContentId.Notes} />}>
              <NotesSection listing={listing} disabled={isLoading} />
            </DetailSection>
            <DeliverySection
              event={event}
              listing={listing!}
              disabled={isLoading}
            />
          </>
        ),
      },
      canSetPrice
        ? {
            value: ListingTab.Pricing,
            title: <Content id={ContentId.Pricing} />,
            content: (
              <>
                <EconomicsSection listing={listing!} disabled={isLoading} />
                {!hasAutoPricingFeature && (
                  <PricingProtection listing={listing!} disabled={isLoading} />
                )}
                <HistoricalPricingGraphSection listing={listing!} />
              </>
            ),
          }
        : null,
      canAutoPrice // this one assumes canSetPrice
        ? {
            value: ListingTab.AutoPricing,
            title: <Content id={ContentId.Autopricing} />,
            content: (
              <>
                {hasIntelligibleAutoPricingSettingsFeature ? (
                  <ListingAutoPricingSettingsFormContentWrapper
                    listing={listing}
                    disabled={isLoading}
                    onSwitchToTab={(tab) => onTabChange(tab)}
                  />
                ) : (
                  <AutoPricingSection
                    listing={listing}
                    disabled={isLoading}
                    onSwitchToTab={(tab) => onTabChange(tab)}
                  />
                )}
              </>
            ),
          }
        : null,
      {
        value: ListingTab.Sales,
        title: <Content id={ContentId.Sales} />,
        content: (
          <SalesSection
            sales={listing?.saleInfos ?? []}
            listingSeating={listing?.seating}
            tickets={listing?.tickets ?? []}
          />
        ),
      },
      {
        value: ListingTab.Purchase,
        title: <Content id={ContentId.Purchase} />,
        content: <PurchaseSection listing={listing} />,
      },
      {
        value: ListingTab.Tags,
        title: <Content id={ContentId.Tags} />,
        content: <TagsSection listing={listing} disabled={isLoading} />,
      },
    ],
    // WARNING: be careful of adding dependencies here - because it will cause the tabs memo to recalculate
    // and cause a re-render of all the tabs (messing up current state and scroll and all that)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTabIndex, event, isLoading, listing]
  );

  const tabsToDisplay = tabs.filter((t) => t != null).map((t) => t!);

  const onTabChange = useCallback(
    (value: ListingTab) => {
      if (value === ListingTab.AutoPricing) {
        onShowContent('comparables');
      } else {
        onCloseSidePanel();
      }

      const tabIndex = tabsToDisplay.findIndex((t) => t.value === value);
      if (tabIndex !== activeTabIndex) {
        setActiveTabIndex(tabIndex ?? 0);
      }
    },
    [tabsToDisplay, activeTabIndex, onShowContent, onCloseSidePanel]
  );

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      onTabPanelKeyDown(e, setActiveTabIndex, tabsToDisplay.length);
    },
    [tabsToDisplay.length]
  );

  const inventoryEventPageUrl = useMemo(() => {
    if (event) {
      const includeAllSearchParams = true;
      const useEventV2 = false;
      const queryParamOverride = undefined;
      const queryParamsToRemove = [InventoryDeeplinkQueryParam];
      return createInventoryEventUrl(
        event.viagVirtualId,
        includeAllSearchParams,
        useEventV2,
        queryParamOverride,
        queryParamsToRemove
      );
    }
    return undefined;
  }, [event]);

  return (
    <Stack
      direction="row"
      style={{ height: '100%', width: '100%', overflowX: 'auto' }}
    >
      {event && listing && (
        <ListingDetailsModalSidePanel event={event} listing={listing} />
      )}

      <Stack direction="column" style={{ height: '100%' }}>
        <CancellableFormHeader
          disabled={isLoading || formState.isSubmitting}
          showDialogOnCancel={hasChanges(getValues())}
        >
          <ConnectedEventEntityHeader
            title={
              <>
                <Content id={ContentId.ViewDetails} />
                {listing!.isDeleted && (
                  <>
                    {' ('}
                    <Content id={ContentId.Deleted} />
                    {')'}
                  </>
                )}
              </>
            }
          />
        </CancellableFormHeader>

        <ModalBody onKeyDown={onKeyDown}>
          <ModalBodyHeaderContainer>
            <Summary
              event={event!}
              posEntity={listing!}
              eventNameLink={inventoryEventPageUrl}
            />
          </ModalBodyHeaderContainer>
          <div className={modalDetails}>
            <ModalBodyDataContainer>
              <TabPanel
                tabs={tabsToDisplay}
                activeTabIndex={activeTabIndex}
                onValueChange={(v) => onTabChange(v as ListingTab)}
              />
            </ModalBodyDataContainer>
          </div>
        </ModalBody>

        <ListingDetailsFooter
          setIsLoading={setIsLoading}
          disabled={
            isLoading ||
            formState.isSubmitting ||
            (!hasIgnoreDupFeature && !!listing?.dupListingId)
          }
          onSubmit={hasChanges(getValues()) ? onSubmitHandler : undefined}
        />
      </Stack>
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.SuspiciousPriceChange} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={alerts}
        onOkay={() => {
          handleSubmit(onSubmit)();
          warningDialog.closeDialog();
        }}
        okText={ContentId.Yes}
        onCancel={() => warningDialog.closeDialog()}
      />
    </Stack>
  );
};
