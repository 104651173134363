/* IMPORTANT - whenever you add a new one here
   You need to add a handling for it in the BulkEditStatusListingPopover.tsx
  */
export const INVENTORY_BULK_UPDATE_LISTING_PRICES_UPDATE_KEY =
  'listingPrices-event-inventory';
export const INVENTORY_BULK_UPDATE_MARKETPLACE_PRICES_UPDATE_KEY =
  'marketplacePrices-event-inventory';
export const INVENTORY_BULK_UPDATE_PRICE_FLOOR_CEILING_UPDATE_KEY =
  'floorCeiling-event-inventory';
export const INVENTORY_BULK_UPDATE_AUTOPRICING_SETTINGS_UPDATE_KEY = `auto-pricing-settings-event-inventory`;
export const INVENTORY_BULK_UPDATE_LISTING_GROUP_SETTINGS_UPDATE_KEY = `listing-group-settings-event-inventory`;
export const INVENTORY_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY =
  'general-settings-event-inventory';
export const INVENTORY_BULK_UPDATE_ADMIN_HOLD_UPDATE_KEY =
  'admin-hold-event-inventory';
export const INVENTORY_BULK_BROADCAST_LISTINGS_KEY = `broadcast-event-inventory`;
export const INVENTORY_BULK_UPDATE_MARKETPLACE_SEATING_UPDATE_KEY =
  'marketplace-seating-event-inventory';
export const INVENTORY_BULK_UPDATE_COMMISSION_UPDATE_KEY =
  'commission-event-inventory';
export const INVENTORY_BULK_UPDATE_TAGS_UPDATE_KEY = 'tags-event-inventory';
export const INVENTORY_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY =
  'update-internal-notes-inventory';
export const INVENTORY_BULK_UPDATE_AUTOFULFILL_UPDATE_KEY =
  'update-autofulfill-inventory';
export const INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY = `create-listing-groups`;
export const INVENTORY_BULK_UPDATE_TICKET_TYPE_RULE_UPDATE_KEY =
  'ticket-type-rule-event-inventory';
export const INVENTORY_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY =
  'update-delivery-type-inventory';
export const INVENTORY_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY =
  'update-seat-traits-inventory';
export const INVENTORY_BULK_DELETE_SEAT_SAVERS_UPDATE_KEY =
  'delete-seat-savers-inventory';
export const INVENTORY_BULK_REMOVE_PREDELIVERY_UPDATE_KEY =
  'delete-predelivery-inventory';
export const INVENTORY_BULK_UNGROUP_LISTINGS_UPDATE_KEY =
  'ungroup-listings-inventory';
export const INVENTORY_BULK_WASTE_TICKETS_KEY = 'waste-tickets';
