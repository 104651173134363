import { clsx } from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack, Switch } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DeleteIcon, EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SALE_LINE_ITEM_CHARGE_REASON_TO_CID,
  SALE_LINE_ITEM_CREDIT_REASON_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { formatCurrency } from 'src/utils/numberFormatter';
import {
  Feature,
  Permission,
  SaleCostType,
  SaleDetails,
  SaleInput,
  SaleLineItem,
} from 'src/WebApiController';

import * as styles from './PaymentSectionTable.css';

interface PaymentSectionTableProps {
  sale: SaleDetails;
  lineItems: SaleLineItem[] | null | undefined;
  onEditSaleLineItem: (lineItem: SaleLineItem) => void;
  onDeleteSaleLineItem: (lineItem: SaleLineItem) => void;
  onPaymentReceivedToggleClicked: () => void;
  onSaleLineItemPaymentReceivedToggleClicked: (lineItem: SaleLineItem) => void;
  hidePartsForWastedSale: boolean;
}

export const PaymentSectionTable = ({
  sale,
  lineItems,
  onEditSaleLineItem,
  onDeleteSaleLineItem,
  onPaymentReceivedToggleClicked,
  onSaleLineItemPaymentReceivedToggleClicked,
  hidePartsForWastedSale,
}: PaymentSectionTableProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const { watch } = useFormContext<SaleInput>();
  const paymentReceived = watch('paymentReceived')?.value ?? false;
  const paymentReferenceId = watch('paymentReferenceId')?.value ?? '';
  const lastPaymentDate = watch('lastPaymentDate')?.value;
  const hasEditSaleLineItemsFeature = useUserHasFeature(
    Feature.SaleEditChargesAndCredits
  );

  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  let originalProceeds = null;
  if (sale.ttlNetProcs && sale.mkpSaleTtlNetProcs) {
    if (sale.ttlNetProcs.currency !== sale.mkpSaleTtlNetProcs.currency) {
      originalProceeds = `(${sale.mkpSaleTtlNetProcs.disp})`;
    }
  }

  const totalProfitAmount = useMemo(() => {
    let sum = hasTotalNetProceedsPermissions ? sale?.ttlNetProcs?.amt ?? 0 : 0;

    if (sale?.soldTktCost != null) {
      sum -= sale?.soldTktCost.amt;
    }

    if (hasEditSaleLineItemsFeature) {
      lineItems?.forEach((li) => {
        // Per James - charges subtracts and credits add
        sum += (li.costType === SaleCostType.Charge ? -1 : 1) * li.cost;
      });
    }

    return sum;
  }, [
    hasEditSaleLineItemsFeature,
    hasTotalNetProceedsPermissions,
    lineItems,
    sale?.soldTktCost,
    sale?.ttlNetProcs?.amt,
  ]);

  return (
    <SimpleTable.Table className={styles.table}>
      <SimpleTable.Thead>
        <SimpleTable.Tr className={styles.tableRow}>
          <SimpleTable.Th className={styles.tableHeader}>
            <Content id={ContentId.Overview} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={clsx(styles.alignRight, styles.tableHeader)}
          >
            <Content id={ContentId.Amount} />
          </SimpleTable.Th>
          <SimpleTable.Th className={styles.tableHeader}>
            <Content id={ContentId.Received} />
          </SimpleTable.Th>
          <SimpleTable.Th className={styles.tableHeader}>
            <Content id={ContentId.Reference} />
          </SimpleTable.Th>
          <SimpleTable.Th className={styles.tableHeader}>
            <Content id={ContentId.PaymentDate} />
          </SimpleTable.Th>
          <SimpleTable.Th className={styles.tableHeader}>
            <Content id={ContentId.Actions} />
          </SimpleTable.Th>
        </SimpleTable.Tr>
      </SimpleTable.Thead>
      <SimpleTable.Tbody>
        <SimpleTable.Tr className={styles.tableRow}>
          <SimpleTable.Td className={styles.tableCell}>
            <Content id={ContentId.Proceed} />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.alignRight}>
            {originalProceeds && (
              <span className={styles.originalProceeds}>
                {originalProceeds}{' '}
              </span>
            )}
            {sale.ttlNetProcs?.disp}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            <Switch
              disabled={hidePartsForWastedSale}
              checked={hidePartsForWastedSale ? false : paymentReceived}
              onChange={(e) => e.stopPropagation()}
              onClick={onPaymentReceivedToggleClicked}
            />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {paymentReferenceId}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {lastPaymentDate &&
              formatInTimeZone(
                stringToUtcDate(lastPaymentDate),
                timeZone,
                'MMM d, yyyy',
                {
                  locale: getLocaleFromLanguageOrCurrent(),
                }
              )}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}></SimpleTable.Td>
        </SimpleTable.Tr>
        {(lineItems ?? []).map((saleLineItem) => {
          const {
            id,
            costType,
            cost,
            costDate,
            currencyCode,
            saleLineItemChargeReason,
            saleLineItemCreditReason,
            paymentReceived,
            paymentReferenceId,
          } = saleLineItem;
          const isCharge = costType === SaleCostType.Charge;
          const lineTypeContentId = isCharge
            ? ContentId.Charge
            : ContentId.Credit;
          const costWithSign = isCharge ? cost * -1 : cost;
          const reasonContentId = isCharge
            ? saleLineItemChargeReason
              ? SALE_LINE_ITEM_CHARGE_REASON_TO_CID[saleLineItemChargeReason]
              : null
            : saleLineItemCreditReason
            ? SALE_LINE_ITEM_CREDIT_REASON_TO_CID[saleLineItemCreditReason]
            : null;
          return (
            <SimpleTable.Tr key={id} className={styles.tableRow}>
              <SimpleTable.Td className={styles.tableCell}>
                <Stack direction="column">
                  <Content id={lineTypeContentId} />
                  {reasonContentId && (
                    <span className={styles.chargeOrCreditReason}>
                      <Content id={reasonContentId} />
                    </span>
                  )}
                </Stack>
              </SimpleTable.Td>
              <SimpleTable.Td
                className={clsx(styles.alignRight, styles.tableCell)}
              >
                {formatCurrency(costWithSign, currencyCode)}
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.tableCell}>
                <Switch
                  checked={!!paymentReceived}
                  disabled={
                    saleLineItem.costType !== SaleCostType.Charge &&
                    saleLineItem.costType !== SaleCostType.Credit
                  }
                  onChange={(e) => e.stopPropagation()}
                  onClick={() =>
                    onSaleLineItemPaymentReceivedToggleClicked(saleLineItem)
                  }
                />
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.tableCell}>
                {paymentReferenceId}
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.tableCell}>
                {costDate &&
                  formatInTimeZone(
                    stringToUtcDate(costDate),
                    timeZone,
                    'MMM d, yyyy',
                    {
                      locale: getLocaleFromLanguageOrCurrent(),
                    }
                  )}
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.tableCell}>
                <Stack>
                  <IconButton
                    onClick={() => onEditSaleLineItem(saleLineItem)}
                    icon={
                      <EditIcon
                        withHoverEffect
                        fill={IconsFill.currentColor}
                        size={vars.iconSize.m}
                      />
                    }
                    titleContentId={ContentId.Edit}
                  />
                  <IconButton
                    onClick={() => onDeleteSaleLineItem(saleLineItem)}
                    icon={
                      <DeleteIcon
                        fill={IconsFill.currentColor}
                        withHoverEffect
                        size={vars.iconSize.m}
                      />
                    }
                    titleContentId={ContentId.Remove}
                  />
                </Stack>
              </SimpleTable.Td>
            </SimpleTable.Tr>
          );
        })}
        <SimpleTable.Tr
          className={clsx(styles.costAndProfitCell, styles.tableRow)}
        >
          <SimpleTable.Td className={styles.tableCell}>
            <Content id={ContentId.TotalCost} />
          </SimpleTable.Td>
          <SimpleTable.Td className={clsx(styles.alignRight, styles.tableCell)}>
            {sale?.soldTktCost == null ? (
              <Content id={ContentId.NA} />
            ) : (
              sale.soldTktCost.disp
            )}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
        </SimpleTable.Tr>
        <SimpleTable.Tr
          className={clsx(styles.costAndProfitCell, styles.tableRow)}
        >
          <SimpleTable.Td className={styles.tableCell}>
            <Content id={ContentId.Profit} />
          </SimpleTable.Td>
          <SimpleTable.Td className={clsx(styles.alignRight, styles.tableCell)}>
            {formatCurrency(totalProfitAmount, sale?.currency)}
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
          <SimpleTable.Td className={styles.tableCell} />
        </SimpleTable.Tr>
      </SimpleTable.Tbody>
    </SimpleTable.Table>
  );
};
