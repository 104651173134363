import { useCallback } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { LockedIcon } from 'src/svgs/LockedIcon';
import { UnlockedIcon } from 'src/svgs/UnlockedIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

interface LockCommissionsProps {
  isCommissionsLocked: boolean;
  onCommissionsLockChange: (isCommissionsLocked: boolean) => void;
  disabledLockButton?: boolean;
}

export const LockCommissions = ({
  isCommissionsLocked,
  onCommissionsLockChange,
  disabledLockButton,
}: LockCommissionsProps) => {
  const onCommissionsLockClick = useCallback(() => {
    onCommissionsLockChange(!isCommissionsLocked);
  }, [isCommissionsLocked, onCommissionsLockChange]);

  return (
    <div>
      {isCommissionsLocked ? (
        <Button variant={'link'} onClick={onCommissionsLockClick}>
          <LockedIcon stroke={IconsFill.currentColor} size={vars.iconSize.m} />
          <Content id={ContentId.UnlockCommission} />
        </Button>
      ) : (
        <Button
          variant={'link'}
          onClick={onCommissionsLockClick}
          disabled={disabledLockButton}
        >
          <UnlockedIcon
            stroke={IconsFill.currentColor}
            size={vars.iconSize.m}
          />
          <Content id={ContentId.LockCommission} />
        </Button>
      )}
    </div>
  );
};
