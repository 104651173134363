import { MarketplacePaymentStatePill } from 'src/components/common/MarketplacePaymentStatePill/MarketplacePaymentStatePill';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { useHasSaleDetailsMarketplaceTermsFeature } from 'src/hooks/features/useHasSaleDetailsMarketplaceTermsFeature';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup } from 'src/modals/common';
import { MarketplacePaymentLines } from 'src/modals/SaleDetails/components/MarketplacePaymentSection/MarketplacePaymentLines';
import { useHasPaymentsSectionsV2Feature } from 'src/modals/SaleDetails/hooks/useHasPaymentsSectionsV2Feature';
import { useShouldHideForWastedSale } from 'src/modals/SaleDetails/hooks/useShouldHideForWastedSale';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
  SALES_STATUS_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { Feature, Permission, SaleDetails } from 'src/WebApiController';

import * as styles from './MarketplacePaymentSection.css';

export const MarketplacePaymentSection = ({
  sale,
}: {
  sale?: SaleDetails | null;
}) => {
  const hasMarketplacePaymentLinesDisplayFeature = useUserHasFeature(
    Feature.MarketplacePaymentLinesDisplay
  );
  const hasManageMarketplacePaymentFeature = useUserHasFeature(
    Feature.ManageMarketplacePayment
  );

  const hasViewMarketplacePaymentLinesPermission = useUserHasAnyOfPermissions(
    Permission.Accounting_ViewAll,
    Permission.Accounting_ViewPaymentsOnSale
  );

  const canViewMarketplacePaymentLines =
    hasViewMarketplacePaymentLinesPermission &&
    (hasMarketplacePaymentLinesDisplayFeature ||
      hasManageMarketplacePaymentFeature);

  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();
  const hasSaleDetailsMarketplaceTermsFeature =
    useHasSaleDetailsMarketplaceTermsFeature();

  const hideForWastedSale = useShouldHideForWastedSale(sale);

  return (
    <Stack direction="column" gap="l">
      <DetailGroup
        style={{ flexDirection: hideForWastedSale ? 'row' : 'column' }}
      >
        {hideForWastedSale && sale?.status && (
          <Detail
            label={<Content id={ContentId.Status} />}
            detail={<Content id={SALES_STATUS_TO_CID[sale.status]} />}
          />
        )}
        {!hasSaleDetailsMarketplaceTermsFeature && (
          <Detail
            label={<Content id={ContentId.PaymentState} />}
            detail={
              <span className={styles.emphasizedText}>
                {sale?.mkpPmtState ? (
                  <>
                    {hasPaymentsSectionsV2Feature ? (
                      <MarketplacePaymentStatePill state={sale.mkpPmtState} />
                    ) : (
                      <Content
                        id={
                          MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[
                            sale.mkpPmtState
                          ]
                        }
                      />
                    )}
                  </>
                ) : sale?.pmtState ? (
                  <>
                    {hasPaymentsSectionsV2Feature ? (
                      <MarketplacePaymentStatePill state={sale.pmtState} />
                    ) : (
                      <Content id={SALE_PAYMENT_STATE_TO_CID[sale.pmtState]} />
                    )}
                  </>
                ) : null}
              </span>
            }
          />
        )}
      </DetailGroup>
      {canViewMarketplacePaymentLines && sale && (
        <MarketplacePaymentLines
          sale={sale}
          canManageMarketplacePayment={hasManageMarketplacePaymentFeature}
        />
      )}
    </Stack>
  );
};
