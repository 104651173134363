import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PaymentSection } from 'src/modals/SaleDetails/components/PaymentSection';
import { PaymentSectionV2 } from 'src/modals/SaleDetails/components/PaymentSectionV2';
import { useHasPaymentsSectionsV2Feature } from 'src/modals/SaleDetails/hooks/useHasPaymentsSectionsV2Feature';
import { PaymentSection as PaymentSectionSaleModalV2 } from 'src/modals/SaleDetailsV2/components/PaymentSection';
import { Feature, SaleDetails, SaleLineItem } from 'src/WebApiController';

interface PaymentSectionSelectorProps {
  sale?: SaleDetails | null;
  lineItems: SaleLineItem[] | null | undefined;
  onLineItemChange?: (item: SaleLineItem, isDelete?: boolean) => void;
}

export const PaymentSectionSelector = ({
  sale,
  lineItems,
  onLineItemChange,
}: PaymentSectionSelectorProps) => {
  const hasPaymentsSectionsV2Feature = useHasPaymentsSectionsV2Feature();
  const userHasSaleDetailsModalUIV2 = useUserHasFeature(Feature.SaleDetailUIV2);

  return hasPaymentsSectionsV2Feature ? (
    <PaymentSectionV2
      lineItems={lineItems}
      onLineItemChange={onLineItemChange}
      sale={sale}
    />
  ) : userHasSaleDetailsModalUIV2 ? (
    <PaymentSectionSaleModalV2
      lineItems={lineItems}
      onLineItemChange={onLineItemChange}
      sale={sale}
    />
  ) : (
    <PaymentSection
      lineItems={lineItems}
      onLineItemChange={onLineItemChange}
      sale={sale}
    />
  );
};
