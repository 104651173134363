import { formatInTimeZone } from 'date-fns-tz';
import { isEqual } from 'lodash-es';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { CancelPurchaseDialog } from 'src/dialogs/CancelPurchaseDialog';
import { RestorePurchaseDialog } from 'src/dialogs/RestorePurchaseDialog';
import { UnsavedChanges } from 'src/dialogs/UnsavedChanges';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetIsPurchaseIdsInUsedForVendorId } from 'src/hooks/useGetIsPurchaseIdsInUsedForVendorId';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LaunchActivityLog } from 'src/modals/ActivityLog';
import { FieldWrapper, HeaderFieldLabel } from 'src/modals/common';
import { PurchaseCurrency } from 'src/modals/common/Purchase/PurchaseCurrency';
import { PurchaseSellerAccountSelector } from 'src/modals/common/Purchase/PurchaseSellerAccountSelector/PurchaseSellerAccountSelector';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PURCHASE_ORDER_STATE_TO_CID,
  QUALITY_CONTROL_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getPathFromMainRoute } from 'src/utils/deepLinkUtils';
import {
  DefaultListingQuery,
  DefaultSaleQuery,
  getPurchaseOrderIdListingQuery,
  getPurchaseOrderIdSaleQuery,
  getUrlFilterQueryParam,
} from 'src/utils/eventQueryUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  isCancelledState,
  PurchaseOrderDetailsInput,
} from 'src/utils/purchaseUtils';
import {
  Feature,
  Permission,
  PurchaseOrderDetails,
  PurchasingIdInUseResultType,
  QualityControlState,
} from 'src/WebApiController';

import { ShareButton } from '../../ShareButton/ShareButton';
import { summaryWrapper } from '../../Summary/Summary.css';
import { fieldText } from '../Purchase.css';
import { PurchaseVendorAccountSelectionInput } from '../PurchaseVendorAccountSelectionInput';
import { PurchaseVendorSelectionInput } from '../PurchaseVendorSelectionInput';
import * as styles from './PurchaseVendorHeader.css';
import {
  DeliverAlternativeAccountCheckboxWrapper,
  HeaderLeftGroup,
  HeaderRightGroup,
  HeaderVendorContainer,
  PurchaseOrderStateContainer,
} from './PurchaseVendorHeader.styled';

export const PurchaseVendorHeader = ({
  editMode,
  disabled,
  numOfSales,
  numOfListings,
  onToggleEditDetails,
  onGoToMessageThread,
  enableMarkAsPaid,
  onMarkPurchaseAsPaid,
}: {
  editMode: boolean;
  disabled?: boolean;
  numOfSales: number;
  numOfListings: number;
  onToggleEditDetails?: () => void;
  onGoToMessageThread?: () => void;
  enableMarkAsPaid?: boolean;
  onMarkPurchaseAsPaid?: () => void;
}) => {
  const { timeZone } = useSiteTimezoneContext();
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    formState,
    clearErrors,
    setError,
  } = useFormContext<PurchaseOrderDetailsInput>();

  const { posEntity: purchaseOrder, setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();

  const hasCreatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Create
  );
  const hasDisplayDeactivatedUser = useUserHasFeature(
    Feature.DisplayDeactivatedUser
  );

  const settings = useSyncCenterSettings();
  const { isAutoPoQualityControlled = false } = settings.data ?? {};

  const {
    id,
    vendor,
    vendorAccount,
    secondaryVendor,
    secondaryVendorAccount,
    vendorOrderId,
    purchaseDate,
    transferId,
    currencyCode,
  } = getValues();

  const purchaseOrderId = watch('id');
  const buyerUserId = watch('buyerUserId');

  const useGetUserInfosQuery = useGetUserInfos(
    buyerUserId?.value ? [buyerUserId.value] : []
  );

  const buyerUserInfo =
    buyerUserId?.value == null
      ? null
      : useGetUserInfosQuery.data?.[buyerUserId.value];

  const qcState = watch('qcState');

  const curLocale = getLocaleFromLanguageOrCurrent();

  const vendorOrderIdPlaceholder = useContent(
    ContentId.EnterPurchaseOrderIdFromVendor
  );

  const transferIdPlaceHolder = useContent(
    ContentId.EnterTransferIdForPurchase
  );

  const requiredMsg = useContent(ContentId.Required);

  const cancelVoidDialog = useBasicDialog();
  const restorePurchaseDialog = useBasicDialog();

  const isCancelled = isCancelledState(purchaseOrder?.poState);
  const isDisabled = disabled || isCancelled;

  const navigateUnsavedDialog = useBasicDialog();
  const navigate = useNavigate();
  const [navigateUrl, setNavigateUrl] = useState<string | undefined>();

  const deactivatedText = useContent(ContentId.Deactivated);
  const youHaveNoSalesMsg = useContent(ContentId.PurchaseHaveNoSales);
  const youHaveNoListingsMsg = useContent(ContentId.PurchaseHaveNoListings);

  const [showSecondaryVendorInputs, setShowSecondaryVendorInputs] =
    useState<boolean>(
      Boolean(secondaryVendor?.value || secondaryVendorAccount?.value)
    );

  const onViewUrlClick = useCallback(
    (e: React.MouseEvent, url: string) => {
      if (e.button === 0 || e.button === 1) {
        e.preventDefault();
        e.stopPropagation();

        if (e.button === 1) {
          // Middle-clicked open a new browser-tab
          window.open(url, '_blank');
        } else if (isEqual(getValues(), formState.defaultValues)) {
          navigate(url);
        } else {
          // Need to confirm unsave changes
          setNavigateUrl(url);
          navigateUnsavedDialog.launchDialog();
        }
      }
    },
    [formState.defaultValues, getValues, navigate, navigateUnsavedDialog]
  );

  const viewSaleOnClick = useCallback(
    (e: React.MouseEvent) => {
      const navigateUrl = `${getPathFromMainRoute(
        MainRoute.Sales
      )}${getUrlFilterQueryParam(
        DefaultSaleQuery,
        getPurchaseOrderIdSaleQuery(id)
      )}`;
      onViewUrlClick(e, navigateUrl);
    },
    [id, onViewUrlClick]
  );

  const viewInventoryOnClick = useCallback(
    (e: React.MouseEvent) => {
      const navigateUrl = `${getPathFromMainRoute(
        MainRoute.Inventory
      )}${getUrlFilterQueryParam(
        DefaultListingQuery,
        getPurchaseOrderIdListingQuery(id)
      )}`;
      onViewUrlClick(e, navigateUrl);
    },
    [id, onViewUrlClick]
  );

  const actions = useMemo(() => {
    const actions: ReactNode[] = [
      <PosDropdownItem
        key={ContentId.EditPurchaseDetails}
        onClick={onToggleEditDetails}
      >
        <Content id={ContentId.EditPurchaseDetails} />
      </PosDropdownItem>,
    ];
    if (id > 0) {
      actions.push(
        <PosDropdownItem
          key={ContentId.CancelVoidPurchase}
          onClick={() => cancelVoidDialog.launchDialog()}
        >
          <Content id={ContentId.CancelPurchase} />
        </PosDropdownItem>
      );

      if (!isDisabled && hasCreatePermission && onGoToMessageThread) {
        actions.push(
          <PosDropdownItem
            key={ContentId.GoToMessageThread}
            onClick={onGoToMessageThread}
          >
            <Content id={ContentId.GoToMessageThread} />
          </PosDropdownItem>
        );
      }

      if (isCancelled) {
        actions.push(
          <PosDropdownItem
            key={ContentId.RestorePurchase}
            onClick={() => {
              restorePurchaseDialog.launchDialog();
            }}
          >
            <Content id={ContentId.RestorePurchase} />
          </PosDropdownItem>
        );
      }

      if (enableMarkAsPaid) {
        actions.push(
          <PosDropdownItem
            key={ContentId.MarkPurchaseAsPaid}
            onClick={onMarkPurchaseAsPaid}
          >
            <Content id={ContentId.MarkPurchaseAsPaid} />
          </PosDropdownItem>
        );
      }

      if (editMode) {
        actions.push(
          <PosDropdownItem
            key={ContentId.ViewSales}
            disabled={numOfSales === 0}
            onClick={viewSaleOnClick}
            onMouseDown={viewSaleOnClick}
            title={numOfSales === 0 ? youHaveNoSalesMsg : undefined}
          >
            <Content id={ContentId.ViewSales} />
          </PosDropdownItem>,
          <PosDropdownItem
            key={ContentId.ViewInventory}
            disabled={numOfListings === 0}
            onClick={viewInventoryOnClick}
            onMouseDown={viewInventoryOnClick}
            title={numOfListings === 0 ? youHaveNoListingsMsg : undefined}
          >
            <Content id={ContentId.ViewInventory} />
          </PosDropdownItem>
        );
      }
    }
    return actions;
  }, [
    onToggleEditDetails,
    id,
    isDisabled,
    hasCreatePermission,
    onGoToMessageThread,
    isCancelled,
    enableMarkAsPaid,
    editMode,
    cancelVoidDialog,
    restorePurchaseDialog,
    onMarkPurchaseAsPaid,
    numOfSales,
    viewSaleOnClick,
    youHaveNoSalesMsg,
    numOfListings,
    viewInventoryOnClick,
    youHaveNoListingsMsg,
  ]);

  const viewEntityButtons =
    id > 0 ? (
      <>
        <Button
          variant="outline"
          disabled={numOfSales === 0}
          onClick={viewSaleOnClick}
          onMouseDown={viewSaleOnClick}
          title={numOfSales === 0 ? youHaveNoSalesMsg : undefined}
        >
          <Content id={ContentId.ViewSales} />
        </Button>
        <Button
          variant="outline"
          disabled={numOfListings === 0}
          onClick={viewInventoryOnClick}
          onMouseDown={viewInventoryOnClick}
          title={numOfListings === 0 ? youHaveNoListingsMsg : undefined}
        >
          <Content id={ContentId.ViewInventory} />
        </Button>
      </>
    ) : null;

  const goToNavigation = useCallback(() => {
    if (navigateUrl) {
      navigate(navigateUrl);
    }
    navigateUnsavedDialog.closeDialog();
  }, [navigate, navigateUnsavedDialog, navigateUrl]);

  const orderIdUsedWarningMessage = useContent(ContentId.OrderIdInUse);
  const orderIdUsedErrorMessage = useContent(ContentId.OrderIdInUseSameVendor);
  const [orderIdBlurred, setOrderIdBlurred] = useState<string>();
  const { purchasingIdInUseQuery } = useGetIsPurchaseIdsInUsedForVendorId(
    orderIdBlurred,
    vendor?.value?.id
  );

  useEffect(() => {
    if (purchaseOrderId < 0) {
      if (
        purchasingIdInUseQuery.data ===
        PurchasingIdInUseResultType.SameOrderIdAndVendorId
      ) {
        setError('vendorOrderId', { message: orderIdUsedErrorMessage });
      } else {
        if (
          formState.errors.vendorOrderId?.message === orderIdUsedErrorMessage
        ) {
          clearErrors('vendorOrderId');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasingIdInUseQuery.data]);

  const orderIdOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setOrderIdBlurred(e.target.value);
  };

  return (
    <>
      {!editMode || isDisabled ? (
        <div className={summaryWrapper}>
          <header className={styles.headerSection}>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup>
                <HeaderVendorContainer>
                  {vendor?.value?.name}
                </HeaderVendorContainer>
              </HeaderLeftGroup>
              <HeaderRightGroup>
                {isCancelled && (
                  <PurchaseOrderStateContainer>
                    <Content
                      id={PURCHASE_ORDER_STATE_TO_CID[purchaseOrder!.poState]}
                    />
                  </PurchaseOrderStateContainer>
                )}
              </HeaderRightGroup>
            </div>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup>
                <FieldWrapper>
                  <HeaderFieldLabel>
                    <Content id={ContentId.ExternalPurchaseId} />
                  </HeaderFieldLabel>
                  <div className={styles.orderIdShareButtonWrapper}>
                    <div className={fieldText}>{vendorOrderId?.value}</div>
                    <div className={styles.sharedButtonWrapper}>
                      <ShareButton
                        deepLinkQueryParam={PurchaseDeeplinkQueryParam}
                        isCopiedLocation="right"
                      />
                    </div>
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <HeaderFieldLabel>
                    <Content id={ContentId.PurchaseDate} />
                  </HeaderFieldLabel>
                  <div className={fieldText}>
                    {purchaseDate?.value &&
                      formatInTimeZone(
                        stringToUtcDate(purchaseDate.value),
                        timeZone,
                        'MMM d, yyyy h:mm a',
                        {
                          locale: curLocale,
                        }
                      )}
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <HeaderFieldLabel>
                    <Content id={ContentId.Currency} />
                  </HeaderFieldLabel>
                  <div className={fieldText}>{currencyCode?.value}</div>
                </FieldWrapper>
                {transferId?.value && (
                  <FieldWrapper>
                    <HeaderFieldLabel>
                      <Content id={ContentId.TransferId} />
                    </HeaderFieldLabel>
                    <div className={fieldText}>{transferId.value}</div>
                  </FieldWrapper>
                )}
                {isAutoPoQualityControlled && qcState != null && (
                  <FieldWrapper>
                    <HeaderFieldLabel>
                      <Content id={ContentId.QualityControl} />
                    </HeaderFieldLabel>
                    {qcState.hasChanged ||
                    qcState?.value === QualityControlState.Pending ? (
                      <Controller
                        control={control}
                        name="qcState"
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <Checkbox
                              name={name}
                              labelPosition="right"
                              label={<Content id={ContentId.Approved} />}
                              checked={
                                value?.value === QualityControlState.Approved
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                onChange(
                                  posChangedField(
                                    isChecked
                                      ? QualityControlState.Approved
                                      : QualityControlState.Pending
                                  )
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <div className={fieldText}>
                        {qcState.value && (
                          <Content
                            id={QUALITY_CONTROL_STATE_TO_CID[qcState.value]}
                          />
                        )}
                      </div>
                    )}
                  </FieldWrapper>
                )}
              </HeaderLeftGroup>
              <HeaderRightGroup>
                <FieldWrapper>
                  <HeaderFieldLabel>
                    <Content id={ContentId.MyAccount} />
                  </HeaderFieldLabel>
                  <div className={fieldText}>
                    {vendorAccount?.value?.email ?? vendorAccount?.value?.name}
                  </div>
                </FieldWrapper>
                <FieldWrapper>
                  <HeaderFieldLabel>
                    <Content id={ContentId.PurchasedBy} />
                  </HeaderFieldLabel>
                  <div className={fieldText}>
                    {`${buyerUserInfo?.name} ${
                      buyerUserInfo?.isDeactivated && !hasDisplayDeactivatedUser
                        ? ` (${deactivatedText})`
                        : ''
                    }`}
                  </div>
                  <div className={fieldText}>{buyerUserInfo?.email}</div>
                </FieldWrapper>
              </HeaderRightGroup>
            </div>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup></HeaderLeftGroup>
              <HeaderRightGroup>
                <FieldWrapper>
                  <div className={styles.menuContainer}>
                    {purchaseOrderId > 0 ? (
                      <LaunchActivityLog
                        entityId={purchaseOrderId}
                        cancelTo={getPurchaseOrderDetailsModalConfigWithDeepLink(
                          purchaseOrderId
                        )}
                      />
                    ) : undefined}
                    {viewEntityButtons}
                    {purchaseOrderId > 0 && actions.length > 0 && (
                      // only show this when NOT creating new purchase
                      <PosDropdown
                        trigger={
                          <MoreIcon
                            withHoverEffect
                            fill={IconsFill.textBrand}
                            align="middle"
                          />
                        }
                        align="end"
                      >
                        {actions}
                      </PosDropdown>
                    )}
                  </div>
                </FieldWrapper>
              </HeaderRightGroup>
            </div>
          </header>
        </div>
      ) : (
        <div className={summaryWrapper}>
          <header className={styles.headerSection}>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup>
                <div className={styles.vendorContainer}>
                  <PosFormField
                    errors={formState.errors.vendor?.message}
                    label={<Content id={ContentId.Website} />}
                  >
                    <PurchaseVendorSelectionInput
                      fieldName="vendor"
                      style={{ width: '100%' }}
                    />
                  </PosFormField>
                </div>
              </HeaderLeftGroup>
              <HeaderRightGroup editMode>
                <div className={styles.vendorContainer}>
                  <PosFormField
                    errors={formState.errors.vendorAccount?.message}
                    label={<Content id={ContentId.Account} />}
                  >
                    <PurchaseVendorAccountSelectionInput
                      vendorFieldName="vendor"
                      fieldName="vendorAccount"
                      style={{ width: '100%' }}
                    />
                  </PosFormField>
                </div>
              </HeaderRightGroup>
            </div>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup>
                <FieldWrapper>
                  <PosFormField
                    errors={formState.errors.vendorOrderId?.message}
                    warnings={
                      purchasingIdInUseQuery.data ===
                      PurchasingIdInUseResultType.SameOrderId
                        ? orderIdUsedWarningMessage
                        : undefined
                    }
                    label={<Content id={ContentId.ExternalPurchaseId} />}
                  >
                    <PosTextField
                      rootProps={{
                        state: getTextFieldState(
                          formState.errors.vendorOrderId
                        ),
                      }}
                      value={vendorOrderId?.value || undefined}
                      placeholder={vendorOrderIdPlaceholder}
                      spellCheck={false}
                      {...register('vendorOrderId.value', {
                        validate: {
                          required: (fieldVal) => {
                            return fieldVal?.length ? undefined : requiredMsg;
                          },
                        },

                        onChange: (event) => {
                          if (event.target.value) {
                            clearErrors('vendorOrderId');
                          }
                          setValue('vendorOrderId.hasChanged', true);
                        },
                      })}
                      onBlur={orderIdOnBlur}
                      trimOnBlur={true}
                    />
                  </PosFormField>
                </FieldWrapper>
                <FieldWrapper>
                  <PosFormField
                    label={<Content id={ContentId.PurchaseDate} />}
                    errors={formState.errors.vendor?.message}
                  >
                    <DatePickerInput
                      fieldError={formState.errors.vendor?.message}
                      date={
                        purchaseDate?.value
                          ? new Date(purchaseDate.value)
                          : undefined
                      }
                      onDateChange={(date: Date) => {
                        if (date) {
                          clearErrors('purchaseDate');
                          const dateStr = date.toISOString();
                          if (dateStr !== purchaseDate?.value) {
                            setValue('purchaseDate', posChangedField(dateStr));
                          }
                        }
                      }}
                      locale={getLocaleFromLanguageOrCurrent()}
                    />
                  </PosFormField>
                </FieldWrapper>
              </HeaderLeftGroup>
              <HeaderRightGroup editMode>
                <FieldWrapper>
                  <PosFormField
                    errors={formState.errors.buyerUserId?.message}
                    label={<Content id={ContentId.PurchasedBy} />}
                  >
                    <PurchaseSellerAccountSelector />
                  </PosFormField>
                </FieldWrapper>
                <FieldWrapper>
                  <PurchaseCurrency />
                </FieldWrapper>
              </HeaderRightGroup>
            </div>
            <div className={styles.headerGroups}>
              <HeaderLeftGroup></HeaderLeftGroup>
              <HeaderRightGroup>
                {!isCancelled && purchaseOrderId > 0 && actions.length > 0 && (
                  <FieldWrapper>
                    <div className={styles.menuContainer}>
                      <PosDropdown
                        trigger={
                          <MoreIcon
                            withHoverEffect
                            fill={IconsFill.textBrand}
                            align="middle"
                          />
                        }
                        align="end"
                      >
                        {actions}
                      </PosDropdown>
                    </div>
                  </FieldWrapper>
                )}
              </HeaderRightGroup>
            </div>
            <Stack direction="column" gap="l" width="full">
              {!isDisabled && !isCancelled && vendorAccount?.value && (
                <DeliverAlternativeAccountCheckboxWrapper>
                  <Checkbox
                    checked={!showSecondaryVendorInputs}
                    onChange={(e) => {
                      const value = e.target.checked;
                      if (!value) {
                        setShowSecondaryVendorInputs(true);
                      } else {
                        setShowSecondaryVendorInputs(false);
                        setValue('secondaryVendor', null);
                        setValue('secondaryVendorAccount', null);
                      }
                    }}
                    labelPosition="right"
                    label={
                      <FormatContent
                        id={FormatContentId.WillDeliverToSecondaryAccount}
                        params={vendor?.value?.name}
                      />
                    }
                  />
                </DeliverAlternativeAccountCheckboxWrapper>
              )}
              {showSecondaryVendorInputs && (
                <>
                  <div className={styles.headerGroups}>
                    <HeaderLeftGroup>
                      <PosFormField
                        errors={formState.errors.secondaryVendor?.message}
                        label={<Content id={ContentId.DeliveryWebsite} />}
                      >
                        <PurchaseVendorSelectionInput
                          fieldName="secondaryVendor"
                          style={{ width: '100%' }}
                        />
                      </PosFormField>
                    </HeaderLeftGroup>
                    <HeaderRightGroup editMode>
                      <PosFormField
                        errors={
                          formState.errors.secondaryVendorAccount?.value
                            ?.message
                        }
                        label={<Content id={ContentId.DeliveryAccount} />}
                      >
                        <PurchaseVendorAccountSelectionInput
                          vendorFieldName="secondaryVendor"
                          fieldName="secondaryVendorAccount"
                          style={{ width: '100%' }}
                        />
                      </PosFormField>
                    </HeaderRightGroup>
                  </div>
                  <div className={styles.headerGroups}>
                    <HeaderLeftGroup>
                      <PosFormField
                        label={<Content id={ContentId.TransferId} />}
                      >
                        <PosTextField
                          placeholder={transferIdPlaceHolder}
                          {...register('transferId.value', {
                            onChange: (event) => {
                              setValue('transferId.hasChanged', true);
                            },
                          })}
                        />
                      </PosFormField>
                    </HeaderLeftGroup>
                    <HeaderRightGroup></HeaderRightGroup>
                  </div>
                  <div
                    className={fieldText}
                    style={{ paddingTop: '10px', color: 'grey', opacity: 0.8 }}
                  >
                    <Content id={ContentId.TransferIdNote} />
                  </div>
                </>
              )}
            </Stack>
          </header>
        </div>
      )}
      <CancelPurchaseDialog
        {...cancelVoidDialog.dialogProps}
        purchaseOrderId={id}
        numOfSales={numOfSales ?? 0}
        onSave={async () => {
          setActivePosEntity(id, vendorOrderId?.value, true);
          if (refreshData) {
            await refreshData(true);
          }
          if (refreshExpandedListItems) {
            await refreshCatalog();
            await refreshExpandedListItems();
          }
          cancelVoidDialog.closeDialog();
        }}
        onCancel={() => cancelVoidDialog.closeDialog()}
      />
      <RestorePurchaseDialog
        {...restorePurchaseDialog.dialogProps}
        purchaseOrderId={id}
        onSave={async () => {
          setActivePosEntity(id, vendorOrderId?.value, true);

          // We need to call refreshData here because setActivePosEntity only update the context data for CatalogContext (listing/sales)
          // but for purchase, it has it's own data context so we have to manually call it here
          // and since the setActivePosEntity doesn't actually update the table data, no need to await it
          if (refreshData) {
            await refreshData(true);
          }
          if (refreshExpandedListItems) {
            await refreshCatalog();
            await refreshExpandedListItems();
          }
          restorePurchaseDialog.closeDialog();
        }}
        onCancel={() => restorePurchaseDialog.closeDialog()}
      />
      <UnsavedChanges
        {...navigateUnsavedDialog.dialogProps}
        onOkay={goToNavigation}
        onCancel={() => navigateUnsavedDialog.closeDialog()}
      />
    </>
  );
};
