import { useQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQuery } from 'src/utils/getServerSideOnlyMetricsQuery';
import {
  CatalogClient,
  EntityWithTicketsQuery,
  Feature,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { getEventsToGetMetricsFor } from './CatalogMetrics.utils';
import { getEventGroupsToGetMetricsFor } from './useGetEventGroupsToGetMetricsFor';

export function useGetDetailedMetricsQuery<
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
  TDetailedMetrics = object,
>(
  queryKey: string,
  getCatalogDetailedMetrics?: (
    client: CatalogClient,
    filterQuery: TQuery
  ) => Promise<Record<string, TDetailedMetrics>>,
  disabled?: boolean
) {
  const { trackError } = useErrorBoundaryContext();

  const { data } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();

  // Removing the client-side queries
  const transformedQuery = getServerSideOnlyMetricsQuery(filterQuery) as TQuery;

  const hasNoBatchMetricsCallFeatures = useUserHasFeature(
    Feature.NoBatchMetricsCalls
  );
  const groupsToGetMetricsFor = useMemo(
    () =>
      hasNoBatchMetricsCallFeatures
        ? getEventsToGetMetricsFor(
            data,
            filterQuery.sortBy,
            filterQuery.isSortDescending
          )
        : getEventGroupsToGetMetricsFor(data),
    [
      data,
      filterQuery.isSortDescending,
      filterQuery.sortBy,
      hasNoBatchMetricsCallFeatures,
    ]
  );

  const detailedMetricsQueriesFromHook = useQueries({
    queries: groupsToGetMetricsFor.map((idGroup) => {
      const shouldQuery =
        !disabled &&
        !(
          !idGroup.length ||
          !getCatalogDetailedMetrics ||
          !transformedQuery ||
          !activeAccountWebClientConfig.activeAccountId
        );

      return {
        queryKey: [
          `${queryKey}-detailed`,
          transformedQuery,
          idGroup,
          activeAccountWebClientConfig,
        ],
        queryFn: async () => {
          if (!shouldQuery) {
            return null;
          }

          const metrics = await getCatalogDetailedMetrics!(
            new CatalogClient(activeAccountWebClientConfig),
            {
              ...transformedQuery,
              eventOrMappingIds: idGroup.map((ev) => ev.viagVirtualId),
              performerIds: idGroup
                .filter((ev) => ev.performerId != null)
                .map((ev) => ev.performerId!),
              venueIds: idGroup.map((ev) => ev.venueId),
            }
          );

          return metrics;
        },
        enabled: shouldQuery,
        staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
        refetchOnWindowFocus: false,
        onError: (error: ErrorTypes) => {
          trackError('CatalogClient.getCatalogDetailedMetrics', error, {
            ...transformedQuery,
            eventIds: idGroup,
          });

          // Since this is just loading metrics, we have the data so we'll just ignore that metrics error
        },
        refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
      };
    }),
  });
  const [detailedMetricsQueries, setDetailedMetricsQueries] = useState(
    detailedMetricsQueriesFromHook
  );

  /**
   * IMPORTANT: This is needed because event that useQueries receives a memoized object,
   * on every render it returns a new array with metrics, causing the app
   * to re-render.
   */
  useEffect(() => {
    if (!isEqual(detailedMetricsQueries, detailedMetricsQueriesFromHook)) {
      setDetailedMetricsQueries(detailedMetricsQueriesFromHook);
      return;
    }
  }, [detailedMetricsQueriesFromHook, detailedMetricsQueries]);

  const detailedMetrics = useMemo(() => {
    const getMergedMetricsRecord = (
      metricsRecords: (Record<string, TDetailedMetrics> | undefined)[]
    ): Record<string, TDetailedMetrics> | undefined => {
      const entries = metricsRecords.filter((m) => m != null);
      if (!entries.length) {
        return undefined;
      }

      return entries.reduce(
        (obj, m) => {
          return { ...obj, ...(m ?? {}) };
        },
        {} as Record<string, TDetailedMetrics>
      );
    };
    return getMergedMetricsRecord(
      detailedMetricsQueries.map((q) => q.data ?? undefined)
    );
  }, [detailedMetricsQueries]);

  const refresh = useCallback(
    () => detailedMetricsQueries.map((q) => q.refetch()),
    [detailedMetricsQueries]
  );

  return {
    refresh,
    detailedMetrics,
  };
}
