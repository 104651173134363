import { useMultipleInternalNotesFilter } from 'src/components/Filters/InternalNotesFilter/useMultipleInternalNotesFilter';
import { TextFilterQuery } from 'src/components/Filters/TextFilter/TextFilter';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import {
  ActionOutboxEntityType,
  EntityWithTicketsQuery,
} from 'src/WebApiController';

interface InternalNotesFilterProps {
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale;
  isHorizontalLayout?: boolean;
}

export const useInternalNotesFilter = <
  TQuery extends QueryWithViewMode &
    TextFilterQuery &
    Pick<EntityWithTicketsQuery, 'internalNotes'>,
>({
  entityType,
  isHorizontalLayout,
}: InternalNotesFilterProps) => {
  const multipleInternalNotesFilter = useMultipleInternalNotesFilter<TQuery>({
    entityType,
    isHorizontalLayout,
  });

  return multipleInternalNotesFilter;
};
