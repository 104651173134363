import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { DetailSection } from 'src/modals/common';
import { useShouldHideForWastedSale } from 'src/modals/SaleDetails/hooks/useShouldHideForWastedSale';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

import * as styles from './SeatSoldAss.css';
import { TicketsAllocated } from './TicketsAllocated';
import { TicketsSoldAs } from './TicketsSoldAs';

interface SeatsSoldAsProps {
  sale: SaleDetails | undefined | null;
}

export const SeatsSoldAs = ({ sale }: SeatsSoldAsProps) => {
  const hideForWastedSale = useShouldHideForWastedSale(sale);

  if (!sale) {
    return null;
  }

  return (
    <>
      {!hideForWastedSale && sale.tickets.length ? (
        <DetailSection
          className={styles.noBorderBottom}
          name={
            <Stack direction="column" gap="m">
              <Content id={ContentId.TicketsAllocated} />
            </Stack>
          }
        >
          <TicketsAllocated sale={sale} />
        </DetailSection>
      ) : null}

      <DetailSection
        name={
          <Stack direction="column" gap="m">
            {hideForWastedSale ? (
              <Content id={ContentId.Tickets} />
            ) : (
              <Content id={ContentId.TicketsSoldAs} />
            )}
          </Stack>
        }
      >
        <TicketsSoldAs sale={sale} />
      </DetailSection>
    </>
  );
};
