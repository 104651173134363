import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { DEAL_CONFIG_SPLIT_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { posChangedField } from 'src/utils/posFieldUtils';
import { DealSplitType, PurchaseOrderDetailsInput } from 'src/WebApiController';

import { createDefaultDealConfig } from './DealConfigSection';
import * as styles from './Deals.css';

export const DealSplitTypeSection = ({
  dealSplitType,
}: {
  dealSplitType: DealSplitType | null;
}) => {
  const { updateLocalDealDetails } = useDealContext();
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const buyerUserId = watch('buyerUserId')?.value;
  const vendorAccountId = watch('vendorAccount')?.value?.id;

  if (!dealSplitType) {
    return;
  }

  return (
    <PosFormField
      label={
        <div className={styles.SectionTitle}>
          <Content id={ContentId.RevenueSplitHurdle} />
        </div>
      }
      errors={undefined}
    >
      <PosEnumSelect
        valueOptionsContent={DEAL_CONFIG_SPLIT_TYPE_TO_CID}
        value={dealSplitType}
        onChange={(value) => {
          if (value === DealSplitType.None) {
            updateLocalDealDetails({
              configSplitType: posChangedField(value),
              dealConfigs: posChangedField([]),
            });
          } else if (
            dealSplitType === DealSplitType.None &&
            (value === DealSplitType.Quantity || value === DealSplitType.Sales)
          ) {
            updateLocalDealDetails({
              configSplitType: posChangedField(value),
              dealConfigs: posChangedField([
                createDefaultDealConfig(vendorAccountId, buyerUserId, 1),
              ]),
            });
          } else {
            updateLocalDealDetails({
              configSplitType: posChangedField(value),
            });
          }
        }}
      />
    </PosFormField>
  );
};
