import React, { MutableRefObject, useImperativeHandle } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { useLinkMarketplacePaymentLineToSale } from 'src/hooks/api/useUnlinkMarketplacePaymentLineToSale';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { LinkMarketplacePaymentLineRefActions } from 'src/tables/MarketplacePaymentLinesTable/Cell/LinkMarketplacePaymentLineToSale';
import { ContentId } from 'src/utils/constants/contentId';
import * as styles from './UnlinkMarketplacePaymentLineToSaleDialog.css';

export interface UnlinkMarketplacePaymentLineRefActions {
  showDialog: () => void;
}

export type UnlinkMarketplacePaymentLineToSaleDialogProps = {
  refActions: MutableRefObject<LinkMarketplacePaymentLineRefActions | null>;
  marketplacePaymentLineId: number;
  onUnlinkSuccess: () => void;
};

export const UnlinkMarketplacePaymentLineToSaleDialog = ({
  refActions,
  marketplacePaymentLineId,
  onUnlinkSuccess,
}: UnlinkMarketplacePaymentLineToSaleDialogProps) => {
  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();

  const { isPending, mutateAsync } = useLinkMarketplacePaymentLineToSale();

  useImperativeHandle(
    refActions,
    () => {
      return {
        showDialog: launchDialog,
      };
    },
    [launchDialog]
  );

  if (!dialogProps.isOpen) {
    return null;
  }

  return (
    <ConfirmDialog
      headerText={<Content id={ContentId.UnlinkSaleAndPayment} />}
      bodyText={
        <div>
          <span className={styles.bodyTitle}>
            <Content id={ContentId.ConfirmUnLinkPaymentAndSaleTitle} />
          </span>
          <Content id={ContentId.ConfirmUnLinkPaymentAndSaleBody} />
        </div>
      }
      okText={ContentId.Confirm}
      cancelText={ContentId.Cancel}
      {...dialogProps}
      isLoading={isPending}
      onOkay={async () => {
        await mutateAsync({ marketplacePaymentLineId });
        closeDialog();
        onUnlinkSuccess();
      }}
      size="l"
    />
  );
};
