import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CompListing, Listing, Seating } from 'src/WebApiController';

export const mapCompListingToListing = (listing: CompListing): Listing => {
  return {
    id: listing.listingId,
    availQty: listing.availableTickets ?? undefined,
    unsoldQty: listing.availableTickets ?? undefined,
    seating: {
      section: listing.section ?? '',
      row: listing.row,
      rowId: listing.rowId,
      seatFr: listing.seatFrom,
      seatTo: listing.seatTo,
    } as Seating,
    allInPrice: listing.sellerAllInPrice?.amt ?? undefined,
    currency:
      listing.sellerAllInPrice?.currency ?? listing.currencyCode ?? 'USD',
  } as Listing;
};

export const prependTicketClassColorColumnForFirstTime = (
  storedInventoryColumnOrderSetting: string[],
  storedInventoryColumnsEnabledSetting: string[],
  setInventoryColumnOrderSetting: (value: string[]) => void,
  setInventoryColumnsEnabledSetting: (value: string[]) => void
) => {
  // Only add if it's not already in the order setting list - meaning the user never configured it
  if (
    !storedInventoryColumnOrderSetting.includes(
      ListingTableColumnId.TicketClassColor
    )
  ) {
    // In this case we turn it on and add it to the beginning of the order setting
    setInventoryColumnOrderSetting([
      ListingTableColumnId.TicketClassColor,
      ...storedInventoryColumnOrderSetting,
    ]);
    if (
      !storedInventoryColumnsEnabledSetting.includes(
        ListingTableColumnId.TicketClassColor
      )
    ) {
      setInventoryColumnsEnabledSetting([
        ListingTableColumnId.TicketClassColor,
        ...storedInventoryColumnsEnabledSetting,
      ]);
    }
  }
};
