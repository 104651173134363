import { useCallback } from 'react';
import { SelectedFilters } from 'src/components/Filters';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { FilterToolbarEmbedded } from 'src/components/FilterToolbarEmbedded';
import { usePurchaseFilters } from 'src/components/MainFilterBar/usePurchaseFilters';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, PurchaseOrderQuery } from 'src/WebApiController';

import * as styles from './PurchaseSearchFilterBar.css';

export const PurchaseSearchFilterBar = ({
  onSubmitFilter,
  selectedFilters,
  ...rest
}: {
  onSubmitFilter?: () => void;
  selectedFilters?: SelectedFilters;
  setSelectedFilters: (sf: SelectedFilters) => void;
}) => {
  const userHasPurchaseCommissionFilterInSearchView = useUserHasFeature(
    Feature.PurchaseCommissionFilterInSearchView
  );
  const filters = usePurchaseFilters({
    isHorizontalLayout: true,
    showCommissionerFilter: userHasPurchaseCommissionFilterInSearchView,
  });

  const {
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<PurchaseOrderQuery>();

  const onSubmitFilterHandler = useCallback(() => {
    setFilterQuery(tempQuery);
    onSubmitFilter?.();
  }, [onSubmitFilter, setFilterQuery, tempQuery]);

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: tempQuery,
    mandatoryFiltersToShow: [],
    initialQuery,
  });

  return (
    <Stack
      gap="m"
      alignItems="center"
      className={styles.container}
      width="full"
    >
      <FilterToolbarEmbedded
        onResetAll={() => {
          setTempQuery(initialQuery);
        }}
        filters={filters}
        appliedFilterIds={appliedFilters}
        mandatoryFiltersToShow={[]}
        resetTempQuery={resetTempQuery}
        tempQuery={tempQuery}
        selectedFilters={selectedFilters}
        {...rest}
      />
      <Button
        onClick={onSubmitFilterHandler}
        disabled={!Object.keys(selectedFilters ?? {}).length}
      >
        <Content id={ContentId.Search} />
      </Button>
    </Stack>
  );
};
