import { maxBy } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import {
  SeatingAllocationInputTab,
  SeatingAllocationInputV1,
  SeatingAllocationInputV2,
} from 'src/modals/ConfirmSale/SeatingAllocationInput';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { getPosManagedMarketplaceSaleInputFromSale } from 'src/utils/saleUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  Feature,
  Marketplace,
  PointOfSaleSaleStatus,
  PosUiActionResult,
  SaleActionType,
  SaleClient,
  SaleDetails,
  Seating,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { FlattenedSeatingAllocation } from './SeatingAllocationInput/SeatingSingleTicketAllocationInput';

export type ConfirmSaleProps = {
  cancelTo?: ModalProps;
  action:
    | SaleActionType.Confirm
    | SaleActionType.Allocate
    | SaleActionType.ConfirmAllocation;
};

export const ConfirmSaleModal = (props: ConfirmSaleProps) => {
  const { loadingState } = useEventItemLoadingDisplay<SaleDetails>(
    FormatContentId.LoadingSaleId,
    FormatContentId.SearchingForSaleId,
    FormatContentId.CouldNotFindSaleId
  );

  return loadingState ? loadingState : <ConfirmSaleModalContent {...props} />;
};

const ConfirmSaleModalContent = ({ cancelTo, action }: ConfirmSaleProps) => {
  const {
    event,
    posEntity: sale,
    setActivePosEntity,
  } = useActivePosEntityContext<SaleDetails>();
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, closeModal } = useContext(ModalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasAllocatedSingleTicketFeature = useUserHasFeature(
    Feature.AllocateSingleTickets
  );

  const [activeTab, setActiveTab] = useState<SeatingAllocationInputTab>(
    SeatingAllocationInputTab.Listings
  );

  const [showSectionRowChangeWarning, setShowSectionRowChangeWarning] =
    useState(false);

  const methods = useForm<SaleDetails>({
    defaultValues: sale!,
  });

  const { isDirty } = methods.formState;
  const seating = methods.watch('seating');

  const [selectedSeatings, setSelectedSeatings] = useState<
    FlattenedSeatingAllocation[]
  >([]);

  const createSeatingFromSelectedTickets = (
    selectedTickets: FlattenedSeatingAllocation[]
  ): Seating => {
    // Find the most frequent section and row
    const sectionCounts: Record<string, number> = {};
    const rowCounts: Record<string, number> = {};

    selectedTickets.forEach((ticket) => {
      const { section, row } = ticket;
      sectionCounts[section] = (sectionCounts[section] || 0) + 1;
      rowCounts[row || ''] = (rowCounts[row || ''] || 0) + 1;
    });

    const mostFrequentSection = maxBy(
      Object.entries(sectionCounts),
      ([_, value]) => value
    )?.[0];

    const mostFrequentRow = maxBy(
      Object.entries(rowCounts),
      ([_, value]) => value
    )?.[0];

    // SeatFr & SeatTo sort by listingOrd or seat text
    let sortedTickets: FlattenedSeatingAllocation[] = [];

    const allHaveListingOrd = selectedTickets.every(
      (ticket) => ticket.listingOrd != null
    );

    if (allHaveListingOrd) {
      sortedTickets = [...selectedTickets].sort(
        (a, b) => (a.listingOrd || 0) - (b.listingOrd || 0)
      );
    } else {
      sortedTickets = [...selectedTickets].sort((a, b) =>
        (a.seat || '').localeCompare(b.seat || '', undefined, {
          numeric: true,
        })
      );
    }

    const seatFr = sortedTickets[0]?.seat || null;
    const seatTo = sortedTickets[sortedTickets.length - 1]?.seat || null;

    // Collect ticketIds & listingIds
    const ticketIds = selectedTickets.map((ticket) => ticket.id);
    const listingIds = selectedTickets.reduce<number[]>((ids, ticket) => {
      if (ticket.listingId != null) {
        ids.push(ticket.listingId);
      }
      return ids;
    }, []);

    const seating: Seating = {
      section: mostFrequentSection || '',
      sectionId: null,
      row: mostFrequentRow || null,
      rowId: null,
      seatFr,
      seatTo,
      ticketIds,
      listingIds,
    };

    return seating;
  };

  const onSubmit = useCallback(
    async (saleForm: SaleDetails, skipAllocation?: boolean) => {
      // If this is called, there are no form errors (else onSubmit is never called)
      if (sale) {
        if (
          !showSectionRowChangeWarning &&
          (saleForm.seating.section !== sale.seating.section ||
            saleForm.seating.row !== sale.seating.row)
        ) {
          setShowSectionRowChangeWarning(true);
          return;
        }
        setIsSubmitting(true);

        tryInvokeApi(
          async () => {
            let result: PosUiActionResult;
            const client = new SaleClient(activeAccountWebClientConfig);
            if (
              sale.mkp === Marketplace.Offline &&
              (sale.status === PointOfSaleSaleStatus.Hold ||
                sale.status === PointOfSaleSaleStatus.CancelledHold ||
                sale.status === PointOfSaleSaleStatus.PendingRejection ||
                sale.status === PointOfSaleSaleStatus.Rejected)
            ) {
              // If it's offline sale and it's currently on hold or cancelled hold,
              result = await client.mergePosManagedMarketplaceSale({
                ...getPosManagedMarketplaceSaleInputFromSale(sale, event!),
                saleStatus: posChangedField(
                  PointOfSaleSaleStatus.PendingConfirmation
                ),
              });

              if (!isSuccess(result)) {
                showErrorDialog(
                  'SaleClient.mergePosManagedMarketplaceSale',
                  {
                    message: result.message ?? genericError,
                    status: result.status!,
                  } as ApiException,
                  {
                    onDismissError: () => cancelTo && setModal(cancelTo),
                    trackErrorData: {
                      item1: saleForm.id,
                      item2: saleForm.seating,
                    },
                  }
                );
                return;
              }
            }

            const skipAllocationValidation =
              activeTab === SeatingAllocationInputTab.Tickets;

            result = await client.confirmAllocationSale(
              saleForm.id,
              skipAllocation ? undefined : saleForm.seating,
              skipAllocationValidation
            );

            if (!isSuccess(result)) {
              showErrorDialog(
                'SaleClient.confirmSale',
                {
                  message: result.message ?? genericError,
                  status: result.status!,
                } as ApiException,
                {
                  onDismissError: () => cancelTo && setModal(cancelTo),
                  trackErrorData: {
                    item1: saleForm.id,
                    item2: saleForm.seating,
                  },
                }
              );
            } else {
              // Refresh the active data so the SaleDetail dialog and table will have the new content
              await setActivePosEntity(saleForm!.id, saleForm!.idOnMkp, true);
              if (cancelTo) {
                setModal(cancelTo);
              } else {
                closeModal(true);
              }
            }
          },
          (error) => {
            showErrorDialog('SaleClient.confirmSale', error, {
              trackErrorData: {
                saleId: saleForm.id,
                seation: saleForm.seating,
              },
            });
          },
          () => setIsSubmitting(false)
        );
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      activeTab,
      cancelTo,
      closeModal,
      event,
      genericError,
      sale,
      setActivePosEntity,
      setModal,
      showErrorDialog,
      showSectionRowChangeWarning,
    ]
  );

  const updateSeatingValueFromSingleTicketSelection = useCallback(() => {
    if (
      !hasAllocatedSingleTicketFeature ||
      activeTab === SeatingAllocationInputTab.Listings
    ) {
      return;
    }
    const seating = createSeatingFromSelectedTickets(selectedSeatings);
    methods.setValue('seating', seating); // Update form state
  }, [activeTab, hasAllocatedSingleTicketFeature, methods, selectedSeatings]);

  const contentId =
    action === SaleActionType.Confirm
      ? ContentId.ConfirmSale
      : action === SaleActionType.ConfirmAllocation
      ? ContentId.ConfirmAllocation
      : ContentId.AllocateTickets;

  return (
    <FormProvider {...methods}>
      <CancellableFormHeader cancelTo={cancelTo} disabled={isSubmitting}>
        <ConnectedEventEntityHeader title={<Content id={contentId} />} />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={sale!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          {isSubmitting ? (
            <PosSpinner />
          ) : showSectionRowChangeWarning ? (
            <SeatingAllocationWarning />
          ) : hasAllocatedSingleTicketFeature ? (
            <SeatingAllocationInputV2
              setSelectedSeatings={setSelectedSeatings}
              selectedSeatings={selectedSeatings}
              originalSeating={sale!.seating}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <SeatingAllocationInputV1 originalSeating={sale!.seating} />
          )}
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isSubmitting}
          showDialogOnCancel={isDirty}
        >
          {showSectionRowChangeWarning && (
            <ConfirmButton
              onClick={() => setShowSectionRowChangeWarning(false)}
              disabled={isSubmitting}
              textContentId={ContentId.Back}
            />
          )}
          {action === SaleActionType.Confirm &&
            sale?.isSeatSaver &&
            !seating?.ticketIds?.length && (
              <ConfirmButton
                onClick={methods.handleSubmit((f) => onSubmit(f, true))}
                disabled={isSubmitting}
                variant="outline"
                textContentId={ContentId.ConfirmWithoutAllocation}
              />
            )}
          <ConfirmButton
            onClick={() => {
              updateSeatingValueFromSingleTicketSelection();
              methods.handleSubmit((f) => onSubmit(f))();
            }}
            disabled={
              isSubmitting ||
              (hasAllocatedSingleTicketFeature &&
              activeTab === SeatingAllocationInputTab.Tickets
                ? selectedSeatings.length < (sale?.qtySold || 0)
                : !seating?.ticketIds?.length)
            }
            textContentId={
              showSectionRowChangeWarning
                ? ContentId.Yes
                : ContentId.ConfirmAllocation
            }
          />
        </CancellableFormFooter>
      </ModalFooter>
    </FormProvider>
  );
};

const SeatingAllocationWarning = () => {
  return (
    <div>
      <h5>
        <Content id={ContentId.AreYouSure} />
      </h5>
      <WarningMessage
        message={<Content id={ContentId.SeatAllocationChangeWarning} />}
      />
    </div>
  );
};
