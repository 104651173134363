import { ComponentProps, ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PosClientConfig,
  PosUiActionResult,
  TicketExternalId,
} from 'src/WebApiController';

import {
  checkDuplicateExternalIds,
  checkExternalIdErrors,
  ExternalIdForm,
} from './UploadExternalIds.utils';

export type UploadExternalIdsFormProps = {
  entityWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating'
  >;
  renderContent: (onUploadClick: () => void, disabled?: boolean) => ReactNode;
  onComplete: (isCancelled: boolean) => void;
  onUpload?: (
    posClientConfig: PosClientConfig,
    entityId: number,
    externalIds: TicketExternalId[]
  ) => Promise<PosUiActionResult>;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
};

export const UploadExternalIdsForm = (props: UploadExternalIdsFormProps) => {
  const methods = useForm<ExternalIdForm>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      externalIds: props.entityWithTickets.tickets?.map(
        ({ id, externalTicketId, row, seat }) => {
          return {
            ticketId: id,
            externalId: externalTicketId || '',
            section: props.entityWithTickets.seating.section,
            row: row,
            seat: seat,
          };
        }
      ),
    },
  });

  return (
    <FormProvider {...methods}>
      <UploadExternalIdsContent {...props} {...methods} />
    </FormProvider>
  );
};

const UploadExternalIdsContent = ({
  entityWithTickets,
  renderContent,
  onComplete,
  onUpload,
  handleSubmit,
  setError,
  watch,
  isReadOnly = false,
}: UploadExternalIdsFormProps &
  Omit<
    ComponentProps<typeof FormProvider<ExternalIdForm, unknown>>,
    'children'
  >) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { ticketCnt } = entityWithTickets;
  const externalIds = watch('externalIds');
  const bulkUpload = watch('isBulkUpload');

  const [readOnly, setReadOnly] = useState<boolean>(isReadOnly);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();

  const [alreadyReportedUsedExternalIds, setAlreadyReportedUsedExternalIds] =
    useState<string[]>([]);
  const externalIdAlreadyUsedWarning = useContent(
    ContentId.TicketIdAlreadyInUsed
  );

  const requiredMsg = useContent(ContentId.Required);
  const duplicateMsg = useContent(ContentId.DuplicateTicketId);
  const externalIdsProvided =
    externalIds?.map((b) => b.externalId).filter((b) => b?.length) ?? [];
  const missingMsg = useFormattedContent(
    FormatContentId.MissingTicketIds,
    `${ticketCnt - externalIdsProvided.length}`
  );

  const extraMsg = useFormattedContent(
    FormatContentId.ExtraTicketIds,
    `${externalIdsProvided.length - ticketCnt}`
  );

  const onSubmit = useCallback(
    async ({ externalIds }: ExternalIdForm) => {
      if (!onUpload) {
        console.log(
          'Internal Error - no onUploadExternalIds provided for non-readonly mode.'
        );
        return;
      }

      if (!entityWithTickets) {
        console.log('Internal Error - entity (listing or sale) is null.');
        return;
      }

      tryInvokeApi(
        async () => {
          setReadOnly(true);

          const result = await onUpload(
            activeAccountWebClientConfig,
            entityWithTickets.id,
            externalIds!
          );
          if (!isSuccess(result)) {
            showErrorDialog(
              'SaleClient.updateExternalIdsForSale',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => onComplete(true),
                trackErrorData: {
                  saleId: entityWithTickets?.id,
                  externalIds,
                },
              }
            );
          } else {
            onComplete(false);
          }
        },
        (error) => {
          showErrorDialog('SaleClient.updateExternalIdsForSale', error, {
            trackErrorData: {
              saleId: entityWithTickets?.id,
              externalIds,
            },
          });
        },
        () => setReadOnly(false)
      );
    },
    [
      onUpload,
      entityWithTickets,
      activeAccountWebClientConfig,
      showErrorDialog,
      genericError,
      onComplete,
    ]
  );

  const onUploadClick = useCallback(async () => {
    let hasErrors = checkExternalIdErrors(
      bulkUpload,
      externalIds,
      ticketCnt,
      setError,
      requiredMsg,
      missingMsg as string,
      extraMsg as string,
      duplicateMsg
    );

    if (hasErrors) {
      return;
    }

    hasErrors = Boolean(
      await checkDuplicateExternalIds(
        entityWithTickets!.id,
        externalIds,
        activeAccountWebClientConfig,
        alreadyReportedUsedExternalIds,
        setAlreadyReportedUsedExternalIds,
        (index: number) =>
          setError(`externalIds.${index}.externalId`, {
            type: 'warning',
            message: externalIdAlreadyUsedWarning,
          }),
        () => setReadOnly(true),
        () => setReadOnly(false),
        showErrorDialog,
        entityWithTickets!.viagVirtualId
      )
    );

    if (!hasErrors) {
      handleSubmit(onSubmit)();
    }
  }, [
    bulkUpload,
    externalIds,
    ticketCnt,
    setError,
    requiredMsg,
    missingMsg,
    extraMsg,
    duplicateMsg,
    entityWithTickets,
    activeAccountWebClientConfig,
    alreadyReportedUsedExternalIds,
    showErrorDialog,
    externalIdAlreadyUsedWarning,
    handleSubmit,
    onSubmit,
  ]);

  const reallyReadOnly = isReadOnly || readOnly;

  return renderContent(onUploadClick, reallyReadOnly);
};
