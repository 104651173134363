import { useCallback, useEffect, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import {
  mapFiltersToFiltersState,
  useFilterFiltersByPermission,
} from '../Filters';
import {
  FilterDropdownItem,
  FilterToolbarGroup,
  FilterToolbarItemId,
  SelectedFilters,
} from '../Filters/Filter.types';
import { AddFilterDropdown } from './AddFilterDropdown';
import { FiltersList } from './FilterList';

export type FilterToolbarEmbeddedProps = {
  filters: FilterToolbarGroup[];
  selectedFilters?: SelectedFilters;
  setSelectedFilters: (sf: SelectedFilters) => void;
  onResetAll: () => void;
  resetTempQuery?: (keys?: FilterToolbarItemId[]) => void; // TODO: make this not optional
  tempQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  initialQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  appliedFilterIds?: FilterToolbarItemId[];
  mandatoryFiltersToShow?: FilterToolbarItemId[];
  performerVenueFilter?: JSX.Element;
};

export function FilterToolbarEmbedded({
  filters,
  selectedFilters,
  setSelectedFilters,
  onResetAll,
  appliedFilterIds,
  resetTempQuery,
  initialQuery = {},
  mandatoryFiltersToShow = [],
  performerVenueFilter,
}: FilterToolbarEmbeddedProps) {
  const filteredByPermissionsFilters = useFilterFiltersByPermission({
    filters,
  });

  useEffect(() => {
    if (selectedFilters == null) {
      const newSelectedFilters = mapFiltersToFiltersState({
        filters: filteredByPermissionsFilters,
        appliedFilterIds: appliedFilterIds ?? [],
        mandatoryFiltersToShow,
      });

      setSelectedFilters(newSelectedFilters);
    }
  }, [
    appliedFilterIds,
    filteredByPermissionsFilters,
    mandatoryFiltersToShow,
    selectedFilters,
    setSelectedFilters,
  ]);

  const hasNonMandatorySelectedFilters = useMemo(() => {
    for (const key in selectedFilters) {
      if (
        selectedFilters[key].some(
          (dropdownItem) =>
            !mandatoryFiltersToShow.includes(dropdownItem.filterId) &&
            !initialQuery[dropdownItem.filterId] != null
        )
      ) {
        return true;
      }
    }

    return false;
  }, [initialQuery, mandatoryFiltersToShow, selectedFilters]);

  const clearAll = useCallback(() => {
    // Clear all and keep the mandatory fields
    const cleanSelectedFilters: SelectedFilters = {};
    for (const key in selectedFilters) {
      selectedFilters[key].forEach((dropdownItem) => {
        if (
          mandatoryFiltersToShow.includes(dropdownItem.filterId) ||
          initialQuery[dropdownItem.filterId] != null
        ) {
          cleanSelectedFilters[key] = cleanSelectedFilters[key] ?? [];
          cleanSelectedFilters[key].push(dropdownItem);
        }
      });
    }
    setSelectedFilters(cleanSelectedFilters);
    resetTempQuery?.();
    onResetAll();
  }, [
    initialQuery,
    mandatoryFiltersToShow,
    onResetAll,
    resetTempQuery,
    selectedFilters,
    setSelectedFilters,
  ]);

  const onFilterRemoved = useCallback(
    (removedFilterDropdownItem: FilterDropdownItem) => {
      const titleContentId = removedFilterDropdownItem.titleContentId as string;

      const filterToolbarGroup = filters.find(
        (group) => group.titleContentId === titleContentId
      );

      if (!filterToolbarGroup) {
        return;
      }

      const filtersToRemove = filterToolbarGroup.items.filter(
        (filter) => filter.filterId === removedFilterDropdownItem.filterId
      );

      const filterIdsToRemove = filtersToRemove.map((f) => f.filterId);
      const filteredDropdownItems = (
        selectedFilters?.[titleContentId] ?? []
      ).filter(
        (dropdownItem) => !filterIdsToRemove.includes(dropdownItem.filterId)
      );

      const updatedSelectedFilters = { ...selectedFilters };
      if (filteredDropdownItems.length > 0) {
        updatedSelectedFilters[titleContentId] = filteredDropdownItems;
      } else {
        delete updatedSelectedFilters[titleContentId];
      }

      // all keys to remove from tempQuery
      const filterQueryKeysToRemove = filtersToRemove.flatMap(
        (f) => f.filterQueryKeys ?? []
      );

      resetTempQuery?.(filterQueryKeysToRemove);
      setSelectedFilters(updatedSelectedFilters);
    },
    [filters, resetTempQuery, selectedFilters, setSelectedFilters]
  );

  const onFilterSelected = useCallback(
    (filterDropdownItems: FilterDropdownItem[]) => {
      let updatedSelectedFilters: SelectedFilters = {
        ...selectedFilters,
      };

      filterDropdownItems.forEach((filterDropdownItem) => {
        const titleContentId = filterDropdownItem.titleContentId as string;
        updatedSelectedFilters = {
          ...updatedSelectedFilters,
          [titleContentId]: [
            ...(updatedSelectedFilters[titleContentId] ?? []),
            filterDropdownItem,
          ],
        };
      });

      setSelectedFilters(updatedSelectedFilters);
    },
    [selectedFilters, setSelectedFilters]
  );

  return (
    <FiltersList
      filters={filteredByPermissionsFilters}
      selectedDropdownItems={selectedFilters ?? {}}
      onFilterRemoved={onFilterRemoved}
      mandatoryFiltersToShow={mandatoryFiltersToShow}
      performerVenueFilter={performerVenueFilter}
      resetButton={
        <Button
          variant="text"
          onClick={clearAll}
          style={{ height: 'fit-content', whiteSpace: 'nowrap' }}
        >
          <Content
            id={
              mandatoryFiltersToShow.length ? ContentId.Reset : ContentId.Clear
            }
          />
        </Button>
      }
      addFilterButton={
        <AddFilterDropdown
          filters={filteredByPermissionsFilters}
          onFilterSelected={onFilterSelected}
          hideLabel={hasNonMandatorySelectedFilters}
        />
      }
    />
  );
}
