import { isEmpty, pickBy } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useCompListingDisabledSections } from 'src/hooks/useCompListingDisabledSections';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import {
  Feature,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  SectionInfo,
} from 'src/WebApiController';

import { ListingTab } from '../../components/listingDetailsUtils';
import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { useAutoPricingSidePanelContext } from '../AutoPricingSidePanel';
import { ListingAutoPricingSettingsFormContentWrapper } from './components/ListingAutoPricingSettingsFormContentWrapper/ListingAutoPricingSettingsFormContentWrapper';
import { AutoPricingSection } from './Sections/AutoPricingSection';
import { useAutoPricingSettings } from './useAutoPricingSettings';

type ListingDetailsAutoPricingFormProps = {
  listing: ListingDetails;
};

export const ListingDetailsAutoPricingForm: React.FC<
  ListingDetailsAutoPricingFormProps
> = ({ listing }) => {
  const { methods, isLoading, onTabChange } = useListingDetailsContext();

  return (
    <FormProvider {...methods.autoPricing}>
      <ListingAutoPricingSettingsFormWrapper
        listing={listing}
        isLoading={isLoading}
        onTabChange={onTabChange}
      />
    </FormProvider>
  );
};

type ListingAutoPricingSettingsFormWrapperProps = {
  listing: ListingDetails;
  isLoading: boolean;
  onTabChange: (v: ListingTab) => void;
};

const ListingAutoPricingSettingsFormWrapper: React.FC<
  ListingAutoPricingSettingsFormWrapperProps
> = ({ listing, isLoading, onTabChange }) => {
  const hasIntelligibleAutoPricingSettingsFeature =
    useUserHasAutopricingFeature(Feature.IntelligibleAutoPricingSettings);
  const { watch } = useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const { updateMetadata } = useAutoPricingSidePanelContext();

  const input = watch();

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    onCompListingChange,
  } = useAutoPricingSettings(listing);

  const { disabledSectionIds } = useCompListingDisabledSections(
    listing,
    false,
    compListingMode,
    compListingFloor,
    compListingCeiling
  );

  const onSelectedSectionsChange = useCallback(
    (selectedSections: SectionInfo[]) => {
      // Restructure the id filters.
      const sectionIdFilter = selectedSections.map((s) => s.id);
      const selectedRowIds = selectedSections
        .flatMap(({ rows }) => rows)
        .map(({ id }) => id);
      const rowIdFilter = [];
      if (isEmpty(compListingSelectedSectionSettings?.rowIdFilter)) {
        rowIdFilter.push(...selectedRowIds);
      } else {
        rowIdFilter.push(
          ...(compListingSelectedSectionSettings?.rowIdFilter?.filter((rowId) =>
            selectedRowIds.includes(rowId)
          ) ?? [])
        );
      }
      const sectionRowIdFilter = pickBy(
        Object.assign(
          {},
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
        ),
        (_, key) => sectionIdFilter.includes(parseInt(key))
      );
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter,
        rowIdFilter,
        sectionRowIdFilter,
      };
      onCompListingChange({
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: true,
        compListingSelectedSectionSettingsNew,
      });
    },
    [compListingSelectedSectionSettings, onCompListingChange]
  );

  useEffect(() => {
    updateMetadata({
      input,
      sectionIds: compListingSelectedSectionSettings?.sectionIdFilter ?? [],
      disabledSectionIds,
      onSelectedSectionsChange,
    });
  }, [
    compListingSelectedSectionSettings?.sectionIdFilter,
    disabledSectionIds,
    onSelectedSectionsChange,
    input,
    updateMetadata,
  ]);

  return (
    <>
      {hasIntelligibleAutoPricingSettingsFeature ? (
        <ListingAutoPricingSettingsFormContentWrapper
          listing={listing}
          disabled={isLoading}
          onSwitchToTab={onTabChange}
        />
      ) : (
        <AutoPricingSection
          listing={listing}
          disabled={isLoading}
          onSwitchToTab={onTabChange}
        />
      )}
    </>
  );
};
