import React, { ComponentProps, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { SaleEventBox } from 'src/dialogs/LinkMarketplacePaymentLineDialog/SaleEventBox/SaleEventBox';
import { useLinkMarketplacePaymentLineToSale } from 'src/hooks/api/useLinkMarketplacePaymentLineToSale';
import { FieldWrapper } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

export type LinkMarketplacePaymentLineToSaleDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onCancel: () => void;
  marketplacePaymentLineId: number | undefined;
  onLinkSuccess: () => void;
};

type LinkMarketplacePaymentLineToSaleForm = {
  saleId: number | null; // or ExternalSaleId
  marketplacePaymentLineId: number;
};

export const LinkMarketplacePaymentLineToSaleDialog = ({
  onCancel,
  payment,
  marketplacePaymentLineId,
  closeDialog,
  onLinkSuccess,
  ...dialogProps
}: LinkMarketplacePaymentLineToSaleDialogProps) => {
  const requiredMsg = useContent(ContentId.Required);
  const [existsSaleId, setExistsSaleId] = useState<boolean>(false);

  const { register, formState, handleSubmit, trigger, getValues, watch } =
    useForm<LinkMarketplacePaymentLineToSaleForm>({
      defaultValues: {
        saleId: null,
        marketplacePaymentLineId,
      },
    });

  const { mutateAsync, isPending } = useLinkMarketplacePaymentLineToSale();

  const saleId = watch('saleId');

  const onSubmit = useCallback(async () => {
    const hasErrors = Object.keys(formState.errors).length > 0;
    if (hasErrors || !existsSaleId) {
      return;
    }
    const { saleId, marketplacePaymentLineId } = getValues();

    await mutateAsync({
      saleId: saleId ?? undefined,
      marketplacePaymentLineId,
    });
    closeDialog();
    onLinkSuccess();
  }, [
    closeDialog,
    existsSaleId,
    formState.errors,
    getValues,
    mutateAsync,
    onLinkSuccess,
  ]);

  const onSearchSaleChanged = useCallback(
    (saleDetails: SaleDetails | undefined) => {
      setExistsSaleId(!!saleDetails);
    },
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GenericDialog
        {...dialogProps}
        size="m"
        header={<Content id={ContentId.LinkSale} />}
        footer={
          <Button
            disabled={!existsSaleId}
            onClick={async () => {
              await trigger();
              await handleSubmit(onSubmit)();
            }}
          >
            <Content id={ContentId.Link} />
          </Button>
        }
        onCancel={onCancel}
      >
        {isPending ? (
          <PosSpinner />
        ) : (
          <>
            <FieldWrapper>
              <PosFormField
                label={<Content id={ContentId.SaleId} />}
                errors={formState.errors.saleId?.message}
              >
                <PosTextField
                  {...register('saleId', { required: requiredMsg })}
                />
              </PosFormField>
              <input hidden type="submit" />
            </FieldWrapper>

            {!!saleId && (
              <SaleEventBox
                saleId={saleId}
                onSearchSaleChanged={onSearchSaleChanged}
              />
            )}
          </>
        )}
      </GenericDialog>
    </form>
  );
};
