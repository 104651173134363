/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  ListingDetailsAutoPricingSectionUpdates,
  SectionInfo,
} from 'src/WebApiController';

type PanelContentType = 'comparables' | 'sectionSelection' | 'none';
type PanelMetadata = {
  sectionIds: number[];
  disabledSectionIds: number[];
  input: ListingDetailsAutoPricingSectionUpdates;
  onSelectedSectionsChange: (sections: SectionInfo[]) => void;
};

type AutoPricingSidePanelContextType = {
  contentType: PanelContentType;
  onCloseSidePanel: () => void;
  onShowContent: (
    newContentType: PanelContentType,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => void;
  metadata: PanelMetadata;
  updateMetadata: (data: PanelMetadata) => void;
};

const AutoPricingSidePanelContext =
  createContext<AutoPricingSidePanelContextType>({
    contentType: 'none',
    onCloseSidePanel: () => {},
    onShowContent: () => {},
    metadata: {
      sectionIds: [],
      disabledSectionIds: [],
      input: {} as ListingDetailsAutoPricingSectionUpdates,
      onSelectedSectionsChange: () => void 0,
    },
    updateMetadata: () => void 0,
  });

type AutoPricingSidePanelContextProviderProps = {
  children: ReactNode;
};

export const AutoPricingSidePanelContextProvider = ({
  children,
}: AutoPricingSidePanelContextProviderProps) => {
  const [contentType, setContentType] = useState<PanelContentType>('none');
  const [metadata, setMetadata] = useState<PanelMetadata>({
    sectionIds: [],
    disabledSectionIds: [],
    input: {} as ListingDetailsAutoPricingSectionUpdates,
    onSelectedSectionsChange: () => void 0,
  });

  const onCloseSidePanel = useCallback(() => {
    if (contentType !== 'none') {
      setContentType('none');
    }
  }, [contentType]);

  const onShowContent = useCallback(
    (
      newContentType: PanelContentType,
      e?: React.MouseEvent<HTMLButtonElement>
    ) => {
      e?.stopPropagation();
      e?.preventDefault();

      if (contentType !== newContentType) {
        setContentType(newContentType);
      } else if (contentType !== 'none') {
        setContentType('none');
      }
    },
    [contentType, setContentType]
  );

  const updateMetadata = useCallback(
    (data: any) => {
      const { onSelectedSectionsChange: onSelectedSectionsChange, ...rest } =
        data;
      const {
        onSelectedSectionsChange: newOnSelectedSectionsChange,
        ...newRest
      } = metadata;
      if (!isEqual(rest, newRest)) {
        setMetadata(data);
      }
    },
    [metadata, setMetadata]
  );

  return (
    <AutoPricingSidePanelContext.Provider
      value={{
        contentType,
        onShowContent,
        onCloseSidePanel,
        metadata,
        updateMetadata,
      }}
    >
      {children}
    </AutoPricingSidePanelContext.Provider>
  );
};

export const useAutoPricingSidePanelContext = () =>
  useContext(AutoPricingSidePanelContext);
