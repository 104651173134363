import { SearchResultIdQueryParam } from './constants/constants';
import { removeFilters } from './eventQueryUtils';

export const createPurchaseSearchPageUrl = (
  searchResultId?: string,
  queryParamsToRemove?: string[] // queryParams to remove from final URL
): string => {
  let searchParams = '';
  const url = new URL(window.location.href);

  if (searchResultId) {
    url.searchParams.set(SearchResultIdQueryParam, searchResultId);
  }
  (queryParamsToRemove ?? []).forEach((param) =>
    url.searchParams.delete(param)
  );
  searchParams = `?${removeFilters(url.searchParams.toString(), [
    'searchText',
  ])}`;
  return `/purchases/search${searchParams}`;
};

export const createSaleSearchPageUrl = (
  searchResultId?: string,
  queryParamsToRemove?: string[] // queryParams to remove from final URL
): string => {
  let searchParams = '';
  const url = new URL(window.location.href);

  if (searchResultId) {
    url.searchParams.set(SearchResultIdQueryParam, searchResultId);
  }
  (queryParamsToRemove ?? []).forEach((param) =>
    url.searchParams.delete(param)
  );
  searchParams = `?${removeFilters(url.searchParams.toString(), [
    'searchText',
  ])}`;
  return `/sales/search${searchParams}`;
};

export const createInventorySearchPageUrl = (
  searchResultId?: string,
  queryParamsToRemove?: string[] // queryParams to remove from final URL
): string => {
  let searchParams = '';
  const url = new URL(window.location.href);

  if (searchResultId) {
    url.searchParams.set(SearchResultIdQueryParam, searchResultId);
  }
  (queryParamsToRemove ?? []).forEach((param) =>
    url.searchParams.delete(param)
  );
  searchParams = `?${removeFilters(url.searchParams.toString(), [
    'searchText',
  ])}`;
  return `/inventory/search${searchParams}`;
};
