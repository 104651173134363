import { FilterToolbarItem, FilterToolbarItemId } from 'src/components/Filters';
import {
  PurchaseVendorAccountSelector,
  StandalonePurchaseVendorAccountsSelector,
} from 'src/components/Selectors/PurchaseVendorAccountSelector';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { YES_ENUM_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import { PurchaseDateRangePresetNames } from 'src/utils/dateTimeUtils';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import { ReportTypes } from 'src/utils/reportsUtils';
import { DateTimeRangeWithRelative, Feature } from 'src/WebApiController';

import { usePurchaseVendorAccountSelector } from '../Selectors/PurchaseVendorAccountSelector/usePurchaseVendorAccountSelector';
import { PurchaseVendorSelector } from '../Selectors/PurchaseVendorSelector';
import { SellerAccountEmployeeSelector } from '../Selectors/SellerAccountEmployeeSelector';
import { VendorAccessControlProvidersSelector } from '../Selectors/VendorAccessControlProvidersSelector/VendorAccessControlProvidersSelector';

export type PurchaseInfoFilter = {
  purchaseVendorId?: number | null;
  purchaseVendorAccountIds?: string[] | null;
  purchaseBuyerId?: string | null;
  purchaseOrderCreatedBy?: string | null;
  purchaseDates?: DateTimeRangeWithRelative | null;
  purchaseCommissionUserId?: string | null;
  purchaseCancellationDates?: DateTimeRangeWithRelative | null;
  purchaseCancelledOnly?: boolean | null;
  vendorAccessControlProviderId?: number | null;
};

export const usePurchaseInfoFilters = <TFilter extends PurchaseInfoFilter>(
  query: TFilter,
  setQuery: (query: TFilter) => void,
  reportType?: ReportTypes,
  showCommissionerFilter?: boolean
) => {
  const isReportPage = reportType != null;
  const useRelativePresets = isReportPage;

  const hasFilterByCommissionUserFeature = useUserHasFeature(
    Feature.ReportFilterByCommissionUser
  );

  const hasStandaloneVendorAccountFilter = useUserHasFeature(
    Feature.StandaloneVendorAccountFilter
  );
  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );
  const hasVendorGroupFilterFeature = useUserHasFeature(
    Feature.VendorGroupFilter
  );

  const { query: vendorAccountQuery } = usePurchaseVendorAccountSelector({
    vendorId: query.purchaseVendorId,
  });

  return {
    titleContentId: ContentId.Purchase,
    type: 'row',
    items: [
      {
        filterId: 'purchaseVendorId' as const,
        labelContentId: ContentId.Vendor,
        filterQueryKeys: ['purchaseVendorId'] as FilterToolbarItemId[],
        filterItem: (
          <PurchaseVendorSelector
            style={{ width: '100%' }}
            placeholderText={ContentId.AllVendors}
            enableEmptySelection
            value={query.purchaseVendorId?.toString()}
            onChange={(newId) => {
              const purchaseVendorId = newId ? +newId : null;
              if (purchaseVendorId !== query?.purchaseVendorId) {
                setQuery({
                  ...query,
                  purchaseVendorId,
                  purchaseVendorAccountIds: null, // changing vendor should reset the vendor-account selection filter
                });
                vendorAccountQuery.refetch();
              }
            }}
          />
        ),
      },
      {
        filterId: 'purchaseVendorAccountIds' as const,
        labelContentId: ContentId.VendorAccount,
        filterQueryKeys: ['purchaseVendorAccountIds'] as FilterToolbarItemId[],
        filterItem: hasStandaloneVendorAccountFilter ? (
          <StandalonePurchaseVendorAccountsSelector
            query={query}
            setQuery={setQuery}
          />
        ) : (
          <PurchaseVendorAccountSelector
            style={{ width: '100%' }}
            disabled={!query.purchaseVendorId}
            placeholderText={ContentId.AllVendorAccounts}
            vendorId={query.purchaseVendorId}
            value={(query.purchaseVendorAccountIds ?? [])[0] ?? ''}
            enableEmptySelection
            onChange={(purchaseVendorAccountId: string | null) => {
              if (
                !query.purchaseVendorAccountIds ||
                purchaseVendorAccountId !== query.purchaseVendorAccountIds[0]
              ) {
                setQuery({
                  ...query,
                  purchaseVendorAccountIds: [purchaseVendorAccountId],
                });
              }
            }}
          />
        ),
      },
      hasVendorGroupFilterFeature && !reportType
        ? {
            filterId: 'vendorAccessControlProviderId' as const,
            labelContentId: ContentId.VendorGroup,
            filterQueryKeys: [
              'vendorAccessControlProviderId',
            ] as FilterToolbarItemId[],
            filterItem: (
              <VendorAccessControlProvidersSelector
                style={{ width: '100%' }}
                placeholderText={ContentId.VendorGroup}
                value={String(query.vendorAccessControlProviderId ?? '')}
                onChange={(vendorAccessControlProviderId: string | null) => {
                  if (vendorAccessControlProviderId) {
                    setQuery({
                      ...query,
                      vendorAccessControlProviderId: Number(
                        vendorAccessControlProviderId
                      ),
                    });
                  }
                }}
              />
            ),
          }
        : null,
      {
        filterId: 'purchaseBuyerId' as const,
        labelContentId: ContentId.PurchasedBy,
        filterQueryKeys: ['purchaseBuyerId'] as FilterToolbarItemId[],
        filterItem: (
          <SellerAccountEmployeeSelector
            value={query.purchaseBuyerId ?? undefined}
            enableEmptySelection
            style={{ width: '100%' }}
            onChange={(purchaseBuyerId) => {
              if (purchaseBuyerId !== query.purchaseBuyerId) {
                setQuery({
                  ...query,
                  purchaseBuyerId,
                });
              }
            }}
          />
        ),
      },
      {
        filterId: 'purchaseDates' as const,
        labelContentId: ContentId.PurchaseDate,
        filterQueryKeys: ['purchaseDates'] as FilterToolbarItemId[],
        filterItem: (
          <DateRangeSelector
            presetNames={PurchaseDateRangePresetNames}
            value={query.purchaseDates}
            onBlur={(value) =>
              setQuery({
                ...query,
                purchaseDates: value,
              })
            }
            useRelativePresets={
              useRelativePresets || hasDynamicDateTimeFiltersFeature
            }
          />
        ),
      },
      {
        filterId: 'purchaseOrderCreatedBy' as const,
        labelContentId: ContentId.CreatedBy,
        filterQueryKeys: ['purchaseOrderCreatedBy'] as FilterToolbarItemId[],
        filterItem: (
          <SellerAccountEmployeeSelector
            value={query.purchaseOrderCreatedBy ?? undefined}
            enableEmptySelection
            style={{ width: '100%' }}
            onChange={(purchaseOrderCreatedBy) => {
              if (purchaseOrderCreatedBy !== query.purchaseOrderCreatedBy) {
                setQuery({
                  ...query,
                  purchaseOrderCreatedBy,
                });
              }
            }}
          />
        ),
      },
      ...(hasFilterByCommissionUserFeature &&
      (isReportPage || showCommissionerFilter)
        ? [
            {
              filterId: 'purchaseCommissionUserId' as FilterToolbarItemId,
              labelContentId: ContentId.Commissioner,
              filterQueryKeys: [
                'purchaseCommissionUserId',
              ] as FilterToolbarItemId[],
              filterItem: (
                <SellerAccountEmployeeSelector
                  value={query.purchaseCommissionUserId ?? undefined}
                  enableEmptySelection
                  style={{ width: '100%' }}
                  onChange={(purchaseCommissionUserId) => {
                    if (
                      purchaseCommissionUserId !==
                      query.purchaseCommissionUserId
                    ) {
                      setQuery({
                        ...query,
                        purchaseCommissionUserId,
                      });
                    }
                  }}
                />
              ),
            },
          ]
        : []),
      ...(reportType === ReportTypes.Inventory
        ? [
            {
              filterId: 'purchaseCancellationDates' as FilterToolbarItemId,
              labelContentId: ContentId.CancellationDate,
              filterQueryKeys: [
                'purchaseCancellationDates',
              ] as FilterToolbarItemId[],
              filterItem: (
                <DateRangeSelector
                  presetNames={PurchaseDateRangePresetNames}
                  value={query.purchaseCancellationDates}
                  onBlur={(value) =>
                    setQuery({
                      ...query,
                      purchaseCancellationDates: value,
                    })
                  }
                  useRelativePresets={useRelativePresets}
                />
              ),
            },
            {
              filterId: 'purchaseCancelledOnly' as FilterToolbarItemId,
              labelContentId: ContentId.Cancelled,
              filterQueryKeys: [
                'purchaseCancelledOnly',
              ] as FilterToolbarItemId[],
              filterItem: (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  value={ToYesNoEnum(query.purchaseCancelledOnly)}
                  placeholderText={ContentId.No}
                  enableEmptySelection
                  onChange={(yesNoEnumValue) => {
                    if (
                      yesNoEnumValue !==
                      ToYesNoEnum(query.purchaseCancelledOnly)
                    ) {
                      setQuery({
                        ...query,
                        purchaseCancelledOnly: FromYesNoEnum(yesNoEnumValue),
                      });
                    }
                  }}
                  valueOptionsContent={YES_ENUM_FILTER_TO_CID}
                />
              ),
            },
          ]
        : []),
    ].filter((f) => f!) as FilterToolbarItem[],
  };
};
