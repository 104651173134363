import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventEntitySearchBox } from 'src/components/Accordions/EventEntitySearchBox';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultPurchaseQuery,
  getQueryFromUrl,
} from 'src/utils/eventQueryUtils';
import {
  combineWithTicketGroupIdSimple,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  splitOutTicketGroupId,
} from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  PurchaseClient,
  PurchaseOrder,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
} from 'src/WebApiController';

import { PurchaseOrderBasicInfo } from '../PurchaseOrderBasicInfo';

export const PurchaseSearchBox = ({
  isEventPage,
}: {
  isEventPage?: boolean;
}) => {
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const [searchText, setSearchText] = useState('');

  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();

  const [searchId, setSearchId] = useState<string>();
  const [searchResults, setSearchResults] = useState<PurchaseOrderDetails[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const { posEntity: purchase, setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const {
    filterQuery: purchaseQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
  } = useFilterQueryContext<PurchaseOrderQuery>();

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const onLookupById = useCallback(
    async (idText: string, isSearching: boolean) => {
      const { id, ticketGroupId } = splitOutTicketGroupId(idText);
      const idNum = Number(id);
      const idStr = String(id);
      if (
        !id &&
        tempQuery.marketplaceEntityIds &&
        hasFullPageEventViewEntitySelection
      ) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }

      if (id && !isLoadingSearch) {
        if (purchase?.vendOrdId === idStr || purchase?.id === idNum) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            setModal(
              getPurchaseOrderDetailsModalConfigWithDeepLink(
                combineWithTicketGroupIdSimple(purchase.id, ticketGroupId)
              )
            );
          }

          // Else, just don't do anything
          return;
        }

        if (hasFullPageEventViewEntitySelection) {
          const matchedIds = idStr
            .split(',')
            .filter((s) => s.trim())
            .map((s) => s.trim()); // split by commas and remove all empty ones

          if (matchedIds.length > 1) {
            const newQuery = {
              ...DefaultPurchaseQuery,
              marketplaceEntityIds: matchedIds,
              viewMode: PurchaseViewMode.FlattenedView,
            };
            setFilterQuery(newQuery);
            return;
          } else if (tempQuery.marketplaceEntityIds) {
            const newQuery = {
              ...tempQuery,
              marketplaceEntityIds: null,
            };

            setFilterQuery(newQuery);
          }
        }

        setSearchId(idStr);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the order id or unique id
          // since 99% of the time they'll be entering a order id, we try to lookup by that first
          const purchaseOrders = isSearching
            ? await tryInvokeApi(
                () =>
                  new PurchaseClient(
                    activeAccountWebClientConfig
                  ).getPurchaseOrdersByVendorOrderId(idStr),
                (error) => {
                  showErrorDialog(
                    'PurchaseClient.getPurchaseOrdersByVendorOrderId',
                    error,
                    { trackErrorData: { saleId: id } }
                  );
                }
              )
            : null;

          if (!purchaseOrders || purchaseOrders.length === 0) {
            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any POs by vendor-order-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true);
            setModal(
              getPurchaseOrderDetailsModalConfigWithDeepLink(
                combineWithTicketGroupIdSimple(idNum, ticketGroupId)
              )
            );

            return true;
          } else {
            // We have POs using the vendor order id
            // If there is duplicate vendor order id (which is rare)
            // we need to present to the user and ask them what they want
            if (purchaseOrders.length === 1) {
              const poInfo = purchaseOrders[0];
              // happy path
              setActivePosEntity(poInfo.id, poInfo.vendOrdId, true);
              setModal(
                getPurchaseOrderDetailsModalConfigWithDeepLink(
                  combineWithTicketGroupIdSimple(poInfo.id, ticketGroupId)
                )
              );

              return true;
            } else {
              setSearchResults(
                purchaseOrders.sort((a, b) => {
                  return a.poDate && b.poDate
                    ? a.poDate < b.poDate
                      ? 1
                      : -1
                    : 0;
                })
              );
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      hasFullPageEventViewEntitySelection,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      purchase?.id,
      purchase?.vendOrdId,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== purchaseQuery.searchText) {
        setFilterQuery({
          ...purchaseQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [purchaseQuery]
  );

  const onResultItemPicked = useCallback(
    (purchaseOrder: PurchaseOrder) => {
      setActivePosEntity(purchaseOrder.id, purchaseOrder.vendOrdId, true);
      setModal(
        getPurchaseOrderDetailsModalConfigWithDeepLink(purchaseOrder.id)
      );

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  const location = useLocation();

  const activeId = getDeepLinkIdFromUrl(
    PurchaseDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length) {
      onLookupById(activeId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    // Since we only support id searching in flattened view for Purchases, reset filters when view mode changes
    if (
      purchaseQuery.viewMode !== PurchaseViewMode.FlattenedView &&
      !isEventPage &&
      purchaseQuery.marketplaceEntityIds
    ) {
      setFilterQuery({
        ...purchaseQuery,
        marketplaceEntityIds: null,
        searchText: null,
      });
      setSearchText('');
    }
  }, [
    purchaseQuery.marketplaceEntityIds,
    purchaseQuery.viewMode,
    isEventPage,
    purchaseQuery,
    setFilterQuery,
    hasFullPageEventViewEntitySelection,
  ]);

  useEffect(() => {
    if (
      purchaseQuery.marketplaceEntityIds &&
      hasFullPageEventViewEntitySelection
    ) {
      return;
    }
    setSearchText(purchaseQuery.searchText ?? '');
  }, [
    hasFullPageEventViewEntitySelection,
    purchaseQuery.marketplaceEntityIds,
    purchaseQuery.searchText,
  ]);

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<PurchaseOrderQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setSearchText(() =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFullPageEventViewEntitySelection, initialQuery, setFilterQuery]);

  return (
    <>
      <EventEntitySearchBox
        placeholderContentId={
          isEventPage ? ContentId.LookupByOrderId : undefined
        }
        maxWidth={350}
        disabled={isLoadingSearch}
        onIdLookup={(value) => onLookupById(value, true)}
        onEventSearch={(value) => debouncedOnSearchChange(value)}
        searchText={searchText}
        setSearchText={setSearchText}
        clearSearchEntity={clearSearchEntity}
        disableSearchMode={isEventPage}
      />
      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindOrderId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(purchaseOrder: PurchaseOrderDetails) => (
          <PurchaseOrderBasicInfo purchaseOrder={purchaseOrder} />
        )}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
};
