import { useMemo } from 'react';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { SimpleTable } from 'src/core/ui';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { shimmerHeight } from 'src/modals/SaleDetails/components/Commissions/Commissions.constants';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { Commission } from 'src/WebApiController';

import * as styles from '../CommissionsTable.css';

interface CommissionsTableProps {
  commissions: Commission[];
  onEditAction: () => void;
}

export const CommissionsOverridesTable = ({
  commissions,
  onEditAction,
}: CommissionsTableProps) => {
  const usersIds = useMemo(
    () => commissions.map((commission) => commission.buyerUserId),
    [commissions]
  );

  const usersQuery = useGetUserInfos(usersIds);

  return (
    <div className={styles.tableWrapper}>
      <SimpleTable.Table className={styles.table}>
        <SimpleTable.Thead>
          <SimpleTable.Tr>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Commissioner} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Commission} />
            </SimpleTable.Th>
            <SimpleTable.Th className={styles.commissionsCell}>
              <Content id={ContentId.Actions} />
            </SimpleTable.Th>
          </SimpleTable.Tr>
        </SimpleTable.Thead>
        <SimpleTable.Tbody>
          {usersQuery.isLoading ? (
            <>
              {commissions.map(({ buyerUserId }) => (
                <SimpleTable.Tr key={buyerUserId}>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                  <SimpleTable.Td className={styles.commissionsCell}>
                    <TableShimmeringDiv height={shimmerHeight} />
                  </SimpleTable.Td>
                </SimpleTable.Tr>
              ))}
            </>
          ) : (
            <>
              {commissions.map(
                ({ commissionPercentage, buyerUserId }, index) => (
                  <SimpleTable.Tr key={buyerUserId}>
                    <SimpleTable.Td className={styles.commissionsCell}>
                      {usersQuery.data?.[buyerUserId]?.name}
                    </SimpleTable.Td>
                    <SimpleTable.Td className={styles.commissionsCell}>
                      {(commissionPercentage * 100).toFixed(0)}%
                    </SimpleTable.Td>
                    <SimpleTable.Td className={styles.commissionsCell}>
                      {index === 0 && (
                        <IconButton
                          onClick={onEditAction}
                          icon={
                            <EditIcon
                              withHoverEffect
                              fill={IconsFill.currentColor}
                            />
                          }
                          titleContentId={ContentId.Edit}
                        />
                      )}
                    </SimpleTable.Td>
                  </SimpleTable.Tr>
                )
              )}
            </>
          )}
        </SimpleTable.Tbody>
      </SimpleTable.Table>
    </div>
  );
};
